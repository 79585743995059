import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../config/config.js";
var $ = require("jquery");
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var Highlight = require("react-highlighter");

class ModalOpenDeliveryNote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.modal,
      modalDelete: false,
      backdrop: true,
      bvId: this.props.bvId,
      notesDate: this.props.notesDate,
      notesNr: this.props.notesNr,
      supplier: this.props.supplier,
      supplierId: this.props.supplierId,
      bv: this.props.bv,
      showUpdate: true,
      showDelete: false,
      hideNotes: false,
      articles: this.props.articles,
      faHide: "fa-hide",
      articles: this.props.articles,
      bvAll: this.props.bvAll,
      billsNr: this.props.billsNr,
    };
  }
  componentDidMount = () => {
    this.readBvNameById(this.props.bvId, true);
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        modal: false,
      });
    } else {
      this.setState({
        modal: !this.state.modal,
      });
    }
  };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false,
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true,
      });
    }
  };

  updateNote = (e) => {
    let createDeliveryNote;
    let array = [];
     this.state.articles.map((items, idx) => {
      createDeliveryNote = {
        id: "0",
        billsNr: this.state.billsNr,
        notesDate: this.state.notesDate,
        notesNr: this.state.notesNr.trim(),
        supplierId: this.state.supplierId,
        bvId: this.state.bvId,
        itemId: items.itemId,
        itemDsc: items.itemDsc,
        itemAmnt: items.itemAmnt.replace(/,/g, "."),
        itemUnit: items.itemUnit,
      };
       array = [...array, createDeliveryNote];
     });
    $.ajax({
      url: BaseUrl + "api/deliveryNotesBills/createDeliveryNote.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(array),
      success: function (response) {}.bind(this),
      error: function (xhr, resp, text) {},
    });
    this.props.removeBillFromList(this.state.billsNr, this.state.notesNr);
    this.setState({
      modal: !this.state.modal,
    });

    e.preventDefault();
  };

  readBvNameById = (id, bool) => {
    let found = this.state.bvAll.find((name) => name.bvId === parseInt(id));
    if (found === undefined) {
      setTimeout(() => {
        this.setState({
          bv: this.state.bvAll,
        });
      }, 200);
    }
    if (bool === true && found !== undefined) {
      return this.setState({
        bv: found.bv,
      });
    } else if (bool === false && found !== undefined) {
      return found.bv;
    }
  };

  autocomplete = (e) => {
    if (e.keyCode === 9 && e.target.name === "bvId") {
      this.readBvNameById(e.target.value, true);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          key={this.props.id}
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <div className="modal-header">
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <p>
                Lieferschein aus Rechnung erstellen?
                <br />
              </p>
            </div>

            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <form className="row">
                <div className="col-md-2">
                  <p>Datum</p>

                  <input
                    disabled
                    type="date"
                    name="notesDate"
                    placeholder="Datum"
                    min="2017-01-01"
                    onChange={this.handleChange}
                    value={this.state.notesDate}
                  />
                </div>
                <div className="col-md-2">
                  <p>LieferscheinNr</p>

                  <input
                    disabled
                    type="text"
                    name="notesNr"
                    placeholder="LieferscheinNr."
                    onChange={this.handleChange}
                    value={this.state.notesNr}
                  />
                </div>
                <div className="col-md-2">
                  <p>Lieferanten-Nr</p>

                  <input
                    disabled
                    type="text"
                    name="supplierId"
                    placeholder="Lieferant Nr"
                    onChange={this.handleChange}
                    value={this.state.supplierId}
                  />
                </div>
                <div className="col-md-2">
                  <p>Lieferant</p>
                  <input
                    disabled
                    name="supplier"
                    value={this.state.supplier}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="col-md-2 tab">
                  <p>BV-Nr</p>
                  <input
                    disabled
                    name="bvId"
                    placeholder="BV-Nr"
                    value={this.state.bvId}
                  />
                  <span>↹</span>
                </div>
                <div className="col-md-2">
                  <p>BV</p>
                  <select disabled name="bv" value={this.state.bv}>
                    <option key="-1" value="-">
                      -
                    </option>
                    {this.state.bvAll.map((bv, i) => (
                      <option key={i} value={bv.bv}>
                        {bv.bv}
                      </option>
                    ))}
                  </select>
                </div>
              </form>

              <form className="row">
                {this.state.articles.map((i, idx) => {
                  return (
                    <div key={idx} className={"row  "}>
                      <div className="col-md-2">
                        <p>ArtikelNr</p>
                        <input
                          disabled
                          type="text"
                          name="itemId"
                          placeholder="ArtikelNr"
                          value={i.itemId}
                        />
                      </div>
                      <div className="col-md-6">
                        <p>Artikelbezeichnung</p>
                        <input
                          disabled
                          type="text"
                          name="itemDsc"
                          placeholder="Artikelbezeichnung"
                          value={i.itemDsc}
                        />
                      </div>
                      <div className="col-md-2">
                        <p>Menge</p>
                        <input
                          disabled
                          type="text"
                          name="itemAmnt"
                          placeholder="Menge"
                          value={i.itemAmnt}
                        />
                      </div>
                      <div className="col-md-2">
                        <p>Einheit</p>
                        <select disabled name="itemUnit" value={i.itemUnit}>
                          <option key="-1" value="-">
                            -
                          </option>
                          <option key={1} value="Beutel">
                            Beutel
                          </option>
                          <option key={2} value="Bund">
                            Bund
                          </option>
                          <option key={3} value="Dose(n)">
                            Dose(n)
                          </option>
                          <option key={4} value="Eimer">
                            Eimer
                          </option>
                          <option key={5} value="Flasche(n)">
                            Flasche(n)
                          </option>
                          <option key={6} value="Gebinde">
                            Gebinde
                          </option>
                          <option key={7} value="Gramm">
                            Gramm
                          </option>
                          <option key={8} value="Karton">
                            Karton
                          </option>
                          <option key={9} value="KG">
                            KG
                          </option>
                          <option key={10} value="Kübel">
                            Kübel
                          </option>
                          <option key={11} value="LE">
                            LE
                          </option>
                          <option key={12} value="Lfm.">
                            Lfm.
                          </option>
                          <option key={13} value="Liter">
                            Liter
                          </option>
                          <option key={14} value="m2">
                            m2
                          </option>
                          <option key={15} value="m3">
                            m3
                          </option>
                          <option key={16} value="Minuten">
                            Minuten
                          </option>
                          <option key={17} value="Paar">
                            Paar
                          </option>
                          <option key={18} value="Pack">
                            Pack
                          </option>
                          <option key={19} value="Paket">
                            Paket
                          </option>
                          <option key={20} value="Palette(n)">
                            Palette(n)
                          </option>
                          <option key={21} value="Pauschal">
                            Pauschal
                          </option>
                          <option key={22} value="Rolle(n)">
                            Rolle(n)
                          </option>
                          <option key={23} value="Sack">
                            Sack
                          </option>
                          <option key={24} value="Satz">
                            Satz
                          </option>
                          <option key={25} value="Set">
                            Set
                          </option>
                          <option key={26} value="Stück">
                            Stück
                          </option>
                          <option key={27} value="Stunden">
                            Stunden
                          </option>
                          <option key={28} value="Tag/e">
                            Tag/e
                          </option>
                          <option key={29} value="Tonne(n)">
                            Tonne(n)
                          </option>
                        </select>
                      </div>
                    </div>
                  );
                })}
              </form>

              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggle} className="btn-red">
                    Abbrechen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.updateNote} className="btn-green">
                    Erstellen <FontAwesomeIcon icon={["far", "edit"]} />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalOpenDeliveryNote;
