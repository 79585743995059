import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { PostData } from "./postdata";
import "../css/bootstrap-grid.css";
import "../css/App.css";
import "../css/index.css";
import "../css/mediaQuerys.css";
 
class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      redirectToReferrer: false,
      error: false
    };
  }

  login = () => {
    if (this.state.username && this.state.password) {
      PostData("login", this.state).then(result => {
        let responseJson = result;
        if (responseJson.userData) {
          sessionStorage.setItem("userData", JSON.stringify(responseJson));
          this.setState({ redirectToReferrer: true });
        } else {
          this.setState({
            error: true
          });
        }
      });
    }
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  enter = e => {
    e.preventDefault();
    if (e.key === "enter") {
      this.login();
    }
  };
  render() {
    if (this.state.redirectToReferrer || sessionStorage.getItem("userData")) {
      return <Redirect to={"/home"}/>
     }

    return (
      <section className="section-start-login">
        <img src={require("../img/logo.png")} alt="" />
        <h1>Kosten- und Leistungsrechnung</h1>
        <div className="row">
          <div className="col-4 offset-4 center">
            <form onSubmit={this.enter}>
 
              <div>
              <p>Benutzername</p>
              <input
                required
                type="text"
                name="username"
                onChange={this.onChange}
              />
              </div>
              <div>
              <p>Passwort</p>
              <input
                required
                type="password"
                name="password"
                onChange={this.onChange}
              />
              </div>
              <span className={this.state.error ? "show error" : "hide"}>
                Benutzername oder Passwort ist falsch
              </span>
              <input
                id="login-button"
                type="submit"
                value="Einloggen"
                onClick={this.login}
              />
            </form>
          </div>
        </div>
      </section>
    );
  }
}
export default Login;
