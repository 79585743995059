import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import BaseUrl from "../../config/config.js";
import OfferbookList from "./OfferbookList.js";
import { min } from "moment";
 var Highlight = require("react-highlighter");
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class Offerbook extends Component {
  constructor() {
    super();
    this.state = {
      offerNr: "",
      version: "",
      date: "",
      customerId: "",
      customer: "",
      bv: "",
      buildingStart: "",
      buildingEnd: "",
      offerAll: [],
      offerAllCopy: [],
      errorSearch: false,
      search: "",
      useSearch: false,
      customerAll:[],
      angebotsstatus: "",
     };
  }

  componentDidMount = () => {
     Promise.all([
      this.readCustomersNames()
    ]).then(() => {
      this.loadOffer();
    });
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true
      });
    }

  };
  handleChange = e => {
 
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handelChangeStatus = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    this.loadOfferPerStatus( e.target.value)
  }
  readCustomersNames = () => {
    return fetch(BaseUrl + "api/all/readCustomerDataAll.php")
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          customerAll: responseJson.records[0]
        });
      })
      .catch(error => {});
  };
  loadOffer = () => {
    return fetch(BaseUrl + "api/offer/readOfferInfo.php")
      .then(response => response.json())
      .then(responseJson => {
         this.setState({
            offerAll: responseJson.records[0],
            offerAllCopy: responseJson.records[0]
          });
 
      })
      .catch(error => {});
  };
  loadOfferPerStatus = ( status ) => {
      return fetch(BaseUrl + "api/offer/readOfferInfo.php?status=" + status)
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          offerAll: []
        })
         this.setState({
            offerAll: responseJson.records[0],
          });
 
      })
      .catch(error => {});
  };
  readCustomerNameById = id => {
    let found = this.state.customerAll.find(name => name.customerId === id);
    if (found === undefined) {
      return "";
    }
    else {
        return found.customerName
    }
  };
  onKeyPress = e => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };
  handleSearch = e => {
    this.setState({
      search: e.target.value
    });

    if (e.target.value === "") {
      this.setState({
        offerAll: this.state.offerAllCopy,
        errorSearch: false
      });
    }
  };

  search = eingabe => {
    let convertEingabe = eingabe.replace(/ /g, "%").replace("+", "%2B");

    if (eingabe === "") {
      this.setState({
        offerAll: this.state.offerAllCopy,
        errorSearch: false
      });
    } else {
      this.setState({
        offerAll: []
      });
      return fetch(
        BaseUrl + "api/offer/searchOffer.php?eingabe=" + convertEingabe
      )
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.message === "No items found.") {
            this.setState({
              offerAll: [],
              errorSearch: true
            });
          } else {
            this.setState({
              errorSearch: false,
              offerAll: responseJson.records[0]
            });
          }
        })
        .catch(error => {});
    }
  };
  convertDate = date => {
    if (date === undefined) {
      return date;
    } else {
      if (date.includes("-")) {
        var dateNew = date.split("-");
        dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
        return dateNew;
      } else {
        return date;
      }
    }
  };
 
  render() {
    return (
      <React.Fragment>
        <section id="offerbook">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home/angebot">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--yellow)" icon="list-ol" />{" "}
                Angebotsliste
              </h1>
            </div>
          </div>
        </section>
        <section id="showItems" className="display-item">
          <h4>Übersicht Angebote</h4>
          <div className="offer-show-items row">
            <div className="col-6 offset-3">
              <input
                className="search"
                type="text"
                name="search"
                placeholder="Suchen"
                onChange={this.handleSearch}
                onKeyPress={this.onKeyPress}
                value={this.state.search}
              />
            </div>
            <div className="offset-1 col-2 absolute-span">
            <p  >
              <span>Filtern n. Status</span>
              <select
                name="angebotsstatus"
                onChange={this.handelChangeStatus}
                value={this.state.angebotsstatus}
               >
                                 <option key="-1" value="Alle">
                  Alle
                </option>
                <option key="0" value="Offen">
                  Offen
                </option>
                <option key="1" value="Erhalten">
                  Erhalten
                </option>
                <option key="2" value="Verloren">
                  Verloren
                </option>
              </select>
 
             </p>
               
 
          </div>
          </div>
 
          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          <ul className="items-list offers-list">

            {this.state.offerAll.map((item, idx) => {
                 return(
                 
 
                  <OfferbookList
                    offerNr={item.offerNr}
                    version={item.version}
                    date={this.convertDate(item.Date)}
                    customerId={item.customerId}
                    customerName= {this.readCustomerNameById(item.customerId)}
                    bv= {item.bv}
                    buildingStart={this.convertDate(item.buildingStart)}
                    buildingEnd={this.convertDate(item.buildingEnd)}
                    angebotsstatus={item.angebotsstatus}
                    key={idx}
                    search={this.state.search}
                    hideStatus={item.version === item.maxVersion ? true : false}
                   />
                )
            })}

          </ul>
        </section>
      </React.Fragment>
    );
  }
}

export default Offerbook;
