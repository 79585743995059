import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

 import BaseUrl from "../config/config.js";
class DirectionWorkListInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bv: "",
      bvId: "",
      hideSpan: true,
      hideLists: true,
      dateFrom: "",
      dateTo: "",
      allYears: [],
      year: new Date().getFullYear(),
      disabledSearch: true
    };
  }

  componentDidMount = () => {
    this.loadYears();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.reset !== this.props.reset) {
      this.setState({
        bvId: "",
        bv: "",
        dateFrom: "",
        dateTo: ""
      });
    }
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if (e.target.name === "bv") {
      this.readBvIdByName(e.target.value, true);
    }

    if (
      (e.target.name === "dateTo" &&
        e.target.value !== "" &&
        this.state.dateFrom !== "") ||
      (e.target.name === "dateFrom" &&
        e.target.value !== "" &&
        this.state.dateTo !== "")
    ) {
      this.setState({
        disabledSearch: false
      });
    }
    if (
      (e.target.name === "dateTo" &&
        e.target.value === "" &&
        this.state.dateFrom === "") ||
      (e.target.name === "dateFrom" &&
        e.target.value === "" &&
        this.state.dateTo === "")
    ) {
      this.setState({
        disabledSearch: true
      });
    }
    if (
      (e.target.name === "dateTo" &&
        e.target.value === "" &&
        this.state.dateFrom !== "") ||
      (e.target.name === "dateFrom" &&
        e.target.value === "" &&
        this.state.dateTo !== "")
    ) {
      this.setState({
        disabledSearch: true
      });
    }
  };

  autocompleteBv = e => {
    if (e.keyCode === 9) {
      this.readBvNameById(e.target.value, true);
    }
  };
  readBvNameById = (id, bool) => {
    let found = this.props.bvAll.find(name => name.bvId === parseInt(id));
    if (found === undefined) {
      return "";
    }
    if (bool === true && found !== undefined) {
      return this.setState({
        bv: found.bv
      });
    } else if (bool === false && found !== undefined) {
      return found.bv;
    }
  };
  readBvIdByName = (name, bool) => {
     let found = this.props.bvAll.find(id => id.bv === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        bvId: found.bvId
      });
    } else {
      return found.bvId;
    }
  };
  readAllInfosPropsOnKeyPress = e => {
    e.preventDefault();
    if (e.key === "Enter") {
      this.props.readAllInfos(
        this.state.bvId,
        this.state.dateFrom,
        this.state.dateTo,
        this.state.year
      );
    }
  };
  readAllInfosProps = () => {
    this.props.readAllInfos(
      this.state.bvId,
      this.state.dateFrom,
      this.state.dateTo,
      this.state.year
    );
  };
  loadYears = () => {
    this.state.allYears = [
      new Date().getFullYear() - 2,
      new Date().getFullYear() - 1,
      new Date().getFullYear(),
      new Date().getFullYear() + 1,
      new Date().getFullYear() + 2
    ];
  };
  render() {
    return (
      <React.Fragment>
        <div id="directionWorkList" className="display-item content  ">
          <div className="row">
            <div className="col-md-12">
              <h3>Abfragedaten Arbeitsstunden: </h3>
            </div>
          </div>
          <div className="row directionWorkListInput">
            <div className="col-2 tab">
              <p>Bv-Nr</p>
              <input
                required
                type="text"
                name="bvId"
                onChange={this.handleChange}
                value={this.state.bvId}
                onKeyDown={this.autocompleteBv}
              />
              <span>↹</span>
            </div>
            <div className="col-6 input">
              <p>BV</p>
              <select
                name="bv"
                value={this.state.bv}
                onChange={this.handleChange}
              >
                <option key="-1" value="-">
                  -
                </option>
                {this.props.bvAll.map((bv, i) => (
                  <option key={i} value={bv.bv}>
                    {bv.bv}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-2 tab input">
              <p>Von</p>
              <input
                required
                type="date"
                name="dateFrom"
                onChange={this.handleChange}
                value={this.state.dateFrom}
              />
            </div>
            <div className=" col-2 tab input">
              <p>Bis</p>
              <input
                required
                type="date"
                name="dateTo"
                onChange={this.handleChange}
                value={this.state.dateTo}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h3>Abfragedaten Lieferscheine/Rechnungen: </h3>
            </div>
          </div>
          <div className="row directionWorkListInput">
            <div className="col-2 input">
              <p>Jahr</p>

              <select
                name="year"
                value={this.state.year}
                onChange={this.handleChange}
              >
                {this.state.allYears.map((year, i) => (
                  <option key={i} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div className="offset-8 col-2 input">
              <button
                disabled={this.state.disabledSearch}
                type="button"
                className="btn-add-item"
                onClick={this.readAllInfosProps}
                onKeyPress={this.readAllInfosPropsOnKeyPress}
              >
                <FontAwesomeIcon icon="search" />
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default DirectionWorkListInput;
