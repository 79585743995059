import React, { Component } from "react";
import "../../css/bootstrap-grid.css";
import "../../css/App.css";
import "../../css/index.css";
import "../../css/mediaQuerys.css";
import BaseUrl from "../../config/config.js";
import "@fortawesome/fontawesome-free-solid";

var $ = require("jquery");

class DeliveryNotesListReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      date: this.props.date,
      notesNr: this.props.notesNr,
      supplier: this.props.supplier,
      supplierId: this.props.supplierId,
      bv: this.props.bv,
      bvId: this.props.bvId,
      articles: this.props.articles,
      save: this.props.save,
      reportNr: this.props.reportNr,
      priceComplete: 0,
      priceNoteCoplete: 0,
    };
  }
  componentDidMount = () => {
    this.completePricePerNote("static");
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.save !== this.props.save) {
      this.updateNotes();
      this.completePricePerNote("change");
    }
    if (prevProps.dataRefreshedNotes !== this.props.dataRefreshedNotes) {
      const timerId = setTimeout(() => {
        this.props.getChangedDataNotes(this.state.articles, this.state.notesNr);
      }, 750);
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "bv") {
      this.readBVId(e.target.value);
    }
    if (e.target.name === "supplier") {
      this.readSupplierId(e.target.value);
    }
    if (this.props.print) {
      this.props.resetButton();
    }
  };

  handleChangeArticles = (idx) => (evt) => {
    const newArticleChange = this.state.articles.map((item, sidx) => {
      if (idx !== sidx) return item;
      if (evt.target.name === "pricePiece" || evt.target.name === "itemAmnt") {
        return {
          ...item,
          [evt.target.name]: evt.target.value.replace(",", "."),
        };
      } else {
        return { ...item, [evt.target.name]: evt.target.value };
      }
    });
    if (this.props.print) {
      this.props.resetButton();
    }
    Promise.all([this.setState({ articles: newArticleChange })]).then(
      (values) => {
        this.completePricePerNote("change");
      }
    );
  };

  handleAddDeliveryNote = () => {
    this.setState({
      articles: this.state.articles.concat([
        {
          itemId: "",
          itemDsc: "",
          itemAmnt: "",
          itemUnit: "",
          pricePiece: "",
        },
      ]),
    });
  };

  handleRemoveDeliveryItem = (idx) => () => {
    this.setState({
      articles: this.state.articles.filter((s, sidx) => idx !== sidx),
    });
    this.removeNotes(this.state.articles[idx].id);
  };

  autocompleteTab = (idx) => (e) => {
    if (e.keyCode === 9) {
      this.getDscUnit(idx, e);
    }
  };
  getDscUnit = (idx, evt) => {
    const convertInput = evt.target.value.replace(/ /g, " ");
    return fetch(
      BaseUrl + "api/deliveryNote/readItemDesc.php?eingabe=" + convertInput
    )
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          return this.setState({
            itemDsc: item.dsc,
            itemUnit: item.unit,
          });
        });

        const newItemAutocomplete = this.state.articles.map((item, sidx) => {
          if (idx !== sidx) return item;

          return {
            ...item,
            itemId: item.itemId,
            itemDsc: this.state.itemDsc,
            itemUnit: this.state.itemUnit,
          };
        });
        this.setState({ articles: newItemAutocomplete });
      })
      .catch((error) => {});
  };
  autocompleteBvSupplier = (e) => {
    if (e.keyCode === 9 && e.target.name === "supplierId") {
      this.readSupplierNameById(e.target.value);
    }
  };
  readSupplierNameById = (id) => {
    let found = this.props.supplierAll.find(
      (name) => name.supplierId === parseInt(id)
    );
    if (found === undefined) {
      return "";
    } else {
      this.setState({
        supplier: found.supplierName,
      });
      return found.supplierName;
    }
  };

  updateNotes = () => {
    let updateDeliveryNotes;
    this.state.articles.map((items, idx) => {
      updateDeliveryNotes = {
        id: items.id === undefined || items.id === "" ? "0" : items.id,
        directionWorkNr: this.state.reportNr,
        date: this.state.date,
        notesNr: this.state.notesNr.trim(),
        supplierId: this.state.supplierId,
        itemId: items.itemId,
        itemDsc: items.itemDsc,
        itemAmnt: items.itemAmnt.replace(/,/g, "."),
        itemUnit: items.itemUnit,
        pricePiece: items.pricePiece,
        bvId: this.state.bvId,
      };

      $.ajax({
        url: BaseUrl + "api/directionWork/updateDeliveryNotes.php",
        type: "POST",
        dataType: "text",
        data: JSON.stringify(updateDeliveryNotes),
        success: function (response) {
          this.setState({
            notesNrOld: this.state.notesNr,
          });
        }.bind(this),
        error: function (xhr, resp, text) {},
      });
      return this;
    });
  };
  removeNotes = (idx) => {
    var deleteItem = {
      id: idx,
    };

    $.ajax({
      url: BaseUrl + "api/directionWork/deleteNotesForReport.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function (response) {
        this.completePricePerNote("change");
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  convertDate = (date) => {
    if (date === undefined) {
      return date;
    } else {
      if (date.includes("-")) {
        var dateNew = date.split("-");
        dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
        return dateNew;
      } else {
        return date;
      }
    }
  };
  itemPriceComplete = (amnt, pricePiece) => {
    let complete = amnt * pricePiece;
    return complete === 0
      ? 0.0
      : complete.toLocaleString("de", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
  };
  completePricePerNote = (what) => {
    let calcComplete = 0;
    this.state.articles.map((item) => {
      calcComplete += item.itemAmnt * item.pricePiece;
    });
    this.props.calcPriceComplete(what, this.state.notesNr, calcComplete);
    this.setState({
      priceNoteCoplete: calcComplete.toLocaleString("de", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    });
  };
  render() {
    return (
      <React.Fragment>
        <div className="no-print" key={this.state.id}>
          <div className="DeliveryNotesListReport">
            <ul>
              <span className="row list-header show0">
                <p className="col-2">
                  <span>Datum:</span>
                  <input
                    type="date"
                    name="date"
                    placeholder="Datum"
                    onChange={this.handleChange}
                    value={this.state.date}
                  />
                </p>
                <p className="col">
                  <span>LieferscheinNr:</span>
                  <input
                    type="text"
                    name="notesNr"
                    placeholder="LieferscheinNr"
                    onChange={this.handleChange}
                    value={this.state.notesNr}
                  />
                </p>
                <p className="col">
                  <span>Lieferanten Nr:</span>
                  <input
                    type="text"
                    name="supplierId"
                    placeholder="LieferantenNr"
                    onChange={this.handleChange}
                    value={this.state.supplierId}
                    onKeyDown={this.autocompleteBvSupplier}
                  />
                  <span id="span-icon">↹</span>
                </p>

                <p className="col">
                  <span>Lieferant:</span>
                  <input
                    type="text"
                    name="supplier"
                    placeholder="Lieferant"
                    onChange={this.handleChange}
                    value={this.state.supplier}
                  />
                </p>
                <p className="col-1">
                  <span>BvNr:</span>
                  <input
                    disabled
                    type="text"
                    name="bvId"
                    placeholder="BvNr"
                    onChange={this.handleChange}
                    value={this.state.bvId}
                    onKeyDown={this.autocompleteBvSupplier}
                  />
                </p>
                <p className="col-3">
                  <span>Bv:</span>
                  <input
                    disabled
                    type="text"
                    name="bv"
                    placeholder="Bv"
                    onChange={this.handleChange}
                    value={this.state.bv}
                  />
                </p>
              </span>
              {this.state.articles.map((i, idx) => {
                return (
                  <div
                    className={this.state.hideArticle ? "hide " : ""}
                    key={idx}
                  >
                    <span
                      className={`row col-width fullwidth hidetext show${idx}`}
                    >
                      <p className="col-2 artNr">
                        <span>Artikelnummer</span>
                        <input
                          type="text"
                          name="itemId"
                          placeholder={`Artikelnummer #${idx + 1}`}
                          onChange={this.handleChangeArticles(idx)}
                          value={i.itemId}
                          onKeyDown={this.autocompleteTab(idx)}
                        />
                        <span id="span-icon">↹</span>
                      </p>
                      <p className="col beschreibung">
                        <span>Artikelbeschreibung:</span>
                        <input
                          type="text"
                          name="itemDsc"
                          placeholder={`Artikelbeschreibung #${idx + 1}`}
                          onChange={this.handleChangeArticles(idx)}
                          value={i.itemDsc}
                        />
                      </p>
                      <p className="col menge">
                        <span>Menge:</span>
                        <input
                          type="text"
                          name="itemAmnt"
                          placeholder={`Menge #${idx + 1}`}
                          onChange={this.handleChangeArticles(idx)}
                          value={i.itemAmnt}
                        />
                      </p>
                      <p className="col einheit">
                        <span>Einheit:</span>
                        <select
                          name="itemUnit"
                          onChange={this.handleChangeArticles(idx)}
                          value={i.itemUnit}
                        >
                          <option key="-1" value="-">
                            -
                          </option>
                          <option key={1} value="Beutel">
                            Beutel
                          </option>
                          <option key={2} value="Bund">
                            Bund
                          </option>
                          <option key={3} value="Dose(n)">
                            Dose(n)
                          </option>
                          <option key={4} value="Eimer">
                            Eimer
                          </option>
                          <option key={5} value="Flasche(n)">
                            Flasche(n)
                          </option>
                          <option key={6} value="Gebinde">
                            Gebinde
                          </option>
                          <option key={7} value="Gramm">
                            Gramm
                          </option>
                          <option key={8} value="Karton">
                            Karton
                          </option>
                          <option key={9} value="KG">
                            KG
                          </option>
                          <option key={10} value="Kübel">
                            Kübel
                          </option>
                          <option key={11} value="LE">
                            LE
                          </option>
                          <option key={12} value="Lfm.">
                            Lfm.
                          </option>
                          <option key={13} value="Liter">
                            Liter
                          </option>
                          <option key={14} value="m2">
                            m2
                          </option>
                          <option key={15} value="m3">
                            m3
                          </option>
                          <option key={16} value="Minuten">
                            Minuten
                          </option>
                          <option key={17} value="Paar">
                            Paar
                          </option>
                          <option key={18} value="Pack">
                            Pack
                          </option>
                          <option key={19} value="Paket">
                            Paket
                          </option>
                          <option key={20} value="Palette(n)">
                            Palette(n)
                          </option>
                          <option key={21} value="Pauschal">
                            Pauschal
                          </option>
                          <option key={22} value="Rolle(n)">
                            Rolle(n)
                          </option>
                          <option key={23} value="Sack">
                            Sack
                          </option>
                          <option key={24} value="Satz">
                            Satz
                          </option>
                          <option key={25} value="Set">
                            Set
                          </option>
                          <option key={26} value="Stück">
                            Stück
                          </option>
                          <option key={27} value="Stunden">
                            Stunden
                          </option>
                          <option key={28} value="Tag/e">
                            Tag/e
                          </option>
                          <option key={29} value="Tonne(n)">
                            Tonne(n)
                          </option>
                        </select>
                      </p>
                      <p className="col preis-stk">
                        <span>Preis/Stk:</span>
                        <input
                          type="text"
                          name="pricePiece"
                          placeholder={`Preis/Stk #${idx + 1}`}
                          onChange={this.handleChangeArticles(idx)}
                          value={i.pricePiece}
                        />
                      </p>
                      <p className="col-2 preis-gesamt">
                        <span>Gesamt:</span>
                        <input
                          type="text"
                          name="complete"
                          placeholder={`Gesamtpreis #${idx + 1}`}
                          onChange={this.handleChangeArticles(idx)}
                          value={this.itemPriceComplete(
                            i.pricePiece,
                            i.itemAmnt
                          )}
                        />
                      </p>
                      <p className="col btn-add">
                        <span>&nbsp;</span>
                        <button
                          type="button"
                          onClick={this.handleRemoveDeliveryItem(idx)}
                          className="btn-remove"
                        >
                          -
                        </button>
                      </p>
                    </span>
                  </div>
                );
              })}
              <div className="row col-width justify-end-center">
                <p className="col text-right">
                  Gesamt: {this.state.priceNoteCoplete} €
                </p>
                <p className=" col btn-add">
                  <button
                    type="button"
                    onClick={this.handleAddDeliveryNote}
                    className="btn-add-item"
                  >
                    +
                  </button>
                </p>
              </div>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default DeliveryNotesListReport;
