import React, { Component } from "react";
import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../config/config.js";
var $ = require("jquery");
var moment = require("moment");
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ModalSelectedBills extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBillsModal: this.props.selectedBillsModal,
      selectedBills: this.props.selectedBills,
      creatingProgress: false,
    };

    this.isMountedFlag = false; // Add a flag to track component mount state
  }

  componentDidMount() {
    this.isMountedFlag = true; // Set flag to true when the component is mounted
  }

  componentWillUnmount() {
    this.isMountedFlag = false; // Reset flag when the component is unmounted
  }

  handleClose = () => {
    this.props.openSelectedBillsModal();
  };

  toggle = () => {
    this.props.openSelectedBillsModal();
  };

  updateNote = (e) => {
    this.setState({
      creatingProgress: true,
    });

    $.ajax({
      url: BaseUrl + "api/deliveryNotesBills/createMultipleDeliveryNotes.php",
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(this.props.selectedBills),
      success: function (response) {
        Promise.all([
          this.props.removeMultipleBillsFromList(this.props.selectedBills),
        ]).then((value) => {
          if (this.isMountedFlag) {
            // Only update state if the component is still mounted
            this.props.openSelectedBillsModal();
            this.props.resetSelectedBills();
            this.setState({
              creatingProgress: false,
            });
          }
        });
      }.bind(this),
      error: function (xhr, resp, text) {
        if (this.isMountedFlag) {
          this.setState({ creatingProgress: false });
        }
      }.bind(this),
    });

    e.preventDefault();
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          className="dif-span"
          isOpen={this.props.selectedBillsModal}
          toggle={this.toggle}
        >
          <div className="modal-header">
            <div>
              <p>
                Lieferscheine aus Rechnung erstellen?
                <br />
              </p>
            </div>

            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div className={this.state.creatingProgress ? "hide" : ""}>
              <div className="row">
                <div className="col">
                  <p>RechnungsNr</p>
                </div>
                <div className="col-1">
                  <p>Lieferant ID</p>
                </div>
                <div className="col">
                  <p>Lieferant</p>
                </div>
                <div className="col">
                  <p>Datum</p>
                </div>
                <div className="col">
                  <p>LieferscheinNr</p>
                </div>
              </div>
              <div>
                {this.state.selectedBills.map((i, idx) => {
                  return (
                    <div className="row" key={idx}>
                      <div className="col">
                        <span>{i.billsNr}</span>
                      </div>
                      <div className="col-1">
                        <span>{i.supplierId}</span>
                      </div>
                      <div className="col">
                        <span>{i.supplier}</span>
                      </div>
                      <div className="col">
                        <span> {moment(i.date).format("DD.MM.YYYY")}</span>
                      </div>
                      <div className="col">
                        <span>{i.notesNr}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className={
                this.state.creatingProgress
                  ? "show text-center m-b-50"
                  : "hide"
              }
            >
              <div className="lds-roller">
                <div></div>
              </div>
            </div>
            <div className=" btn-modal row">
              <div className="col">
                <button onClick={this.toggle} className="btn-red">
                  Abbrechen <FontAwesomeIcon icon="trash" />
                </button>
              </div>
              <div className="col">
                <button onClick={this.updateNote} className="btn-green">
                  Erstellen <FontAwesomeIcon icon={["far", "edit"]} />
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalSelectedBills;
