import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../config/config.js";
var $ = require("jquery");
var Highlight = require("react-highlighter");

class ModalDeliveryNotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      id: this.props.id,
      date: this.props.date,
      notesNr: this.props.notesNr,
      notesNrOld: this.props.notesNrOld,
      supplier: this.props.supplier,
      supplierId: this.props.supplierId,
      bv: this.props.bv,
      bvId: this.props.bvId,
      showUpdate: true,
      showDelete: false,
      hideNotes: false,
      hideArticle: false,
      articles: this.props.articles,
      idxCopy: this.props.idxCopy,
      faHide: "fa-hide"
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        modal: false
      });
    } else {
      this.setState({
        modal: !this.state.modal
      });
    }
  };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

    if (e.target.name === "bv") {
      this.readBvIdByName(e.target.value, true);
    }
    if (e.target.name === "supplier") {
      this.readSupplierIdByName(e.target.value, true);
    }
  };
  handleChangeCheck = e => {
    this.setState({
      done: !this.state.done
    });
  };
  updateNote = e => {
    let updateDeliveryNotes;
    this.state.articles.map((items, idx) => {
      if (items.id === undefined) {
         updateDeliveryNotes = {
          id: "0",
          date: this.state.date,
          notesNr: this.state.notesNr.trim(),
          notesNrOld: this.state.notesNrOld.trim(),
          supplierId: this.state.supplierId,
          itemId: items.itemId,
          itemDsc: items.itemDsc,
          itemAmnt: items.itemAmnt.replace(/,/g, "."),
          itemUnit: items.itemUnit,
          bvId: this.state.bvId
        };
      } else {
         updateDeliveryNotes = {
          id: items.id,
          date: this.state.date,
          notesNr: this.state.notesNr.trim(),
          notesNrOld: this.state.notesNrOld.trim(),
          supplierId: this.state.supplierId,
          itemId: items.itemId,
          itemDsc: items.itemDsc,
          itemAmnt: items.itemAmnt.replace(/,/g, "."),
          itemUnit: items.itemUnit,
          bvId: this.state.bvId
        };
      }
      $.ajax({
        url: BaseUrl + "api/deliveryNote/updateNote.php",
        type: "POST",
        dataType: "text",
        data: JSON.stringify(updateDeliveryNotes),
        success: function(response) {
          this.setState({
            notesNrOld: this.state.notesNr,
            modal: false
          });
        }.bind(this),
        error: function(xhr, resp, text) {}
      });
      return this;
    });

    e.preventDefault();
  };

  removeNotePerNr = (idx,id) => e => {
    e.preventDefault();
    console.log(this.state.id)
    let deleteItem = {
      id: id
    };

    $.ajax({
      url: BaseUrl + "api/deliveryNote/deleteNotePerNr.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function(response) {
        this.setState({
          articles: this.state.articles.filter((s, sidx) => idx !== sidx)
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  handleRemoveDeliveryItem = idx => () => {
    this.setState({
      articles: this.state.articles.filter((s, sidx) => idx !== sidx)
    });
  };
  removeNotes = () => {
    let deleteItem = {
      id: this.state.notesNr
    };
    $.ajax({
      url: BaseUrl + "api/deliveryNote/deleteNote.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function(response) {
        this.setState({
          modal: !this.state.modal,
          hideNotes: true
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  handleAddDeliveryItem = () => {
    this.setState({
      articles: this.state.articles.concat([
        { itemId: "", itemDsc: "", itemAmnt: "", itemUnit: "" }
      ])
    });
  };
  handleChangeDeliverd = idx => evt => {
    const newItemChange = this.state.articles.map((item, sidx) => {
      if (idx !== sidx) return item;
      return { ...item, [evt.target.name]: evt.target.value };
    });
    this.setState({ articles: newItemChange });
  };
  autocompleteTab = idx => e => {
    if (e.keyCode === 9) {
      this.getDscUnit(idx, e);
    }
  };
  getDscUnit = (idx, evt) => {
    const convertInput = evt.target.value.replace(/ /g, " ");
    return fetch(
      BaseUrl + "api/deliveryNote/readItemDesc.php?eingabe=" + convertInput
    )
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            itemDsc: item.dsc,
            itemUnit: item.unit
          });
        });

        const newItemAutocomplete = this.state.articles.map((item, sidx) => {
          if (idx !== sidx) return item;

          return {
            ...item,
            itemId: item.itemId,
            itemDsc: this.state.itemDsc,
            itemUnit: this.state.itemUnit
          };
        });
        this.setState({ articles: newItemAutocomplete });
      })
      .catch(error => {});
  };
  convertDate = date => {
    if (date.includes("-")) {
      var dateNew = date.split("-");
      dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };

  readBvNameById = (id, bool) => {
    let found = this.props.bvAll.find(name => name.bvId === parseInt(id));
    if (found === undefined) {
      this.props.readBvCompleteById(id);
      setTimeout(() => {
        this.setState({
          bv: this.props.bvAllFound
        });
      }, 200);
    }
    if (bool === true && found !== undefined) {
      return this.setState({
        bv: found.bv
      });
    } else if (bool === false && found !== undefined) {
      return found.bv;
    }

  };


  readBvIdByName = (name, bool) => {
     let found = this.props.bvAll.find(id => id.bv === name);
     if (found === undefined) {
      return "";
    }
    if (bool === true) {
       return this.setState({
        bvId: found.bvId
      });
    } else {
      return found.bvId;
    }
  };
  readSupplierNameById = (id, bool) => {
    let found = this.props.supplierAll.find(name => name.supplierId === parseInt(id));
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        supplier: found.supplierName
      });
    } else {
      return found.supplierName;
    }
  };
  readSupplierIdByName = (name, bool) => {
    let found = this.props.supplierAll.find(id => id.supplierName === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        supplierId: found.supplierId
      });
    } else {
      return found.supplierId;
    }
  };
  autocomplete = e => {
    if (e.keyCode === 9 && e.target.name === "bvId") {
      this.readBvNameById(e.target.value, true);
    }
    if (e.keyCode === 9 && e.target.name === "supplierId") {
      this.readSupplierNameById(e.target.value, true);
    }
  };
  copyToClipboard = idxCopy => e => {
    var copyText = document.getElementById(idxCopy);
    copyText.select();
    document.execCommand("copy");
     copyText = document.getElementById(idxCopy + "fa");
    copyText.classList.add("fa-show");
    setTimeout(() => {
      copyText.classList.remove("fa-show");
    }, 1500);
  };

  render() {
    return (
      <React.Fragment>
        <li className={this.state.hideNotes ? "hide " : "border-bottom-last"}>
          <div onClick={this.toggle}>
            <span className="row list-header show0">
              <p className="col-2">
                <span>Datum:</span>
                <Highlight id="font-normal" search={this.props.search}>
                  {this.convertDate(this.state.date)}
                </Highlight>
              </p>
              <p className="col">
                <span>LieferscheinNr:</span>
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.notesNr}
                </Highlight>
              </p>
              <p className="col">
                <span>Lieferanten Nr:</span>
                {this.state.supplierId}
              </p>
              <p className="col">
                <span>Lieferant:</span>
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.supplier}
                </Highlight>
              </p>
              <p className="col-3">
                <span>Bv:</span>
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.bv}
                </Highlight>
              </p>
              <p className="col-1">
                <span>Bv-Nr:</span>
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.bvId}
                </Highlight>
              </p>
            </span>
          </div>
          <div className="row font-small">
            <div className="col-md-2">
              <span>Artikelnummer</span>
            </div>
            <div className="col-md-8">
              <span>Artikelbeschreibung:</span>
            </div>
            <div className="col-md-1">
              <span>Menge:</span>
            </div>
            <div className="col-md-1">
              <span>Einheit:</span>
            </div>
          </div>
          {this.state.articles.map((i, idx) => {
            return (
              <div className={this.state.hideArticle ? "hide " : "border-bottom-notes"} key={idx}>
                <div className={`row fullwidth hidetext`}>
                  <div className="col-md-2 copy-field">
                    <Highlight id="font-normal" search={this.props.search}>
                      <input
                        onChange={this.handleChange}
                        onClick={this.copyToClipboard(
                          this.state.idxCopy + idx + "-" + i.itemId
                        )}
                        id={`${this.state.idxCopy + idx + "-" + i.itemId}`}
                        value={i.itemId}
                      />
                      <p id="overlay-copy-field">{i.itemId}</p>

                      <FontAwesomeIcon
                        className="fa-hide"
                        id={`${this.state.idxCopy +
                          idx +
                          "-" +
                          i.itemId +
                          "fa"}`}
                        icon={["far", "copy"]}
                      />
                    </Highlight>
                  </div>
                  <p onClick={this.toggle} className="col-md-8">
                    <Highlight id="font-normal" search={this.props.search}>
                      {i.itemDsc}
                    </Highlight>
                  </p>
                  <p onClick={this.toggle} className="col-md-1">
                    {i.itemAmnt}
                  </p>
                  <p onClick={this.toggle} className="col-md-1">
                    {i.itemUnit}
                  </p>
                </div>
              </div>
            );
          })}
        </li>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal-header">
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <p>
                Lieferschein bearbeiten?
                <br />
              </p>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <p>
                Lieferschein wirklich Löschen?
                <br />
              </p>

              <span>Dieser Vorgang kann nicht Rückgänging gemacht werden</span>
            </div>
            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <form className="row">
                <div className="col-md-2">
                  <p>Datum</p>

                  <input
                    type="date"
                    name="date"
                    placeholder="Datum"
                    min="2017-01-01"
                    onChange={this.handleChange}
                    value={this.state.date}
                  />
                </div>
                <div className="col-md-2">
                  <p>LieferscheinNr</p>

                  <input
                    type="text"
                    name="notesNr"
                    placeholder="LieferscheinNr."
                    onChange={this.handleChange}
                    value={this.state.notesNr}
                  />
                </div>
                <div className="col-md-2 tab">
                  <p>Lieferanten-Nr</p>

                  <input
                    type="text"
                    name="supplierId"
                    placeholder="Lieferant Nr"
                    onChange={this.handleChange}
                    value={this.state.supplierId}
                    onKeyDown={this.autocomplete}
                  />
                  <span>↹</span>
                </div>
                <div className="col-md-2">
                  <p>Lieferant</p>
                  <select
                    name="supplier"
                    value={this.state.supplier}
                    onChange={this.handleChange}
                  >
                    <option value="-">-</option>
                    {this.props.supplierAll.map((supplier, i) => (
                      <option key={i} value={supplier.supplierName}>
                        {supplier.supplierName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-2 tab">
                  <p>BV-Nr</p>

                  <input
                    type="text"
                    name="bvId"
                    placeholder="BV-Nr"
                    onChange={this.handleChange}
                    value={this.state.bvId}
                    onKeyDown={this.autocomplete}
                  />
                  <span>↹</span>
                </div>
                <div className="col-md-2">
                  <p>BV</p>
                  <select
                    name="bv"
                    value={this.state.bv}
                    onChange={this.handleChange}
                  >
                    <option key="-1" value="-">
                      -
                    </option>
                    {this.props.bvAll.map((bv, i) => (
                      <option key={i} value={bv.bv}>
                        {bv.bv}
                      </option>
                    ))}
                  </select>
                </div>
              </form>

              <form className="row">
                {this.state.articles.map((i, idx) => {
                  return (
                    <div
                      key={idx}
                      className={
                        this.state.hideArticle
                          ? "hide row fullwidth"
                          : "row fullwidth"
                      }
                    >
                      <div className="col-md-1">
                        <button
                          onClick={this.removeNotePerNr(idx, i.id)}
                          className="x-red"
                        >
                          x
                        </button>
                      </div>
                      <div className="col-md-2">
                        <p>ArtikelNr</p>
                        <input
                          type="text"
                          name="itemId"
                          placeholder="ArtikelNr"
                          onKeyDown={this.autocompleteTab(idx)}
                          onChange={this.handleChangeDeliverd(idx)}
                          value={i.itemId}
                        />
                      </div>
                      <div className="col-md-5">
                        <p>Artikelbezeichnung</p>
                        <input
                          type="text"
                          name="itemDsc"
                          placeholder="Artikelbezeichnung"
                          onChange={this.handleChangeDeliverd(idx)}
                          value={i.itemDsc}
                        />
                      </div>
                      <div className="col-md-2">
                        <p>Menge</p>
                        <input
                          type="text"
                          name="itemAmnt"
                          placeholder="Menge"
                          onChange={this.handleChangeDeliverd(idx)}
                          value={i.itemAmnt}
                        />
                      </div>
                      <div className="col-md-2">
                        <p>Einheit</p>
                        <select
                          name="itemUnit"
                          onChange={this.handleChangeDeliverd(idx)}
                          value={i.itemUnit}
                        >
                          <option key="-1" value="-">
                            -
                          </option>
                          <option key={1} value="Beutel">
                            Beutel
                          </option>
                          <option key={2} value="Bund">
                            Bund
                          </option>
                          <option key={3} value="Dose(n)">
                            Dose(n)
                          </option>
                          <option key={4} value="Eimer">
                            Eimer
                          </option>
                          <option key={5} value="Flasche(n)">
                            Flasche(n)
                          </option>
                          <option key={6} value="Gebinde">
                            Gebinde
                          </option>
                          <option key={7} value="Gramm">
                            Gramm
                          </option>
                          <option key={8} value="Karton">
                            Karton
                          </option>
                          <option key={9} value="KG">
                            KG
                          </option>
                          <option key={10} value="Kübel">
                            Kübel
                          </option>
                          <option key={11} value="LE">
                            LE
                          </option>
                          <option key={12} value="Lfm.">
                            Lfm.
                          </option>
                          <option key={13} value="Liter">
                            Liter
                          </option>
                          <option key={14} value="m2">
                            m2
                          </option>
                          <option key={15} value="m3">
                            m3
                          </option>
                          <option key={16} value="Minuten">
                            Minuten
                          </option>
                          <option key={17} value="Paar">
                            Paar
                          </option>
                          <option key={18} value="Pack">
                            Pack
                          </option>
                          <option key={19} value="Paket">
                            Paket
                          </option>
                          <option key={20} value="Palette(n)">
                            Palette(n)
                          </option>
                          <option key={21} value="Pauschal">
                            Pauschal
                          </option>
                          <option key={22} value="Rolle(n)">
                            Rolle(n)
                          </option>
                          <option key={23} value="Sack">
                            Sack
                          </option>
                          <option key={24} value="Satz">
                            Satz
                          </option>
                          <option key={25} value="Set">
                            Set
                          </option>
                          <option key={26} value="Stück">
                            Stück
                          </option>
                          <option key={27} value="Stunden">
                            Stunden
                          </option>
                          <option key={28} value="Tag/e">
                            Tag/e
                          </option>
                          <option key={29} value="Tonne(n)">
                            Tonne(n)
                          </option>
                        </select>
                      </div>
                    </div>
                  );
                })}
              </form>
              <div className="row">
                <div className="col-11" />
                <div className="col-1">
                  <button
                    type="button"
                    onClick={this.handleAddDeliveryItem}
                    className="btn-add-item"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Löschen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.updateNote} className="btn-green">
                    Ändern <FontAwesomeIcon icon={["far", "edit"]} />
                  </button>
                </div>
              </div>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Nein <FontAwesomeIcon icon="ban" />
                  </button>
                </div>

                <div className="col">
                  <button onClick={this.removeNotes} className="btn-green">
                    Ja <FontAwesomeIcon icon="check-circle" />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalDeliveryNotes;
