import React, { Component } from "react";
import "../css/bootstrap-grid.css";
import "../css/App.css";
import "../css/index.css";
import "../css/mediaQuerys.css";

import "@fortawesome/fontawesome-free-solid";

class HoursList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      bv: this.props.bv,
      bvId: this.props.bvId,
      hours: this.props.hours,
      checkedAll: false
    };
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleChecked = () => {

     this.setState({checkedAll: !this.state.checkedAll});

   }
  convertDate = date => {
    if (date === undefined) {
      return date;
    } else {
      if (date.includes("-")) {
        var dateNew = date.split("-");
        dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
        return dateNew;
      } else {
        return date;
      }
    }
  };
  handleChangeToggle = idx => evt => {
    const changedHours = this.state.hours.map((item, sidx) => {
      if (idx !== sidx) return item;
      return { ...item, [evt.target.name]: evt.target.checked };
    });

    Promise.all([this.setState({ hours: changedHours })]).then(values => {
      const allInfos = {
        id: this.state.hours[idx].id,
        lastName: this.state.lastName,
        firstName: this.state.firstName,
        bv: this.state.bv,
        bvId: this.state.bvId,
        date: this.state.hours[idx].date,
        beginning: this.state.hours[idx].beginning,
        end: this.state.hours[idx].end,
        driveTime: this.state.hours[idx].driveTime,
        pause: this.state.hours[idx].pause,
        result: this.state.hours[idx].result
      };
      if (this.state.hours[idx].checked === true) {
        this.props.addRemoveToSave([allInfos], "add", this.state.hours[idx].id);
      }
      if (this.state.hours[idx].checked === false) {
        this.props.addRemoveToSave(
          [allInfos],
          "remove",
          this.state.hours[idx].id
        );
      }
    });
  };
  handleChangeAll = e => {


      this.state.hours.map((item, idx) => {
       const allInfos = {
         id: item.id,
         lastName: this.state.lastName,
         firstName: this.state.firstName,
         bv: this.state.bv,
         bvId: this.state.bvId,
         date: item.date,
         beginning: item.beginning,
         end: item.end,
         driveTime: item.driveTime,
         pause: item.pause,
         result: item.result
      };

      if (this.state.checkedAll === true) {

        this.props.addRemoveToSave(
          [allInfos],
          "add",
          this.state.hours
        );
      }
      if (this.state.checkedAll === false) {
        this.props.addRemoveToSave(
          [allInfos],
          "remove",
          this.state.hours
        );
      }
      return this;
    })

  };
  render() {
    return (
      <React.Fragment>
        <div className="one-page" key={this.state.id}>
          <div className={this.state.hideList ? "hide " : "HoursList"}>
            <div className="row  ">
              <div className="col-1">
                <input
                  name="checkedAll"
                  type="checkbox"
                  checked={this.state.checkedAll ? this.handleChangeAll() : this.handleChangeAll() }
                  onChange={this.handleChecked}
                />
              </div>
              <div className="col-10">
                <h4>
                  {this.state.lastName} {this.state.firstName}
                </h4>
              </div>
              <div className="col-1"></div>
            </div>
            <hr/>


            <ul>
              <div className="row  ">
                <p className="col-1 width-check"></p>
                <p className="col-2">
                  <span>Datum:</span>
                </p>
                <p className="col-1">
                  <span>Beginn:</span>
                </p>
                <p className="col-1">
                  <span>Ende:</span>
                </p>
                <p className="col-1">
                  <span>Pause:</span>
                </p>
                <p className="col-1">
                  <span>Fahrtzeit:</span>
                </p>
                <p className="col-1">
                  <span>Ergebnis:</span>
                </p>
              </div>
              {this.state.hours.map((i, idx) => {
                return (
                  <div key={idx} className="workHours-list-checkbox">
                    <div className="row ">
                      <p className="col-1 width-check">
                        <input
                          name="checked"
                          type="checkbox"
                          onChange={this.handleChangeToggle(idx)}
                          checked={this.state.checkedAll ? 'true' : i.checked}
                        />
                      </p>
                      <p className="col-2">{this.convertDate(i.date)}</p>
                      <p className="col-1">{i.beginning}</p>
                      <p className="col-1">{i.end}</p>
                      <p className="col-1">{i.pause}</p>
                      <p className="col-1">{i.driveTime}</p>
                      <p className="col-1">{i.result}</p>
                    </div>
                  </div>
                );
              })}
            </ul>
          </div>
        </div>

        <br clear="all" id="pagebreak" />
      </React.Fragment>
    );
  }
}
export default HoursList;
