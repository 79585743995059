import React, { Component } from "react";
import ModalBills from "../bills/ModalBills";
import BillsInputFormTop from "../bills/BillsInputFormTop";
import BillsSearch from "./BillsSearch.js";
import BaseUrl from "../config/config.js";
import "@fortawesome/fontawesome-free-solid";
import { ResponsiveEmbed } from "react-bootstrap";

class Bills extends Component {
  constructor() {
    super();
    this.state = {
      date: "",
      billsNr: "",
      notesNr: "",
      supplier: "",
      supplierId: "",
      supplierAll: [],
      bv: "",
      bvHelp: "",
      bvId: "",
      itemsAll: [],
      bvAll: [],
      errorSearch: false,
      search: "",
      billArticles: [
        {
          itemId: "",
          itemDsc: "",
          itemAmnt: "",
          itemUnit: "",
          pricePiece: "",
          discount: "",
          priceComplete: "",
        },
      ],
      showItem: [],
      showItemCopy: [],
      articles: [],
      searchArr: [],
      bvAllInput: [],
    };
  }

  componentDidMount = () => {
    Promise.all([
      this.readBvAll(),
      this.readsuppliers(),
      this.readBvComplete(),
    ]).then(() => {
      this.loadBills();
    });
  };
  readsuppliers = () => {
    return fetch(BaseUrl + "api/all/readSupplierNames.php")
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          return (this.state.supplierAll = this.state.supplierAll.concat(item));
        });
        this.setState({
          supplierAll: this.state.supplierAll,
        });
      })
      .catch((error) => {});
  };
  readBvComplete = () => {
    return fetch(BaseUrl + "api/all/readBvComplete.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          bvComplete: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };
  readBvAll = () => {
    return fetch(BaseUrl + "api/all/readBVNotDone.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          bvAll: responseJson.records[0],
          bvAllInput: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };

  convertDateToNorm = (date) => {
    var dateNew = "";
    if (date.includes(".")) {
      dateNew = date.split(".");
      dateNew = dateNew[2] + "-" + dateNew[1] + "-" + dateNew[0];
      return dateNew;
    } else {
      dateNew = date.split("-");
      dateNew = dateNew[0] + "-" + dateNew[1] + "-" + dateNew[2];
      return dateNew;
    }
  };
  sortByDate = (array) => {
    array.sort((a, b) => {
      let x = a.date.split(".").reverse().join();
      let y = b.date.split(".").reverse().join();
      if (x > y) {
        return -1;
      }
      if (x < y) {
        return 1;
      }
      return 0;
    });
    return array;
  };
  loadBills = () => {
    let helpFunc = [];

    return fetch(BaseUrl + "api/bills/readBillsPerBillsNr.php")
      .then((response) => response.json())
      .then((responseJson) => {
        Object.keys(responseJson.records).map((billsNr, i) => {
          Object.keys(responseJson.records[billsNr]).map((notesNr, idx) => {
            Object.keys(responseJson.records[billsNr][notesNr]).map(
              (bvId, id) => {
                const article = responseJson.records[billsNr][notesNr][
                  bvId
                ].map((i, sidx) => {
                  return {
                    key: sidx,
                    date: this.convertDateToNorm(i.date),
                    notesDate: this.convertDateToNorm(i.notesDate),
                    id: i.id,
                    itemId: i.itemId,
                    itemDsc: i.itemDsc,
                    itemAmnt: i.itemAmnt,
                    itemUnit: i.itemUnit,
                    pricePiece: i.pricePiece,
                    discount: i.discount,
                    priceComplete: i.priceComplete,
                  };
                });
                const notes = responseJson.records[billsNr][notesNr][bvId]
                  .slice(0, 1)
                  .map((i, sidx) => {
                    return {
                      key: sidx,
                      date: this.convertDateToNorm(i.date),
                      notesDate: this.convertDateToNorm(
                        i.notesDate === "" ? "2017-01-01" : i.notesDate
                      ),
                      id: i.id,
                      billsNr: i.billsNr,
                      notesNr: i.notesNr,
                      supplier: i.supplier,
                      supplierId: i.supplierId,
                      itemBv: i.bv,
                      itemBvId: i.bvId,
                      articles: article,
                    };
                  });
                return (helpFunc = [...helpFunc, ...notes]);
              }
            );
          });
        });
        this.setState({
          showItem: this.sortByDate(helpFunc),
          showItemCopy: this.sortByDate(helpFunc),
        });
      });
  };
  readLastBill = () => {
    let helpFunc = [];
    return fetch(BaseUrl + "api/bills/readLastBill.php ")
      .then((response) => response.json())
      .then((responseJson) => {
        Object.keys(responseJson.records).map((billsNr, i) => {
          Object.keys(responseJson.records[billsNr]).map((notesNr, idx) => {
            Object.keys(responseJson.records[billsNr][notesNr]).map(
              (bvId, id) => {
                const article = responseJson.records[billsNr][notesNr][
                  bvId
                ].map((i, sidx) => {
                  return {
                    key: sidx,
                    date: this.convertDateToNorm(i.date),
                    notesDate: this.convertDateToNorm(i.notesDate),
                    id: i.id,
                    itemId: i.itemId,
                    itemDsc: i.itemDsc,
                    itemAmnt: i.itemAmnt,
                    itemUnit: i.itemUnit,
                    pricePiece: i.pricePiece,
                    discount: i.discount,
                    priceComplete: i.priceComplete,
                  };
                });
                const notes = responseJson.records[billsNr][notesNr][bvId]
                  .slice(0, 1)
                  .map((i, sidx) => {
                    return {
                      key: sidx,
                      date: this.convertDateToNorm(i.date),
                      notesDate: this.convertDateToNorm(
                        i.notesDate === "" ? "2017-01-01" : i.notesDate
                      ),
                      id: i.id,
                      billsNr: i.billsNr,
                      notesNr: i.notesNr,
                      supplier: i.supplier,
                      supplierId: i.supplierId,
                      itemBv: i.bv,
                      itemBvId: i.bvId,
                      articles: article,
                    };
                  });
                helpFunc = [...this.state.showItem, ...notes];
                Promise.all(notes).then((values) => {
                  this.sortByDate(helpFunc);
                });
                const sorted = this.state.showItem.map((index) => {
                  return this.setState({
                    showItem: [],
                  });
                });

                Promise.all(sorted).then((values) => {
                  this.setState({
                    showItem: helpFunc,
                    showItemCopy: helpFunc,
                  });
                });
              }
            );
          });
        });
      });
  };
  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };

  search = (search) => {
    let searchHelpFunc = [];
    this.setState({
      showItem: [],
      refresh: true,
    });
    let convertEingabe = search.replace("+", "%2B");
    fetch(BaseUrl + "api/bills/searchBills.php?eingabe=" + convertEingabe)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "No items found.") {
          this.setState({
            showItem: [],
            refresh: false,
          });
        } else {
          Object.keys(responseJson.records).map((billsNr, i) => {
            Object.keys(responseJson.records[billsNr]).map((notesNr, idx) => {
              Object.keys(responseJson.records[billsNr][notesNr]).map(
                (bvId, id) => {
                  const article = responseJson.records[billsNr][notesNr][
                    bvId
                  ].map((i, sidx) => {
                    return {
                      key: sidx,
                      date: this.convertDateToNorm(i.date),
                      notesDate: this.convertDateToNorm(i.notesDate),
                      id: i.id,
                      itemId: i.itemId,
                      itemDsc: i.itemDsc,
                      itemAmnt: i.itemAmnt,
                      itemUnit: i.itemUnit,
                      pricePiece: i.pricePiece,
                      discount: i.discount,
                      priceComplete: i.priceComplete,
                    };
                  });
                  const notes = responseJson.records[billsNr][notesNr][bvId]
                    .slice(0, 1)
                    .map((i, sidx) => {
                      return {
                        key: sidx,
                        date: this.convertDateToNorm(i.date),
                        notesDate: this.convertDateToNorm(
                          i.notesDate === "" ? "2017-01-01" : i.notesDate
                        ),
                        id: i.id,
                        billsNr: i.billsNr,
                        notesNr: i.notesNr,
                        supplier: i.supplier,
                        supplierId: i.supplierId,
                        itemBv: i.bv,
                        itemBvId: i.bvId,
                        articles: article,
                      };
                    });
                  return (searchHelpFunc = [...searchHelpFunc, ...notes]);
                }
              );
            });
          });
          this.setState({
            refresh: false,
            showItem: this.sortByDate(searchHelpFunc),
          });
        }
      });
  };
  handleSearch = (e) => {
    this.state.search = e.target.value;
    if (e.target.value === "") {
      const reset = this.state.showItem.map((index) => {
        return this.setState({
          showItem: [],
        });
      });

      Promise.all(reset).then((values) => {
        this.setState({
          showItem: this.state.showItemCopy,
          errorSearch: false,
        });
      });
    }
  };
  readSupplierNameById = (id) => {
    let found = this.state.supplierAll.find(
      (name) => name.supplierId === parseInt(id)
    );
    if (found === undefined) {
      return "";
    } else {
      return found.supplierName;
    }
  };

  readBvCompleteById = (id) => {
    let foundComplete = this.state.bvComplete.find(
      (name) => name.bvId === parseInt(id)
    );
    if (foundComplete === undefined) {
      return "";
    } else {
      this.state.bvAll.concat({ bv: foundComplete.bv, bvId: id });
      return foundComplete.bv;
    }
  };
  readOldBvById = (id) => {
    let foundComplete = this.state.bvComplete.find(
      (name) => name.bvId === parseInt(id)
    );
    if (foundComplete.bv === undefined) {
      return "";
    } else {
      this.setState({
        bvAllInput: this.state.bvAll.concat({ bv: foundComplete.bv, bvId: id }),
        bvAllFound: foundComplete.bv,
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <BillsInputFormTop
          id={this.state.id}
          date={this.state.date}
          billsNr={this.state.billsNr}
          notesNr={this.state.notesNr}
          supplier={this.state.supplier}
          supplierId={this.state.supplierId}
          bv={this.state.bv}
          bvId={this.state.bvId}
          bvAll={this.state.bvAll}
          billArticles={this.state.billArticles}
          readLastBill={this.readLastBill}
          search={this.state.search}
          handleSearch={this.handleSearch}
          onKeyPress={this.onKeyPress}
          supplierAll={this.state.supplierAll}
          bvComplete={this.bvComplete}
          bvAllInput={this.state.bvAllInput}
          bvAllFound={this.state.bvAllFound}
          readOldBvById={this.readOldBvById}
        />
        <section id="showItems" className="display-item">
          <h4>Übersicht Rechnungen</h4>

          <BillsSearch
            search={this.state.search}
            onKeyPress={this.onKeyPress}
            handleSearch={this.handleSearch}
          />
        </section>
        <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
          Kein Suchergebnis
        </p>
        <div className="text-center">
          <div className={this.state.refresh ? "lds-roller" : "hide"}>
            <div></div>
          </div>
        </div>
        <section id="showItems">
          <ul className="notes-list">
            {this.state.showItem.map((item, idx) => {
              return (
                <ModalBills
                  key={idx}
                  id={item.id}
                  date={item.date}
                  notesDate={
                    item.notesDate === "" ? "2017-01-01" : item.notesDate
                  }
                  billsNr={item.billsNr}
                  billsNrOld={item.billsNr}
                  notesNr={item.notesNr}
                  supplier={this.readSupplierNameById(item.supplierId)}
                  supplierId={item.supplierId}
                  supplierAll={this.state.supplierAll}
                  bv={this.readBvCompleteById(item.itemBvId)}
                  bvHelp={this.state.bvHelp}
                  bvId={item.itemBvId}
                  articles={item.articles}
                  bvAll={[
                    ...new Set(
                      this.state.bvAll.concat({
                        bv: this.readBvCompleteById(item.itemBvId),
                        bvId: item.itemBvId,
                      })
                    ),
                  ]}
                  search={this.state.search}
                  searchBvComplete={this.searchBvComplete}
                  readBvCompleteById={this.readBvCompleteById}
                />
              );
            })}
          </ul>
        </section>
      </React.Fragment>
    );
  }
}

export default Bills;
