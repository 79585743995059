import React, { Component } from "react";
import ProductsSearch from "./ProductsSearch.js";
import ModalProducts from "./ModalProducts.js";
 import "@fortawesome/fontawesome-free-solid";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from "react-router-dom";
import Textarea from "react-textarea-autosize";
import BaseUrl from "../config/config.js";
var $ = require("jquery");

class Products extends Component {
  constructor() {
    super();
    this.state = {
      nr: "",
      dsc: "",
      unit: "",
      calcPrice: "",
      category: "",
      productsAll: [],
      productsAllCopy: [],
      errorSearch: false,
      search: "",
      useSearch: false,
      calcPriceAll: [],
      calcPriceId: ""
    };
  }

  componentDidMount = () => {
    this.loadProducts();
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleChangeCategory = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();

    let Products = {
      nr: this.state.nr.trim(),
      dsc: this.state.dsc,
      unit: this.state.unit,
      calcPrice: parseFloat(this.state.calcPrice.replace(/,/g, ".")).toFixed(2),
      category: this.state.category
    };
    $.ajax({
      url: BaseUrl + "api/products/createProducts.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(Products),
      success: function(response) {
        this.setState({
          nr: "",
          dsc: "",
          unit: "",
          calcPrice: "",
          category: ""
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });

    this.state.productsAll.unshift({
      id: parseInt(this.state.productsAll[0].id) + 1,
      nr: this.state.nr.trim(),
      dsc: this.state.dsc,
      unit: this.state.unit,
      calcPrice: parseFloat(this.state.calcPrice.replace(/,/g, ".")).toFixed(2),
      category: this.state.category
    });
    return this.setState({
      productsAll: this.state.productsAll
    });
  };
  loadProducts = () => {
    return fetch(BaseUrl + "api/products/readProducts.php")
      .then(response => response.json())
      .then(responseJson => {
        return this.setState({
          productsAll: responseJson.records[0],
          productsAllCopy: responseJson.records[0]
        });
      })
      .catch(error => {});
  };

  onKeyPress = e => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };
  handleSearch = e => {
    this.state.search = e.target.value;
    if (e.target.value === "") {
      this.setState({
        productsAll: this.state.productsAllCopy,
        errorSearch: false
      });
    }
  };

  search = eingabe => {
    let convertEingabe = eingabe.replace(/ /g, "%").replace("+", "%2B");

    if (eingabe === "") {
      this.setState({
        productsAll: this.state.productsAllCopy,
        errorSearch: false
      });
    } else {
      this.setState({
        productsAll: []
      });
      return fetch(
        BaseUrl + "api/products/searchProducts.php?eingabe=" + convertEingabe
      )
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.message === "No Productss found.") {
            this.setState({
              productsAll: [],
              errorSearch: true
            });
          } else {
            this.setState({
              errorSearch: false,
              productsAll: responseJson.records[0]
            });
          }
        })
        .catch(error => {});
    }
  };

  autocompleteBvcalcPrice = e => {
    if (e.keyCode === 9 && e.target.name === "calcPriceId") {
      this.getcalcPrice(e);
    }
  };
  render() {
    return (
      <React.Fragment>
        <section id="insertProducts">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--greenSeyband)" icon="list-ol" />{" "}
                Produktstamm
              </h1>
            </div>
          </div>
          <h4>Produkt Hinzufügen</h4>
          <form className="products" onSubmit={this.handleSubmit}>
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="row">
                <div className="col-md-2">
                  <p>Artikel-Nr</p>
                  <input
                    required
                    type="text"
                    name="nr"
                    onChange={this.handleChange}
                    value={this.state.nr}
                  />
                </div>
                <div className="col-md-6">
                  <p>Artikelbezeichnung</p>
                  <Textarea
                    type="text"
                    name="dsc"
                    onChange={this.handleChange}
                    value={this.state.dsc}
                  />
                </div>
                <div className="col-md-2">
                  <p>Einheit</p>
                  <select
                    name="unit"
                    onChange={this.handleChange}
                    value={this.state.unit}
                  >
                    <option key="-1" value="-">
                      -
                    </option>
                    <option key={1} value="Beutel">
                      Beutel
                    </option>
                    <option key={2} value="Bund">
                      Bund
                    </option>
                    <option key={3} value="Dose(n)">
                      Dose(n)
                    </option>
                    <option key={4} value="Eimer">
                      Eimer
                    </option>
                    <option key={5} value="Flasche(n)">
                      Flasche(n)
                    </option>
                    <option key={6} value="Gebinde">
                      Gebinde
                    </option>
                    <option key={7} value="Gramm">
                      Gramm
                    </option>
                    <option key={8} value="Karton">
                      Karton
                    </option>
                    <option key={9} value="KG">
                      KG
                    </option>
                    <option key={10} value="Kübel">
                      Kübel
                    </option>
                    <option key={11} value="LE">
                      LE
                    </option>
                    <option key={12} value="Lfm.">
                      Lfm.
                    </option>
                    <option key={13} value="Liter">
                      Liter
                    </option>
                    <option key={14} value="m2">
                      m2
                    </option>
                    <option key={15} value="m3">
                      m3
                    </option>
                    <option key={16} value="Minuten">
                      Minuten
                    </option>
                    <option key={17} value="Paar">
                      Paar
                    </option>
                    <option key={18} value="Pack">
                      Pack
                    </option>
                    <option key={19} value="Paket">
                      Paket
                    </option>
                    <option key={20} value="Palette(n)">
                      Palette(n)
                    </option>
                    <option key={21} value="Pauschal">
                      Pauschal
                    </option>
                    <option key={22} value="Rolle(n)">
                      Rolle(n)
                    </option>
                    <option key={23} value="Sack">
                      Sack
                    </option>
                    <option key={24} value="Satz">
                      Satz
                    </option>
                    <option key={25} value="Set">
                      Set
                    </option>
                    <option key={26} value="Stück">
                      Stück
                    </option>
                    <option key={27} value="Stunden">
                      Stunden
                    </option>
                    <option key={28} value="Tag/e">
                      Tag/e
                    </option>
                    <option key={29} value="Tonne(n)">
                      Tonne(n)
                    </option>
                  </select>
                </div>
                <div className="col-md-2">
                  <p>Kalk. Preis</p>
                  <input
                    required
                    type="text"
                    name="calcPrice"
                    onChange={this.handleChange}
                    value={this.state.calcPrice}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Kategorie</p>
                  <select
                    name="category"
                    onChange={this.handleChangeCategory}
                    value={this.state.category}
                  >
                    <option key="-1" value="-">
                      -
                    </option>
                    <option key={"0"} value="Decke">
                      Decke
                    </option>
                    <option key={"1"} value="Wand">
                      Wand
                    </option>
                    <option key={"2"} value="Boden">
                      Boden
                    </option>
                    <option key={"3"} value="Abbruch">
                      Abbruch
                    </option>
                    <option key={"4"} value="Logistik">
                      Logistik
                    </option>
                  </select>
                </div>
                <div className="offset-8 col-md-2">
                  <button className="btn-create-products">
                    Produkt speichern <FontAwesomeIcon icon="save" />
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </section>
        <section id="showProducts" className="display-Products">
          <h4>Übersicht Produkte</h4>
          <ProductsSearch
            search={this.state.search}
            onKeyPress={this.onKeyPress}
            handleSearch={this.handleSearch}
          />
          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          <ul className="products-list">
            {this.state.productsAll.map(Products => {
              return (
                <ModalProducts
                  onChange={this.handleChange}
                  key={Products.id}
                  id={Products.id}
                  nr={Products.nr}
                  dsc={Products.dsc}
                  unit={Products.unit}
                  calcPriceId={Products.calcPriceId}
                  calcPrice={Products.calcPrice}
                  internnr={Products.internnr}
                  calcPriceAll={this.state.calcPriceAll}
                  category={Products.category}
                  search={this.state.search}
                />
              );
            })}
          </ul>
        </section>
      </React.Fragment>
    );
  }
}

export default Products;
