import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import DirectionWorkReportInput from "./DirectionWorkReportInput";
import HoursListReport from "./HoursListReport";
import DeliveryNotesListReport from "./DeliveryNotesListReport";
import BillsListReport from "./BillsListReport";
import Textarea from "react-textarea-autosize";
import BaseUrl from "../../config/config.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrintReport from "./PrintReport.js";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
var $ = require("jquery");

class DirectionWorkReport extends Component {
  constructor() {
    super();
    this.state = {
      workingHours: [],
      workingHoursPrint: [],
      deliveryNotes: [],
      bills: [],
      hideHours: true,
      hideNotes: true,
      hideBills: true,
      hideList: true,
      reportNr: "",
      save: false,
      disableSave: false,
      hoursComplete: "",
      printPrice: false,
      serviceOverview: "",
      equipment: "",
      customerName: "",
      extraInfoId: "",
      resultCollected: [],
      helpResult: "00:00",
      printAndCalc: false,
      notesBillSplit: false,
      supplierAll: [],
      hoursVorarbeiter: "00:00",
      hoursHilfsarbeiter: "00:00",
      hoursFacharbeiter: "00:00",
      notesPriceComplete: 0,
      arrPriceCompleteNote: [],
      billsPriceComplete: 0,
      arrPriceCompleteBills: [],
      arrHours: [],
      print: false,
      dataRefreshedBills: false,
      dataRefreshedNotes: false,
      dataRefreshedHours: false
    };
  }
  componentDidMount = () => {
    this.readBvAll();
    this.readsuppliers();
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (this.state.print) {
      this.setState({
        print: false,
      });
    }
  };
  readAllInfos = (bvId, reportNr) => {
    const wait = this.setState({
      bv: this.readBvNameById(bvId),
      bvId: bvId,
      save: false,
      printAndCalc: false,
      hideHours: true,
      hideNotes: true,
      hideBills: true,
      hideList: true,
      workingHours: [],
      deliveryNotes: [],
      bills: [],
      notesPriceComplete: 0,
      arrPriceCompleteNote: [],
      billsPriceComplete: 0,
      arrPriceCompleteBills: [],
    });
    this.state.reportNr = reportNr;
    this.readWorkingHours(bvId, reportNr);
    this.loadDeliveryNotes(bvId, reportNr);
    this.loadBills(bvId, reportNr);
    this.loadExtraInfo(bvId, reportNr);
    Promise.all([wait]).then((values) => {
      this.loadCustomer(bvId, this.state.bv);
      if (this.state.print) {
        this.setState({
          print: false,
        });
      }
    });
  };
  readBvAll = () => {
    return fetch(BaseUrl + "api/all/readBvComplete.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          bvAll: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };
  readsuppliers = () => {
    return fetch(BaseUrl + "api/all/readSupplierNames.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          supplierAll: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };

  readBvNameById = (id) => {
    let found = this.state.bvAll.find((name) => name.bvId === parseInt(id));
    if (found === undefined) {
      return "";
    } else {
      this.setState({
        bv: found.bv,
      });
      return found.bv;
    }
  };
  readSupplierNameById = (id) => {
    let found = this.state.supplierAll.find(
      (name) => name.supplierId === parseInt(id)
    );
    if (found === undefined) {
      return "";
    } else {
      this.setState({
        supplierName: found.supplierName,
      });
      return found.supplierName;
    }
  };
  sortByDate = (array) => {
    array.sort((a, b) => {
      let x = a.date.split(".").reverse().join();
      let y = b.date.split(".").reverse().join();
      if (x > y) {
        return -1;
      }
      if (x < y) {
        return 1;
      }
      return 0;
    });
    return array;
  };
  loadCustomer = (bvId) => {
    return fetch(BaseUrl + "api/all/readCustomerNameId.php?bvId=" + bvId)
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          return this.setState({
            customerName: responseJson.records[0].customerName,
          });
        });
      })
      .catch((error) => {});
  };
  loadExtraInfo = (bvId, reportNr) => {
    return fetch(
      BaseUrl +
        "api/directionWork/searchExtraInfo.php?bvId=" +
        bvId +
        "&reportNr=" +
        reportNr
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return this.setState({
          extraInfoId: responseJson.records[0].id,
          serviceOverview: responseJson.records[0].serviceOverview,
          equipment: responseJson.records[0].equipment,
        });
      })
      .catch((error) => {});
  };

  readWorkingHours = (bvId, reportNr) => {
    let hoursVorarbeiter = "00:00";
    let hoursHilfsarbeiter = "00:00";
    let hoursFacharbeiter = "00:00";
    this.setState({
      workingHours: [],
    });
    let searchHelpFunc = [];
    let helpCalc = "00:00";
    return fetch(
      BaseUrl +
        "api/directionWork/searchWorkingHoursForReport.php?bvId=" +
        bvId +
        "&reportNr=" +
        reportNr
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "No items found.") {
          this.setState({
            workingHours: [],
          });
        } else {
          responseJson.records.map((item, sidx) => {
            Object.keys(item).map((notesNr, idx) => {
              const hours = item[notesNr].map((item, sidx) => {
                let drive;
                if (item.driveTime === "") {
                  drive = "00:00";
                } else {
                  drive = item.driveTime;
                }
                helpCalc = this.addTimes(item.result, helpCalc);
                if (item.categorie === "Vorarbeiter") {
                  hoursVorarbeiter = this.addTimes(
                    hoursVorarbeiter,
                    item.result
                  );
                }
                if (item.categorie === "Hilfsarbeiter") {
                  hoursHilfsarbeiter = this.addTimes(
                    hoursHilfsarbeiter,
                    item.result
                  );
                }
                if (item.categorie === "Facharbeiter") {
                  hoursFacharbeiter = this.addTimes(
                    hoursFacharbeiter,
                    item.result
                  );
                }
                return {
                  id: item.id,
                  key: sidx,
                  date: item.date,
                  beginning: item.beginning,
                  end: item.end,
                  pause: item.pause,
                  driveTime: drive,
                  result: item.result,
                  checked: false,
                };
              });

              const directionHours = item[notesNr]
                .slice(0, 1)
                .map((item, ix) => {
                  return {
                    id: item.id,
                    lastName: item.lastName,
                    firstName: item.firstName,
                    categorie: item.categorie,
                    bv: this.readBvNameById(item.bvId),
                    bvId: item.bvId,
                    hours: hours,
                  };
                });
              this.setState(
                (prevState) => ({
                  arrHours: [...prevState.arrHours, directionHours],
                }),
                () => {
                  this.helpCalcBills();
                }
              );
              return (searchHelpFunc = [...searchHelpFunc, ...directionHours]);
            });
            return this;
          });
          this.setState({
            workingHours: searchHelpFunc,
            hoursVorarbeiter: hoursVorarbeiter,
            hoursHilfsarbeiter: hoursHilfsarbeiter,
            hoursFacharbeiter: hoursFacharbeiter,
            hideHours: false,
            hideList: false,
          });
        }
      });
  };
/*   readWorkingHoursPrint = (bvId, reportNr) => {
    this.setState({
      workingHoursPrint: [],
    });
    let searchHelpFunc = [];
    let helpCalc = "00:00";
    return fetch(
      BaseUrl +
        "api/directionWork/searchWorkingHoursForReport.php?bvId=" +
        bvId +
        "&reportNr=" +
        reportNr
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "No items found.") {
          this.setState({
            workingHours: [],
          });
        } else {
          responseJson.records.map((item, sidx) => {
            Object.keys(item).map((notesNr, idx) => {
              const hours = item[notesNr].map((item, sidx) => {
                let drive;
                if (item.driveTime === "") {
                  drive = "00:00";
                } else {
                  drive = item.driveTime;
                }
                helpCalc = this.addTimes(item.result, helpCalc);
                return {
                  id: item.id,
                  key: sidx,
                  date: item.date,
                  beginning: item.beginning,
                  end: item.end,
                  pause: item.pause,
                  driveTime: drive,
                  result: item.result,
                  checked: false,
                  lastName: item.lastName,
                  firstName: item.firstName,
                  categorie: item.categorie,
                  bv: this.readBvNameById(item.bvId),
                  bvId: item.bvId,
                };
              });

              return (searchHelpFunc = [...searchHelpFunc, ...hours]);
            });
            return this;
          });
          this.setState({
            workingHoursPrint: this.sortByDate(searchHelpFunc),
          });
        }
      });
  }; */
  loadDeliveryNotes = (bvId, reportNr) => {
    this.setState({
      deliveryNotes: [],
    });
    let searchHelpFunc = [];

    return fetch(
      BaseUrl +
        "api/directionWork/searchNotesForReport.php?bvId=" +
        bvId +
        "&reportNr=" +
        reportNr
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "No items found.") {
          this.setState({
            deliveryNotes: [
              {
                key: 0,
                date: "",
                id: "",
                notesNr: "",
                supplier: "",
                supplierId: "",
                bv: this.state.bv,
                bvId: this.state.bvId,
                articles: [
                  {
                    key: 0,
                    date: "",
                    id: "",
                    itemId: "",
                    itemDsc: "",
                    itemAmnt: "",
                    itemUnit: "",
                    pricePiece: "",
                  },
                ],
              },
            ],
            hideNotes: false,
            hideList: false,
          });
        } else {
          responseJson.records.map((item, sidx) => {
            Object.keys(item).map((notesNr, idx) => {
              const article = item[notesNr].map((i, ix) => {
                return {
                  key: sidx,
                  date: i.date,
                  id: i.id,
                  itemId: i.itemId,
                  itemDsc: i.itemDsc,
                  itemAmnt: i.itemAmnt,
                  itemUnit: i.itemUnit,
                  pricePiece: i.pricePiece,
                };
              });
              const notes = item[notesNr].slice(0, 1).map((i, ix) => {
                return {
                  key: idx,
                  date: i.date,
                  id: i.id,
                  notesNr: i.notesNr,
                  supplier: this.readSupplierNameById(i.supplierId),
                  supplierId: i.supplierId,
                  bv: this.readBvNameById(i.bvId),
                  bvId: i.bvId,
                  articles: article,
                };
              });
              return (searchHelpFunc = [...searchHelpFunc, ...notes]);
            });
            return this;
          });
          this.setState({
            deliveryNotes: this.sortByDate(searchHelpFunc),
          });
        }
        this.setState({
          hideNotes: false,
          hideList: false,
        });
      });
  };
  loadBills = (bvId, reportNr) => {
    this.setState({
      bills: [],
    });
    let helpFunc = [];

    return fetch(
      BaseUrl +
        "api/directionWork/searchBillsForReport.php?bvId=" +
        bvId +
        "&reportNr=" +
        reportNr
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "No items found.") {
          this.setState({
            bills: [
              {
                key: 0,
                date: "",
                id: "",
                billsNr: "",
                notesNr: "",
                supplier: "",
                supplierId: "",
                bv: this.state.bv,
                bvId: this.state.bvId,
                articles: [
                  {
                    key: 0,
                    date: "",
                    id: "",
                    itemId: "",
                    itemDsc: "",
                    itemAmnt: "",
                    itemUnit: "",
                    pricePiece: "",
                    discount: "",
                    priceComplete: "",
                  },
                ],
              },
            ],
            hideBills: false,
            hideList: false,
          });
        } else {
          responseJson.records.map((item, sidx) => {
            Object.keys(item).map((billsNr, idx) => {
              const searchArticle = item[billsNr].map((item, ix) => {
                return {
                  key: sidx,
                  date: item.date,
                  id: item.id,
                  itemId: item.itemId,
                  itemDsc: item.itemDsc,
                  itemAmnt: item.itemAmnt,
                  itemUnit: item.itemUnit,
                  pricePiece: item.pricePiece,
                  discount: item.discount,
                  priceComplete: item.priceComplete,
                };
              });
              const searchNotes = item[billsNr].slice(0, 1).map((item, ix) => {
                return {
                  key: sidx,
                  date: item.date,
                  id: item.id,
                  billsNr: item.billsNr,
                  notesNr: item.notesNr,
                  supplier: this.readSupplierNameById(item.supplierId),
                  supplierId: item.supplierId,
                  bv: this.readBvNameById(item.bvId),
                  bvId: item.bvId,
                  articles: searchArticle,
                };
              });
              return (helpFunc = [...helpFunc, ...searchNotes]);
            });
            return this;
          });
          this.setState({
            bills: this.sortByDate(helpFunc),
          });
        }
        this.setState({
          hideBills: false,
          hideList: false,
        });
      });
  };

  save = () => {
    this.setState({
      save: !this.state.save,
    });
    this.saveExtraInfo();
  };
 
  timeToMins = (time) => {
    var b = time.split(":");
    return b[0] * 60 + +b[1];
  };

  timeFromMins = (mins) => {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    var h = (mins / 60) | 0;
    var m = mins % 60;
    return z(h) + ":" + z(m);
  };

  addTimes = (t0, t1) => {
    return this.timeFromMins(this.timeToMins(t0) + this.timeToMins(t1));
  };

  collectResult = (i) => {
    this.state.resultCollected = [...this.state.resultCollected, i];
    this.state.helpResult = this.addTimes(i, this.state.helpResult);
    this.setState({
      hoursComplete: this.state.helpResult,
      printAndCalc: false,
    });
  };
  handleCheck = (e) => {
    if (e.target.name === "printPrice") {
      this.setState({ printPrice: !this.state.printPrice });
    } else {
      this.setState({ notesBillSplit: !this.state.notesBillSplit });
    }
    if (this.state.print) {
      this.setState({
        print: false,
      });
    }
  };
  saveExtraInfo = () => {
    let info = {
      id: this.state.extraInfoId === "" ? "neu" : this.state.extraInfoId,
      directionWorkNr: this.state.reportNr,
      bvId: this.state.bvId,
      bv: this.state.bv,
      serviceOverview: this.state.serviceOverview,
      equipment: this.state.equipment,
    };
    $.ajax({
      url: BaseUrl + "api/directionWork/createExtraInfo.php",
      type: "POST",
      dataType: "json",
      data: JSON.stringify(info),
      success: function (response) {
        this.setState({
          reset: true,
        });
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  calcPriceComplete = (what, notesNr, completeNote) => {
    if (what === "change") {
      const updatedArr = this.state.arrPriceCompleteNote.map((obj) => {
        if (Object.keys(obj)[0] === notesNr) {
          // Update the desired object
          return { ...obj, [notesNr]: completeNote };
        }
        return obj;
      });
      this.setState(
        (prevState) => ({
          arrPriceCompleteNote: updatedArr,
        }),
        () => {
          this.helpCalc();
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          arrPriceCompleteNote: [
            ...prevState.arrPriceCompleteNote,
            { [notesNr]: completeNote },
          ],
        }),
        () => {
          this.helpCalc();
        }
      );
    }
  };
  helpCalc = () => {
    let help = 0;
    this.state.arrPriceCompleteNote.map((i) => {
      help += Object.values(i)[0];
    });
    this.setState({
      notesPriceComplete: help.toLocaleString("de", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    });
  };
  calcPriceCompleteBills = (what, billsNr, completeBill) => {
    if (what === "change") {
      const updatedArr = this.state.arrPriceCompleteBills.map((obj) => {
        if (Object.keys(obj)[0] === billsNr) {
          // Update the desired object
          return { ...obj, [billsNr]: completeBill };
        }
        return obj;
      });

      this.setState(
        (prevState) => ({
          arrPriceCompleteBills: updatedArr,
        }),
        () => {
          this.helpCalcBills();
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          arrPriceCompleteBills: [
            ...prevState.arrPriceCompleteBills,
            { [billsNr]: completeBill },
          ],
        }),
        () => {
          this.helpCalcBills();
        }
      );
    }
  };
  helpCalcBills = () => {
    let help = 0;
    this.state.arrPriceCompleteBills.map((i) => {
      help += Object.values(i)[0];
    });
    this.setState({
      billsPriceComplete: help.toLocaleString("de", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    });
  };
  helpCalcHours = (key, id, newHours) => {
    const updatedArr = this.state.arrHours[key].map((obj) => {
      if (obj.id === parseInt(id)) {
        // Update the desired object
        return { ...obj, hours: newHours };
      }
    });
    this.state.arrHours[key] = updatedArr;
    {
      this.setState(
        (prevState) => ({
          arrHours: this.state.arrHours,
        }),
        () => {
          this.calcNewHours();
        }
      );
    }
  };
  calcNewHours = () => {
    let hoursVorarbeiter = "00:00";
    let hoursHilfsarbeiter = "00:00";
    let hoursFacharbeiter = "00:00";

    this.state.arrHours.map((obj) => {
      obj[0].hours.map((hours) => {
        if (obj[0].categorie === "Vorarbeiter") {
          hoursVorarbeiter = this.addTimes(hoursVorarbeiter, hours.result);
        }
        if (obj[0].categorie === "Facharbeiter") {
          hoursFacharbeiter = this.addTimes(hoursFacharbeiter, hours.result);
        }
        if (obj[0].categorie === "Hilfsarbeiter") {
          hoursHilfsarbeiter = this.addTimes(hoursHilfsarbeiter, hours.result);
        }
      });
    }),
      this.setState((prevState) => ({
        hoursComplete: this.timeFromMins(
          this.timeToMins(hoursVorarbeiter) +
            this.timeToMins(hoursFacharbeiter) +
            this.timeToMins(hoursHilfsarbeiter)
        ),
        hoursVorarbeiter: hoursVorarbeiter,
        hoursFacharbeiter: hoursFacharbeiter,
        hoursHilfsarbeiter: hoursHilfsarbeiter,
      }));
  };
  print = () => {
    this.setState({
      workingHoursPrint: []
    })
    Promise.all([
       this.calcNewHours(),
      this.getChangedData(),
    ]).then((result) => {
      if (this.state.dataRefreshedBills && this.state.dataRefreshedNotes && this.state.dataRefreshedHours) {
        const timerId = setTimeout(() => {
          this.setState({
            print: true,
            dataRefreshedBills: false,
            dataRefreshedNotes: false,
            dataRefreshedHours: false,
          });
        }, 750);
      }
    });
  };
  resetButton = () => {
    if (this.state.print) {
      this.setState({
        print: false,
      });
    }
  };
  getChangedData = () => {
    this.setState({
      dataRefreshedBills: true,
      dataRefreshedNotes: true,
      dataRefreshedHours: true
    });
  };
  getChangedDataBills = (data, billsNr) => {
    const indexToUpdate = this.state.bills.findIndex(
      (obj) => obj.billsNr === billsNr
    );
    if (indexToUpdate !== -1) {
      const updateData = [...this.state.bills];
      updateData[indexToUpdate] = {
        ...updateData[indexToUpdate],
        articles: data,
      };
      this.setState({
        bills: updateData,
      });
    }
  };
  getChangedDataNotes = (data, notesNr) => {
    const indexToUpdate = this.state.deliveryNotes.findIndex(
      (obj) => obj.notesNr === notesNr
    );

    if (indexToUpdate !== -1) {
      const updateData = [...this.state.deliveryNotes];
      updateData[indexToUpdate] = {
        ...updateData[indexToUpdate],
        articles: data,
      };
      this.setState({
        deliveryNotes: updateData,
      });
    }
  };
  getChangedDataHours = (data, lastName, firstName, categorie) => {
    const newArray = [].concat.apply([], data.map((employee) =>
     ({
      "id": employee.id,
      "key": employee.key,
      "date": employee.date,
      "beginning": employee.beginning,
      "end": employee.end,
      "pause": employee.pause,
      "driveTime": employee.driveTime,
      "result": employee.result,
      "checked": employee.checked,
      "lastName": lastName,
      "firstName": firstName,
      "categorie": categorie,
      "bv": employee.bv,
      "bvId": employee.bvId
     })
  ));
  
    this.setState({
      workingHoursPrint: [...this.state.workingHoursPrint, ...newArray]
    })
    

  };
  render() {
    return (
      <React.Fragment>
        <section id="directionWork">
          <div className="wrapper-btn-text row no-print">
            <div className="col-2">
              <Link to="/home/regiearbeit">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-8">
              <h1>
                <FontAwesomeIcon color="var(--yellow)" icon="pencil-alt" />{" "}
                Regiebericht
              </h1>
            </div>
          </div>
        </section>
        <section id="directionWorkReport" className="display-item content">
          <div>
            <DirectionWorkReportInput readAllInfos={this.readAllInfos} />
            <div className={this.state.hideHours ? "hide " : ""}>
              <h2 className=" no-print">
                Stundenübersicht (Regiebericht Nr. {this.state.reportNr})<br />
                <span>
                  ({this.state.bvId} - {this.state.bv})
                </span>
              </h2>
              <div className="scrollable-div">
                {this.state.workingHours.map((item, idx) => {
                  return (
                    <HoursListReport
                      key={idx}
                      objId={idx}
                      id={item.id}
                      firstName={item.firstName}
                      lastName={item.lastName}
                      bv={item.bv}
                      bvId={item.bvId}
                      hours={item.hours}
                      categorie={item.categorie}
                      reportNr={this.state.reportNr}
                      save={this.state.save}
                      printAndCalc={this.state.printAndCalc}
                      collectResult={this.collectResult}
                      helpCalcHours={this.helpCalcHours}
                      resetButton={this.resetButton}
                      print={this.state.print}
                      dataRefreshedHours={this.state.dataRefreshedHours}
                      getChangedDataHours={this.getChangedDataHours}
                    />
                  );
                })}
              </div>
              <div className="row no-print hours-per-category">
                <div className="col-md-12">
                  <b>Vorarbeiter:</b> {this.state.hoursVorarbeiter} h<br />
                  <b>Hilfsarbeiter:</b> {this.state.hoursHilfsarbeiter} h<br />
                  <b>Facharbeiter:</b> {this.state.hoursFacharbeiter} h<br />
                </div>
              </div>
            </div>
            <div className={this.state.hideList ? "hide " : "row   margin-top"}>
              <div className="col-12">
                <h2 className="no-print">
                  Leistungsübersicht (Regiebericht Nr. {this.state.reportNr})
                  <br />
                  <span>
                    ({this.state.bvId} - {this.state.bv})
                  </span>
                </h2>
              </div>
              <div
                className={
                  this.state.serviceOverview === ""
                    ? "no-print col-12"
                    : "col-12"
                }
              >
                <Textarea
                  minRows={4}
                  name="serviceOverview"
                  value={this.state.serviceOverview}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="page-break-inside">
              <div className={this.state.hideNotes ? "hide " : ""}>
                <h2 className="no-print">
                  Lieferscheinübersicht (Regiebericht Nr. {this.state.reportNr})
                  <br />
                  <span>
                    ({this.state.bvId} - {this.state.bv})
                  </span>
                </h2>
                <div className="scrollable-div">
                  {this.state.deliveryNotes.map((item, idx) => {
                    return (
                      <DeliveryNotesListReport
                        key={idx}
                        id={item.id}
                        date={item.date}
                        notesNr={item.notesNr}
                        supplier={item.supplier}
                        supplierId={item.supplierId}
                        bv={item.bv}
                        bvId={item.bvId}
                        reportNr={this.state.reportNr}
                        articles={item.articles}
                        save={this.state.save}
                        printPrice={this.state.printPrice}
                        supplierAll={this.state.supplierAll}
                        calcPriceComplete={this.calcPriceComplete}
                        notesBillSplit={this.state.notesBillSplit}
                        resetButton={this.resetButton}
                        print={this.state.print}
                        dataRefreshedNotes={this.state.dataRefreshedNotes}
                        getChangedDataNotes={this.getChangedDataNotes}
                      />
                    );
                  })}
                </div>

                <div className="row no-print hours-per-category">
                  <div className="col-md-12">
                    <b>Gesamtsumme:</b> {this.state.notesPriceComplete} €
                  </div>
                </div>
              </div>

              <div className={this.state.hideBills ? "hide " : ""}>
                <h2 className="no-print">
                  Rechnungsübersicht (Regiebericht Nr. {this.state.reportNr})
                  <br />
                  <span>
                    ({this.state.bvId} - {this.state.bv})
                  </span>
                </h2>
                <div className="scrollable-div">
                  {this.state.bills.map((item, idx) => {
                    return (
                      <BillsListReport
                        key={idx}
                        id={item.id}
                        date={item.date}
                        billsNr={item.billsNr}
                        notesNr={item.notesNr}
                        supplier={item.supplier}
                        supplierId={item.supplierId}
                        bv={item.bv}
                        bvId={item.bvId}
                        reportNr={this.state.reportNr}
                        articles={item.articles}
                        save={this.state.save}
                        printPrice={this.state.printPrice}
                        supplierAll={this.state.supplierAll}
                        calcPriceCompleteBills={this.calcPriceCompleteBills}
                        notesBillSplit={this.state.notesBillSplit}
                        print={this.state.print}
                        resetButton={this.resetButton}
                        dataRefreshedBills={this.state.dataRefreshedBills}
                        getChangedDataBills={this.getChangedDataBills}
                      />
                    );
                  })}
                </div>
                <div className="row no-print hours-per-category">
                  <div className="col-md-12">
                    <b>Gesamtsumme:</b> {this.state.billsPriceComplete} €
                  </div>
                </div>
              </div>

              <div
                className={this.state.hideList ? "hide " : "row   margin-top"}
              >
                <div className="col-12">
                  <h2 className="no-print">
                    Geräteeinsatz (Regiebericht Nr. {this.state.reportNr})<br />
                    <span>
                      ({this.state.bvId} - {this.state.bv})
                    </span>
                  </h2>
                </div>
                <div
                  className={
                    this.state.equipment === "" ? "no-print col-12" : "col-12"
                  }
                >
                  <Textarea
                    minRows={2}
                    name="equipment"
                    value={this.state.equipment}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className={this.state.hideList ? "hide " : "no-print"}>
              <div className="row row-width-90">
                <div className="col-3 checkbox-price">
                  <div className="justify-content">
                    <input
                      type="checkbox"
                      onChange={this.handleCheck}
                      name="split"
                      defaultChecked={this.state.notesBillSplit}
                    />
                    <p>Lieferschein/Rechnung aufschlüsseln</p>
                  </div>
                </div>
                <div className="col-3 checkbox-price">
                  <div className="justify-content">
                    <input
                      type="checkbox"
                      onChange={this.handleCheck}
                      name="printPrice"
                      defaultChecked={this.state.printPrice}
                    />
                    <p>Preise drucken</p>
                  </div>
                </div>

                <div className="col-3">
                  <button
                    disabled={this.state.disableSave}
                    type="button"
                    className="btn-save-directionWork"
                    onClick={this.save}
                  >
                    Speichern <FontAwesomeIcon icon="save" />
                  </button>
                </div>
                <div className="col-3">
                  <button
                    className={
                      !this.state.print ? "btn-save-directionWork" : "hide"
                    }
                    type="button"
                    disabled={this.state.disableSave}
                    onClick={this.print}
                  >
                    <b>Regiebericht</b>
                    <br />
                    generieren <FontAwesomeIcon icon="print" />
                  </button>
                  {this.state.print ? (
                    <PDFDownloadLink
                      document={
                        <PrintReport
                          workingHoursPrint={this.state.workingHoursPrint}
                          customerName={this.state.customerName}
                          bv={this.state.bv}
                          reportNr={this.state.reportNr}
                          hoursFacharbeiter={this.state.hoursFacharbeiter}
                          hoursHilfsarbeiter={this.state.hoursHilfsarbeiter}
                          hoursVorarbeiter={this.state.hoursVorarbeiter}
                          hoursComplete={this.state.hoursComplete}
                          serviceOverview={this.state.serviceOverview}
                          notesBillSplit={this.state.notesBillSplit}
                          deliveryNotes={this.state.deliveryNotes}
                          supplierAll={this.state.supplierAll}
                          printPrice={this.state.printPrice}
                          bills={this.state.bills}
                          equipment={this.state.equipment}
                        />
                      }
                      fileName={
                        `Regiebericht-` +
                        this.state.bvId +
                        "-" +
                        this.state.reportNr +
                        "-(" +
                        this.state.bv +
                        ")"
                      }
                      className="btn-download-regie fullwidth"
                    >
                      {({ blob, url, loadingAngebot, error }) =>
                        loadingAngebot ? (
                          <span>Regiebericht wird erstellt</span>
                        ) : (
                          <span>
                            <b>Regiebericht</b>
                            <br />
                            herunterladen <FontAwesomeIcon icon="download" />
                          </span>
                        )
                      }
                    </PDFDownloadLink>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default DirectionWorkReport;
