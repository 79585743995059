import React, { Component } from "react";
import HoursReportingWageAccountingList from "../hoursreporting/HoursReportingWageAccountingList";
import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class HoursReportingWageAccounting extends Component {
  constructor() {
    super();
    this.state = {
      name: "-",
      firstName: "",
      lastName: "",
      years: "-",
      month: "-",
      day: "",
      itemsAll: [],
      errorSearch: false,
      errorInput: false,
      hideSpan: true,
      search: "",
      btnAddItem: true,
      useSearch: false,
      showHoursReport: [],
      showHoursReportCopy: [],
      hoursMonth: [],
      calcAll: "",
      searchArr: [],
      showMissed: false,
       hideSearch: true,
      hidePrintSingle: true,
      hidePrintAll: true,
      hidePrintAllButton: true,
      hideList: false,
      disablePrint: true,
      employeesNames: [],
      yearsAll: [],
      hoursComplete: "",
      monthNames: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember"
      ]
    };
  }

  componentDidMount = () => {
    this.readEmployeesNames();
    this.readYears();
    this.setState({
      years: new Date().getFullYear()
    });
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,

      errorInput: false
    });

    if (e.target.name === "month" && e.target.value !== "Monat") {
      this.setState({
        hidePrintAll: false
      });
     }

    if (
      e.target.name === "name" &&
      e.target.value !== "-" &&
      (this.state.years !== "-" && this.state.month !== "-")
    ) {
      this.setState({
        hidePrintAll: false,
        hidePrintAllButton: true,
        hidePrintSingle: false,
        hideSearch: false,
        showHoursReport: [],
        disablePrint: true,
        hideSpan: true,
     });
    }
    if (
      e.target.name === "years" &&
      e.target.value !== "-" &&
      (this.state.name !== "-" && this.state.month !== "-")
    ) {
      this.setState({
        hidePrintAll: false,
        hidePrintAllButton: true,
        hidePrintSingle: false,
        hideSearch: false,
       });
    }
    if (
      e.target.name === "month" &&
      e.target.value !== "-" &&
      (this.state.name !== "-" && this.state.years !== "-")
    ) {
      this.setState({
        hidePrintAll: false,
        hidePrintAllButton: true,
        hidePrintSingle: false,
        hideSearch: false,
       });
     }
    if (
      e.target.name === "years" &&
      e.target.value !== "-" &&
      (this.state.name === "-" && this.state.month !== "-")
    ) {
      this.setState({
        hidePrintAll: false,
        hidePrintSingle: false,
        disablePrint: true,
        hidePrintAllButton: false,
        hideSearch: true,
        hideSpan: true,
       });
    }
    if (
      e.target.name === "month" &&
      e.target.value !== "-" &&
      (this.state.name === "-" && this.state.years !== "-")
    ) {
      this.setState({
         hidePrintAll: false,
        hidePrintSingle: false,
        disablePrint: true,
        hidePrintAllButton: false,
        hideSearch: true,
        hideSpan: true,
       });

    }
    if (
      e.target.name === "month" &&
      e.target.value !== "-" &&
      (this.state.name === "-" && this.state.years === "-" || e.target.name === "years" && e.target.value === "-")
    ) {
      this.setState({
         hidePrintAll: true,
        hidePrintSingle: true,
        disablePrint: true,
        hidePrintAllButton: true,
        hideSearch: true,
        hideSpan: true,
       });
     }
    if (e.target.name === "name" && e.target.value === "-" && this.state.years !== "-" && this.state.month !== "-") {
      this.setState({
        showHoursReport:[],
        hidePrintAll: false,
        hidePrintSingle: false,
        disablePrint: true,
        hidePrintAllButton: false,
        hideSearch: true,
        hideSpan: true,
       });
    }
    if (e.target.name === "name" && e.target.value === "-" && this.state.years === "-" && this.state.month === "-" ||
        e.target.name === "month" && e.target.value === "-" && this.state.name === "-" && this.state.years === "-" ||
        e.target.name === "years" && e.target.value === "-" && this.state.name === "-" && this.state.month === "-" ||
        e.target.name === "years" && e.target.value === "-" && this.state.name === "-" && this.state.month !== "-" ||
        e.target.name === "month" && e.target.value === "-" && this.state.name === "-" && this.state.years !== "-" ||
        e.target.name === "month" && e.target.value === "-" && this.state.name !== "-" && this.state.years !== "-" ||
      e.target.name === "years" && e.target.value === "-" && this.state.name !== "-" && this.state.month !== "-") {
      this.setState({
        hidePrintAll: false,
        hidePrintSingle: false,
        disablePrint: true,
        hidePrintAllButton: true,
        hideSearch: true,
        hideSpan: true,
       });
    }
  };
  onKeyPress = e => {
    this.search(e.target.value);
    this.setState({
      hideSpan: false
    });
  };
  onKeyPressPrint = e => {
    document.title =
      this.state.lastName +
      "_" +
      this.state.firstName +
      "(" +
      this.state.month +
      "-" +
      this.state.years +
      ")";
    window.print();
    document.title = "Kosten-und Leistungsrechnung";
    return false;
  };

  onKeyPressPrintAll = e => {
    const wait = this.state.employeesNames.map(item => {
      this.setState({
        showHoursReport: []
      });
      return fetch(
        BaseUrl + "api/hoursreporting/searchHoursReporting.php?lastName=" +
          item.lastName +
          "&firstName=" +
          item.firstName +
          "&year=" +
          this.state.years +
          "&month=" +
          this.state.month
      )
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.message === "No items found.") {
          } else {
            this.setState({
              showHoursReport: this.state.showHoursReport.concat([
                responseJson.records
              ])
            });
          }
        })
        .catch(error => {});
    });
    Promise.all(wait).then(values => {
      this.combineDates();
      document.title =
        "Stundenberichte" +
        "(" +
        this.state.month +
        "-" +
        this.state.years +
        ")";
      window.print();
      document.title = "Kosten-und Leistungsrechnung";
    });

    return false;
  };

  readEmployeesNames = () => {
    return fetch(BaseUrl + "api/hoursreporting/readEmployeesNames.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            employeesNames: responseJson.records
          });
        });
      })
      .catch(error => {});
  };
  readYears = () => {
    return fetch(BaseUrl + "api/hoursreporting/readYears.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            yearsAll: responseJson.records
          });
        });
      })
      .catch(error => {});
  };

  timeToMins = time => {
    var b = time.split(":");
    return b[0] * 60 + +b[1];
  };

  timeFromMins = mins => {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    var h = (mins / 60) | 0;
    var m = mins % 60;
    return z(h) + ":" + z(m);
  };

  addTimes = (t0, t1) => {
    return this.timeFromMins(this.timeToMins(t0) + this.timeToMins(t1));
  };

  search = eingabe => {
    const wait = this.state.employeesNames.map(name => {
      if (name.lastName + " " + name.firstName === this.state.name) {
        this.setState({
          firstName: name.firstName,
          lastName: name.lastName
        });

      }
              return this;
    });
    Promise.all(wait).then(values => {
      this.setState({
        showHoursReport: []
      });
      return fetch(
        BaseUrl + "api/hoursreporting/searchHoursReporting.php?lastName=" +
          this.state.lastName +
          "&firstName=" +
          this.state.firstName +
          "&year=" +
          this.state.years +
          "&month=" +
          this.state.month
      )
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.message === "No items found.") {
            this.setState({
              showHoursReport: [],
              errorSearch: true,
              hideSpan: true,
              disablePrint: true,
               hideList: false
            });
          } else {
            this.setState({
              errorSearch: false,
              showHoursReport: [responseJson.records],
              hideList: false,
              disablePrint: false,
             });
            this.combineDates();
          }
        })
        .catch(error => {});
    });
  };

  combineDates = () => {
    for (let i = 0; i < this.state.showHoursReport.length; i++) {
      this.state.showHoursReport[i].map((item, ix) => {
        if (ix + 1 < this.state.showHoursReport[i].length) {
          while (
            this.state.showHoursReport[i][ix].date ===
            this.state.showHoursReport[i][ix + 1].date
          ) {
            this.state.showHoursReport[i][ix + 1].result = this.addTimes(
              this.state.showHoursReport[i][ix].result,
              this.state.showHoursReport[i][ix + 1].result
            );
            this.state.showHoursReport[i].splice(ix, 1);
            if (ix + 1 === this.state.showHoursReport[i].length) {
              --ix;
            }
          }
        } else {
          return false;
        }
        return this;
      });
      this.setState({
        showHoursReport: this.state.showHoursReport
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <section id="insertHoursReporting" className="no-print">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home/stundenbericht">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon
                  color="var(--greenSeyband)"
                  icon="sign-out-alt"
                />{" "}
                Stundenbericht Lohnbüro
              </h1>
            </div>
          </div>
        </section>
        <section id="showHoursReports" className="display-item content">
          <h4 className="no-print">Stundenbericht</h4>

          <div className="no-print row">
            <div className="col-2 offset-2">
              <select
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
              >
                <option key="-1" value="-">
                  Name
                </option>
                {this.state.employeesNames.map((names, i) => (
                  <option key={i} value={names.name}>
                    {names.lastName} {names.firstName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-2">
              <select
                name="years"
                value={this.state.years}
                onChange={this.handleChange}
              >
                <option key="-1" value="-">
                  Jahr
                </option>
                {this.state.yearsAll.map((year, i) => (
                  <option key={i} value={year.years}>
                    {year.years}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-2">
              <select
                name="month"
                value={this.state.month}
                onChange={this.handleChange}
              >
                <option key="-1" value="-">
                  Monat
                </option>
                {this.state.monthNames.map((month, i) => (
                  <option key={i} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-1 hoursreporting">
              <button
                type="button"
                onClick={this.onKeyPress}
                className="btn-add-item"
                disabled={this.state.hideSearch}
              >
                <FontAwesomeIcon icon="search" />
              </button>
            </div>
            <div className="col-1 hoursreporting">
              <button
                type="button"
                disabled={this.state.disablePrint}
                onClick={this.onKeyPressPrint}
                className="btn-add-item"
              >
                <FontAwesomeIcon icon="print" />
              </button>
            </div>
            <div className="col-1 hoursreporting">
              <button
                type="button"
                onClick={this.onKeyPressPrintAll}
                className="btn-add-item"
                disabled={this.state.hidePrintAllButton}
              >
                <FontAwesomeIcon icon="file-pdf" />
              </button>
            </div>

          </div>

          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          <p
            className={this.state.hideList ? "wage-accounting druck-p" : "hide"}
          >
            Liste zum druck bereit
          </p>
          {this.state.showHoursReport.map((i, idx) => {
            return (
              <HoursReportingWageAccountingList
                firstName={i[0].firstName}
                lastName={i[0].lastName}
                years={this.state.years}
                month={this.state.month}
                showHoursReport={this.state.showHoursReport}
                hideList={this.state.hideList}
                key={idx}
                i={i}
              />
            );
          })}
        </section>
      </React.Fragment>
    );
  }
}
export default HoursReportingWageAccounting;
