import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-solid";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
import CSVReader from "react-csv-reader";
 var $ = require("jquery");

class SpecificationListFormInputTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bvId: "",
      bvAll: this.props.bvAll,
      customerId: "",
      customer: "",
      slAll: [
        { pos: "", dsc: "", unit: "", pricePiece: "", targetQuantity: "" }
      ],
      errorSearch: false,
      errorInput: false,
      btnAddItem: true,
      useSearch: false,
      searchRes: [],
      searchNewArray: [],
      searchArr: [],
      searchHelp: [],
      disabledFieldset: false,
      wait: false,
     };
  }
  componentDidMount = () => {
     if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true
      });
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      errorInput: false
    });
    if (e.target.name === "bv") {
      this.readBvIdByName(e.target.value,true);
      this.props.resetSL();

    }
  };

  autocompleteBv = e => {
    if (e.keyCode === 9) {
      this.readBvNameById(e.target.value,true);
      this.readCustomerNameId(e.target.value);
    }
  };

  readBvNameById = (id, bool) => {
    let found = this.props.bvAll.find(name => name.bvId === id);
    if (found === undefined) {
      this.props.readOldBvById(id);
      setTimeout(() => {
        this.setState({
          bv: this.props.bvAllFound
        });
      }, 200);
    }
    if (bool === true && found !== undefined) {
      return this.setState({
        bv: found.bv
      });
    } else if (bool === false && found !== undefined) {
      return found.bv;
    }

  };
  readBvIdByName = (name, bool) => {
     let found = this.props.bvAll.find(id => id.bv === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      this.readCustomerNameId(found.bvId);

      return this.setState({
        bvId: found.bvId
      });
    } else {
      return found.bvId;
    }
  };
  readCustomerNameId = eingabe => {
    return fetch(BaseUrl + "api/all/readCustomerNameId.php?bvId=" + eingabe)
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            customer: item.customerName,
            customerId: item.customerId
          });
        });
      })
      .catch(error => {});
  };
  handleSubmit = e => {
    e.preventDefault();
    let specificationList;
    this.state.slAll.map(
      (items, idx) => (
        (specificationList = {
          bvId: this.state.bvId,

          pos: items.pos,
          dsc: items.dsc,
          unit: items.unit,
          pricePiece: items.pricePiece.replace(/,/g, "."),
          targetQuantity: items.targetQuantity
        }),
        this.saveSpecificationList(specificationList)
      )
    );
    this.loadLastEntrys();
    this.loadSL();
  };

  saveSpecificationList = (specificationList) => {
    $.ajax({
      url: BaseUrl + "api/specificationList/createSpecificationList.php",
      type: "POST",
      dataType: "json",
      data: JSON.stringify(specificationList),
      success: function(response) {

      }.bind(this),
      error: function(xhr, resp, text) {}
    });

  };
loadLastEntrys = () => {
  this.props.loadLastSl(this.state.bvId, this.state.slAll.length-1);
  this.state.slAll.map(
    (items, idx) => {
      this.setState({
        slAll: [
          { pos: "", dsc: "", unit: "", pricePiece: "", targetQuantity: "" }
        ]
      });
    })

}
  handleAddDeliveryItem = () => {
    this.setState({
      slAll: this.state.slAll.concat([
        { pos: "", dsc: "", unit: "", pricePiece: "", targetQuantity: "" }
      ])
    });
  };

  handelRemoveSpecificationList = idx => () => {
    this.setState({
      slAll: this.state.slAll.filter((s, sidx) => idx !== sidx)
    });
  };
  handleChangeSpecification = idx => evt => {
    const sl = this.state.slAll.map((sl, sidx) => {
      if (idx !== sidx) return sl;
      return { ...sl, [evt.target.name]: evt.target.value };
    });
    this.setState({ slAll: sl });
  };
  loadSL = () => {
    this.props.loadSpecificationList(this.state.bvId);
  };

  handleUpload = data => {
    this.setState({
      slAll: [],
     });
         data.map((item, i) => {
        if (i > 0 && i + 1 < data.length) {
          this.state.slAll = this.state.slAll.concat([
            {
              id: i,
              pos:  item[0],
              dsc: item[1],
              unit: item[2],
              pricePiece: item[3].replace(",",".").replace("€",""),
              targetQuantity: item[4]
            }
          ]);
        }
        return true;
      });
      this.setState({
        slAll: this.state.slAll
      });

  };

  render() {
    return (
      <React.Fragment>
        <section id="specification">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--yellow)" icon="list-alt" />{" "}
                Leistungsverzeichnis
              </h1>
            </div>
          </div>
          <form onSubmit={this.handleSubmit} className="customer">
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="row">
                <div className="col-2 tab">
                  <p>Bv-Id</p>
                  <input
                    required
                    type="text"
                    name="bvId"
                    onChange={this.handleChange}
                    value={this.state.bvId}
                    onKeyDown={this.autocompleteBv}
                  />
                  <span>↹</span>
                </div>
                <div className="col-4">
                  <p>Bv</p>

                  <select
                    name="bv"
                    value={this.state.bv}
                    onChange={this.handleChange}
                  >
                    <option value="-">-</option>
                    {this.props.bvAll.map((item, i) => (
                      <option key={i} value={item.bv}>
                        {item.bv}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-1">
                  <p>Kunden-Id</p>
                  <input
                    disabled="true"
                    type="text"
                    name="customerId"
                    onChange={this.handleChange}
                    value={this.state.customerId}
                  />
                </div>
                <div className="col-3">
                  <p>Kunde</p>
                  <input
                    disabled="true"
                    type="text"
                    name="customer"
                    onChange={this.handleChange}
                    value={this.state.customer}
                  />
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    className="btn-search"
                    onClick={this.loadSL}
                  >
                    Suchen{" "}
                    <FontAwesomeIcon color="var(--white)" icon="search" />{" "}
                  </button>
                </div>
              </div>

              <div className={this.props.hideFields ? "hide" : "show"}>
                <hr />
                <div className="item-btn col-2">
                  <FontAwesomeIcon
                    id="upload-icon"
                    color="var(--white)"
                    icon="upload"
                  />
                  <CSVReader
                    cssClass="csv-input"
                    onFileLoaded={this.handleUpload}
                    inputId="upload"
                   />
                </div>

                <hr />
                <h4>Leistungen</h4>
                {this.state.slAll.map((items, idx) => (
                  <div key={idx} className="row">
                    <div className="col-1">
                      <p>{`Position #${idx + 1}`}</p>
                      <input
                        type="text"
                        name="pos"
                        onChange={this.handleChangeSpecification(idx)}
                        value={items.pos}
                      />
                    </div>
                    <div className="col-5">
                      <p>{`Bezeichnung #${idx + 1}`}</p>
                      <input
                        type="text"
                        name="dsc"
                        onChange={this.handleChangeSpecification(idx)}
                        value={items.dsc}
                      />
                    </div>
                    <div className="col-1">
                      <p>{`Einheit #${idx + 1}`}</p>
                      <p>Einheit</p>
                      <select
                        name="unit"
                        onChange={this.handleChangeSpecification(idx)}
                        value={items.unit}
                      >
                        <option key="-1" value="-">
                          -
                        </option>
                        <option key={1} value="Beutel">
                          Beutel
                        </option>
                        <option key={2} value="Bund">
                          Bund
                        </option>
                        <option key={3} value="Dose(n)">
                          Dose(n)
                        </option>
                        <option key={4} value="Eimer">
                          Eimer
                        </option>
                        <option key={5} value="Flasche(n)">
                          Flasche(n)
                        </option>
                        <option key={6} value="Gebinde">
                          Gebinde
                        </option>
                        <option key={7} value="Gramm">
                          Gramm
                        </option>
                        <option key={8} value="Karton">
                          Karton
                        </option>
                        <option key={9} value="KG">
                          KG
                        </option>
                        <option key={10} value="Kübel">
                          Kübel
                        </option>
                        <option key={11} value="LE">
                          LE
                        </option>
                        <option key={12} value="Lfm.">
                          Lfm.
                        </option>
                        <option key={13} value="Liter">
                          Liter
                        </option>
                        <option key={14} value="m2">
                          m2
                        </option>
                        <option key={15} value="m3">
                          m3
                        </option>
                        <option key={16} value="Minuten">
                          Minuten
                        </option>
                        <option key={17} value="Paar">
                          Paar
                        </option>
                        <option key={18} value="Pack">
                          Pack
                        </option>
                        <option key={19} value="Paket">
                          Paket
                        </option>
                        <option key={20} value="Palette(n)">
                          Palette(n)
                        </option>
                        <option key={21} value="Pauschal">
                          Pauschal
                        </option>
                        <option key={22} value="Rolle(n)">
                          Rolle(n)
                        </option>
                        <option key={23} value="Sack">
                          Sack
                        </option>
                        <option key={24} value="Satz">
                          Satz
                        </option>
                        <option key={25} value="Set">
                          Set
                        </option>
                        <option key={26} value="Stück">
                          Stück
                        </option>
                        <option key={27} value="Stunden">
                          Stunden
                        </option>
                        <option key={28} value="Tag/e">
                          Tag/e
                        </option>
                        <option key={29} value="Tonne(n)">
                          Tonne(n)
                        </option>
                      </select>
                    </div>
                    <div className="col-2">
                      <p>{`Einzelpreis #${idx + 1}`}</p>
                      <input
                        type="text"
                        name="pricePiece"
                        onChange={this.handleChangeSpecification(idx)}
                        value={items.pricePiece}
                      />
                    </div>
                    <div className="col-2">
                      <p>{`Sollmenge #${idx + 1}`}</p>
                      <input
                        type="text"
                        name="targetQuantity"
                        onChange={this.handleChangeSpecification(idx)}
                        value={items.targetQuantity}
                      />
                    </div>
                    <div className="col-1">
                      <button
                        type="button"
                        onClick={this.handelRemoveSpecificationList(idx)}
                        className="btn-remove"
                      >
                        -
                      </button>
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="col-11" />
                  <div className="col-1">
                    <button
                      type="button"
                      onClick={this.handleAddDeliveryItem}
                      className="btn-add-item"
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="item-btn col-12">
                    <button className="btn-add-item fullwidth">
                      Leistung(en) speichern <FontAwesomeIcon icon="save" />
                    </button>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </section>
      </React.Fragment>
    );
  }
}

export default SpecificationListFormInputTop;
