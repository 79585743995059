import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import Textarea from "react-textarea-autosize";
import BaseUrl from "../../config/config.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

 var $ = require("jquery");
 class OfferBaseData extends Component {
  constructor() {
    super();
    this.state = {
      id:"",
      name: "",
      strasse: "",
      plz:"",
      land:"",
      tel: "",
      telefax: "",
      mobil: "",
      mail: "",
      web:"",
      sitzGesellschaft: "",
      stNr: "",
      ustNr: "",
      geschaeftsfuehrer: "",
      bank: "",
      blz: "",
      kontoNr: "",
      iban: "",
      bic: "",
      textAnschreiben: "",
      textNetto: "",
      schlusstext: "",
      auftragsbestaetigung : "",
      widerruf: "",
      Widerrufsformular: "",
      errorSearch: false,
      search: "",
      useSearch: false,
      calcPriceAll: [],
      calcPrice: "",
      calcPriceId: "",
      saved: false
    };
  }

  componentDidMount = () => {
    this.loadBaseData();
     if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

  };
  handleSubmit = e => {
    e.preventDefault();

          this.updateNote( );

  };

  updateNote = e => {
    var updateBaseData;

      if (this.state.id === "undefined") {
        updateBaseData = {
          id: "0",
          name: this.state.name,
          strasse: this.state.strasse,
          plz: this.state.plz,
          land: this.state.land,
          tel: this.state.tel,
          telefax: this.state.telefax,
          mobil: this.state.mobil,
          mail: this.state.mail,
          web: this.state.web,
          sitzGesellschaft: this.state.sitzGesellschaft,
          stNr: this.state.stNr.trim(),
          ustNr: this.state.ustNr.trim(),
          geschaeftsfuehrer: this.state.geschaeftsfuehrer,
          bank: this.state.bank,
          blz: this.state.blz.trim(),
          kontoNr: this.state.kontoNr,
          iban: this.state.iban.trim(),
          bic: this.state.bic.trim(),
          textAnschreiben: this.state.textAnschreiben,
          textNetto: this.state.textNetto,
          schlusstext: this.state.schlusstext,
          widerruf: this.state.widerruf,
          widerrufsformular: this.state.widerrufsformular,
          auftragsbestaetigung: this.state.auftragsbestaetigung
        };
      } else {
        updateBaseData = {
          id: this.state.id,
          name: this.state.name,
          strasse: this.state.strasse,
          plz: this.state.plz,
          land: this.state.land,
          tel: this.state.tel,
          telefax: this.state.telefax,
          mobil: this.state.mobil,
          mail: this.state.mail,
          web: this.state.web,
          sitzGesellschaft: this.state.sitzGesellschaft,
          stNr: this.state.stNr.trim(),
          ustNr: this.state.ustNr.trim(),
          geschaeftsfuehrer: this.state.geschaeftsfuehrer,
          bank: this.state.bank,
          blz: this.state.blz.trim(),
          kontoNr: this.state.kontoNr,
          iban: this.state.iban.trim(),
          bic: this.state.bic.trim(),
          textAnschreiben: this.state.textAnschreiben,
          textNetto: this.state.textNetto,
          schlusstext: this.state.schlusstext,
          widerruf: this.state.widerruf,
          widerrufsformular: this.state.widerrufsformular,
          auftragsbestaetigung: this.state.auftragsbestaetigung
        };
      }
      $.ajax({
        url: BaseUrl + "api/baseData/updateBaseData.php",
        type: "POST",
        dataType: "text",
        data: JSON.stringify(updateBaseData),
        success: function(response) {
          this.setState({
            saved: true
          })

        }.bind(this),
        error: function(xhr, resp, text) {}
      });


   };
  loadBaseData = () => {
     return fetch(BaseUrl + "api/baseData/readBaseData.php")
      .then(response => response.json())
      .then(responseJson => {
           return this.setState({
             id: responseJson.records[0][0].id,
             name: responseJson.records[0][0].name,
             strasse: responseJson.records[0][0].strasse,
             plz: responseJson.records[0][0].plz,
             land: responseJson.records[0][0].land,
             tel: responseJson.records[0][0].tel,
             telefax: responseJson.records[0][0].telefax,
             mobil: responseJson.records[0][0].mobil,
             mail: responseJson.records[0][0].mail,
             web: responseJson.records[0][0].web,
             sitzGesellschaft: responseJson.records[0][0].sitzGesellschaft,
             stNr: responseJson.records[0][0].stNr,
             ustNr: responseJson.records[0][0].ustNr,
             geschaeftsfuehrer: responseJson.records[0][0].geschaeftsfuehrer,
             bank: responseJson.records[0][0].bank,
             blz: responseJson.records[0][0].blz,
             kontoNr: responseJson.records[0][0].kontoNr,
             iban: responseJson.records[0][0].iban,
             bic: responseJson.records[0][0].bic,
             textAnschreiben: responseJson.records[0][0].textAnschreiben,
             textNetto: responseJson.records[0][0].textNetto,
             schlusstext: responseJson.records[0][0].schlusstext,
             widerruf: responseJson.records[0][0].widerruf,
             widerrufsformular: responseJson.records[0][0].widerrufsformular,
             auftragsbestaetigung: responseJson.records[0][0].auftragsbestaetigung
           });
       })
      .catch(error => {});
  };

  onKeyPress = e => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };

  render() {
    return (
      <React.Fragment>
        <section id="baseData">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home/angebot">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--yellow)" icon="database" />{" "}
                Angebot Stammdaten
              </h1>
            </div>
          </div>

          <form className="baseData" onSubmit={this.handleSubmit}>
            <fieldset disabled={this.state.disabledFieldset}>
              <h4>Anschrift</h4>
              <div className="row">
                <div className="col-md-4">
                  <p>Name</p>
                  <input
                    required
                    type="text"
                    name="name"
                    onChange={this.handleChange}
                    value={this.state.name}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <p>Strasse</p>
                  <input
                    required
                    type="text"
                    name="strasse"
                    onChange={this.handleChange}
                    value={this.state.strasse}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <p>Postleitzahl</p>
                  <input
                    required
                    type="text"
                    name="plz"
                    onChange={this.handleChange}
                    value={this.state.plz}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <p>Land</p>
                  <input
                    required
                    type="text"
                    name="land"
                    onChange={this.handleChange}
                    value={this.state.land}
                  />
                </div>
              </div>
              <h4>Kontakt</h4>
              <div className="row">
                <div className="col-md-4">
                  <p>Telefon</p>
                  <input
                    required
                    type="text"
                    name="tel"
                    onChange={this.handleChange}
                    value={this.state.tel}
                  />
                </div>
                <div className="col-md-4">
                  <p>Telefax</p>
                  <input
                    required
                    type="text"
                    name="telefax"
                    onChange={this.handleChange}
                    value={this.state.telefax}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <p>Mobil</p>
                  <input
                    required
                    type="text"
                    name="mobil"
                    onChange={this.handleChange}
                    value={this.state.mobil}
                  />
                </div>
                <div className="col-md-4">
                  <p>Email</p>
                  <input
                    required
                    type="text"
                    name="mail"
                    onChange={this.handleChange}
                    value={this.state.mail}
                  />
                </div>
                <div className="col-md-4">
                  <p>Web</p>
                  <input
                    required
                    type="text"
                    name="web"
                    onChange={this.handleChange}
                    value={this.state.web}
                  />
                </div>
              </div>
              <h4>Rechtliches</h4>
              <div className="row">
                <div className="col-md-4 margin-input-fields">
                  <p>Sitz der Gesellschaft</p>
                  <input
                    required
                    type="text"
                    name="sitzGesellschaft"
                    onChange={this.handleChange}
                    value={this.state.sitzGesellschaft}
                  />
                  <p>Steuernummer</p>
                  <input
                    required
                    type="text"
                    name="stNr"
                    onChange={this.handleChange}
                    value={this.state.stNr}
                  />
                  <p>USt-Id Nr.:</p>
                  <input
                    required
                    type="text"
                    name="ustNr"
                    onChange={this.handleChange}
                    value={this.state.ustNr}
                  />
                </div>
                <div className="col-md-4">
                  <p>Geschäftsführer</p>
                  <Textarea
                    minRows={4}
                    name="geschaeftsfuehrer"
                    onChange={this.handleChange}
                    value={this.state.geschaeftsfuehrer}
                  />
                </div>
              </div>
              <h4>Bankverbindung</h4>
              <div className="row">
                <div className="col-md-4 margin-input-fields">
                  <p>Bank</p>
                  <input
                    required
                    type="text"
                    name="bank"
                    onChange={this.handleChange}
                    value={this.state.bank}
                  />
                  <p>Bankleitzahl</p>
                  <input
                    required
                    type="text"
                    name="blz"
                    onChange={this.handleChange}
                    value={this.state.blz}
                  />
                  <p>Kontonummer</p>
                  <input
                    required
                    type="text"
                    name="kontoNr"
                    onChange={this.handleChange}
                    value={this.state.kontoNr}
                  />
                </div>

                <div className="col-md-4  margin-input-fields">
                  <p>IBAN</p>
                  <input
                    required
                    type="text"
                    name="iban"
                    onChange={this.handleChange}
                    value={this.state.iban}
                  />
                  <p>SWIFT-BIC</p>
                  <input
                    required
                    type="text"
                    name="bic"
                    onChange={this.handleChange}
                    value={this.state.bic}
                  />
                </div>
              </div>
              <h4>Text Anschreiben</h4>
              <div className="row">
                <div className="col-md-12">
                  <Textarea
                    minRows={4}
                    name="textAnschreiben"
                    onChange={this.handleChange}
                    value={this.state.textAnschreiben}
                  />
                </div>
              </div>
              <h4>Text Netto</h4>
              <div className="row">
                <div className="col-md-12">
                  <Textarea
                    minRows={4}
                    name="textNetto"
                    onChange={this.handleChange}
                    value={this.state.textNetto}
                  />
                </div>
              </div>
              <h4>Schlusstext</h4>
              <div className="row">
                <div className="col-md-12">
                  <Textarea
                    minRows={4}
                    name="schlusstext"
                    onChange={this.handleChange}
                    value={this.state.schlusstext}
                  />
                </div>
              </div>
              <h4>Auftragsbestätigung</h4>
              <div className="row">
                <div className="col-md-12">
                  <Textarea
                    minRows={4}
                    name="auftragsbestaetigung"
                    onChange={this.handleChange}
                    value={this.state.auftragsbestaetigung}
                  />
                </div>
              </div>
              <h4>Widerruf</h4>
              <div className="row">
                <div className="col-md-12">
                  <Textarea
                    minRows={4}
                    name="widerruf"
                    onChange={this.handleChange}
                    value={this.state.widerruf}
                  />
                </div>
              </div>
              <h4>Widerrufsformular</h4>
              <div className="row">
                <div className="col-md-12">
                  <Textarea
                    minRows={4}
                    name="widerrufsformular"
                    onChange={this.handleChange}
                    value={this.state.widerrufsformular}
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className={
                    this.state.saved
                      ? "saved visible offset-8 col-md-4"
                      : "saved invisible"
                  }
                >
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      color="var(--greenSeyband)"
                      icon="check-circle"
                    />{" "}
                    Daten wurden gespeichert
                  </p>
                </div>
                <div className="offset-10 col-md-2">
                  <button className="btn-create-products">
                    Daten speichern <FontAwesomeIcon icon="save" />
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </section>
      </React.Fragment>
    );
  }
}

export default OfferBaseData;
