import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseUrl from "../../config/config.js";
import moment from "moment";
import localization from "moment/locale/de";
import ConstructionDiaryPrint from "./ConstructionDiaryPrint.js";
var $ = require("jquery");

moment.locale("de", localization);
class ConstructionDiaryCreate extends Component {
  constructor() {
    super();
    this.state = {
      customerAll: [],
      customer: "",
      bvId: "",
      bv: "",
      bvAll: [],
      date: "",
      dateFrom: "",
      dateTo: "",
      erbrachteLeistung: "",
      vorkommnisse: "",
      customerId: "",
      disabledGetData: true,
      deliveryNotes: [],
      hours: [],
      hoursFilled: [],
      daysBetween: 0,
      arrDates: [],
      showText: false,
      saved: false,
      printDiary: false,
      btnPrintDisabled: true,
      gewerk: "Trockenbau",
      noNotes: true,
      noHours: true
    };
  }

  componentDidMount = () => {
    this.loadCustomerBV();

    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true,
      });
    }

    if (this.props.match.params.id !== "0") {
      this.loadDiary(this.props.match.params.id);
 
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeForm = (e) => {
    let wait = this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name.includes("bv") || e.target.name.includes("customer")) {
      this.completeCusBv(e.target.name, e.target.value);
    }
    if (e.target.name === "dateFrom") {
      this.setState({
        dateTo: moment(e.target.value)
          .endOf("week")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
      });
    }
    Promise.all([wait]).then(() => {
      this.enableBtnGetData();
    });
  };
  handleChangeDeliverd = (idx) => (evt) => {
    const newNote = this.state.deliveryNotes.map((item, sidx) => {
      if (idx !== sidx) return item;
      return { ...item, [evt.target.name]: evt.target.value };
    });
    this.setState({ deliveryNotes: newNote });
  };
  handleChangeHours = (idx, i, name) => (evt) => {
    const changedHour = this.state.hoursFilled.map((hours, sidx) => {
      if (idx !== sidx) return hours;

      return { ...hours, [name]: { ...hours[name], [i]: evt.target.value } };
    });
    this.setState({ hoursFilled: changedHour });
  };
  handleChangeName = (idx, name) => (evt) => {
    let replacements = { [name]: evt.target.value };

    const changedName = this.state.hoursFilled.map((hours, sidx) => {
      if (idx !== sidx) return hours;

      let help = {
        [replacements[Object.keys(hours)] || evt.target.value]:
          evt.target.value,
        [evt.target.value]: hours[name],
      };
      return help;
    });
    this.setState({
      hoursFilled: changedName,
    });
  };
  enableBtnGetData = () => {
    this.setState({
      disabledGetData:
        this.state.bv &&
        this.state.bvId &&
        this.state.dateFrom &&
        this.state.dateTo
          ? false
          : true,
    });
  };

  loadCustomerBV = () => {
    return fetch(BaseUrl + "api/constructionDiary/readCustomerBv.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          customerAll: responseJson.customer[0],
          bvAll: responseJson.bv[0],
        });
      })
      .catch((error) => {});
  };
  completeCusBv = (name, value) => {
    let array = name.includes("bv") ? "bvAll" : "customerAll";
    let help = name.includes("bv") ? "bv" : "customer";

    let setValue = name.includes("Id") ? help : help + "Id";

    this.state[array].filter((e) => {
      if (e[name] === value) {
        this.setState({
          [setValue]: e[setValue],
        });
      }
    });
  };
  onKeyDownComplete = (e) => {
    if (e.keyCode === 9) {
      this.completeCusBv(e.target.name, e.target.value);
    }
  };
  getData = (e) => {
    e.preventDefault();
    this.setState({
      deliveryNotes: [],
      hours: [],
      hoursFilled:[],
      showText: true,
      noNotes: true,
      noHours: true
    })
    return fetch(
      BaseUrl +
        "api/constructionDiary/readDeliveryHours.php?dateFrom=" +
        this.state.dateFrom +
        "&dateTo=" +
        this.state.dateTo +
        "&bvId=" +
        this.state.bvId
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.deliverynotes[0]){
          this.setState({
            noNotes: false
          })
        }
        if(responseJson.hours){
          this.setState({
            noHours: false
          })
        }
        let wait = this.setState({
          deliveryNotes: responseJson.deliverynotes[0] ? responseJson.deliverynotes[0] : [{}],
          hours: responseJson.hours ? responseJson.hours : [{}],
          showText: true,
          printDiary: true,
          btnPrintDisabled: false,
        });
        Promise.all([wait, this.calcDays()]).then(() => {
          this.orderHours();
        });
      })
      .catch((error) => {});
  };
  loadDiary = (id) => {
    return fetch(BaseUrl + "api/constructionDiary/loadDiary.php?id=" + id)
      .then((response) => response.json())
      .then((responseJson) => {
         this.setState({
          customerId: responseJson.cdList[0]["0"].customerId,
          customer: responseJson.cdList[0]["0"].customerName,
          bvId: responseJson.cdList[0]["0"].bvId,
          bv: responseJson.cdList[0]["0"].bv,
          date: responseJson.cdList[0]["0"].dateSaved,
          dateFrom: responseJson.cdList[0]["0"].dateFrom,
          dateTo: responseJson.cdList[0]["0"].dateTo,
          gewerk:
            responseJson.cdList[0]["0"].gewerk === ""
              ? this.state.gewerk
              : responseJson.cdList[0]["0"].gewerk,
          vorkommnisse: responseJson.cdList[0]["0"].vorkommnisse,
          erbrachteLeistung: responseJson.cdList[0]["0"].leistung,
          disabledGetData: true,
          showText: true,
          deliveryNotes: responseJson.cdListNotes[0] ? responseJson.cdListNotes[0] : [],
          hours: responseJson.cdHours ? responseJson.cdHours : [],
          btnPrintDisabled: false,
          noHours:responseJson.cdListNotes[0] ? true : false,
          noNotes: responseJson.cdHours ? true :  false

        });
        this.calcDays();
        this.orderHoursSaved();
      })
      .catch((error) => {});
  };
  calcDays = () => {
    let from = moment(this.state.dateFrom);
    let to = moment(this.state.dateTo);
    let days = moment.duration(to.diff(from)).asDays();

    let everyDays = [];

    [...Array(days + 1)].map((i, idx) => {
      everyDays = [
        ...everyDays,
        moment(this.state.dateFrom).add("days", idx).format("YYYY-MM-DD"),
      ];
    });
    this.setState({
      daysBetween: days + 1,
      arrDates: everyDays,
    });
  };
  removeDeliveryNote = (id, supplierName, notesNr, date) => {
    this.setState({
      deliveryNotes: this.state.deliveryNotes.filter((e) => e.id !== parseInt(id)),
 
    });
    if (this.props.match.params.id !== "0") {
      this.removeNoteHour("note", notesNr);
    }
  };
  addEmptyDeliveryNote = (e) => {

    e.preventDefault();
    this.setState({
      deliveryNotes: this.state.deliveryNotes.concat([
        { id: "", supplierName: "", notesNr: "", date: "", bvId: "", bv: "" },
      ]),
      noNotes: this.state.noNotes ? false : this.state.noNotes
    });
  };
  removeHours = (id, name) => {
    this.setState({
      hoursFilled: this.state.hoursFilled.filter(
        (e) => Object.keys(e)[0] !== name
      ),
    });
    if (this.props.match.params.id !== "0") {
      this.removeNoteHour("hour", name);
    }
  };
  addHours = (e) => {
    e.preventDefault();
    let help = [{}];
    this.state.arrDates.map((date, i) => {
      help = { ...help, [date]: "00:00" };
    });
    this.setState({
      hoursFilled: this.state.hoursFilled.concat([{ name: "", help }]),
    });
  };
  orderHours = () => {
    let helpArray = [];

    let help = [{}];
    let hoursCombined = [];
    this.state.arrDates.map((date, i) => {
      help = { ...help, [date]: "00:00" };
    });

    this.state.hours.map((i, id) => {
      Object.keys(i).map((j, idx) => {
        hoursCombined = help;

        this.state.hours[0][j].map((hours) => {
          this.state.arrDates.map((item, sidx) => {
            if (item === hours.date) {
              hoursCombined = { ...hoursCombined, [hours.date]: hours.result };
            }
          });
        });
        helpArray = [...helpArray, { [j]: hoursCombined }];
      });
      this.setState({
        hoursFilled: helpArray,
      });
    });
  };
  orderHoursSaved = () => {
    let helpArray = [];

    let help = [{}];
    let hoursCombined = [];
    this.state.arrDates.map((date, i) => {
      help = { ...help, [date]: "00:00" };
    });

    this.state.hours.map((i, id) => {
      Object.keys(i).map((j, idx) => {
        hoursCombined = help;

        this.state.hours[0][j].map((hours) => {
          this.state.arrDates.map((item, sidx) => {
            let countUp = "day" + Number(sidx + 1);
            hoursCombined = { ...hoursCombined, [item]: hours[countUp] };
          });
        });
        helpArray = [...helpArray, { [j]: hoursCombined }];
      });
      this.setState({
        hoursFilled: helpArray,
      });
    });
  };
  checkHoursEmpty = (value) => {
    return value === "" ? "00:00" : value;
  };
  saveConstructionDiary = (e) => {
    e.preventDefault();
    let diaryNotes = [];
    let hours = [];

    let diaryId = this.state.bvId + "_" + this.state.date.replace(/-/g, "_");

    this.state.deliveryNotes.map((i) => {
      let addNote = {
        diaryId: diaryId,
        uniqueId: i.supplierId+"_"+diaryId,
        supplierId: i.supplierId,
        notesNr: i.notesNr,
        date: i.date,
      };
      diaryNotes = [...diaryNotes, addNote];
    });
    this.state.hoursFilled.map((i) => {
      let name = Object.keys(i)[0];
      let addHours = {
        uniqueId: name + "_" + diaryId,
        name: name,
        diaryId: diaryId,
        day1: "",
        day2: "",
        day3: "",
        day4: "",
        day5: "",
        day6: "",
        day7: "",
      };

      this.state.arrDates.map((hours, idx) => {
        let countUp = "day" + Number(idx + 1);
        addHours = { ...addHours, [countUp]: i[Object.keys(i)[0]][hours] };
      });
      hours = [...hours, addHours];
    });
    const diary = {
      diaryId: diaryId,
      customerId: this.state.customerId,
      bvId: this.state.bvId,
      dateSaved: this.state.date,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      gewerk: this.state.gewerk,
      leistung: this.state.erbrachteLeistung,
      vorkommnisse: this.state.vorkommnisse,
      deliveryNotes: diaryNotes,
      hours: hours,
    };
    $.ajax({
      url: BaseUrl + "api/constructionDiary/saveConstructionDiary.php",
      type: "POST",
      dataType: "json",
      data: JSON.stringify(diary),
      success: function (response) {
        this.wait5sec("saved");
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  removeNoteHour = (what, value) => {
    let deleteEntry = {};
    if (what === "note") {
      deleteEntry = {
        what: "notes",
        notesNr: value,
        diaryId: this.state.bvId + "_" + this.state.date.replace(/-/g, "_"),
      };
    } else {
      deleteEntry = {
        what: "hours",
        uniqueId:
          value +
          "_" +
          this.state.bvId +
          "_" +
          this.state.date.replace(/-/g, "_"),
      };
    }

    $.ajax({
      url: BaseUrl + "api/constructionDiary/removeHourNote.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteEntry),
      success: function (response) {}.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  wait5sec = (state) => {
    console.log(state);
    this.setState({
      [state]: true,
    });
    setTimeout(() => {
      this.setState({
        [state]: false,
      });
    }, 5000);
  };
  printDiary = (e) => {
    e.preventDefault();
    var promise = new Promise((resolve, reject) => {
      resolve(
        this.setState({
          printDiary: true,
        })
      );
    });
    promise.then((result) => {
      setTimeout(() => {
        document.title =
          this.state.bvId +
          "_Bautagebuch_KW" +
          moment(this.state.dateFrom, "YYYY-MM-DD").week();
        window.print();
        document.title = "Kosten-und Leistungsrechnung";
      }, 1000);
      setTimeout(() => {
        this.setState({
          printDiary: false,
        });
      }, 1000);
    });
  };
  render() {
    return (
      <React.Fragment>
        <section id="construction-diary" className="no-print">
          <div className="wrapper-btn-text row  ">
            <div className="col-md-2">
              <Link to="/home/bautagebuch">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--yellow)" icon="calculator" />{" "}
                Bautagebuch erstellen
              </h1>
            </div>
          </div>
          <form onSubmit={this.saveConstructionDiary} className="offerCreate ">
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="row">
                <div className="col-md-1 tab">
                  <p>Kunden Id</p>
                  <input
                    required
                    type="text"
                    name="customerId"
                    onChange={this.handleChangeForm}
                    value={this.state.customerId}
                    onKeyDown={this.onKeyDownComplete}
                  />
                  <span>↹</span>
                </div>
                <div className="col-md-3 tab">
                  <p>Kunde</p>
                  <select
                    name="customer"
                    value={this.state.customer}
                    onChange={this.handleChangeForm}
                  >
                    <option value="-">-</option>
                    {this.state.customerAll.map((customer, i) => (
                      <option key={i} value={customer["customer"]}>
                        {customer["customer"]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-1 tab">
                  <p>BvId</p>
                  <input
                    required
                    type="text"
                    name="bvId"
                    onChange={this.handleChangeForm}
                    value={this.state.bvId}
                    onKeyDown={this.onKeyDownComplete}
                  />
                  <span>↹</span>
                </div>
                <div className="col-md-4 tab">
                  <p>Bv</p>
                  <select
                    name="bv"
                    value={this.state.bv}
                    onChange={this.handleChangeForm}
                  >
                    <option value="-">-</option>
                    {this.state.bvAll.map((bv, i) => (
                      <option key={i} value={bv["bv"]}>
                        {bv["bv"]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <p>Datum</p>
                  <input
                    required
                    type="date"
                    name="date"
                    onChange={this.handleChangeForm}
                    value={this.state.date}
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-2">
                  <p>Zeitraum - von</p>
                  <input
                    required
                    type="date"
                    name="dateFrom"
                    onChange={this.handleChangeForm}
                    value={this.state.dateFrom}
                  />
                </div>
                <div className="col-md-2">
                  <p>Zeitraum - bis</p>
                  <input
                    required
                    type="date"
                    name="dateTo"
                    onChange={this.handleChangeForm}
                    value={this.state.dateTo}
                  />
                </div>
                <div className="col-md-3 offset-5">
                  <button
                    disabled={this.state.disabledGetData}
                    onClick={this.getData}
                    className="btn-save-product fullwidth"
                    type="button"
                  >
                    Daten abrufen <FontAwesomeIcon icon="print" />
                  </button>
                </div>
              </div>
              <div className={this.state.showText ? "show" : "hide"}>
                <hr />
                <div className="row">
                  <div className="col-md-5">
                    <h3>Gewerke</h3>
                    <input
                      type="text"
                      name="gewerk"
                      onChange={this.handleChange}
                      value={this.state.gewerk}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9 ">
                    <h3>Erbrachte Leistung</h3>
                    <textarea
                      type="text"
                      name="erbrachteLeistung"
                      onChange={this.handleChange}
                      value={this.state.erbrachteLeistung}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 smaller-input">
                    <h3>Lieferscheine</h3>
                    {this.state.noNotes ? ("Keine Lieferscheine vorhanden") : ""}
                    {this.state.deliveryNotes.length > 0
                      ? this.state.deliveryNotes.map((notes, i) => (
                          <div key={i} className="row ">
                            <div className="col-md-3">
                              <p>Lieferant</p>
                              <input
                                name="supplierName"
                                onChange={this.handleChangeDeliverd(i)}
                                type="text"
                                value={notes.supplierName}
                              />
                            </div>
                            <div className="col-md-4">
                              <p>Lieferschein Nr.</p>
                              <input
                                name="notesNr"
                                onChange={this.handleChangeDeliverd(i)}
                                type="text"
                                value={notes.notesNr}
                              />
                            </div>
                            <div className="col-md-3">
                              <p>Datum</p>
                              <input
                                name="date"
                                onChange={this.handleChangeDeliverd(i)}
                                type="date"
                                value={notes.date}
                              />
                            </div>
                            <div className="col-md-1 text-right">
                              <button
                                className="diary-remove-button"
                                type="button"
                                onClick={() =>
                                  this.removeDeliveryNote(
                                    notes["id"],
                                    notes["supplierName"],
                                    notes["notesNr"],
                                    notes["date"]
                                  )
                                }
                              >
                                {" "}
                                <FontAwesomeIcon
                                  color="var(--red)"
                                  icon="minus-circle"
                                />
                              </button>
                            </div>
                          </div>
                        ))
                      : ""}
                    <div className="row">
                      <div className="col-md-1 offset-10  text-right">
                        <button
                          className="diary-add-button"
                          onClick={this.addEmptyDeliveryNote}
                        >
                          {" "}
                          <FontAwesomeIcon
                            color="var(--green)"
                            icon="plus-circle"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 smaller-input">
                    <h3>Stunden</h3>
                    {this.state.noHours ? ("Keine Stunden vorhanden") : ""}

                    <div className="row">
                      <div className="col-md-2"></div>
                      {[...Array(this.state.daysBetween)].map((i, idx) => {
                        return (
                          <div key={"date" + idx} className="col">
                            {moment(this.state.dateFrom)
                              .add("days", idx)
                              .format("DD. MMM")}
                          </div>
                        );
                      })}
                      <div className="col-md-1"></div>
                    </div>
                    {this.state.hoursFilled.length > 0
                      ? this.state.hoursFilled.map((hf, ix) => (
                          <div key={ix}>
                            <div className="row">
                              <div className="col-md-2">
                                <p>Name</p>
                                <input
                                  name="lastName"
                                  onChange={this.handleChangeName(
                                    ix,
                                    Object.keys(hf)[0]
                                  )}
                                  type="text"
                                  value={Object.keys(hf)[0]}
                                />
                              </div>
                              {this.state.arrDates.map((i, idx) => (
                                <div key={"hours" + idx} className="col">
                                  <p>Stunden</p>
                                  <input
                                    name="result"
                                    onChange={this.handleChangeHours(
                                      ix,
                                      i,
                                      Object.keys(hf)[0]
                                    )}
                                    type="time"
                                    value={this.checkHoursEmpty(
                                      hf[Object.keys(hf)[0]][i]
                                    )}
                                  />
                                </div>
                              ))}

                              <div className="col-md-1  text-right">
                                <button
                                  type="button"
                                  className="diary-remove-button"
                                  onClick={() =>
                                    this.removeHours(ix, Object.keys(hf)[0])
                                  }
                                >
                                  {" "}
                                  <FontAwesomeIcon
                                    color="var(--red)"
                                    icon="minus-circle"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                      : ""}
                    <div className="row">
                      <div className="col-md-1 offset-11  text-right">
                        <button
                          className="diary-add-button"
                          onClick={this.addHours}
                        >
                          {" "}
                          <FontAwesomeIcon
                            color="var(--green)"
                            icon="plus-circle"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9 ">
                    <h3>Besondere Vorkommnisse</h3>
                    <textarea
                      type="text"
                      name="vorkommnisse"
                      onChange={this.handleChange}
                      value={this.state.vorkommnisse}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8">
                  <button
                    disabled={this.state.showText ? false : true}
                    className="btn-create-item fullwidth"
                  >
                    Bautagebuch speichern <FontAwesomeIcon icon="save" />
                  </button>
                </div>
                <div className="col-md-4">
                  {" "}
                  <button
                    disabled={this.state.btnPrintDisabled}
                    type="button"
                    onClick={this.printDiary}
                    className="btn-print-diary fullwidth"
                  >
                    Drucken <FontAwesomeIcon icon="print" />
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
          <div
            className={
              this.state.saved ? "show text-center popupsaved" : "popupsaved"
            }
          >
            <div className="col-md-12">
              <h3>
                {" "}
                <FontAwesomeIcon
                  color="var(--greenSeyband)"
                  icon="check-circle"
                />{" "}
                Bautagebuch wurde gespeichert
              </h3>
            </div>
          </div>
        </section>
        <div className="print">
          {this.state.printDiary ? (
            <ConstructionDiaryPrint
              customerId={this.state.customerId}
              customer={this.state.customer}
              bvId={this.state.bvId}
              bv={this.state.bv}
              dateSaved={this.state.date}
              erbrachteLeistung={this.state.erbrachteLeistung}
              vorkommnisse={this.state.vorkommnisse}
              deliveryNotes={this.state.deliveryNotes}
              hoursFilled={this.state.hoursFilled}
              arrDates={this.state.arrDates}
              daysBetween={this.state.daysBetween}
              dateFrom={this.state.dateFrom}
              gewerk={this.state.gewerk}
            />
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default ConstructionDiaryCreate;
