import React, { Component } from "react";
import ModalBillbook from "./ModalBillbook.js";
import BillbookInputFormTop from "./BillbookInputFormTop.js";
import BillbookSearch from "./BillbookSearch.js";
import PrintOpenBillbook from "./PrintOpenBillbook.js";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import BaseUrl from "../config/config.js";
var currencyFormatter = require("currency-formatter");
 
class Billbook extends Component {
  constructor() {
    super();
    this.state = {
      rechnungsNr: "",
      rechnungsdatum: "",
      kunde: "",
      bv: "",
      bvNr: "",
      rechnungsbetrag: "",
      rechnungsbetragDatum: "",
      skonto: "",
      betragSkonto: "",
      steuersatz: "",
      betragEingang: "",
      betragEingangDatum: "",
      zahlungsziel: "",
      sr_az: "SR",
      azNr: "",
      zahlungsdatum: "",
      zahlungsdatumVOB: "",
      mahnung1: "",
      mahnung2: "",
      mahnung3: "",
      billbookAll: [],
      billbookOpenAll: [],
      billbookAllCopy: [],
      errorSearch: false,
      errorInput: false,
      search: "",
      useSearch: false,
      bezahlt: false,
      customerAll: [],
      bvAll: [],
      hideModalBillbook: false,
      openBillsValue: 0.0,
      currentDate: "",
      currentDatePrint: "",
      lastRechnungsNr: "",
      bvComplete: [],
      print: false,
     };
  }

  componentDidMount = () => {
    Promise.all([
      this.readBvComplete(),
      this.readCustomersNames(),
      this.getCurrentDate(),
    ]).then(() => {
      this.loadBillbook();
    });
  };
  readBvComplete = () => {
    return fetch(BaseUrl + "api/all/readBvComplete.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          bvComplete: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };
  readCustomersNames = () => {
    return fetch(BaseUrl + "api/all/readCustomersNames.php")
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          return (this.state.customerAll = this.state.customerAll.concat(item));
        });
        this.setState({
          customerAll: this.state.customerAll,
        });
      })
      .catch((error) => {});
  };
  loadBillbook = () => {
    return fetch(BaseUrl + "api/billbook/readBillbook.php")
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records[0].slice(0, 1).map((item, i) => {
          this.setState({
            lastRechnungsNr: parseInt(item.rechnungsNr) + 1,
          });
        });
        return this.setState({
          billbookAll: responseJson.records[0],
          billbookAllCopy: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };
  loadOpenBillbook = () => {
    return fetch(BaseUrl + "api/billbook/readOpenBillbook.php")
      .then((response) => response.json())
      .then((responseJson) => {
        let help = 0.0;
        responseJson.records[0].map((item) => {
          return (help += parseFloat(item.rechnungsbetrag));
        });
        this.setState({
          billbookOpenAll: responseJson.records[0],
          hideModalBillbook: true,
          openBillsValue: help.toFixed(2),
        });
      })
      .catch((error) => {});
  };
  showBillbook = () => {
    this.setState({
      hideModalBillbook: false,
    });
  };
  loadLastBillbook = (item) => {
    this.state.billbookAll.unshift(item);
    return this.setState({
      billbookAll: this.state.billbookAll,
    });
  };
  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };
  handleSearch = (e) => {
    this.state.search = e.target.value;

    if (e.target.value === "") {
      this.setState({
        billbookAll: this.state.billbookAllCopy,
        errorSearch: false,
      });
    }
  };
  convertPrice = (price) => {
    return currencyFormatter.format(price, { locale: "de-DE" });
  };
  search = (eingabe) => {
    let convertEingabe = eingabe.replace(/ /g, "%").replace("+", "%2B");

    if (eingabe === "") {
      this.setState({
        billbookAll: this.state.billbookAllCopy,
        errorSearch: false,
      });
    } else {
      this.setState({
        billbookAll: [],
      });
      return fetch(
        BaseUrl + "api/billbook/searchBillbook.php?eingabe=" + convertEingabe
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message === "No items found.") {
            this.setState({
              billbookAll: [],
              errorSearch: true,
            });
          } else {
            this.setState({
              billbookAll: responseJson.records[0],
              errorSearch: false,
            });
          }
        })
        .catch((error) => {});
    }
  };
  searchBvComplete = (convertInput, bvAlreadyIn) => {
    return fetch(
      BaseUrl + "api/all/searchBvComplete.php?eingabe=" + convertInput
    )
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          if (
            this.state.bvAll.find(
              (item) => item.bv === responseJson.records[0].bv
            )
          ) {
            this.setState({
              bv: item.bv,
              bvHelp: item.bv,
            });
          } else {
            if (item.bv === bvAlreadyIn) {
              this.setState({
                bvHelp: item.bv,
              });
            } else {
              this.setState({
                bvAll: this.state.bvAll.concat(responseJson.records),
                bv: item.bv,
                bvHelp: item.bv,
              });
            }
          }
          return this;
        });
      })

      .catch((error) => {});
  };
  getCurrentDate = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    this.state.currentDate = dd + "." + mm + "." + yyyy;
    this.state.currentDatePrint = dd + "-" + mm + "-" + yyyy;
  };
  onKeyPressPrint = (e) => {
    this.setState({
      print: true,
    });
  };

  readBvNameById = (id, bool) => {
    let found = this.state.bvComplete.find(
      (name) => name.bvId === parseInt(id)
    );
    if (found === undefined) {
      return "";
    }
    if (bool === true && found !== undefined) {
      return this.setState({
        bv: found.bv,
      });
    } else if (bool === false && found !== undefined) {
      return found.bv;
    }
  };

  readCustomerNameById = (id, bool) => {
    let found = this.state.customerAll.find((name) => name.customerId === id);
    if (found === undefined) {
      return "";
    }

    if (bool === true) {
      return this.setState({
        customerName: found.customerName,
      });
    } else {
      return found.customerName;
    }
  };
  updateOpenBillbook = (id, updateBillbookOpen) => {
    let elementIndex = this.state.billbookAll.findIndex(
      (elem) => elem.id == id
    );
    let billbookAll = [...this.state.billbookAll];
    billbookAll[elementIndex] = {
      ...billbookAll[elementIndex],
      id: updateBillbookOpen.id,
      rechnungsNr: updateBillbookOpen.rechnungsNr,
      rechnungsdatum: updateBillbookOpen.rechnungsdatum,
      customerId: updateBillbookOpen.customerId,
      bvNr: updateBillbookOpen.bvNr,
      rechnungsbetrag: updateBillbookOpen.rechnungsbetrag.replace(/,/g, "."),
      skonto: updateBillbookOpen.skonto.replace(/,/g, "."),
      betragSkonto: updateBillbookOpen.betragSkonto.replace(/,/g, "."),
      steuersatz: updateBillbookOpen.steuersatz,
      betragEingang: updateBillbookOpen.betragEingang.replace(/,/g, "."),
      betragEingangDatum: updateBillbookOpen.betragEingangDatum,
      zahlungsziel: updateBillbookOpen.zahlungsziel,
      sr_az: updateBillbookOpen.sr_az,
      azNr: updateBillbookOpen.azNr,
      zahlungsdatum: updateBillbookOpen.zahlungsdatum,
      zahlungsdatumVOB: updateBillbookOpen.zahlungsdatumVOB,
      mahnung1: updateBillbookOpen.mahnung1,
      mahnung2: updateBillbookOpen.mahnung2,
      mahnung3: updateBillbookOpen.mahnung3,
      bezahlt: updateBillbookOpen.bezahlt,
    };
    this.setState({
      billbookAll: [],
    });
    this.setState({
      billbookAll: billbookAll,
    });
  };
  render() {
    return (
      <React.Fragment>
        {this.state.lastRechnungsNr ? (
          <div>
            <BillbookInputFormTop
              key={this.state.id}
              id={this.state.id}
              rechnungsNr={this.state.rechnungsNr}
              rechnungsdatum={this.state.rechnungsdatum}
              kunde={this.state.kunde}
              bv={this.state.bv}
              bvNr={this.state.bvNr}
              rechnungsbetrag={this.state.rechnungsbetrag}
              skonto={this.state.skonto}
              betragSkonto={this.state.betragSkonto}
              betragEingang={this.state.betragEingang}
              steuersatz={this.state.steuersatz}
              betragEingangDatum={this.state.betragEingangDatum}
              zahlungsziel={this.state.zahlungsziel}
              sr_az={this.state.sr_az}
              azNr={this.state.azNr}
              zahlungsdatum={this.state.zahlungsdatum}
              zahlungsdatumVOB={this.state.zahlungsdatumVOB}
              mahnung1={this.state.mahnung1}
              mahnung2={this.state.mahnung2}
              mahnung3={this.state.mahnung3}
              bezahlt={this.state.bezahlt}
              search={this.state.search}
              handleSearch={this.handleSearch}
              onKeyPress={this.onKeyPress}
              loadLastBillbook={this.loadLastBillbook}
              customerAll={this.state.customerAll}
              lastRechnungsNr={this.state.lastRechnungsNr}
              billbookAll={this.state.billbookAll}
              bvComplete={this.state.bvComplete}
            />
            <BillbookSearch
              search={this.state.search}
              onKeyPress={this.onKeyPress}
              handleSearch={this.handleSearch}
              loadOpenBillbook={this.loadOpenBillbook}
              showBillbook={this.showBillbook}
              hideModalBillbook={this.state.hideModalBillbook}
            />
          </div>
        ) : (
          <div />
        )}

        <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
          Kein Suchergebnis
        </p>
        <section>
          <div className={this.state.hideModalBillbook ? "hide" : ""}>
            <ul className="items-list no-print">
              {this.state.billbookAll.map((item) => {
                return (
                  <ModalBillbook
                    key={item.id}
                    id={item.id}
                    rechnungsNr={item.rechnungsNr}
                    rechnungsdatum={item.rechnungsdatum}
                    customerId={item.customerId}
                    customerName={this.readCustomerNameById(
                      item.customerId,
                      false
                    )}
                    bv={this.readBvNameById(item.bvNr, false)}
                    bvNr={item.bvNr}
                    rechnungsbetrag={item.rechnungsbetrag}
                    skonto={item.skonto}
                    betragSkonto={item.betragSkonto}
                    steuersatz={item.steuersatz}
                    betragEingang={item.betragEingang}
                    betragEingangDatum={item.betragEingangDatum}
                    zahlungsziel={item.zahlungsziel}
                    sr_az={item.sr_az}
                    azNr={item.azNr}
                    zahlungsdatum={item.zahlungsdatum}
                    zahlungsdatumVOB={item.zahlungsdatumVOB}
                    mahnung1={item.mahnung1}
                    mahnung2={item.mahnung2}
                    mahnung3={item.mahnung3}
                    bezahlt={item.bezahlt}
                    customerAll={this.state.customerAll}
                    search={this.state.search}
                    bvAll={[
                      ...new Set(
                        this.state.bvAll.concat({
                          bv: this.readBvNameById(item.bvNr, false),
                          bvId: item.bvNr,
                        })
                      ),
                    ]}
                    openBool={false}
                  />
                );
              })}
            </ul>
          </div>
          <ul className="items-list billbooks-list">
            <div
              className={
                this.state.hideModalBillbook ? "row minus-margin" : "hide"
              }
            >
              <div className="col-12">
                <h5>
                  Rechnungsbeträge:{" "}
                  {this.convertPrice(this.state.openBillsValue)}
                  <br />
                </h5>
              </div>
              <div className="col-3 offset-9 no-print">
                <button
                  style={{ textTransform: "none" }}
                  onClick={this.onKeyPressPrint}
                  className={this.state.print ? "hide" : "btn-create-item"}
                >
                  {" "}
                  Offene Rechnungen
                  <br /> generieren <FontAwesomeIcon icon="sync" />
                </button>
                {this.state.print && (
                  <PDFDownloadLink
                    document={
                      <PrintOpenBillbook
                        billbookOpenAll={this.state.billbookOpenAll}
                        readCustomerNameById={this.readCustomerNameById}
                        readBvNameById={this.readBvNameById}
                        openBillsValue={this.state.openBillsValue}
                      />
                    }
                    fileName={`Offene-Rechnungen-${this.state.currentDatePrint}`}
                    className="btn-save-product-span fullwidth"
                  >
                    {({ blob, url, loadingWiderruf, error }) =>
                      loadingWiderruf ? (
                        <span>
                          Übersicht offene Rechnung
                          <br />
                          wird erstellt
                        </span>
                      ) : (
                        <span>
                          Offene Rechnungen
                          <br />
                          <b>herunterladen!</b>{" "}
                          <FontAwesomeIcon icon="download" />
                        </span>
                      )
                    }
                  </PDFDownloadLink>
                )}
              </div>
            </div>

            <div className={this.state.hideModalBillbook ? "" : "hide"}>
              {this.state.billbookOpenAll.map((item) => {
                return (
                  <ModalBillbook
                    key={item.id}
                    id={item.id}
                    rechnungsNr={item.rechnungsNr}
                    rechnungsdatum={item.rechnungsdatum}
                    customerId={item.customerId}
                    customerName={this.readCustomerNameById(
                      item.customerId,
                      false
                    )}
                    bv={this.readBvNameById(item.bvNr, false)}
                    bvNr={item.bvNr}
                    rechnungsbetrag={item.rechnungsbetrag}
                    skonto={item.skonto}
                    betragSkonto={item.betragSkonto}
                    betragEingang={item.betragEingang}
                    steuersatz={item.steuersatz}
                    betragEingangDatum={item.betragEingangDatum}
                    zahlungsziel={item.zahlungsziel}
                    sr_az={item.sr_az}
                    azNr={item.azNr}
                    zahlungsdatum={item.zahlungsdatum}
                    zahlungsdatumVOB={item.zahlungsdatumVOB}
                    mahnung1={item.mahnung1}
                    mahnung2={item.mahnung2}
                    mahnung3={item.mahnung3}
                    bezahlt={item.bezahlt}
                    customerAll={this.state.customerAll}
                    search={this.state.search}
                    bvAll={[
                      ...new Set(
                        this.state.bvAll.concat({
                          bv: this.readBvNameById(item.bvNr, false),
                          bvId: item.bvNr,
                        })
                      ),
                    ]}
                    updateOpenBillbook={this.updateOpenBillbook}
                    openBool={true}
                  />
                );
              })}
            </div>
          </ul>
        </section>
      </React.Fragment>
    );
  }
}

export default Billbook;
