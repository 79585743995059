import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../config/config.js";
import { globalUnits } from "../global/units.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var $ = require("jquery");
var Highlight = require("react-highlighter");
var currencyFormatter = require("currency-formatter");

class ModalBills extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      id: this.props.id,
      date: this.props.date,
      notesDate: this.props.notesDate,
      billsNr: this.props.billsNr,
      billsNrOld: this.props.billsNrOld,
      notesNr: this.props.notesNr,
      supplier: this.props.supplier,
      supplierId: this.props.supplierId,
      bv: this.props.bv,
      bvId: this.props.bvId,
      showUpdate: true,
      showDelete: false,
      hideNotes: false,
      hideArticle: false,
      showItem: this.props.showItem,
      articles: this.props.articles,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        modal: false,
      });
    } else {
      this.setState({
        modal: !this.state.modal,
      });
    }
  };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false,
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true,
      });
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "bv") {
      this.readBvIdByName(e.target.value, true);
    }
    if (e.target.name === "supplier") {
      this.readSupplierIdByName(e.target.value, true);
    }
  };

  handleChangeCheck = (e) => {
    this.setState({
      done: !this.state.done,
    });
  };
  updateNote = (e) => {
    var updateBills;
    this.state.articles.map((items, idx) => {
      if (items.id === undefined) {
        updateBills = {
          id: "0",
          date: this.state.date,
          notesDate: this.state.notesDate,
          notesNr: this.state.notesNr,
          billsNr: this.state.billsNr.trim(),
          billssNrOld: this.state.billsNrOld.trim(),
          supplierId: this.state.supplierId,
          itemId: items.itemId,
          itemDsc: items.itemDsc,
          itemAmnt: items.itemAmnt.replace(/,/g, "."),
          itemUnit: items.itemUnit,
          pricePiece: items.pricePiece.replace(/,/g, "."),
          discount: items.discount.replace(/,/g, "."),
          priceComplete: items.priceComplete.replace(/,/g, "."),
          bvId: this.state.bvId,
        };
      } else {
        updateBills = {
          id: items.id,
          date: this.state.date,
          notesDate: this.state.notesDate,
          billsNr: this.state.billsNr.trim(),
          billssNrOld: this.state.billsNrOld.trim(),
          notesNr: this.state.notesNr,
          supplierId: this.state.supplierId,
          itemId: items.itemId,
          itemDsc: items.itemDsc,
          itemAmnt: items.itemAmnt.replace(/,/g, "."),
          itemUnit: items.itemUnit,
          pricePiece: items.pricePiece.replace(/,/g, "."),
          discount: items.discount.replace(/,/g, "."),
          priceComplete: items.priceComplete.replace(/,/g, "."),
          bvId: this.state.bvId,
        };
      }
      $.ajax({
        url: BaseUrl + "api/bills/updateBills.php",
        type: "POST",
        dataType: "text",
        data: JSON.stringify(updateBills),
        success: function (response) {
          console.log("true")
          this.setState({
            notesNrOld: this.state.notesNr,
            modal: false,
          });
        }.bind(this),
        error: function (xhr, resp, text) {},
      });
      return this;
    });

    e.preventDefault();
  };

  removeBillsPerNr = (idx) => (e) => {
    e.preventDefault();
    var deleteItem = {
      id: this.state.id,
    };

    $.ajax({
      url: BaseUrl + "api/bills/deleteBillPerNr.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function (response) {
        this.setState({
          articles: this.state.articles.filter((s, sidx) => idx !== sidx),
        });
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };

  removeNotes = () => {
    var deleteItem = {
      id: this.state.billsNr,
      notesNr: this.state.notesNr
    };
    $.ajax({
      url: BaseUrl + "api/bills/deleteBill.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function (response) {
        this.setState({
          modal: !this.state.modal,
          hideNotes: true,
        });
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  handleAddDeliveryItem = () => {
    this.setState({
      articles: this.state.articles.concat([
        {
          itemId: "",
          itemDsc: "",
          itemAmnt: "",
          itemUnit: "",
          pricePiece: "",
          discount: "",
          priceComplete: "",
        },
      ]),
    });
  };
  handleChangeDeliverd = (idx) => (evt) => {
    const newItemChange = this.state.articles.map((item, sidx) => {
      if (idx !== sidx) return item;
      return { ...item, [evt.target.name]: evt.target.value };
    });
    this.setState({ articles: newItemChange });
  };
  autocompleteTabItem = (idx) => (e) => {
    if (e.keyCode === 9) {
      this.getDscUnit(idx, e);
    }
  };
  getDscUnit = (idx, evt) => {
    const convertInput = evt.target.value.replace(/ /g, " ");
    return fetch(BaseUrl + "api/bills/readItemDesc.php?eingabe=" + convertInput)
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          return this.setState({
            itemDsc: item.dsc,
            itemUnit: item.unit,
          });
        });

        const newItemAutocomplete = this.state.articles.map((item, sidx) => {
          if (idx !== sidx) return item;

          return {
            ...item,
            itemId: item.itemId,
            itemDsc: this.state.itemDsc,
            itemUnit: this.state.itemUnit,
          };
        });
        return this.setState({ articles: newItemAutocomplete });
      })
      .catch((error) => {});
  };
  calcPriceComplete = (idx) => (e) => {
    if (e.keyCode === 9) {
      const newItemAutocomplete = this.state.articles.map((item, sidx) => {
        if (idx !== sidx) return item;
        let calcPrice = "";
        if (parseFloat(item.discount.replace(/,/g, ".")) === 0) {
          calcPrice =
            parseFloat(item.pricePiece.replace(/,/g, ".")) *
            parseFloat(item.itemAmnt.replace(/,/g, "."));
        } else {
          calcPrice =
            (parseFloat(item.pricePiece.replace(/,/g, ".")) -
              parseFloat(item.pricePiece.replace(/,/g, ".")) *
                parseFloat(item.discount.replace(/,/g, ".") / 100)) *
            parseFloat(item.itemAmnt.replace(/,/g, "."));
        }
        return {
          ...item,
          itemId: item.itemId,
          itemDsc: item.itemDsc,
          itemAmnt: item.itemAmnt,
          itemUnit: item.itemUnit,
          pricePiece: item.pricePiece,
          discount: item.discount,
          priceComplete: calcPrice.toFixed(2),
        };
      });
      return this.setState({ articles: newItemAutocomplete });
    }
  };
  convertDate = (date) => {
    if (date.includes("-")) {
      var dateNew = date.split("-");
      dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  completePriceBill = () => {
    let complete = 0.0;
    this.state.articles.map((i, idx) => {
      return (complete = parseFloat(complete) + parseFloat(i.priceComplete));
    });
    return complete.toFixed(2);
  };
  readBvNameById = (id, bool) => {
    let found = this.props.bvAll.find((name) => name.bvId === id);
    if (found === undefined) {
      this.props.readBvCompleteById(id);
      setTimeout(() => {
        this.setState({
          bv: this.props.bvAllFound,
        });
      }, 200);
    }
    if (bool === true && found !== undefined) {
      return this.setState({
        bv: found.bv,
      });
    } else if (bool === false && found !== undefined) {
      return found.bv;
    }
  };

  readBvIdByName = (name, bool) => {
    let found = this.props.bvAll.find((id) => id.bv === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        bvId: found.bvId,
      });
    } else {
      return found.bvId;
    }
  };
  readSupplierNameById = (id, bool) => {
    let found = this.props.supplierAll.find((name) => name.supplierId === id);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        supplier: found.supplierName,
      });
    } else {
      return found.supplierName;
    }
  };
  readSupplierIdByName = (name, bool) => {
    let found = this.props.supplierAll.find((id) => id.supplierName === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        supplierId: found.supplierId,
      });
    } else {
      return found.supplierId;
    }
  };
  autocomplete = (e) => {
    if (e.keyCode === 9 && e.target.name === "bvId") {
      this.readBvNameById(e.target.value, true);
    }
    if (e.keyCode === 9 && e.target.name === "supplierId") {
      this.readSupplierNameById(e.target.value, true);
    }
  };
  convertPrice = (price) => {
    return currencyFormatter.format(price, { locale: "de-DE" });
  };
  render() {
    return (
      <React.Fragment>
        <li
          onClick={this.toggle}
          className={this.state.hideNotes ? "hide " : " border-bottom-last"}
        >
          <div>
            <span className="row list-header show0 ">
              <p className="col-2 max-width-180">
                <span>Datum</span>
                <Highlight id="font-normal" search={this.props.search}>
                  {this.convertDate(this.state.date)}
                </Highlight>
              </p>
              <p className="col">
                <span>Rechnungs-Nr</span>
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.billsNr}
                </Highlight>
              </p>
              <p className="col">
                <span>Lieferschein Datum</span>
                <Highlight id="font-normal" search={this.props.search}>
                  {this.convertDate(this.state.notesDate)}
                </Highlight>
              </p>
              <p className="col">
                <span>Lieferschein-Nr</span>
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.notesNr}
                </Highlight>
              </p>
              <p className="col-2">
                <span>Lieferant</span>
                <Highlight id="font-normal" search={this.props.search}>
                  ( {this.state.supplierId} ) {this.state.supplier}
                </Highlight>
              </p>
              <p className="col-4">
                <span>Bv</span>
                <Highlight id="font-normal" search={this.props.search}>
                  ( {this.state.bvId} ) {this.state.bv}
                </Highlight>
              </p>

              <p className="col-2 max-width-150 text-right">
                <span>Rechnungssumme</span>
                <Highlight id="font-normal" search={this.props.search}>
                  {this.convertPrice(this.completePriceBill())}
                </Highlight>
              </p>
            </span>
          </div>
          <div className="row font-small">
            <div className="col max-width-180">
              <span>Artikel-Nr</span>
            </div>
            <div className="col-4">
              <span>Artikelbeschreibung</span>
            </div>
            <div className="col-1">
              <span>Menge</span>
            </div>
            <div className="col-1">
              <span>Einheit</span>
            </div>
            <div className="col-1 text-right">
              <span>Preis/stk</span>
            </div>
            <div className="col-1 text-right">
              <span>Rabatt</span>
            </div>
            <div className="col text-right">
              <span>Gesamtpreis</span>
            </div>
          </div>
          {this.state.articles.map((i, idx) => {
            return (
              <div
                className={
                  this.state.hideArticle ? "hide " : "border-bottom-bills"
                }
                key={idx}
              >
                <span className="row">
                  <p className="col max-width-180">
                    <Highlight id="font-normal" search={this.props.search}>
                      {i.itemId}
                    </Highlight>
                  </p>
                  <p className="col-4">
                    <Highlight id="font-normal" search={this.props.search}>
                      {i.itemDsc}
                    </Highlight>
                  </p>
                  <p className="col-1">{i.itemAmnt}</p>
                  <p className="col-1">{i.itemUnit}</p>
                  <p className="col-1 text-right">
                    {this.convertPrice(i.pricePiece)}
                  </p>
                  <p className="col-1 text-right">{i.discount} %</p>
                  <p className="col text-right">
                    {this.convertPrice(i.priceComplete)}
                  </p>
                </span>
                <span className="row" />
              </div>
            );
          })}
        </li>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal-header">
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <p>
                Rechnung bearbeiten?
                <br />
              </p>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <p>
                Rechnung wirklich Löschen?
                <br />
              </p>

              <span>Dieser Vorgang kann nicht Rückgänging gemacht werden</span>
            </div>
            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <form>
                <div className="row">
                  <div className="col-md-2 max-width-180">
                    <p>Datum</p>
                    <input
                      type="date"
                      name="date"
                      placeholder="Datum"
                      min="2017-01-01"
                      onChange={this.handleChange}
                      value={this.state.date}
                    />
                  </div>
                  <div className="col-md-4 max-width-350">
                    <p>Rechnungs-Nr</p>
                    <input
                      type="text"
                      name="billsNr"
                      placeholder="RechnungsNr"
                      onChange={this.handleChange}
                      value={this.state.billsNr}
                    />
                  </div>
                </div>
                <div className="row">
                <div className="col-md-2  max-width-180">
                    <p>Lieferschein Datum</p>
                    <input
                      type="date"
                      name="notesDate"
                      placeholder="Datum"
                      min="2017-01-01"
                      onChange={this.handleChange}
                      value={this.state.notesDate}
                    />
                  </div>
                  <div className="col tab max-width-175">
                    <p>Lieferschein-Nr</p>
                    <input
                      type="text"
                      name="notesNr"
                      placeholder="LieferscheinNr"
                      onChange={this.handleChange}
                      value={this.state.notesNr}
                    />
                    <span>↹</span>
                  </div>
                  <div className="col tab max-width-175">
                    <p>Lieferanten-Nr</p>
                    <input
                      required
                      type="text"
                      name="supplierId"
                      placeholder="-"
                      onChange={this.handleChange}
                      value={this.state.supplierId}
                      onKeyDown={this.autocomplete}
                    />
                    <span>↹</span>
                  </div>
                  <div className="col max-width-150">
                    <p>Lieferant</p>
                    <select
                      name="supplier"
                      value={this.state.supplier}
                      onChange={this.handleChange}
                    >
                      <option value="-">-</option>
                      {this.props.supplierAll.map((supplier, i) => (
                        <option key={i} value={supplier.supplierName}>
                          {supplier.supplierName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col tab  max-width-100">
                    <p>BV-Nr</p>
                    <input
                      type="text"
                      name="bvId"
                      placeholder="BV-Nr"
                      onChange={this.handleChange}
                      value={this.state.bvId}
                      onKeyDown={this.autocomplete}
                    />
                    <span>↹</span>
                  </div>
                  <div className="col">
                    <p>BV</p>
                    <select
                      name="bv"
                      value={this.state.bv}
                      onChange={this.handleChange}
                    >
                      <option key="-1" value="-">
                        -
                      </option>
                      {this.props.bvAll.map((bv, i) => (
                        <option key={i} value={bv.bv}>
                          {bv.bv}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </form>
              <form className="row">
                {this.state.articles.map((i, idx) => {
                  return (
                    <div
                      key={idx}
                      className={this.state.hideArticle ? "hide" : "fullwidth"}
                    >
                      <div className="row">
                        <div className="col-md-1 max-width-75">
                          <p>&nbsp;</p>

                          <button
                            onClick={this.removeBillsPerNr(idx)}
                            className="x-red"
                          >
                            x
                          </button>
                        </div>
                        <div className="col-md-2 tab">
                          <p>Artikel-Nr</p>
                          <input
                            type="text"
                            name="itemId"
                            placeholder="ArtikelNr"
                            onKeyDown={this.autocompleteTabItem(idx)}
                            onChange={this.handleChangeDeliverd(idx)}
                            value={i.itemId}
                          />
                          <span>↹</span>
                        </div>
                        <div className="col">
                          <p>Artikelbezeichnung</p>
                          <input
                            type="text"
                            name="itemDsc"
                            placeholder="Artikelbezeichnung"
                            onChange={this.handleChangeDeliverd(idx)}
                            value={i.itemDsc}
                          />
                        </div>
                        <div className="col-md-2 max-width-150">
                          <p>Menge</p>
                          <input
                            type="text"
                            name="itemAmnt"
                            placeholder="Menge"
                            onChange={this.handleChangeDeliverd(idx)}
                            value={i.itemAmnt}
                          />
                        </div>
                        <div className="col-md-2">
                          <p>Einheit</p>

                          <select
                            name="itemUnit"
                            onChange={this.handleChangeDeliverd(idx)}
                            value={i.itemUnit}
                          >
                            {globalUnits.map((unit, idx) => {
                              return (
                                <option key={idx} value={unit}>
                                  {unit}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-1  max-width-75" />
                        <div className="col-md-2">
                          <p>Preis/stk</p>
                          <input
                            type="text"
                            name="pricePiece"
                            placeholder="Preis/stk"
                            onChange={this.handleChangeDeliverd(idx)}
                            value={i.pricePiece}
                          />
                        </div>
                        <div className="col-md-2 tab max-width-130">
                          <p>Rabatt</p>
                          <input
                            type="text"
                            name="discount"
                            placeholder="Rabatt"
                            onChange={this.handleChangeDeliverd(idx)}
                            onKeyDown={this.calcPriceComplete(idx)}
                            value={i.discount}
                          />
                          <span>↹</span>
                        </div>
                        <div className="col-md-2">
                          <p>Gesamtpreis</p>
                          <input
                            type="text"
                            name="priceComplete"
                            placeholder="Gesamtpreis"
                            onChange={this.handleChangeDeliverd(idx)}
                            value={i.priceComplete}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </form>
              <div className="row fullwidth">
                <div className="col-11" />
                <div className="col-1">
                  <button
                    type="button"
                    onClick={this.handleAddDeliveryItem}
                    className="btn-add-item"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Löschen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.updateNote} className="btn-green">
                    Ändern <FontAwesomeIcon icon={["far", "edit"]} />
                  </button>
                </div>
              </div>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Nein <FontAwesomeIcon icon="ban" />
                  </button>
                </div>

                <div className="col">
                  <button onClick={this.removeNotes} className="btn-green">
                    Ja <FontAwesomeIcon icon="check-circle" />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalBills;
