import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
import { BrowserRouter as Route, Link } from "react-router-dom";
import Textarea from "react-textarea-autosize";
import OfferPrint from "./OfferPrint.js";
import CSVReader from "react-csv-reader";
import BaseUrl from "../../config/config.js";
import OfferCreateSearch from "./OfferCreateSearch.js";
import { globalUnits } from "../../global/units.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import PrintConfirmationPDF from "./PrintConfirmationPDF.js";
import PrintWiderrufPDF from "./PrintWiderrufPDF.js";
import PrintWiderrufformularPDF from "./PrintWiderrufformularPDF.js";
var $ = require("jquery");

class OfferCreate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      offerNr: "",
      oldVersion: [],
      showOldVersion: false,
      version: "1",
      versionOld: "",
      date: "",
      customerId: "",
      customer: "",
      customerAll: [],
      streetCustomer: "",
      zipCodeCustomer: "",
      cityCustomer: "",
      bv: "",
      buildingStart: "",
      buildingEnd: "",
      products: [
        {
          id: "",
          nr: "",
          pos: "",
          dsc: "",
          amnt: "",
          unit: "",
          pricePiece: "",
          priceComplete: "",
          searchModal: false,
        },
      ],
      productsSort: [],
      productsAll: [],
      paragraph: false,
      chkConfirmation: false,
      chkWiederruf: false,
      chkWiederrufformular: false,
      print: false,
      printConfirmation: false,
      printWiderruf: false,
      printWiderrufformular: false,
      allPayments: [],
      paymentName: "",
      paymentText: "",
      discountComplete: "",
      saved: false,
      disabled: true,
      downloadClicked: false,
      offerIdExists: false
    };
  }

  componentDidMount = () => {
    Promise.all([this.readCustomersNames(), this.loadLastOfferNr()]).then(
      () => {
        this.loadPayment();
        if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
          this.setState({
            disabledFieldset: true,
          });
        }
        if (
          this.props.match.params.offerNr > 0 &&
          this.props.match.params.version > 0
        ) {
          this.setState({
            offerNr: this.props.match.params.offerNr,
          });
          this.loadOffer(
            this.props.match.params.version,
            this.props.match.params.offerNr
          );
          this.loadLastVersion(this.props.match.params.offerNr);
        }
      }
    );
  };
  loadLastOfferNr = () => {
    return fetch(BaseUrl + "api/offer/loadLastOfferNr.php")
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((payment) => {
          return this.setState({
            offerNr: parseInt(responseJson.records[0].lastOfferNr) + 1,
          });
        });
      })
      .catch((error) => {});
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "paymentName") {
      this.loadSinglePayment(e.target.value);
    }
    if (e.target.name === "customer") {
      this.readCustomerIdByName(e.target.value);
    }
    if (e.target.name === "customer" && e.target.value === "-") {
      this.setState({
        customerId: "",
      });
    }
  };
  handleCheck = (e) => {
    this.setState({
      print: false,
      [e.target.name]: !this.state[e.target.name],
    });
  };
  handleChangeOldVersion = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      print: false,
    });
    if (e.target.value.includes("0")) {
      let v = e.target.value.split("0");
      v = v[1];
      this.loadOffer(v, this.state.offerNr);
    } else {
      this.loadOffer(e.target.value, this.state.offerNr);
    }
  };
  loadPayment = () => {
    return fetch(BaseUrl + "api/all/readTermOfPayment.php")
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((payment) => {
          return this.setState({
            allPayments: responseJson.records,
          });
        });
      })
      .catch((error) => {});
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.version === "") {
    } else {
      this.setState({
        disabled: false,
      });
      this.updateOffer();
    }
  };

  handleAddProduct = () => {
    this.setState({
      products: this.state.products.concat([
        {
          id: "0",
          nr: "",
          pos: "",
          dsc: "",
          amnt: "",
          unit: "",
          pricePiece: "",
          priceComplete: "",
          searchModal: false,
        },
      ]),
    });
  };

  handleRemoveProduct = (idx, id) => () => {
    var deleteItem = id;
    if (id) {
      $.ajax({
        url: BaseUrl + "api/offer/deleteProductOffer.php",
        type: "POST",
        dataType: "text",
        data: JSON.stringify(deleteItem),
        success: function (response) {}.bind(this),
        error: function (xhr, resp, text) {},
      });
    }
    this.setState({
      products: this.state.products.filter((s, sidx) => idx !== sidx),
    });
  };

  handleChangeProduct = (idx) => (evt) => {
    const newItemChange = this.state.products.map((item, sidx) => {
      if (idx !== sidx) return item;
      if (evt.target.name === "pricePiece") {
        return {
          ...item,
          [evt.target.name]: evt.target.value.replace(/,/g, "."),
        };
      } else {
        return { ...item, [evt.target.name]: evt.target.value };
      }
    });
    this.setState({ products: newItemChange });
  };
  searchedProduct = (idx, artNr, dsc, unit, calcPrice) => {
    const newItemChange = this.state.products.map((item, sidx) => {
      if (idx !== sidx) return item;
      return {
        ...item,
        nr: artNr,
        dsc: dsc,
        unit: unit,
        pricePiece: calcPrice,
      };
    });
    this.setState({ products: newItemChange });
  };
  updateOffer = (e) => {
    var updateOffer;
    let sameVersion =
      this.state.version < 9 ? "0" + this.state.version : this.state.version;
    this.state.products.map((products, idx) => {
      if (
        products.id === undefined ||
        products.id === "0" ||
        parseInt(this.state.versionOld) !== parseInt(sameVersion)
      ) {
        updateOffer = {
          id: "0",
          offerNr: this.state.offerNr,
          version: this.state.version,
          date: this.state.date,
          customerId: this.state.customerId,
          streetCustomer: this.state.streetCustomer,
          zipCodeCustomer: this.state.zipCodeCustomer,
          cityCustomer: this.state.cityCustomer,
          bv: this.state.bv,
          buildingStart: this.state.buildingStart,
          buildingEnd: this.state.buildingEnd,
          paragraph: this.state.paragraph,
          termOfPayment: this.state.paymentName,
          discountComplete: this.state.discountComplete,
          pos: products.pos,
          nr: products.nr,
          dsc: products.dsc,
          amnt: products.amnt,
          unit: products.unit,
          pricePiece: products.pricePiece.replace(/,/g, "."),
          priceComplete: products.priceComplete.replace(/,/g, "."),
          angebotsstatus: this.state.angebotsstatus,
        };
      } else {
        updateOffer = {
          id: products.id,
          offerNr: this.state.offerNr,
          version: this.state.version,
          date: this.state.date,
          customerId: this.state.customerId,
          streetCustomer: this.state.streetCustomer,
          zipCodeCustomer: this.state.zipCodeCustomer,
          cityCustomer: this.state.cityCustomer,
          bv: this.state.bv,
          buildingStart: this.state.buildingStart,
          buildingEnd: this.state.buildingEnd,
          paragraph: this.state.paragraph,
          termOfPayment: this.state.paymentName,
          discountComplete: this.state.discountComplete,
          pos: products.pos,
          nr: products.nr,
          dsc: products.dsc,
          amnt: products.amnt,
          unit: products.unit,
          pricePiece: products.pricePiece.replace(/,/g, "."),
          priceComplete: products.priceComplete.replace(/,/g, "."),
          angebotsstatus: this.state.angebotsstatus,
        };
      }
      $.ajax({
        url: BaseUrl + "api/offer/updateCreateOffer.php",
        type: "POST",
        dataType: "text",
        data: JSON.stringify(updateOffer),
        success: function (response) {
          var parsedData = JSON.parse(response);
          if(parsedData.text !== "klappt"){
           this.setState({
             offerIdExists: true,
            })
           setTimeout(() => {
             this.setState({
               offerIdExists: false
             })
           }, 5000);
         }
         else{
          this.setState({
            saved: true,
          });
        }
        }.bind(this),
        error: function (xhr, resp, text) {},
      });
      return this;
    });
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };

  autocompleteDsc = (idx) => (e) => {
    if (e.keyCode === 9) {
      this.getDscUnit(idx, e);
    }
  };
  getDscUnit = (idx, evt) => {
    const convertInput = evt.target.value.replace(/ /g, " ");
    return fetch(
      BaseUrl + "api/offer/readProductDesc.php?eingabe=" + convertInput
    )
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          return this.setState({
            dsc: item.dsc,
            unit: item.unit,
            pricePiece: item.calcPrice,
          });
        });

        const autocomplete = this.state.products.map((item, sidx) => {
          if (idx !== sidx) return item;

          return {
            ...item,
            dsc: this.state.dsc,
            unit: this.state.unit,
            pricePiece: this.state.pricePiece,
          };
        });
        return this.setState({ products: autocomplete });
      })
      .catch((error) => {});
  };
  calcCompletePrice = (idx) => (e) => {
    if (e.keyCode === 9) {
      const autocomplete = this.state.products.map((item, sidx) => {
        if (idx !== sidx) return item;
        let calcHelp;
        if (item.amnt === "") {
          calcHelp = 0 * parseFloat(item.pricePiece);
        } else {
          calcHelp =
            parseFloat(item.amnt.replace(/,/g, ".")) *
            parseFloat(item.pricePiece.replace(/,/g, "."));
        }

        return {
          ...item,
          priceComplete: calcHelp.toFixed(2),
        };
      });
      return this.setState({ products: autocomplete });
    }
  };
  readCustomerNameById = (id, bool) => {
    let found = this.state.customerAll.find((name) => name.customerId === id);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        customer: found.customerName,
        streetCustomer: found.street,
        zipCodeCustomer: found.zipCode,
        cityCustomer: found.city,
      });
    } else {
      return found.customerName;
    }
  };
  readCustomerIdByName = (name) => {
    let found = this.state.customerAll.find((id) => id.customerName === name);
    if (found === undefined) {
      return "";
    } else {
      return this.setState({
        customerId: found.customerId,
        streetCustomer: found.street,
        zipCodeCustomer: found.zipCode,
        cityCustomer: found.city,
      });
    }
  };

  readCustomersNames = () => {
    return fetch(BaseUrl + "api/all/readCustomerDataAll.php")
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          return (this.state.customerAll = this.state.customerAll.concat(item));
        });
        this.setState({
          customerAll: this.state.customerAll,
        });
      })
      .catch((error) => {});
  };

  autocompleteCustomer = (e) => {
    if (e.keyCode === 9 && e.target.name === "customerId") {
      this.readCustomerNameById(e.target.value, true);
    }
  };
  loadVersions = (e) => {
    if (e.keyCode === 9 && e.target.name === "offerNr") {
      this.setState({
        oldVersion: [],
      });
      this.loadLastVersion(e.target.value);
    }
  };
  loadLastVersion = (offerNr) => {
    let help = [];
    const wait = fetch(
      BaseUrl + "api/offer/loadLastVersion.php?offerNr=" + offerNr
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "No items found.") {
          this.setState({
            showOldVersion: false,
          });
        } else {
          responseJson.records.map((i, idx) => {
            return (help = help.concat([
              { version: i.version <= 9 ? "0" + i.version : i.version },
            ]));
          });
          let newVersion = help
            .sort((a, b) => a.version < b.version)
            .slice(0, 1);
          this.setState({
            showOldVersion: true,
            oldVersion: help,
            versionOld: parseInt(newVersion[0].version),
            version: parseInt(newVersion[0].version) + 1,
          });
        }
      })
      .catch((error) => {});

    Promise.all([wait]).then((value) => {
      if (this.props.match.params.version.length !== 0) {
        this.setState({
          versionOld:
            this.props.match.params.version <= 9
              ? "0" + this.props.match.params.version
              : this.props.match.params.version,
        });
      }
    });
  };
  loadOffer = (version, offerNr) => {
    fetch(
      BaseUrl +
        "api/offer/readOfferPerNr.php?nr=" +
        offerNr +
        "&version=" +
        version
    )
      .then((response) => response.json())
      .then((responseJson) => {
        const products = responseJson.records.map((item, sidx) => {
          return {
            key: sidx,
            id: item.id,
            pos: item.pos,
            nr: item.nr,
            dsc: item.dsc,
            amnt: item.amnt,
            unit: item.unit,
            pricePiece: item.pricePiece,
            priceComplete: item.priceComplete,
            searchModal: false,
          };
        });
        responseJson.records.slice(0, 1).map((item, sidx) => {
          this.loadSinglePayment(item.termOfPayment);

          return this.setState({
            disabled: false,
            key: sidx,
            id: item.id,
            offerNr: item.offerNr,
            date: item.date,
            customerId: item.customerId,
            customer: this.readCustomerNameById(item.customerId, false),
            streetCustomer: item.streetCustomer,
            zipCodeCustomer: item.zipCodeCustomer,
            cityCustomer: item.cityCustomer,
            bv: item.bv,
            buildingStart: item.buildingStart,
            buildingEnd: item.buildingStart,
            paragraph: item.paragraph === "0" ? false : true,
            paymentName: item.termOfPayment,
            discountComplete: item.discountComplete,
            angebotsstatus: item.angebotsstatus,
            products: products,
          });
        });
      });
  };
  loadSinglePayment = (paymentName) => {
    return fetch(
      BaseUrl + "api/all/readSingleTermOfPayment.php?paymentname=" + paymentName
    )
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((payment) => {
          return this.setState({
            paymentText: payment.paymentText,
          });
        });
      })
      .catch((error) => {});
  };
  handleUpload = (data) => {
    this.setState({
      products: [],
    });
    console.log(data);
    data.map((item, i) => {
      if (i > 0 && i + 1 < data.length) {
        this.setState({
          products: this.state.products.concat([
            {
              id: i,
              pos: item[0],
              nr: "",
              dsc: item[1],
              amnt: item[2].replace(",", "."),
              unit: item[3],
              pricePiece: item[4].replace(",", "."),
              priceComplete:
                item[5].length !== 0 ? item[5].replace(",", ".") : "0.0",
            },
          ]),
        });
      }
      return this;
    });
  };
  sortProductsPos = (products) => {
    const sorting = [].concat(products).sort((a, b) => a.pos > b.pos);
    return sorting;
  };
  printList = (e) => {
    e.preventDefault();
    this.setState({
      print: true,
    });
  };
  printConfirmation = (e) => {
    e.preventDefault();
    this.setState({
      printConfirmation: true,
    });
  };
  printWiderruf = (e) => {
    e.preventDefault();
    this.setState({
      printWiderruf: true,
    });
  };
  printWiderrufformular = (e) => {
    e.preventDefault();
    this.setState({
      printWiderrufformular: true,
    });
  };
  openSearchModal = (idx, name) => (evt) => {
    evt.preventDefault();
    const newItemChange = this.state.products.map((item, sidx) => {
      if (idx !== sidx) return item;
      return { ...item, [name]: !evt.target.value };
    });
    this.setState({ products: newItemChange });
  };
  toggle = (idx, name) => (evt) => {
    const newItemChange = this.state.products.map((item, sidx) => {
      if (idx !== sidx) return item;
      return { ...item, [name]: false };
    });
    this.setState({ products: newItemChange });
  };
  handleDownloadButtonClick = () => {
    this.setState({ downloadClicked: true });
  };
  
  render() {
    return (
      <React.Fragment>
        <section id="offerCreate">
          <div className="wrapper-btn-text row no-print">
            <div className="col-md-2">
              <Link to="/home/angebot">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--yellow)" icon="calculator" />{" "}
                Angebot erstellen
              </h1>
            </div>
          </div>

          <form className="offerCreate no-print" onSubmit={this.handleSubmit}>
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="row">
                <div className="col-md-4 tab">
                  <p>Angebotsnummer</p>
                  <input
                    required
                    type="text"
                    name="offerNr"
                    onChange={this.handleChange}
                    value={this.state.offerNr}
                    onKeyDown={this.loadVersions}
                  />
                  <span>↹</span>
                </div>
                <div
                  className={
                    this.state.showOldVersion ? "show col-md-2" : "hide"
                  }
                >
                  <p>alte Version</p>
                  <select
                    name="versionOld"
                    onChange={this.handleChangeOldVersion}
                    value={this.state.versionOld}
                  >
                    <option key="-1" value="-">
                      -
                    </option>
                    {this.state.oldVersion
                      .sort((a, b) => a.version > b.version)
                      .map((item, i) => (
                        <option key={i + ""} value={item.version}>
                          {item.version}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-2">
                  <p>Version 1)</p>

                  <input
                    required
                    type="text"
                    name="version"
                    onChange={this.handleChange}
                    value={this.state.version}
                  />
                </div>

                <div className="col-md-2">
                  <p>Datum</p>
                  <input
                    required
                    type="date"
                    name="date"
                    onChange={this.handleChange}
                    value={this.state.date}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-1 tab">
                  <p>Knd.Id</p>
                  <input
                    required
                    type="text"
                    name="customerId"
                    onChange={this.handleChange}
                    value={this.state.customerId}
                    onKeyDown={this.autocompleteCustomer}
                  />
                  <span>↹</span>
                </div>
                <div className="col-md-3">
                  <p>Kunde</p>
                  <select
                    name="customer"
                    value={this.state.customer}
                    onChange={this.handleChange}
                  >
                    <option value="-">-</option>
                    {this.state.customerAll.map((customer, i) => (
                      <option key={i} value={customer.customerName}>
                        {customer.customerName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <p>Strasse</p>
                  <input
                    required
                    type="text"
                    name="streetCustomer"
                    onChange={this.handleChange}
                    value={this.state.streetCustomer}
                  />
                </div>
                <div className="col-md-2">
                  <p>Plz</p>
                  <input
                    required
                    type="text"
                    name="zipCodeCustomer"
                    onChange={this.handleChange}
                    value={this.state.zipCodeCustomer}
                  />
                </div>
                <div className="col-md-3">
                  <p>Ort</p>
                  <input
                    required
                    type="text"
                    name="cityCustomer"
                    onChange={this.handleChange}
                    value={this.state.cityCustomer}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <p>Bv</p>
                  <input
                    required
                    type="text"
                    name="bv"
                    onChange={this.handleChange}
                    value={this.state.bv}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <p>Baubeginn</p>
                  <input
                    type="date"
                    name="buildingStart"
                    onChange={this.handleChange}
                    value={this.state.buildingStart}
                  />
                </div>
                <div className="col-md-3">
                  <p>Bauende</p>
                  <input
                    type="date"
                    name="buildingEnd"
                    onChange={this.handleChange}
                    value={this.state.buildingEnd}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-1 inline">
                  <input
                    type="checkbox"
                    name="paragraph"
                    onChange={this.handleCheck}
                    value={this.state.paragraph}
                    checked={this.state.paragraph}
                  />
                  <label>§13b</label>
                </div>
                <div className="col-md-3">
                  <p>Zahlungsziel</p>
                  <select
                    value={this.state.paymentName}
                    name="paymentName"
                    onChange={this.handleChange}
                  >
                    <option key="-1" value="-">
                      -
                    </option>
                    {this.state.allPayments.map((item, i) => {
                      return (
                        <option key={i} value={item.paymentName}>
                          {item.paymentName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-2">
                  <p>Nachlass</p>
                  <input
                    type="text"
                    name="discountComplete"
                    onChange={this.handleChange}
                    value={this.state.discountComplete}
                  />
                </div>
                <div className="offset-md-4 col-md-2">
                  <p>Angebotsstatus</p>
                  <select
                    name="angebotsstatus"
                    onChange={this.handleChange}
                    value={this.state.angebotsstatus}
                  >
                    <option key="0" value="Offen">
                      Offen
                    </option>
                    <option key="1" value="Erhalten">
                      Erhalten
                    </option>
                    <option key="2" value="Verloren">
                      Verloren
                    </option>
                  </select>
                </div>
              </div>
              <hr />
              <div className="item-btn col-2">
                <FontAwesomeIcon
                  id="upload-icon"
                  color="var(--white)"
                  icon="upload"
                />
                <CSVReader
                  cssClass="csv-input"
                  onFileLoaded={this.handleUpload}
                  inputId="upload"
                />
              </div>
              <hr />
              <div className="row">
                {this.state.products.map((products, idx) => (
                  <div key={idx + "row"} className="row">
                    <div className="col">
                      <p>Position</p>
                      <input
                        key={idx + "pos"}
                        type="text"
                        name="pos"
                        onChange={this.handleChangeProduct(idx)}
                        value={products.pos}
                      />
                    </div>
                    <div className="col tab">
                      <p>Nummer</p>
                      <input
                        key={idx + "nr"}
                        type="text"
                        name="nr"
                        onChange={this.handleChangeProduct(idx)}
                        onKeyDown={this.autocompleteDsc(idx)}
                        value={products.nr}
                      />
                      <span>↹</span>
                    </div>
                    <div className="col-5">
                      <p>Bezeichnung</p>
                      <Textarea
                        key={idx + "dsc"}
                        type="text"
                        name="dsc"
                        onChange={this.handleChangeProduct(idx)}
                        value={products.dsc}
                      />
                      <button
                        key={idx + "modal"}
                        type="text"
                        name="searchModal"
                        onClick={this.openSearchModal(idx, "searchModal")}
                        className="dsc-search"
                      >
                        <FontAwesomeIcon icon="search" />
                      </button>
                      {products.searchModal ? (
                        <OfferCreateSearch
                          toggle={this.toggle(idx, "searchModal")}
                          searchModal={products.searchModal}
                          idx={idx}
                          searchedProduct={this.searchedProduct}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col">
                      <p>Menge</p>
                      <input
                        key={idx + "amnt"}
                        type="text"
                        name="amnt"
                        onChange={this.handleChangeProduct(idx)}
                        value={products.amnt}
                      />
                    </div>
                    <div className="col">
                      <p>Einheit</p>
                      <select
                        name="unit"
                        onChange={this.handleChangeProduct(idx)}
                        value={products.unit}
                      >
                        {globalUnits.map((unit, idx) => {
                          return (
                            <option key={idx} value={unit} name="unit">
                              {unit}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col tab">
                      <p>Einzelpreis</p>
                      <input
                        key={idx + "pricePiece"}
                        type="text"
                        name="pricePiece"
                        onChange={this.handleChangeProduct(idx)}
                        onKeyDown={this.calcCompletePrice(idx)}
                        value={products.pricePiece}
                      />
                      <span>↹</span>
                    </div>
                    <div className="col">
                      <p>Gesamtpreis</p>
                      <input
                        key={idx + "priceComplete"}
                        type="text"
                        name="priceComplete"
                        onChange={this.handleChangeProduct(idx)}
                        value={products.priceComplete}
                      />
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        onClick={this.handleRemoveProduct(idx, products.id)}
                        className="btn-remove"
                      >
                        -
                      </button>
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="offset-11 col-1">
                    <button
                      type="button"
                      onClick={this.handleAddProduct}
                      className="btn-add-item"
                    >
                      +
                    </button>
                  </div>
                </div>
                <hr />
                <div className="item-btn row">
                  <div className="col-12">
                    <div
                      className={
                        this.state.saved ? "saved visible" : "invisible saved"
                      }
                    >
                      <p>
                        {" "}
                        <FontAwesomeIcon
                          color="var(--greenSeyband)"
                          icon="check-circle"
                        />{" "}
                        Daten wurden gespeichert
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8 div-checkbox">
                    <h2>Anlagen:</h2>
                    <div className="width-checkbox">
                      <label>Auftragsbestätigung</label>
                      <input
                        type="checkbox"
                        onChange={this.handleCheck}
                        value={this.state.chkConfirmation}
                        checked={this.state.chkConfirmation}
                        name="chkConfirmation"
                      />
                    </div>
                    <div className="width-checkbox">
                      <label>Wiederruf</label>
                      <input
                        type="checkbox"
                        onChange={this.handleCheck}
                        value={this.state.chkWiederruf}
                        checked={this.state.chkWiederruf}
                        name="chkWiederruf"
                      />
                    </div>
                    <div className="width-checkbox">
                      <label>Wiederrufsformular</label>
                      <input
                        type="checkbox"
                        onChange={this.handleCheck}
                        value={this.state.chkWiederrufformular}
                        checked={this.state.chkWiederrufformular}
                        name="chkWiederrufformular"
                      />
                    </div>
                  </div>
                </div>
                <div className="row print-buttons">
                  <div className="col-4">
                    <button
                      disabled={this.state.disabled}
                      onClick={this.printList}
                      className={
                        this.state.print ? "hide" : "btn-save-product fullwidth"
                      }
                    >
                      <b>Angebot</b>
                      <br />
                      generieren <FontAwesomeIcon icon="sync" />
                    </button>

                    {this.state.print && (
                      <PDFDownloadLink
                        document={
                          <OfferPrint
                            customer={this.readCustomerNameById(
                              this.state.customerId
                            )}
                            streetCustomer={this.state.streetCustomer}
                            zipCodeCustomer={this.state.zipCodeCustomer}
                            cityCustomer={this.state.cityCustomer}
                            date={this.state.date}
                            offerNr={this.state.offerNr}
                            version={this.state.version}
                            versionOld={this.state.versionOld}
                            paymentText={this.state.paymentText}
                            discountComplete={this.state.discountComplete}
                            bv={this.state.bv}
                            productsSort={this.sortProductsPos(
                              this.state.products
                            )}
                            paragraph={this.state.paragraph}
                            chkConfirmation={this.state.chkConfirmation}
                            chkWiederruf={this.state.chkWiederruf}
                            chkWiederrufformular={
                              this.state.chkWiederrufformular
                            }
                          />
                        }
                        fileName={
                          `Angebot-` +
                          this.state.offerNr +
                          "-" +
                          this.state.version +
                          "-(" +
                          this.state.customer +
                          ")"
                        }
                        className="btn-save-product-span fullwidth"
                      >
                        {({ blob, url, loadingAngebot, error }) =>
                          loadingAngebot ? (
                            <span>Angebot wird erstellt</span>
                          ) : (
                            <span>
                              <b>Angebot</b>
                              <br />
                              herunterladen! <FontAwesomeIcon icon="download" />
                            </span>
                          )
                        }
                      </PDFDownloadLink>
                    )}
                  </div>

                  <div className="col">
                    <button
                      disabled={this.state.disabled}
                      onClick={this.printConfirmation}
                      className={
                        this.state.printConfirmation
                          ? "hide"
                          : "btn-save-product fullwidth"
                      }
                    >
                      <b>Auftragsbestätigung</b>
                      <br />
                      generieren <FontAwesomeIcon icon="sync" />
                    </button>
                    {this.state.printConfirmation && (
                      <PDFDownloadLink
                        document={
                          <PrintConfirmationPDF
                            customer={this.readCustomerNameById(
                              this.state.customerId
                            )}
                            streetCustomer={this.state.streetCustomer}
                            zipCodeCustomer={this.state.zipCodeCustomer}
                            cityCustomer={this.state.cityCustomer}
                            date={this.state.date}
                            offerNr={this.state.offerNr}
                            version={this.state.version}
                            versionOld={this.state.versionOld}
                            bv={this.state.bv}
                          />
                        }
                        fileName={
                          `Auftragsbestaetigung-` +
                          this.state.offerNr +
                          "-" +
                          this.state.version +
                          "-(" +
                          this.state.customer +
                          ")"
                        }
                        className="btn-save-product-span fullwidth"
                      >
                        {({ blob, url, loadingConfimartion, error }) =>
                          loadingConfimartion ? (
                            <span>
                              Auftragsbestätigung
                              <br />
                              wird erstellt
                            </span>
                          ) : (
                            <span>
                              Auftragsbestätigung
                              <br />
                              <b>herunterladen!</b>{" "}
                              <FontAwesomeIcon icon="download" />
                            </span>
                          )
                        }
                      </PDFDownloadLink>
                    )}
                  </div>
                  <div className="col">
                    <button
                      disabled={this.state.disabled}
                      onClick={this.printWiderruf}
                      className={
                        this.state.printWiderruf
                          ? "hide"
                          : "btn-save-product fullwidth"
                      }
                    >
                      <b>Widerruf</b>
                      <br />
                      generieren <FontAwesomeIcon icon="sync" />
                    </button>
                    {this.state.printWiderruf && (
                      <PDFDownloadLink
                        document={
                          <PrintWiderrufPDF
                            customer={this.readCustomerNameById(
                              this.state.customerId
                            )}
                            streetCustomer={this.state.streetCustomer}
                            zipCodeCustomer={this.state.zipCodeCustomer}
                            cityCustomer={this.state.cityCustomer}
                            date={this.state.date}
                            offerNr={this.state.offerNr}
                            version={this.state.version}
                            versionOld={this.state.versionOld}
                            bv={this.state.bv}
                          />
                        }
                        fileName={
                          `Widerruf-` +
                          this.state.offerNr +
                          "-" +
                          this.state.version +
                          "-(" +
                          this.state.customer +
                          ")"
                        }
                        className="btn-save-product-span fullwidth"
                      >
                        {({ blob, url, loadingWiderruf, error }) =>
                          loadingWiderruf ? (
                            <span>
                              Widerruf
                              <br />
                              wird erstellt
                            </span>
                          ) : (
                            <span>
                              Widerruf
                              <br />
                              <b>herunterladen!</b>{" "}
                              <FontAwesomeIcon icon="download" />
                            </span>
                          )
                        }
                      </PDFDownloadLink>
                    )}
                  </div>
                  <div className="col">
                    <button
                      disabled={this.state.disabled}
                      onClick={this.printWiderrufformular}
                      className={
                        this.state.printWiderrufformular
                          ? "hide"
                          : "btn-save-product fullwidth"
                      }
                    >
                      <b>Widerrufsformular</b>
                      <br />
                      generieren <FontAwesomeIcon icon="sync" />
                    </button>
                    {this.state.printWiderrufformular && (
                      <PDFDownloadLink
                        document={
                          <PrintWiderrufformularPDF
                            customer={this.readCustomerNameById(
                              this.state.customerId
                            )}
                            streetCustomer={this.state.streetCustomer}
                            zipCodeCustomer={this.state.zipCodeCustomer}
                            cityCustomer={this.state.cityCustomer}
                            date={this.state.date}
                            offerNr={this.state.offerNr}
                            version={this.state.version}
                            versionOld={this.state.versionOld}
                            bv={this.state.bv}
                          />
                        }
                        fileName={
                          `Widerrufformular-` +
                          this.state.offerNr +
                          "-" +
                          this.state.version +
                          "-(" +
                          this.state.customer +
                          ")"
                        }
                        className="btn-save-product-span fullwidth"
                      >
                        {({ blob, url, loadingWiderrufformular, error }) =>
                          loadingWiderrufformular ? (
                            <span>
                              Widerrufformular
                              <br />
                              wird erstellt
                            </span>
                          ) : (
                            <span>
                              Widerrufformular
                              <br />
                              <b>herunterladen!</b>{" "}
                              <FontAwesomeIcon icon="download" />
                            </span>
                          )
                        }
                      </PDFDownloadLink>
                    )}
                  </div>
                </div>
                <hr />
                <div className="row btn-save-offer">
                  <div className="col">
                    <button className="btn-save-product fullwidth">
                      Angebot speichern <FontAwesomeIcon icon="save" />
                    </button>
                  </div>
                </div>
                <div className="info-offer">
                  <hr />
                  <p>
                    1) Zum Aktualisieren eines Angebotes, muss das Eingabefeld
                    "alte Version" und "Version" übereinstimmen
                  </p>
                </div>
              </div>
            </fieldset>
          </form>
        </section>
        <div className={this.state.offerIdExists ? "errorBvIdExists" : "hideExist errorBvIdExists"}><FontAwesomeIcon icon="times-circle" /><br/>AngebostsNr. existiert bereits</div>

      </React.Fragment>
    );
  }
}

export default OfferCreate;
