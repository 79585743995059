import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
import fontawesome from "@fortawesome/fontawesome";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseUrl from "../../config/config.js";
import ConstructionDiaryList from "./ConstructionDiaryList.js";
var $ = require("jquery");

class ConstructionDiaryListMain extends Component {
  constructor() {
    super();
    this.state = {
      cdList: [],
      cdListCopy: [],
      search: ""
    };
  }

  componentDidMount = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true,
      });
    }
     this.loadConstructionDiary();
 };
 handleChange = e => {
 
  this.setState({
    [e.target.name]: e.target.value
  });
};
onKeyPress = e => {
  if (e.key === "Enter") {
    this.search(e.target.value);
  }
};
handleSearch = e => {
  this.setState({
    search: e.target.value
  });

  if (e.target.value === "") {
    this.setState({
      cdList: this.state.cdListCopy,
      errorSearch: false
    });
  }
};

search = eingabe => {
  let convertEingabe = eingabe.replace(/ /g, "%").replace("+", "%2B");

  if (eingabe === "") {
    this.setState({
      cdList: this.state.cdListCopy,
      errorSearch: false
    });
  } else {
    this.setState({
      cdList: []
    });
    return fetch(
      BaseUrl + "api/constructionDiary/searchDiaryList.php?eingabe=" + convertEingabe
    )
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.message === "No items found.") {
          this.setState({
            cdList: [],
            errorSearch: true
          });
        } else {
          this.setState({
            errorSearch: false,
            cdList: responseJson.records[0]
          });
        }
      })
      .catch(error => {});
  }
};
  loadConstructionDiary = () => {
    return fetch(BaseUrl + "api/constructionDiary/loadDiaryList.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          cdList: responseJson.cdList[0],
          cdListCopy: responseJson.cdList[0]
        });
      })
      .catch((error) => {});
  };

  render() {
    return (
      <React.Fragment>
          <section id="construction-diary">
                  <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home/bautagebuch">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--yellow)" icon="calculator" />{" "}
                Bautagebuch Liste
              </h1>
            </div>
          </div>
          </section>
          <section id="showItems" className="display-item">
          <h4>Übersicht Bautagebücher</h4>
          <div className="diary-show-items row">
            <div className="col-6 offset-3">
              <input
                className="search"
                type="text"
                name="search"
                placeholder="Suchen"
                onChange={this.handleSearch}
                onKeyPress={this.onKeyPress}
                value={this.state.search}
              />
            </div>
 
          </div>
 
          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          <ul className="items-list offers-list">

            {this.state.cdList.map((item, idx) => {
                 return(
                 
 
                  <ConstructionDiaryList
                  customerId={item.customerId}
                  customerName= {item.customerName} 
                  bvId={item.bvId}
                  bv= {item.bv}
                  dateSaved= {item.dateSaved}
                  dateFrom= {item.dateFrom}
                  dateTo= {item.dateTo}
                    key={idx}
                    search={this.state.search}
                    />
                )
            })}

          </ul>
        </section>
       </React.Fragment>
    );
  }
}

export default ConstructionDiaryListMain;