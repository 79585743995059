import React, { useState, useEffect } from "react";
import "@fortawesome/fontawesome-free-solid";
import BaseUrl from "../../config/config.js";
import CustomFontRegular from "../../fonts/Lunchtype22-Regular.ttf";
import CustomFontBold from "../../fonts/Lunchtype22-Medium.ttf";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Link,
  Line,
  Svg,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "CustomFont", // Font family name
  fonts: [
    { src: CustomFontRegular, fontWeight: "normal" },
    { src: CustomFontBold, fontWeight: "bold" },
  ],
});
function PrintWiderrufformularPDF(props) {
  const [state, setState] = useState({
    id: "",
    name: "",
    strasse: "",
    plz: "",
    land: "",
    tel: "",
    telefax: "",
    mobil: "",
    mail: "",
    web: "",
    widerrufsformular: "",
    customer: props.customer,
    streetCustomer: props.streetCustomer,
    zipCodeCustomer: props.zipCodeCustomer,
    cityCustomer: props.cityCustomer,
    version: props.version,
    versionOld: props.versionOld,
  });

  useEffect(() => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      setState((prevState) => ({
        ...prevState,
        disabledFieldset: true,
      }));
    }
    loadBaseData();
  }, []);

  const loadBaseData = () => {
    return fetch(BaseUrl + "api/baseData/readBaseData.php")
      .then((response) => response.json())
      .then((responseJson) => {
        const data = responseJson.records[0][0];

        setState((prevState) => ({
          ...prevState,
          id: data.id,
          name: data.name,
          strasse: data.strasse,
          plz: data.plz,
          land: data.land,
          tel: data.tel,
          telefax: data.telefax,
          mobil: data.mobil,
          mail: data.mail,
          web: data.web,
          widerrufsformular: data.widerrufsformular,
        }));
      })
      .catch((error) => {});
  };

  const replaceText = (text) => {
    const anschrift = [
      `${state.name}`,
      `${state.strasse}`,
      `${state.plz}`,
      `Telefon: ${state.tel}`,
      `Telefax: ${state.telefax}`,
      `Email: ${state.mail}`,
    ];

    return text.replace("%anschrift%", anschrift.join("\n")).replace(/##/g, "");
  };
  const styles = StyleSheet.create({
    page: {
      flexWrap: "wrap",
      fontSize: "8pt",
      flexDirection: "row",
      paddingTop: 35,
      paddingBottom: "4cm",
      paddingLeft: 35,
      paddingRight: 35,
      lineHeight: "1.5pt",
      fontFamily: "CustomFont",
      fontWeight: "normal",
    },

    view: {
      width: "100%",
    },
    width40: {
      width: "40%",
    },
    width60: {
      width: "60%",
    },
    width50: {
      width: "50%",
    },

    marginT25: {
      marginTop: "25px",
      display: "block",
      width: "100%",
    },

    marginT30: {
      marginTop: "50px",
    },
    marginT25: {
      marginTop: "35px",
    },
    marginT10: {
      marginTop: 10,
    },

    marginT3: {
      marginTop: 3,
    },
    marginB20: {
      marginBottom: 20,
    },

    textRight: {
      textAlign: "right",
    },

    alignRight: {
      alignItems: "flex-end",
      width: "100%",
    },
    image: {
      width: 100,
    },
    imageFirstPage: {
      width: 165,
    },

    pt7: {
      marginBottom: "7pt",
      fontSize: "7pt",
    },

    h2: {
      fontSize: "18pt",
      fontWeight: "bold",
      textAlign: "left",
      marginTop: "0px",
      marginBottom: "10px",
    },

    h4: {
      fontSize: "13pt",
      fontWeight: "bold",
    },
  });

  const stylesList = StyleSheet.create({
    view: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      marginBottom: "0.5mm",
    },
    bold: {
      fontWeight: "bold",
    },
  });

  return (
    <Document> 
    <Page size="A4" style={styles.page} wrap>
      <View style={[stylesList.view]}>
        <View style={styles.alignRight} fixed>
          <Image
            style={styles.imageFirstPage}
            src={require("../../img/logo.png")}
          />
        </View>

        <View style={[styles.view, styles.marginB20]}>
          <Text style={styles.h2}>Widerrufsformular</Text>
        </View>
        <View style={styles.view}>
          {state.widerrufsformular.split("\n").map((item, key) => {
            if (item.includes("##")) {
              return <Text key={key}>{replaceText(item)}</Text>;
            } else if (item.includes("%unterschriftOrtDatum%")) {
              return (
                <View style={[stylesList.view, styles.marginT30]} key={key}>
                  <View style={styles.width50}>
                    <Svg height="1" width="400">
                      <Line
                        x1="0"
                        y1="0"
                        x2="200"
                        y2="00"
                        strokeWidth={1}
                        stroke="rgb(0,0,0)"
                      />
                    </Svg>
                    <Text style={[styles.marginT10]}>(Ort, Datum)</Text>
                  </View>
                  <View style={[styles.width50, styles.textRight]}>
                    <Svg height="1" width="400">
                      <Line
                        x1="50"
                        y1="0"
                        x2="263"
                        y2="00"
                        strokeWidth={1}
                        stroke="rgb(0,0,0)"
                      />
                    </Svg>
                    <Text style={[styles.marginT10]}>
                      (Unterschrift des/der Verbraucher/s)
                    </Text>
                  </View>
                </View>
              );
            } else if (item.includes("%unterschriftAg")) {
              return (
                <View style={[stylesList.view, styles.marginT10]} key={key}>
                  <View style={[styles.width40]}>
                    <Text>
                      Name und Anschrift der/des AG
                    </Text>
                  </View>
                  <View style={[styles.width60, styles.marginT10]}>
                    <View style={[]}>
                      <Svg height="1" width="400">
                        <Line
                          x1="0"
                          y1="0"
                          x2="320"
                          y2="0"
                          strokeWidth={1}
                          stroke="rgb(0,0,0)"
                        />
                      </Svg>
                    </View>
                    <View style={[styles.marginT25]}>
                      <Svg height="1" width="400">
                        <Line
                          x1="0"
                          y1="0"
                          x2="320"
                          y2="00"
                          strokeWidth={1}
                          stroke="rgb(0,0,0)"
                        />
                      </Svg>
                    </View>
                  </View>
                </View>
              );
            } else if (item.includes("<small>")) {
              return (
                <Text
                  style={[styles.pt7, styles.marginB20, styles.marginT10]}
                  key={key}
                >
                  {item.replace("<small>", "")}
                </Text>
              );
            } else if (item.includes("%linie%")) {
              return (
                <View style={[styles.marginB20, styles.marginT10]} key={key}>
                  <Svg height="1" width="1000">
                    <Line
                      x1="0"
                      y1="0"
                      x2="530"
                      y2="00"
                      strokeWidth={1}
                      stroke="rgb(0,0,0)"
                    />
                  </Svg>
                </View>
              );
            } else if (item.includes("%anschrift%")) {
              return (
                <Text style={stylesList.bold} key={key}>
                  {replaceText(item)}
                  {"\n"}
                </Text>
              );
            } else {
              return (
                <Text key={key}>
                  {replaceText(item)}
                  {"\n"}
                </Text>
              );
            }
          })}
        </View>
      </View>
    </Page>
    </Document>
  );
}

export default PrintWiderrufformularPDF;
