import React, { Component } from "react";
import CalcEmployeesModal from "../calcHourlyWage/CalcEmployeesModal.js";
 import { Modal, ModalBody } from "reactstrap";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
var $ = require("jquery");

class CalcEmployees extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      fullName: "",
      lastName: "",
      firstName: "",
      jahr: "",
      arbeitstage: "",
      stunden_tag: "",
      stunden_jahr: "",
      stundenlohn: "",
      lohnBrutto: "",
      rentenvers: "",
      arbeitslosenvers: "",
      krankenvers: "",
      pflegevers: "",
      insolvenzgeldumlage: "",
      umlage_erstattung: "",
      lohnInklNebenkosten: "",
      krankheitstage: "",
      krankheitstageProzent: "",
      bezFeiertage: "",
      jahresstundenlohn: "",
      bgHauptumlage: "",
      bgLastenverteilung: "",
      soka: "",
      kosten_h: "",
      gk: "",
      sk_h: "",
      gewinn: "",
      kosten_jahr: "",
      angebotspreis: "",
      namesWageAll: [],
      yearsAll: [],
      calcDataEmployees: [],
      calcDataEmployeesCopy: [],
      dataAll: [],
      dataAllCopy: [],
      errorSearch: false,
      errorInput: false,
      search: "",
      useSearch: false,
      isDisabled: true,
      isDisabledAll: true,
      isDisabledSingle: true,
      jahrShow: "",
      disabledFieldset: false,
      disableFields: true
    };
  }

  componentDidMount = () => {
    this.loadNameHourlyWage();
    this.loadYears();
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true
      });
    }
  };
  toggle = e => {
    e.preventDefault();
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        modal: false
      });
    } else {
      this.setState({
        modal: !this.state.modal
      });
    }
  };
  handleChangeSpecial = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      errorInput: false
    });
    if (e.target.name === "jahr" && e.target.value !== "-") {
      this.setState({
        jahr: e.target.value,
        isDisabledAll: false,
        isDisabled: false
      });
      this.loadData(e.target.value);
    }

    if (e.target.name === "jahr" && e.target.value === "-") {
      this.setState({
        isDisabled: true,
        fullName: "-"
      });
    }
    if (e.target.name === "name" && e.target.value === "-") {
      this.setState({
        fullName: e.target.value,
        isDisabledSingle: true,
        isDisabledAll: false
      });
    }
    if (e.target.name === "jahr" && e.target.value === "-") {
      this.setState({
        fullName: e.target.value,
        isDisabledSingle: true,
        isDisabledAll: true
      });
    }
    if (e.target.name === "name" && e.target.value !== "-") {
      let divideName = e.target.value.split(" ");
      this.setState({
        fullName: e.target.value,
        isDisabledSingle: false,
        isDisabledAll: true
      });
      this.calcEverything(divideName[0], divideName[1], false);
    } else {
      this.setState({
        jahr: "",
        arbeitstage: "",
        stunden_tag: "",
        stunden_jahr: "",
        stundenlohn: "",
        lohnBrutto: "",
        rentenvers: "",
        arbeitslosenvers: "",
        krankenvers: "",
        pflegevers: "",
        insolvenzgeldumlage: "",
        umlage_erstattung: "",
        lohnInklNebenkosten: "",
        krankheitstage: "",
        bezFeiertage: "",
        jahresstundenlohn: "",
        bgHauptumlage: "",
        bgLastenverteilung: "",
        soka: "",
        kosten_jahr: "",
        kosten_h: "",
        gk: "",
        sk_h: "",
        gewinn: "",
        angebotspreis: "",
        disableFields: true
      });
    }
  };
  handleChangeShow = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    this.loadCalcDataEmployees(e.target.value);
  };
  handleSubmitSingle = e => {
    e.preventDefault();
    let divideName = this.state.fullName.split(" ");

    this.removeSingle(divideName[0], divideName[1]);

    let item = {
      lastName: divideName[0],
      firstName: divideName[1],
      jahr: this.state.jahr,
      arbeitstage: this.state.arbeitstage,
      stunden_tag: this.state.stunden_tag,
      stunden_jahr: this.state.stunden_jahr,
      stundenlohn: this.state.stundenlohn,
      lohnBrutto: this.state.lohnBrutto,
      rentenvers: this.state.rentenvers,
      arbeitslosenvers: this.state.arbeitslosenvers,
      krankenvers: this.state.krankenvers,
      pflegevers: this.state.pflegevers,
      insolvenzgeldumlage: this.state.insolvenzgeldumlage,
      umlage_erstattung: this.state.umlage_erstattung,
      lohnInklNebenkosten: this.state.lohnInklNebenkosten,
      krankheitstage: this.state.krankheitstage,
      bezFeiertage: this.state.bezFeiertage,
      jahresstundenlohn: this.state.jahresstundenlohn,
      bgHauptumlage: this.state.bgHauptumlage,
      bgLastenverteilung: this.state.bgLastenverteilung,
      soka: this.state.soka,
      kosten_jahr: this.state.kosten_jahr,
      kosten_h: this.state.kosten_h,
      gk: this.state.gk,
      sk_h: this.state.sk_h,
      gewinn: this.state.gewinn,
      angebotspreis: this.state.angebotspreis
    };
    $.ajax({
      url: BaseUrl + "api/calcHourlyWage/createCalcEmployee.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(item),
      success: function(response) {
        this.savedcalchourlywageSingle(divideName[0], divideName[1]);
        this.setState({
          jahr: "-",
          fullName: "-",
          arbeitstage: "",
          stunden_tag: "",
          stunden_jahr: "",
          stundenlohn: "",
          lohnBrutto: "",
          rentenvers: "",
          arbeitslosenvers: "",
          krankenvers: "",
          pflegevers: "",
          insolvenzgeldumlage: "",
          umlage_erstattung: "",
          lohnInklNebenkosten: "",
          krankheitstage: "",
          bezFeiertage: "",
          jahresstundenlohn: "",
          bgHauptumlage: "",
          bgLastenverteilung: "",
          soka: "",
          kosten_jahr: "",
          kosten_h: "",
          gk: "",
          sk_h: "",
          gewinn: "",
          angebotspreis: "",
          disableFields: true
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
    this.loadCalcDataEmployees(this.state.jahrShow);
  };
  handleSubmitAll = item => {
    $.ajax({
      url: BaseUrl + "api/calcHourlyWage/createCalcEmployee.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(item),
      success: function(response) {
        this.setState({
          jahr: "-",
          fullName: "-",
          arbeitstage: "",
          stunden_tag: "",
          stunden_jahr: "",
          stundenlohn: "",
          lohnBrutto: "",
          rentenvers: "",
          arbeitslosenvers: "",
          krankenvers: "",
          pflegevers: "",
          insolvenzgeldumlage: "",
          umlage_erstattung: "",
          lohnInklNebenkosten: "",
          krankheitstage: "",
          bezFeiertage: "",
          jahresstundenlohn: "",
          bgHauptumlage: "",
          bgLastenverteilung: "",
          soka: "",
          kosten_jahr: "",
          kosten_h: "",
          gk: "",
          sk_h: "",
          gewinn: "",
          angebotspreis: "",
          modal: false,
          disableFields: true
        });
        this.loadCalcDataEmployees(this.state.jahrShow);
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };

  loadData = jahr => {
    return fetch(
      BaseUrl + "api/calcHourlyWage/readCalcData.php?eingabe=" + jahr
    )
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            dataAll: responseJson.records,
            dataAllCopy: responseJson.records
          });
        });
      })
      .catch(error => {});
  };
  loadNameHourlyWage = () => {
    return fetch(BaseUrl + "api/calcHourlyWage/readNameWage.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            namesWageAll: responseJson.records
          });
        });
      })
      .catch(error => {});
  };
  loadYears = () => {
    return fetch(BaseUrl + "api/calcHourlyWage/readYears.php ")
      .then(response => response.json())
      .then(responseJson => {
        let year = responseJson.records[0];
        this.loadCalcDataEmployees(year.jahr);

        responseJson.records.map(item => {
          return this.setState({
            yearsAll: responseJson.records
          });
        });
      })
      .catch(error => {});
  };
  loadCalcDataEmployees = eingabe => {
    this.state.calcDataEmployees = [];

    fetch(
      BaseUrl + "api/calcHourlyWage/readCalcDataEmployees.php?eingabe=" +
        eingabe
    )
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.message === "No itms found.") {
          this.setState({
            calcDataEmployees: [],
            errorSearch: true
          });
        } else {
          this.setState({
            calcDataEmployees: responseJson.records,
            calcDataEmployeesCopy: responseJson.records,
            errorSearch: false
          });
        }
      })
      .catch(error => {});
  };
  findHourlyWage = (lastName, firstName) => {
    const result = this.state.namesWageAll.find(
      hourlyWage =>
        hourlyWage.lastName === lastName && hourlyWage.firstName === firstName
    );
    return result;
  };
  calcEverything = (lastName, firstName, boolean) => {
    const getWage = this.findHourlyWage(lastName, firstName);
    this.state.stundenlohn = getWage.hourlyWage;
    let bruttolohn;
    let rente;
    let arbeitslosen;
    let kranken;
    let pflege;
    let insolvenz;
    let umlage;
    let lohnkosten;
    let kranktage;
    let feiertage;
    let jahreslohn_stunde;
    let bgHaupt;
    let bgLast;
    let sozka;
    let kostenJahr;
    let kostenStunde;
    let gemeink;
    let skStunde;
    let gew;
    let angebot;

    if (getWage.special === "ja") {
      this.setState({
        disableFields: false
      });
      if (this.state.lohnBrutto === "") {
        bruttolohn = 0.0;
      } else {
        bruttolohn = this.state.lohnBrutto;
      }
      rente = 0.0;
      arbeitslosen = 0.0;
      pflege = 0.0;
      insolvenz = 0.0;
      umlage = 0.0;
      if (this.state.krankenvers === "") {
        kranken = 0.0;
      } else {
        kranken = this.state.krankenvers;
      }
      lohnkosten = (
        parseFloat(bruttolohn) +
        parseFloat(rente) +
        parseFloat(arbeitslosen) +
        parseFloat(kranken) +
        parseFloat(pflege) +
        parseFloat(insolvenz) +
        parseFloat(umlage)
      ).toFixed(2);
      kranktage = 0.0;
      feiertage = (
        (lohnkosten / this.state.dataAll[0].arbeitstage) *
        this.state.dataAll[0].bezFeiertage
      ).toFixed(2);
      jahreslohn_stunde = (
        parseFloat(lohnkosten) +
        parseFloat(kranktage) +
        parseFloat(feiertage)
      ).toFixed(2);

      bgHaupt = 0.0;
      bgLast = 0.0;
      sozka = 0.0;
      kostenJahr = (
        parseFloat(jahreslohn_stunde) +
        parseFloat(bgHaupt) +
        parseFloat(bgLast) +
        parseFloat(sozka)
      ).toFixed(2);

      kostenStunde = (kostenJahr / this.state.dataAll[0].stunden_jahr).toFixed(
        2
      );
      gemeink = (
        this.state.dataAll[0].gk /
        (this.state.namesWageAll.length * this.state.dataAll[0].stunden_jahr)
      ).toFixed(2);

      skStunde = (parseFloat(kostenStunde) + parseFloat(gemeink)).toFixed(2);
      gew = ((skStunde * this.state.dataAll[0].gewinn) / 100).toFixed(2);
      angebot = (parseFloat(skStunde) + parseFloat(gew)).toFixed(2);
    } else {
      this.setState({
        disableFields: true
      });
      bruttolohn = (
        this.state.stundenlohn *
        this.state.dataAll[0].arbeitstage *
        this.state.dataAll[0].stunden_tag
      ).toFixed(2);
      rente = (
        (bruttolohn * this.state.dataAll[0].rentenvers) /
        100 /
        2
      ).toFixed(2);
      arbeitslosen = (
        (bruttolohn * this.state.dataAll[0].arbeitslosenvers) /
        100 /
        2
      ).toFixed(2);
      kranken = (
        (bruttolohn * this.state.dataAll[0].krankenvers) /
        100 /
        2
      ).toFixed(2);
      pflege = (
        (bruttolohn * this.state.dataAll[0].pflegevers) /
        100 /
        2
      ).toFixed(2);
      insolvenz = (
        (bruttolohn * this.state.dataAll[0].insolvenzgeldumlage) /
        100 /
        2
      ).toFixed(2);
      umlage = (
        (bruttolohn * this.state.dataAll[0].umlage_erstattung) /
        100 /
        2
      ).toFixed(2);

      lohnkosten = (
        parseFloat(bruttolohn) +
        parseFloat(rente) +
        parseFloat(arbeitslosen) +
        parseFloat(kranken) +
        parseFloat(pflege) +
        parseFloat(insolvenz) +
        parseFloat(umlage)
      ).toFixed(2);
      kranktage = (
        this.state.dataAll[0].krankheitstage *
        8 *
        (lohnkosten / (37 * 52)) *
        (this.state.dataAll[0].krankheitstageProzent / 100)
      ).toFixed(2);
      feiertage = (
        (lohnkosten / this.state.dataAll[0].arbeitstage) *
        this.state.dataAll[0].bezFeiertage
      ).toFixed(2);
      jahreslohn_stunde = (
        parseFloat(lohnkosten) +
        parseFloat(kranktage) +
        parseFloat(feiertage)
      ).toFixed(2);

      bgHaupt = (
        (jahreslohn_stunde * this.state.dataAll[0].bgHauptumlage) /
        100
      ).toFixed(2);
      bgLast = (
        (jahreslohn_stunde * this.state.dataAll[0].bgLastenverteilung) /
        100
      ).toFixed(2);
      sozka = ((jahreslohn_stunde * this.state.dataAll[0].soka) / 100).toFixed(
        2
      );
      kostenJahr = (
        parseFloat(jahreslohn_stunde) +
        parseFloat(bgHaupt) +
        parseFloat(bgLast) +
        parseFloat(sozka)
      ).toFixed(2);

      kostenStunde = (kostenJahr / this.state.dataAll[0].stunden_jahr).toFixed(
        2
      );
      gemeink = (
        this.state.dataAll[0].gk /
        (this.state.namesWageAll.length * this.state.dataAll[0].stunden_jahr)
      ).toFixed(2);

      skStunde = (parseFloat(kostenStunde) + parseFloat(gemeink)).toFixed(2);
      gew = ((skStunde * this.state.dataAll[0].gewinn) / 100).toFixed(2);
      angebot = (parseFloat(skStunde) + parseFloat(gew)).toFixed(2);
    }
    this.setState({
      jahr: this.state.dataAll[0].jahr,
      arbeitstage: this.state.dataAll[0].arbeitstage,
      stunden_tag: this.state.dataAll[0].stunden_tag,
      stunden_jahr: this.state.dataAll[0].stunden_jahr,
      stundenlohn: this.state.stundenlohn,
      lohnBrutto: bruttolohn,
      rentenvers: rente,
      arbeitslosenvers: arbeitslosen,
      krankenvers: kranken,
      pflegevers: pflege,
      insolvenzgeldumlage: insolvenz,
      umlage_erstattung: umlage,
      lohnInklNebenkosten: lohnkosten,
      krankheitstage: kranktage,
      bezFeiertage: feiertage,
      jahresstundenlohn: jahreslohn_stunde,
      bgHauptumlage: bgHaupt,
      bgLastenverteilung: bgLast,
      soka: sozka,
      kosten_jahr: kostenJahr,
      kosten_h: kostenStunde,
      gk: gemeink,
      sk_h: skStunde,
      gewinn: gew,
      angebotspreis: angebot
    });
    const help = {
      jahr: this.state.dataAll[0].jahr,
      lastName: lastName,
      firstName: firstName,
      arbeitstage: this.state.dataAll[0].arbeitstage,
      stunden_tag: this.state.dataAll[0].stunden_tag,
      stunden_jahr: this.state.dataAll[0].stunden_jahr,
      stundenlohn: this.state.stundenlohn,
      lohnBrutto: bruttolohn,
      rentenvers: rente,
      arbeitslosenvers: arbeitslosen,
      krankenvers: kranken,
      pflegevers: pflege,
      insolvenzgeldumlage: insolvenz,
      umlage_erstattung: umlage,
      lohnInklNebenkosten: lohnkosten,
      krankheitstage: kranktage,
      bezFeiertage: feiertage,
      jahresstundenlohn: jahreslohn_stunde,
      bgHauptumlage: bgHaupt,
      bgLastenverteilung: bgLast,
      soka: sozka,
      kosten_jahr: kostenJahr,
      kosten_h: kostenStunde,
      gk: gemeink,
      sk_h: skStunde,
      gewinn: gew,
      angebotspreis: angebot
    };
    if (boolean) {
      this.handleSubmitAll(help);
    }
  };
  calcEverythingSpecial = e => {
    e.preventDefault();
    let divideName = this.state.name.split(" ");

    this.calcEverything(divideName[0], divideName[1], false);
  };
  removeAll = (lastName, firstName) => {
    let deleteItem = {
      lastName: lastName,
      firstName: firstName,
      jahr: this.state.dataAll[0].jahr
    };

    $.ajax({
      url: BaseUrl + "api/calcHourlyWage/deleteCalcDataEmployees.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function(response) {
        this.calcEverything(lastName, firstName, true);
        this.savedcalchourlywageAll(lastName, firstName);
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  removeSingle = (lastName, firstName) => {
    let deleteItem = {
      lastName: lastName,
      firstName: firstName,
      jahr: this.state.dataAll[0].jahr
    };

    $.ajax({
      url: BaseUrl + "api/calcHourlyWage/deleteCalcDataEmployees.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function(response) {}.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  handleClickAll = e => {
    e.preventDefault();
    this.state.namesWageAll.map(names => {
      return this.removeAll(names.lastName, names.firstName);
    });
  };
  savedcalchourlywageAll = (lastName, firstName) => {
    const saved = {
      lastName: lastName,
      firstName: firstName,
      date: new Date().toJSON().slice(0, 10),
      sk_h: this.state.sk_h,
      angebotspreis: this.state.angebotspreis
    };
    $.ajax({
      url: BaseUrl + "api/calcHourlyWage/createSavedCalcHourlyWage.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(saved),
      success: function(response) {}.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  savedcalchourlywageSingle = (lastName, firstName) => {
    const saved = {
      lastName: lastName,
      firstName: firstName,
      date: new Date().toJSON().slice(0, 10),
      sk_h: this.state.sk_h,
      angebotspreis: this.state.angebotspreis
    };
    $.ajax({
      url: BaseUrl + "api/calcHourlyWage/createSavedCalcHourlyWage.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(saved),
      success: function(response) {}.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  onKeyPress = e => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };
  handleSearch = e => {
    this.setState({
      search: e.target.value
    });

    if (e.target.value === "") {
      this.setState({
        calcDataEmployees: this.state.calcDataEmployeesCopy,
        errorSearch: false
      });
    }
  };
  search = eingabe => {
    let convertEingabe = eingabe.replace(/ /g, "%").replace("+", "%2B");
    if (eingabe === "") {
      this.setState({
        calcDataEmployees: this.state.calcDataEmployeesCopy,
        errorSearch: false
      });
    } else {
      this.setState({
        calcDataEmployees: [],
        errorSearch: false
      });
      return fetch(
        BaseUrl + "api/calcHourlyWage/searchDataEmployees.php?eingabe=" +
          convertEingabe +
          "&jahr=" +
          this.state.jahrShow
      )
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.message === "No items found.") {
            this.setState({
              calcDataEmployees: [],
              errorSearch: true
            });
          } else {
            this.setState({
              calcDataEmployees: responseJson.records,
              errorSearch: false
            });
          }
        })
        .catch(error => {});
    }
  };
  render() {
    return (
      <React.Fragment>
        <section id="insertCalcHourlyWage">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home/stundenkalkulation">
                <FontAwesomeIcon icon="arrow-left" /> Zurück
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--redBtn)" icon="list-ol" />{" "}
                Stundenkalkulation Mitarbeiter
              </h1>
            </div>
          </div>
          <h4>Stundenkalkulation Hinzufügen</h4>
          <form>
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="row  calcDataEmployee">
                <div className="col-12">
                  <label>
                    <span>Jahr:</span>
                    <select
                      name="jahr"
                      value={this.state.value}
                      onChange={this.handleChange}
                    >
                      <option key="-1" value="-">
                        -
                      </option>
                      {this.state.yearsAll.map((years, i) => (
                        <option key={i} value={years.jahr}>
                          {years.jahr}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    <span>Name:</span>
                    <select
                      disabled={this.state.isDisabled}
                      name="name"
                      value={this.state.fullName}
                      onChange={this.handleChange}
                    >
                      <option key="-1" value="-">
                        -
                      </option>
                      {this.state.namesWageAll.map((names, i) => (
                        <option
                          key={i}
                          value={names.lastName + " " + names.firstName}
                        >
                          {names.lastName} {names.firstName}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    <span>Arbeitstage:</span>
                    <input
                      disabled
                      type="text"
                      name="arbeitstage"
                      placeholder="Arbeitstage"
                      onChange={this.handleChange}
                      value={this.state.arbeitstage}
                    />
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    <span>Stunden pro Tag:</span>
                    <input
                      disabled
                      type="text"
                      name="stunden_tag"
                      placeholder="Stunden pro Tag"
                      onChange={this.handleChange}
                      value={this.state.stunden_tag}
                    />
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    <span>Stunden pro Jahr:</span>
                    <input
                      disabled
                      type="text"
                      name="stunden_jahr"
                      placeholder="Stunden pro Jahr"
                      onChange={this.handleChange}
                      value={this.state.stunden_jahr}
                    />
                  </label>
                </div>

                <div className="col-12">
                  <label>
                    <span>Stundenlohn:</span>
                    <input
                      disabled
                      type="text"
                      name="stundenlohn"
                      placeholder="Stundenlohn"
                      onChange={this.handleChange}
                      value={this.state.stundenlohn}
                    />
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    <span>Bruttolohn:</span>
                    <input
                      disabled={this.state.disableFields}
                      type="text"
                      name="lohnBrutto"
                      placeholder="Bruttolohn"
                      onChange={this.handleChangeSpecial}
                      value={this.state.lohnBrutto}
                      className={this.state.disableFields ? "" : "cursor"}
                    />
                  </label>
                </div>

                <div className="col-12">
                  <label>
                    <span>Rentenversicherung:</span>
                    <input
                      disabled
                      type="text"
                      name="rentenvers"
                      placeholder="Rentenversicherung"
                      onChange={this.handleChange}
                      value={this.state.rentenvers}
                    />
                  </label>
                </div>

                <div className="col-12">
                  <label>
                    <span>Arbeitslosenversicherung:</span>
                    <input
                      disabled
                      type="text"
                      name="arbeitslosenvers"
                      placeholder="Arbeitslosenversicherung"
                      onChange={this.handleChange}
                      value={this.state.arbeitslosenvers}
                    />
                  </label>
                </div>

                <div className="col-12">
                  <label>
                    <span>Krankenversicherung:</span>
                    <input
                      disabled={this.state.disableFields}
                      type="text"
                      name="krankenvers"
                      placeholder="Krankenversicherung"
                      onChange={this.handleChangeSpecial}
                      value={this.state.krankenvers}
                      className={this.state.disableFields ? "" : "cursor"}
                    />
                    <button
                      className={
                        this.state.disableFields ? "hide" : "btn-calcNew"
                      }
                      onClick={this.calcEverythingSpecial}
                    >
                      <FontAwesomeIcon icon="calculator" />
                    </button>
                  </label>
                </div>

                <div className="col-12">
                  <label>
                    <span>Pflegeversicherung:</span>
                    <input
                      disabled
                      type="text"
                      name="pflegevers"
                      placeholder="Pflegeversicherung"
                      onChange={this.handleChange}
                      value={this.state.pflegevers}
                    />
                  </label>
                </div>

                <div className="col-12">
                  <label>
                    <span>Insolvenzgeldumlage:</span>
                    <input
                      disabled
                      type="text"
                      name="insolvenzgeldumlage"
                      placeholder="Insolvenzgeldumlage"
                      onChange={this.handleChange}
                      value={this.state.insolvenzgeldumlage}
                    />
                  </label>
                </div>

                <div className="col-12">
                  <label>
                    <span>Umlage- und Erstattungssätze:</span>
                    <input
                      disabled
                      type="text"
                      name="umlage_erstattung"
                      placeholder="Umlage- und Erstattungssätze:"
                      onChange={this.handleChange}
                      value={this.state.umlage_erstattung}
                    />
                  </label>
                </div>
                <hr />
                <div className="col-12">
                  <label>
                    <span>Lohn inkl. Lohnnebenkosten:</span>
                    <input
                      disabled
                      type="text"
                      name="lohnInklNebenkosten"
                      placeholder="Lohn inkl. Lohnnebenkosten"
                      onChange={this.handleChange}
                      value={this.state.lohnInklNebenkosten}
                    />
                  </label>
                </div>

                <div className="col-12">
                  <label>
                    <span>Krankheitstage:</span>
                    <input
                      disabled
                      type="text"
                      name="krankheitstage"
                      placeholder="Krankheitstage"
                      onChange={this.handleChange}
                      value={this.state.krankheitstage}
                    />
                  </label>
                </div>

                <div className="col-12">
                  <label>
                    <span>Bezahlte Feiertage:</span>
                    <input
                      disabled
                      type="text"
                      name="bezFeiertage"
                      placeholder="Bezahlte Feiertage"
                      onChange={this.handleChange}
                      value={this.state.bezFeiertage}
                    />
                  </label>
                </div>
                <hr />
                <div className="col-12">
                  <label>
                    <span>Jahresstundenlohn:</span>
                    <input
                      disabled
                      type="text"
                      name="jahresstundenlohn"
                      placeholder="Jahresstundenlohn"
                      onChange={this.handleChange}
                      value={this.state.jahresstundenlohn}
                    />
                  </label>
                </div>

                <div className="col-12">
                  <label>
                    <span>BG-Hauptumlage:</span>
                    <input
                      disabled
                      type="text"
                      name="bgHauptumlage"
                      placeholder="BG-Hauptumlage"
                      onChange={this.handleChange}
                      value={this.state.bgHauptumlage}
                    />
                  </label>
                </div>

                <div className="col-12">
                  <label>
                    <span>BG-Lastenverteilung:</span>
                    <input
                      disabled
                      type="text"
                      name="bgLastenverteilung"
                      placeholder="BG-Lastenverteilung"
                      onChange={this.handleChange}
                      value={this.state.bgLastenverteilung}
                    />
                  </label>
                </div>

                <div className="col-12">
                  <label>
                    <span>Sozialkasse:</span>
                    <input
                      disabled
                      type="text"
                      name="soka"
                      placeholder="Sozialkasse"
                      onChange={this.handleChange}
                      value={this.state.soka}
                    />
                  </label>
                </div>
                <hr />
                <div className="col-12">
                  <label>
                    <span>Kosten pro Jahr:</span>
                    <input
                      disabled
                      type="text"
                      name="kosten_jahr"
                      placeholder="Kosten pro Jahr"
                      onChange={this.handleChange}
                      value={this.state.kosten_jahr}
                    />
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    <span>Kosten pro Stunde:</span>
                    <input
                      disabled
                      type="text"
                      name="kosten_h"
                      placeholder="Kosten pro Stunde"
                      onChange={this.handleChange}
                      value={this.state.kosten_h}
                    />
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    <span>Gemeinkosten:</span>
                    <input
                      disabled
                      type="text"
                      name="gk"
                      placeholder="Gemeinkosten"
                      onChange={this.handleChange}
                      value={this.state.gk}
                    />
                  </label>
                </div>
                <hr />
                <div className="col-12">
                  <label>
                    <span>Selbskosten pro Stunde:</span>
                    <input
                      disabled
                      type="text"
                      name="sk_h"
                      placeholder="Selbskosten pro Stunde"
                      onChange={this.handleChange}
                      value={this.state.sk_h}
                    />
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    <span>Gewinn:</span>
                    <input
                      disabled
                      type="text"
                      name="gewinn"
                      placeholder="Gewinn"
                      onChange={this.handleChange}
                      value={this.state.gewinn}
                    />
                  </label>
                </div>
                <hr />
                <div className="col-12">
                  <label>
                    <span>
                      <b>Angebotspreis:</b>
                    </span>
                    <input
                      disabled
                      type="text"
                      name="angebotspreis"
                      placeholder="Angebotspreis"
                      onChange={this.handleChange}
                      value={this.state.angebotspreis}
                    />
                  </label>
                </div>
                <div className="col-6">
                  <button
                    disabled={this.state.isDisabledSingle}
                    onClick={this.handleSubmitSingle}
                    className="btn-create-item"
                  >
                    <p>
                      Stundenkalkulation speichern{" "}
                      <FontAwesomeIcon icon="save" />
                    </p>
                  </button>
                </div>
                <div className="col-6">
                  <button
                    disabled={this.state.isDisabledAll}
                    onClick={this.toggle}
                    className="btn-create-item"
                  >
                    Stapelverarbeitung Stundenkalkulation{" "}
                    <FontAwesomeIcon icon="cubes" />
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
          <p className={this.state.errorInput ? "show errorInput" : "hide"}>
            Bitte Bezeichnung ausfüllen
          </p>
        </section>
        <section id="showItems" className="display-item">
          <h4>
            Übersicht Stundenkalkulation
            <label id="smallerPaddingShow">
              <select
                name="jahrShow"
                value={this.state.jahrShow}
                onChange={this.handleChangeShow}
              >
                {this.state.yearsAll.map((years, i) => (
                  <option key={i} value={years.jahr}>
                    {years.jahr}
                  </option>
                ))}
              </select>
            </label>
          </h4>

          <div className="show-items-btn row">
            <div className="col-12 offset-6">
              <input
                className="search"
                type="text"
                name="search"
                placeholder="Suchen"
                onChange={this.handleSearch}
                onKeyPress={this.onKeyPress}
                value={this.state.search}
              />
            </div>
          </div>
          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          <ul className="items-list  calcDataEmployeeShow row">
            {this.state.calcDataEmployees.map(item => {
              return (
                <CalcEmployeesModal
                  onChange={this.handleChange}
                  key={item.id}
                  id={item.id}
                  lastName={item.lastName}
                  firstName={item.firstName}
                  jahr={item.jahr}
                  arbeitstage={item.arbeitstage}
                  stunden_tag={item.stunden_tag}
                  stunden_jahr={item.stunden_jahr}
                  stundenlohn={item.stundenlohn}
                  lohnBrutto={item.lohnBrutto}
                  rentenvers={item.rentenvers}
                  arbeitslosenvers={item.arbeitslosenvers}
                  krankenvers={item.krankenvers}
                  pflegevers={item.pflegevers}
                  insolvenzgeldumlage={item.insolvenzgeldumlage}
                  umlage_erstattung={item.umlage_erstattung}
                  lohnInklNebenkosten={item.lohnInklNebenkosten}
                  krankheitstage={item.krankheitstage}
                  bezFeiertage={item.bezFeiertage}
                  jahresstundenlohn={item.jahresstundenlohn}
                  bgHauptumlage={item.bgHauptumlage}
                  bgLastenverteilung={item.bgLastenverteilung}
                  soka={item.soka}
                  kosten_jahr={item.kosten_jahr}
                  kosten_h={item.kosten_h}
                  gk={item.gk}
                  sk_h={item.sk_h}
                  gewinn={item.gewinn}
                  angebotspreis={item.angebotspreis}
                  active={item.active}
                  search={this.state.search}
                />
              );
            })}
          </ul>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <div className="modal-header">
              <button className="close" onClick={this.toggle}>
                {" "}
                <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
              </button>
            </div>
            <ModalBody className="modal-font-calcEmployee">
              <p>
                Soll die Stundenkalkulation für alle Mitarbeiter durchgeführt
                werden?
              </p>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggle} className="btn-red">
                    Abbrechen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.handleClickAll} className="btn-green">
                    Ja <FontAwesomeIcon icon={["far", "edit"]} />
                  </button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </section>
      </React.Fragment>
    );
  }
}

export default CalcEmployees;
