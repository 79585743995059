import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../config/config.js";
var $ = require("jquery");
var Highlight = require('react-highlighter');

class ModalCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      id: this.props.id,
      customerId: this.props.customerId,
      customerIdOld: this.props.customerIdOld,
      customerName: this.props.customerName,
      street: this.props.street,
      zipCode: this.props.zipCode,
      city: this.props.city,
       showUpdate: true,
      showDelete: false,
      hideNotes: false,
      hideArticle: false,
       cpAll: this.props.cpAll
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    if(sessionStorage.getItem("userData").indexOf("admin") === -1){
       this.setState({
       modal:false
     })

   }
   else{
     this.setState({
       modal: !this.state.modal
     });
   }
  };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleChangeCheck = e => {
    this.setState({
      done: !this.state.done
    });
  };
  updateCustomer = e => {
    let updateCustomer;
    this.state.cpAll.map((items, idx) => {
       if(items.id === undefined){
          updateCustomer = {
          id: "0",
          customerId: this.state.customerId,
          customerIdOld: this.state.customerIdOld,
          customerName: this.state.customerName,
          street: this.state.street,
          zipCode: this.state.zipCode,
          city: this.state.city,
          cpName: items.cpName,
          cpTel: items.cpTel,
          cpMail: items.cpMail
        };
      }
      else{
       updateCustomer = {
        id: items.id,
        customerId: this.state.customerId,
        customerIdOld: this.state.customerIdOld,
         customerName: this.state.customerName,
        street: this.state.street,
        zipCode: this.state.zipCode,
        city:this.state.city,
        cpName: items.cpName,
        cpTel: items.cpTel,
        cpMail: items.cpMail
      };
    }
       $.ajax({
        url: BaseUrl + "api/customer/updateCustomer.php",
        type: "POST",
        dataType: "text",
        data: JSON.stringify(updateCustomer),
        success: function(response) {
          this.setState({
            custemorOld: this.state.custemorId,
            modal: !this.state.modal
          });
        }.bind(this),
        error: function(xhr, resp, text) {}
      });
      return this;
    });

    e.preventDefault();
  };

  removeCpPerNr = idx => e => {
    e.preventDefault();
    let deleteCustomer = {
      id: this.state.id
    };

    $.ajax({
      url: BaseUrl + "api/customer/deleteCustomerPerNr.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteCustomer),
      success: function(response) {
        this.setState({
          cpAll: this.state.cpAll.filter((s, sidx) => idx !== sidx)
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  handleRemoveDeliveryItem = idx => () => {
     this.setState({
      cpAll: this.state.cpAll.filter((s, sidx) => idx !== sidx)
    });
  };
  removeCustomer = () => {
    let deleteCustomer = {
      id: this.state.customerId
    };
     $.ajax({
      url: BaseUrl + "api/customer/deleteCustomer.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteCustomer),
      success: function(response) {
        this.setState({
          modal: !this.state.modal,
          hideNotes: true
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  handleAddDeliveryItem = () => {
    this.setState({
      cpAll: this.state.cpAll.concat([
        {cpName: "", cpTel: "", cpMail: ""}
      ])
    });
  };
  handleChangeCp = idx => evt =>{
     const newCpChange = this.state.cpAll.map((item, sidx) => {
      if (idx !== sidx) return item;
      return { ...item, [evt.target.name]: evt.target.value };
    });
    this.setState({ cpAll: newCpChange });
}

  render() {
    return (
      <React.Fragment>
        <li onClick={this.toggle} className={this.state.hideNotes ? "hide " : ""}>
          <div  >
            <span className="row list-header show0">
              <p className="col-2"><span>Kunden-Nr</span><Highlight id="font-normal"  search={this.props.search}>{this.state.customerId}</Highlight></p>
              <p className="col"><span>Kundenname</span><Highlight id="font-normal"  search={this.props.search}>{this.state.customerName}</Highlight></p>
              <p className="col"><span>Strasse</span>{this.state.street}</p>
              <p className="col-3"><span>Plz</span>{this.state.zipCode}</p>
              <p className="col-1"><span>Ort</span>{this.state.city}</p>
            </span>
          </div>

          {this.state.cpAll.map((i, idx) => {
            return (
              <div
                className={this.state.hideArticle ? "hide " : ""}
                key={idx}

              >

                <span className={`row fullwidth hidetext show${idx}`}>
                  <p className="col-md-3"><span>Name</span><Highlight id="font-normal"  search={this.props.search}>{i.cpName}</Highlight></p>
                  <p className="col-md-2"><span>Telefonnummer</span>{i.cpTel}</p>
                  <p className="col-md-4"><span>Email</span>{i.cpMail}</p>
                 </span>
              </div>
            );
          })}
        </li>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal-header">
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <p>
                Kunde bearbeiten?<br />
              </p>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <p>
                Kunde wirklich Löschen?<br />
              </p>

              <span>Dieser Vorgang kann nicht Rückgänging gemacht werden</span>
            </div>
            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <form className="row">
                 <div className="col-md-2">
                <p>Kunden-Nr</p>

                  <input
                    type="text"
                    name="customerId"
                    placeholder="Kundennummer"
                    onChange={this.handleChange}
                    value={this.state.customerId}
                  />
                </div>
                <div className="col-md-3">
                <p>Kundenname</p>

                  <input
                    type="text"
                    name="customerName"
                    placeholder="Kundenname"
                    onChange={this.handleChange}
                    value={this.state.customerName}
                  />
                </div>
                <div className="col-md-3">
                <p>Straße</p>

                  <input
                    type="text"
                    name="street"
                    placeholder="Straße"
                    onChange={this.handleChange}
                    value={this.state.street}
                  />
                </div>
                <div className="col-md-2">
                <p>Plz</p>

                  <input
                    type="text"
                    name="zipCode"
                    placeholder="Postleitzahl"
                    onChange={this.handleChange}
                    value={this.state.zipCode}
                  />
                </div>
                <div className="col-md-2">
                <p>Ort</p>

                  <input
                    type="text"
                    name="city"
                    placeholder="Ort"
                    onChange={this.handleChange}
                    value={this.state.city}
                  />
                </div>
              </form>

              <form className="row">

                {this.state.cpAll.map((i, idx) => {
                     return (
                      <div
                        key={idx}
                        className={this.state.hideArticle ? "hide row fullwidth" : "row fullwidth"}
                      >
                      <div className="col-md-1">
                        <button
                          onClick={this.removeCpPerNr(idx)}
                          className="x-red"
                        >
                          x
                        </button>
                      </div>
                        <div className="col-md-2">
                          <p>{`Name #${idx + 1}`}</p>
                          <input
                            type="text"
                            name="cpName"
                             onChange={this.handleChangeCp(idx)}
                            value={i.cpName}
                          />
                        </div>
                        <div className="col-md-5">
                          <p>{`Telefonnummer #${idx + 1}`}</p>
                          <input
                            type="text"
                            name="cpTel"
                            onChange={this.handleChangeCp(idx)}
                            value={i.cpTel}
                          />
                        </div>
                        <div className="col-md-2">
                          <p>{`Email #${idx + 1}`}</p>
                          <input
                            type="text"
                            name="cpMail"
                            onChange={this.handleChangeCp(idx)}
                            value={i.cpMail}
                          />
                        </div>
                      </div>
                    );
                 })}
              </form>
              <div className="row">
                <div className="col-11" />
                <div className="col-1" >
                  <button
                    type="button"
                    onClick={this.handleAddDeliveryItem}
                    className="btn-add-blue"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Löschen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.updateCustomer} className="btn-blue">
                    Ändern <FontAwesomeIcon icon={["far", "edit"]} />
                  </button>
                </div>
              </div>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Nein <FontAwesomeIcon icon="ban" />
                  </button>
                </div>

                <div className="col">
                  <button onClick={this.removeCustomer} className="btn-blue">
                    Ja <FontAwesomeIcon icon="check-circle" />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalCustomer;
