import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
import moment from "moment";
class ConstructionDiaryPrint extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
 
    };
  }
  componentDidMount = () => {};

  checkHoursEmpty = hour => {
      if(hour === "" || hour === undefined){
          return "00:00"
      }
      else return hour
  }

  render() {
    return (
      <React.Fragment>
        <div className="diaryPrint" id="offerPrint">
          <div className="row ">
            <div className="col-md-12">
              <h1>Regiebericht / Bautagebuch</h1>
            </div>
          </div>
 
          <div className="row">
          <div className="col-3 print">
              <p><b>Firma:</b></p>
            </div>
            <div className="col-4 print">
              <p>Seyband</p>
            </div>
            <div className="col-2 print">
              <p><b>Datum:</b></p>
            </div>
            <div className="col-3 print">
              <p>{moment(this.props.dateSaved).format("DD.MM.YYYY")}</p>
            </div>
            <div className="col-3">
              <p><b>Auftraggeber:</b></p>
            </div>
            <div className="col-9">
              <p>{this.props.customer}</p>
            </div>
            <div className="col-3">
              <p><b>Bauvorhaben:</b></p>
            </div>
            <div className="col-9">
              <p>{this.props.bv}</p>
            </div>
            <div className="col-3">
              <p><b>Gewerk:</b></p>
            </div>
            <div className="col-9">
              <p>{this.props.gewerk}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h2>Erbrachte Leistung:</h2>
            </div>
            <div className="col-12 border-print-cd textarea">
              <p>{this.props.erbrachteLeistung}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h2>Material:</h2>
            </div>
            <table className="col-12 border-print-cd">
              <thead>
                <tr>
                  <th><p>Lieferant</p></th>
                  <th><p>Lieferschein</p></th>
                  <th><p>Datum</p></th>
                </tr>
              </thead>
              <tbody>
                {this.props.deliveryNotes.map((i, idx) => (
                  <tr key={"notes"+idx}>
                    <td><p>{i.supplierName}</p></td>
                    <td><p>{i.notesNr}</p></td>
                    <td><p>{moment(i.date).format("DD.MM.YYYY")}</p></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="row">
            <div className="col-12">
              <h2>Arbeitszeit / Mitarbeiter und jeweilige Stundenzahl</h2>
            </div>
            <table className="col-12 border-hours-print-cd">
            <thead>
              <tr className="row">
                 <th></th>
                {[...Array(this.props.daysBetween)].map((i, idx) => {
                  return (
                    <th key={"day"+idx}className="col print">
                      {moment(this.props.dateFrom)
                        .add("days", idx)
                        .format("DD. MMM")}
                    </th>
                  );
                })}
              </tr>
            </thead>
              <tbody>
                {this.props.hoursFilled.map((hf, ix) => (
                  <tr key={"name"+ix}className="row">
                    <td>
                      <p>{Object.keys(hf)[0]}</p>
                    </td>
                    {this.props.arrDates.map((i, idx) => (
                      <td key={"hours"+idx}className="col print">
                        <p>{this.checkHoursEmpty(hf[Object.keys(hf)[0]][i])}</p>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-12">
              <h2>Besondere Vorkommnisse:</h2>
            </div>
            <div className="col-12 border-print-cd textarea">
              <p>{this.props.vorkommnisse}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-2 align-center">
               <p>Unterschrift:</p>
               </div>
               <div className="col-8 underline">
               <img className="print" src={require("../../img/unterschrift-bautagebuch.png")} alt="" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ConstructionDiaryPrint;
