import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../config/config.js";
import {globalUnits} from "../global/units.js";

var $ = require("jquery");
class ModalAddItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };
  }
  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        modal: false,
      });
    } else {
      this.setState({
        modal: !this.state.modal,
        supplierAll: this.props.supplierAll,
        supplier: this.props.supplier,
        supplierId: this.props.supplierId,
        artNr: this.props.itemId,
        dsc: this.props.itemDsc,
        unit: this.props.itemUnit,
        artGroup: "",
        internArtNr: "",
      });
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "supplier") {
      this.readSupplierIdByName(e.target.value, true);
    }
  };
  readSupplierNameById = (id, bool) => {
    let found = this.props.supplierAll.find((name) => name.supplierId === parseInt(id));
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        supplier: found.supplierName,
      });
    } else {
      return found.supplierName;
    }
  };
  readSupplierIdByName = (name, bool) => {
    let found = this.props.supplierAll.find((id) => id.supplierName === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        supplierId: found.supplierId,
      });
    } else {
      return found.supplierId;
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "supplier" && e.target.value === "-") {
      this.setState({
        supplierId: "",
      });
    }

    if (e.target.name === "supplier") {
      this.readSupplierIdByName(e.target.value, true);
    }
  };
  autocompleteBvSupplier = (e) => {
    if (e.keyCode === 9 && e.target.name === "supplierId") {
      this.readSupplierNameById(e.target.value, true);
    }
  };
  handleChangeArtGroup = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    this.searchArtMax(e.target.value);
  };
  createItem = (e) => {
    e.preventDefault();

    let item = {
      artNr: this.state.artNr.trim(),
      dsc: this.state.dsc,
      unit: this.state.unit,
      supplierId: this.state.supplierId,
      internArtNr: this.state.internArtNr,
      artGroup: this.state.artGroup,
    };
    $.ajax({
      url: BaseUrl + "api/item/createItem.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(item),
      success: function (response) {
        this.props.hideAddArticle(
          this.props.idx,
          this.state.dsc,
          this.state.unit
        );

        this.setState({
          artNr: "",
          dsc: "",
          unit: "",
          supplierId: "",
          supplier: "",
          internArtNr: "",
          artGroup: "",
          modal: !this.state.modal,
        });
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  searchArtMax = (eingabe) => {
    if (eingabe === "") {
      this.setState({
        errorSearch: false,
      });
    } else {
      return fetch(BaseUrl + "api/item/searchMaxArtNr.php?eingabe=" + eingabe)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message === "No items found.") {
            this.setState({
              internArtNr: "-",
            });
          } else {
            let countUp = parseInt(responseJson.records[0].max) + 1;
            this.setState({
              internArtNr: String(countUp).padStart(7, "0"),
            });
          }
        })
        .catch((error) => {});
    }
  };
  render() {
    return (
      <React.Fragment>
        <button type="button" className="btn-remove" onClick={this.toggle}>
        <FontAwesomeIcon icon={["far", "edit"]} />
        </button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal-header">
            <p>neuen Artikel Anlegen</p>

            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div>
              <form>
                <div className="row">
                  <div className="col-md-2">
                    <p>Artikel-Nr:</p>
                    <input
                      type="text"
                      name="artNr"
                      placeholder="Artikel-Nr"
                      onChange={this.handleChange}
                      value={this.state.artNr}
                    />
                  </div>
                  <div className="col-md-8">
                    <p>Artikelbezeichnung:</p>
                    <input
                      type="text"
                      name="dsc"
                      placeholder="Artikelbezeichnung"
                      onChange={this.handleChange}
                      value={this.state.dsc}
                    />
                  </div>
                  <div className="col-md-2">
                    <p>Einheit</p>
                    <select
                      name="unit"
                      onChange={this.handleChange}
                      value={this.state.unit}
                    >
                        {globalUnits.map((unit, idx) => {
                        return(
                        <option key={idx} value={unit}>
                          {unit}
                        </option>
                        )
                        })}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 tab">
                    <p>Lieferanten-Nr</p>
                    <input
                      type="text"
                      name="supplierId"
                      placeholder="0"
                      onChange={this.handleChange}
                      value={this.state.supplierId}
                      onKeyDown={this.autocompleteBvSupplier}
                    />
                    <span>↹</span>
                  </div>
                  <div className="col-4">
                    <p>Lieferant</p>
                    <select
                      name="supplier"
                      value={this.state.supplier}
                      onChange={this.handleChange}
                    >
                      <option value="-">-</option>
                      {this.props.supplierAll.map((supplier, i) => (
                        <option key={i} value={supplier.supplierName}>
                          {supplier.supplierName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-2">
                    <p>Warengruppe</p>
                    <select
                      name="artGroup"
                      onChange={this.handleChangeArtGroup}
                      value={this.state.artGroup}
                    >
                      <option key="-1" value="-">
                        -
                      </option>
                      <option key={"w1"} value="01">
                        01 - Profile
                      </option>
                      <option key={"w2"} value="02">
                        02 - Gipsplatten
                      </option>
                      <option key={"w3"} value="03">
                        03 - Spachtelmasse
                      </option>
                      <option key={"w4"} value="04">
                        04 - Montagezubehör
                      </option>
                      <option key={"w5"} value="05">
                        05 - Werkzeuge
                      </option>
                      <option key={"w6"} value="06">
                        06 - Büromaterial
                      </option>
                      <option key={"w7"} value="07">
                        07 - Logistikkosten
                      </option>
                      <option key={"w8"} value="08">
                        08 - Entsorgung
                      </option>
                      <option key={"w9"} value="09">
                        09 - Arbeitskleidung
                      </option>
                      <option key={"w10"} value="10">
                        10 - KFZ-Zubehör
                      </option>
                      <option key={"w11"} value="11">
                        11 - Dämmung
                      </option>
                      <option key={"w12"} value="12">
                        12 - Türen
                      </option>
                      <option key={"w13"} value="13">
                        13 - Zargen
                      </option>
                      <option key={"w14"} value="14">
                        14 - Drückergarnituren
                      </option>
                      <option key={"w15"} value="15">
                        15 - Bodenbeläge
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2">
                    <p>Art-Nr intern</p>
                    <input
                      type="text"
                      name="internArtNr"
                      placeholder="Artikelnummer intern"
                      onChange={this.handleChange}
                      value={this.state.internArtNr}
                    />
                  </div>
                </div>
              </form>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggle} className="btn-red">
                    Abbrechen <FontAwesomeIcon icon="times-circle" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.createItem} className="btn-green">
                    Anlegen <FontAwesomeIcon icon={["far", "save"]} />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalAddItem;
