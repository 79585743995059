import React, { Component } from "react";

import "../css/bootstrap-grid.css";
import "../css/App.css";
import "../css/index.css";
import "../css/mediaQuerys.css";
import DirectionWorkList from "./DirectionWorkList";
import DirectionWorkReport from "./Report/DirectionWorkReport";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "@fortawesome/fontawesome-free-solid";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


const directionWorkMain = () => {
 return (
   <React.Fragment>
     <section id="directionWorkMain" className="section-start">
       <div className=" wrapper-btn-text row">
         <div className="col-md-2">
           <Link to="/home">
             <FontAwesomeIcon icon="arrow-left" /> Home
           </Link>
         </div>
         <div className="col-md-8">
           <h1>
             <FontAwesomeIcon color="var(--yellow)" icon="pencil-alt" />{" "}
             Regiearbeiten
           </h1>
         </div>
       </div>
       <br />
       <div className="row">
         <div className="col">
           <Link className="btn-start-yellow" to="/home/regiearbeit/regiearbeit-liste">
             <FontAwesomeIcon icon="eye" />
             <br />
             Regiearbeiten anzeigen
           </Link>
         </div>
         <div className="col">
           <Link className="btn-start-yellow"to="/home/regiearbeit/regiebericht">
             <FontAwesomeIcon icon="pencil-alt" />
             <br />
             Regiebericht
           </Link>
         </div>
       </div>
     </section>
   </React.Fragment>
 );
};

const directionWorkList = () => {
 return <DirectionWorkList />;
};
const directionWorkReport = () => {
 return <DirectionWorkReport />;
};
class DirectionWorkMain extends Component {

 render() {
   return (
        <React.Fragment>
          <Route exact path="/home/regiearbeit" component={directionWorkMain} />
          <Route

            path="/home/regiearbeit/regiearbeit-liste"
            component={directionWorkList}
          />
          <Route

            path="/home/regiearbeit/regiebericht"
            component={directionWorkReport}
         />
       </React.Fragment>
    );
 }
}
export default DirectionWorkMain;
