import React, { useState, useEffect } from "react";
import "@fortawesome/fontawesome-free-solid";
import Linkify from "linkifyjs/react";
import { ReactHeight } from "react-height";
import BaseUrl from "../../config/config.js";
import CustomFontRegular from "../../fonts/Lunchtype22-Regular.ttf";
import CustomFontBold from "../../fonts/Lunchtype22-Medium.ttf";
import PrintConfirmationPDF from "./PrintConfirmationPDF.js";
import PrintWiderrufPDF from "./PrintWiderrufPDF.js";
import PrintWiderrufformularPDF from "./PrintWiderrufformularPDF.js";
var currencyFormatter = require("currency-formatter");
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Link,
  Line,
  Svg,
  Font,
} from "@react-pdf/renderer";
 

Font.register({
  family: "CustomFont", // Font family name
  fonts: [
    { src: CustomFontRegular, fontWeight: "normal" },
    { src: CustomFontBold, fontWeight: "bold" },
  ],
});
function OfferPrint(props) {
  const [state, setState] = useState({
    id: "",
    name: "",
    strasse: "",
    plz: "",
    land: "",
    tel: "",
    telefax: "",
    mobil: "",
    mail: "",
    web: "",
    sitzGesellschaft: "",
    stNr: "",
    ustNr: "",
    geschaeftsfuehrer: "",
    bank: "",
    blz: "",
    kontoNr: "",
    iban: "",
    bic: "",
    textAnschreiben: "",
    textNetto: "",
    schlusstext: "",
    paymentText: "",
    customer: props.customer,
    streetCustomer: props.streetCustomer,
    zipCodeCustomer: props.zipCodeCustomer,
    cityCustomer: props.cityCustomer,
    version: props.version,
    versionOld: props.versionOld,
    sumNetto: 0.0,
    subSum: 0.0,
    productsSort: props.productsSort,
  });
  let savedPageNr = 2;
  let savedPageNrBottom = 2;
  let showMenu = false;
  let savedIdx = [];
  let subSumArr = [];
  let currentPageItems = 0;
  let subSum = 0;
  useEffect(() => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      setState((prevState) => ({
        ...prevState,
        disabledFieldset: true,
      }));
    }
    loadBaseData();
  }, []);

  const loadBaseData = () => {
    return fetch(BaseUrl + "api/baseData/readBaseData.php")
      .then((response) => response.json())
      .then((responseJson) => {
        const data = responseJson.records[0][0];

        setState((prevState) => ({
          ...prevState,
          id: data.id,
          name: data.name,
          strasse: data.strasse,
          plz: data.plz,
          land: data.land,
          tel: data.tel,
          telefax: data.telefax,
          mobil: data.mobil,
          mail: data.mail,
          web: data.web,
          sitzGesellschaft: data.sitzGesellschaft,
          stNr: data.stNr,
          ustNr: data.ustNr,
          geschaeftsfuehrer: data.geschaeftsfuehrer,
          bank: data.bank,
          blz: data.blz,
          kontoNr: data.kontoNr,
          iban: data.iban,
          bic: data.bic,
          textAnschreiben: data.textAnschreiben,
          textNetto: data.textNetto,
          schlusstext: data.schlusstext,
        }));
      })
      .catch((error) => {});
  };

  const convertDate = (date) => {
    if (date === null || date === "0000-00-00" || date === undefined) {
      return date;
    }

    if (date.includes("-")) {
      var dateNew = date.split("-");
      dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  const geschaeftsfuehrerName = (geschaeftsfuehrer) => {
    let help = geschaeftsfuehrer.split("\n");
    return help[0];
  };
  const convertPrice = (price) => {
     return currencyFormatter.format(price, { locale: "de-DE" });
  };
  const calcSum = (idx, sum) => {
    if (idx === 0) {
      state.sumNetto = 0;

      return (state.sumNetto += parseFloat(sum === "" || isNaN(sum) ? 0 : sum));
    } else {
      return (state.sumNetto += parseFloat(sum === "" || isNaN(sum) ? 0 : sum));
    }
  };
  const calcSubSum = (idx, sum) => {
    if (idx === 0) {
      subSumArr = [];
      state.subSum = 0;
      subSumArr.push({
        idx: idx,
        subSum: (state.subSum += parseFloat(
          sum === "" || isNaN(sum) ? 0 : sum
        )),
      });
    } else {
      subSumArr.push({
        idx: idx,
        subSum: (state.subSum += parseFloat(
          sum === "" || isNaN(sum) ? 0 : sum
        )),
      });
    }
  };

  const calcDiscountComplete = () => {
    const help =
      parseFloat(state.sumNetto) * (parseFloat(props.discountComplete) / 100);
    return help;
  };

  const checkSpaceAnlage = () => {
    if (
      props.chkConfirmation ||
      props.chkWiederruf ||
      props.chkWiederrufformular
    ) {
      return true;
    } else {
      return false;
    }
  };

  const taxCalc = () => {
    if (props.date < "2020-07-01" || props.date > "2020-12-31") {
      return 0.19;
    } else {
      return 0.16;
    }
  };
  const taxText = () => {
    if (props.date < "2020-07-01" || props.date > "2020-12-31") {
      return "+19 % MwSt.";
    } else {
      return "+16 % MwSt.";
    }
  };

  const stylesFooter = StyleSheet.create({
    bottom: {
      position: "absolute",
      bottom: 5,
      left: 35,
      height: "3cm",
    },
    anschrift: {
      width: "5cm",
    },
    geschaeftsfuehrer: {
      width: "4cm",
    },
    steuer: {
      width: "3cm",
    },
    bank: {
      width: "6cm",
    },
  });
  const Footer = () => {
    return (
      <View style={[stylesList.view, styles.pt6, styles.footerHeight]}>
        <View style={stylesFooter.anschrift}>
          <Text style={stylesList.bold}>{state.name}</Text>

          <Text>{state.strasse}</Text>
          <Text>{state.land}</Text>
          <Text style={styles.marginT3}>Tel: {state.tel}</Text>
          <Text>Fax: {state.telefax}</Text>
        </View>

        <View style={stylesFooter.geschaeftsfuehrer}>
          <Text style={stylesList.bold}>Geschäftsführer</Text>
          {state.geschaeftsfuehrer.split("\n").map((item, key) => {
            return (
              <Text key={key}>
                {item}
                {"\n"}
              </Text>
            );
          })}
          <Text style={[stylesList.bold, styles.marginT3]}>
            Sitz der Gesellschaft
          </Text>
          <Text>{state.sitzGesellschaft}</Text>
        </View>
        <View style={stylesFooter.steuer}>
          <Text style={stylesList.bold}>Steuernummer:</Text>
          <Text>{state.stNr}</Text>
          <Text style={[stylesList.bold, styles.marginT3]}>USt-Id Nr.:</Text>
          <Text>{state.ustNr}</Text>
        </View>
        <View style={stylesFooter.bank}>
          <Text style={stylesList.bold}>Bankverbindung: </Text>
          <View style={stylesList.view}>
            <View style={styles.width30}>
              <Text>Bank:</Text>
            </View>
            <View style={styles.width70}>
              {" "}
              <Text>{state.bank}</Text>
            </View>

            <View style={styles.width30}>
              {" "}
              <Text>Bankleitzahl:</Text>
            </View>
            <View style={styles.width70}>
              {" "}
              <Text>{state.blz}</Text>
            </View>

            <View style={styles.width30}>
              {" "}
              <Text>Kontonummer:</Text>
            </View>
            <View style={styles.width70}>
              {" "}
              <Text>{state.kontoNr}</Text>
            </View>

            <View style={styles.width30}>
              {" "}
              <Text>IBAN:</Text>
            </View>
            <View style={styles.width70}>
              {" "}
              <Text>{state.iban}</Text>
            </View>

            <View style={styles.width30}>
              {" "}
              <Text>SWIFT-BIC:</Text>
            </View>
            <View style={styles.width70}>
              {" "}
              <Text>{state.bic}</Text>
            </View>
          </View>
        </View>
      </View>
    );
  };
  const styles = StyleSheet.create({
    page: {
      flexWrap: "wrap",
      fontSize: "7pt",
      flexDirection: "row",
      paddingTop: 35,
      paddingBottom: "4cm",
      paddingLeft: 35,
      paddingRight: 35,
      lineHeight: "1.5pt",
      fontFamily: "CustomFont",
      fontWeight: "normal",
    },
    footerHeight: {
      height: "4cm",
    },
    view: {
      width: "100%",
    },
    width50: {
      width: "50%",
    },
    width70: {
      width: "70%",
    },
    width30: {
      width: "30%",
    },
    marginT25: {
      marginTop: "25px",
      display: "block",
      width: "100%",
    },
    marginT80: {
      marginTop: "80px",
      display: "block",
      width: "100%",
    },
    marginT30: {
      marginTop: "50px",
    },
    marginT5: {
      marginTop: 5,
    },
    marginT10: {
      marginTop: 10,
    },
    marginT3: {
      marginTop: 3,
    },
    textRight: {
      textAlign: "right",
    },
    width33: {
      width: "33%",
    },
    alignRight: {
      alignItems: "flex-end",
      width: "100%",
    },
    image: {
      width: 100,
    },
    imageFirstPage: {
      width: 165,
    },
    pt6: {
      marginBottom: "7pt",
      fontSize: "6pt",
    },
    pt7: {
      marginBottom: "7pt",
      fontSize: "7pt",
    },
    pt8: {
      fontSize: "8pt",
      fontWeight: "900",
    },
    lineHeigt3pt: {
      lineHeight: "1.5pt",
    },
    anschrift: {
      fontWeight: "900",
      lineHeight: "2pt",
      fontSize: "9pt",
    },
    h3: {
      fontSize: "14pt",
      marginBottom: "8px",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "13pt",
      fontWeight: "bold",
    },
  });
  const FirstPage = () => {
    return (
      <View style={[stylesList.view]}>
        <View style={styles.alignRight} fixed>
          <Image
            style={styles.imageFirstPage}
            src={require("../../img/logo.png")}
          />
        </View>

        <View style={styles.width70}>
          <Text style={styles.pt7}>
            {state.name} · {state.strasse} ·{state.plz}
          </Text>
          <Text style={styles.anschrift}>{props.customer}</Text>
          <Text style={styles.anschrift}>{props.streetCustomer}</Text>
          <Text style={[styles.anschrift, styles.marginT10]}>
            {props.zipCodeCustomer} {props.cityCustomer}
          </Text>
        </View>
        <View style={[styles.width30, styles.pt8, styles.lineHeigt3pt]}>
          <Text>Telefon: {state.tel}</Text>
          <Text>Telefax: {state.telefax}</Text>
          <Text>Mobil: {state.mobil}</Text>
          <Text>Email: {state.mail}</Text>
          <Text>Internet: {state.web}</Text>
        </View>
        <View style={styles.marginT80}></View>
        <View style={styles.width33}>
          <Text>Ihr Zeichen</Text>
        </View>
        <View style={styles.width33}>
          <Text>Unser Zeichen</Text>
        </View>
        <View style={[styles.width33, styles.textRight]}>
          <Text>Datum {convertDate(props.date)}</Text>
        </View>
        <View style={styles.marginT30}></View>
        <View className="angebot-daten ">
          <Text style={styles.h3}>
            Angebot {props.offerNr} -{" "}
            {props.version !== "" ? props.version : props.versionOld}
          </Text>
          <Text style={styles.h4}>Bauvorhaben: {props.bv}</Text>
        </View>
        <View style={styles.marginT25}></View>
        <View className="anschreiben ">
          {state.textAnschreiben.split("\n").map((item, key) => {
            if (item.includes("http")) {
              let noLink = item.split("http://")[0];
              let link = item.split("http://")[1];
              return (
                <Text key={key}>
                  {noLink}{" "}
                  <Link src={`https://${link}`}>
                    {link}
                    {"\n"}
                  </Link>
                </Text>
              );
            } else {
              return (
                <Text key={key}>
                  {item}
                  {"\n"}
                </Text>
              );
            }
          })}
          <Image
            style={styles.image}
            src={require("../../img/unterschrift.png")}
            alt=""
          />
          <Text className="">
            {geschaeftsfuehrerName(state.geschaeftsfuehrer)}
          </Text>
          <Text>{state.name}</Text>
        </View>
      </View>
    );
  };
  const stylesList = StyleSheet.create({
    view: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      marginBottom: "0.5mm",
    },

    pos: {
      width: "1cm",
      paddingRight: "3mm",
    },
    bez: {
      width: "10.5cm",
      paddingRight: "3mm",
    },
    menge: {
      width: "1cm",
      paddingRight: "3mm",
    },
    einheit: {
      width: "1.5cm",
      paddingRight: "3mm",
    },
    preisEinzel: {
      width: "2cm",
      paddingRight: "3mm",
    },
    preisGesamt: {
      width: "2cm",
    },
    fullwidth: {
      width: "18cm",
    },
    none: {
      display: "none",
    },
    uebertrag: {
      paddingTop: "2px",
      paddingBottom: "2px",
      marginTop: "4px",
    },
    marginBottomMinus: {
      marginBottom: "-8px",
    },
    calcText: {
      width: "3cm",
      textAlign: "right",
    },
    calcNumber: { width: "3cm", paddingRight: "6mm", textAlign: "right" },
    flexEnd: {
      justifyContent: "flex-end", // Aligns content to the bottom of the view
      alignItems: "flex-end",
    },
    borderTop: {
      borderTop: "1px solid black",
      marginTop: 5,
      marginBottom: 5,
    },
    borderTopBrutto: {
      borderTop: "1px solid black",
    },
    borderCalc: {
      borderTop: "1px solid black",
      paddingTop: 10,
      paddingBottom: 10,
    },
    viewCalc: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      justifyContent: "flex-end", // Aligns content to the bottom of the view
      textAlign: "Right",
    },
    anlagen: {
      borderTop: "1px solid #ccc",
      paddingTop: 5,
      marginTop: 15,
    },
    bold: {
      fontWeight: "bold",
    },
  });

  const handlePageChange = (pageNumber, idx) => {
    if (pageNumber !== savedPageNr || idx === 0) {
      savedPageNr = pageNumber;
      showMenu = true;
      currentPageItems = 0;
      return true;
    } else {
      currentPageItems++;
      showMenu = false;
      return false;
    }
  };

  const handleEndPage = (pageNumber, idx) => {
    if (pageNumber > savedPageNrBottom) {
      savedPageNrBottom = pageNumber;
      savedIdx.push(idx - 1);
    }
  };

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <FirstPage />
        <View style={[stylesList.view, stylesFooter.bottom]} fixed>
          <Footer />
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.alignRight} fixed>
          <Image style={styles.image} src={require("../../img/logo.png")} />
        </View>
        {props.productsSort.map((products, idx) => {
          calcSubSum(idx, products.priceComplete);
          calcSum(idx, products.priceComplete);

          return (
            <View key={idx} style={stylesList.view} wrap={false}>
              <View style={[stylesList.view, styles.pt8]}>
                <Text
                  style={[stylesList.pos]}
                  render={({ pageNumber }) =>
                    handlePageChange(pageNumber, idx) && `Pos`
                  }
                />
                <Text
                  style={[stylesList.bez]}
                  render={({ pageNumber }) => showMenu && `Beschreibung`}
                />
                <Text
                  style={[stylesList.menge, styles.textRight]}
                  render={({ pageNumber }) => showMenu && `Menge`}
                />
                <Text
                  style={[stylesList.einheit, styles.textRight]}
                  render={({ pageNumber }) => showMenu && `Einheit`}
                />
                <Text
                  style={[stylesList.preisEinzel, styles.textRight]}
                  render={({ pageNumber }) => showMenu && `Einzelpreis`}
                />
                <Text
                  style={[stylesList.preisGesamt, styles.textRight]}
                  render={({ pageNumber }) => showMenu && `Gesamtpreis`}
                />
                <View
                  render={({ pageNumber }) =>
                    showMenu && (
                      <View style={{ height: "1px" }}>
                        <Svg height="1" width="500">
                          <Line
                            x1="0"
                            y1="0"
                            x2="1000"
                            y2="00"
                            strokeWidth={2}
                            stroke="rgb(255,0,0)"
                          />
                        </Svg>
                      </View>
                    )
                  }
                />

                <Text
                  style={[
                    styles.textRight,
                    stylesList.fullwidth,
                    stylesList.uebertrag,
                  ]}
                  render={({ pageNumber }) =>
                    showMenu &&
                    idx !== 0 &&
                    `Übertrag ` +
                      convertPrice(
                        subSumArr.find((subSum) => subSum.idx === idx - 1)
                          ?.subSum
                      )
                  }
                />
              </View>
              <View
                style={[stylesList.view, stylesList.marginBottomMinus]}
                wrap={false}
              >
                <View style={stylesList.pos}>
                  <Text>{products.pos}</Text>
                </View>
                <View style={stylesList.bez}>
                  <Text>{products.dsc}</Text>
                </View>
                <View style={[stylesList.menge, styles.textRight]}>
                  <Text>{products.amnt}</Text>
                </View>
                <View style={[stylesList.einheit, styles.textRight]}>
                  <Text>{products.unit}</Text>
                </View>
                <View style={[stylesList.preisEinzel, styles.textRight]}>
                  <Text>{convertPrice(products.pricePiece)}</Text>
                </View>
                <View style={[stylesList.preisGesamt, styles.textRight]}>
                  <Text>{convertPrice(products.priceComplete)}</Text>
                </View>
                <Text
                  style={[styles.pt8, styles.textRight, stylesList.fullwidth]}
                  render={({ pageNumber }) =>
                    handleEndPage(pageNumber, idx) && ``
                  }
                />
                <Text
                  style={[styles.pt8, styles.textRight, stylesList.fullwidth]}
                  render={({ pageNumber }) =>
                    savedIdx.indexOf(idx) !== -1 &&
                    `Übertrag ` +
                      convertPrice(
                        subSumArr.find((subSum) => subSum.idx === idx)?.subSum
                      )
                  }
                />
              </View>
            </View>
          );
        })}
        {props.paragraph ? (
          <View style={[stylesList.view]} wrap={false}>
            <View style={[stylesList.viewCalc]}>
              <View style={[stylesList.view, stylesList.borderTop]} />
              <Text style={[stylesList.calcText, stylesList.textRight]}>
                Netto Summe{" "}
              </Text>
              <Text style={[stylesList.calcNumber, stylesList.textRight]}>
                {convertPrice(state.sumNetto)}
              </Text>
            </View>
            {props.discountComplete !== "" ? (
              <View style={[stylesList.view, stylesList.textRight]}>
                <View style={[stylesList.viewCalc]}>
                  <Text style={[stylesList.calcText, stylesList.textRight]}>
                    - Nachlass{" "}
                  </Text>
                  <Text style={[stylesList.calcNumber, stylesList.textRight]}>
                    {convertPrice(calcDiscountComplete())}
                  </Text>
                </View>
                <View style={[stylesList.viewCalc]}>
                <View style={{ height: "1px" }}>
                        <Svg height="10" width="500">
                        <Line
                                x1="300"
                                y1="0"
                                x2="1200"
                                y2="000"
                                strokeWidth={2}
                                stroke="rgb(0,0,0)"
                              />
                        </Svg>
                      </View>
                  <Text style={[stylesList.calcText, stylesList.textRight, styles.marginT5]}>Gesamtpreis Netto </Text>
                  <Text
                    style={[
                      stylesList.calcNumber,
                      stylesList.textRight, 
                      styles.marginT5
                    ]}
                  >
                    {convertPrice(state.sumNetto - calcDiscountComplete())}
                  </Text>
                </View>
              </View>
            ) : (
              ""
            )}
          </View>
        ) : (
          ``
        )}
        {props.paragraph ? (
          ""
        ) : (
          <View style={[stylesList.viewCalc]} wrap={false}>
            <View style={[stylesList.viewCalc]}>
              <View style={[stylesList.view, stylesList.borderTop]} />
              <Text style={[stylesList.calcText, stylesList.textRight]}>
                Netto Summe{" "}
              </Text>
              <Text style={[stylesList.calcNumber, stylesList.textRight]}>
                {convertPrice(state.sumNetto)}
              </Text>
            </View>
            {props.discountComplete === "" ? (
              ""
            ) : (
              <View style={[stylesList.viewCalc]}>
                <View style={[stylesList.viewCalc]}>
                  <Text style={[stylesList.calcText, stylesList.textRight]}>
                    - Nachlass{" "}
                  </Text>
                  <Text style={[stylesList.calcNumber, stylesList.textRight]}>
                    {convertPrice(calcDiscountComplete())}
                  </Text>
                </View>
                <View style={[stylesList.viewCalc]}>
                  <Text style={[stylesList.calcText, stylesList.textRight]}>
                    {taxText()}
                  </Text>
                  <Text style={[stylesList.calcNumber, stylesList.textRight]}>
                    {convertPrice(
                      (state.sumNetto - calcDiscountComplete()) * taxCalc()
                    )}
                  </Text>
                </View>
              </View>
            )}

            {props.paragraph ? (
              ""
            ) : (
              <View style={[stylesList.viewCalc]} wrap={false}>
                <View style={[stylesList.viewCalc]}>
                  {props.discountComplete === "" ? (
                    <View style={[stylesList.viewCalc]}>
                      <Text style={[stylesList.calcText, stylesList.textRight]}>
                        {taxText()}
                      </Text>
                      <Text
                        style={[stylesList.calcNumber, stylesList.textRight]}
                      >
                        {convertPrice(state.sumNetto * taxCalc())}
                      </Text>
                    </View>
                  ) : (
                    ""
                  )}
                </View>

                <View style={[stylesList.viewCalc, stylesList.bold]}>
                <View style={{ height: "1px" }}>
                        <Svg height="10" width="500">
                        <Line
                                x1="300"
                                y1="0"
                                x2="1200"
                                y2="000"
                                strokeWidth={2}
                                stroke="rgb(0,0,0)"
                              />
                        </Svg>
                      </View>
                  <Text style={[stylesList.calcText, stylesList.textRight, styles.marginT5]}>
                    Brutto Summe
                  </Text>
                  {props.discountComplete === "" ? (
                    <Text style={[stylesList.calcNumber, stylesList.textRight,styles.marginT5]}>
                      {convertPrice(
                        state.sumNetto + state.sumNetto * taxCalc()
                      )}
                    </Text>
                  ) : (
                    <Text style={[stylesList.calcNumber, stylesList.textRight,styles.marginT5]}>
                      {convertPrice(
                        parseFloat(state.sumNetto - calcDiscountComplete()) +
                          parseFloat(state.sumNetto - calcDiscountComplete()) *
                            taxCalc()
                      )}
                    </Text>
                  )}
                </View>
              </View>
            )}
          </View>
        )}
        <View style={[stylesList.view, styles.marginT30]} wrap={false}>
          <Text>{props.paymentText}</Text>

          {props.paragraph ? (
            <View>
              {state.textNetto.split("\n").map((item, key) => {
                return (
                  <Text key={key}>
                    {item}
                    {"\n"}
                  </Text>
                );
              })}
            </View>
          ) : (
            ""
          )}

          <View>
            {state.schlusstext.split("\n").map((item, key) => {
              if (item.includes("www.")) {
                let noLink = item.split("www.")[0];
                let link = item.split("www.")[1];
                return (
                  <Text key={key}>
                    {noLink}{" "}
                    <Link src={`https://${link}`}>
                      {`www.` + link}
                      {"\n"}
                    </Link>
                  </Text>
                );
              } else {
                return (
                  <Text key={key}>
                    {item}
                    {"\n"}
                  </Text>
                );
              }
            })}
          </View>
        </View>
        {checkSpaceAnlage() ? (
          <View wrap={false}>
            <Text style={[stylesList.anlagen, stylesList.bold]}>Anlagen</Text>
            {props.chkConfirmation ? <Text>Auftragsbestätigung</Text> : ""}
            {props.chkWiederruf ? <Text>Widerrufsbelehrung</Text> : ""}
            {props.chkWiederrufformular ? <Text>Widerrufsformular</Text> : ""}
          </View>
        ) : (
          ""
        )}

        <View style={[stylesList.view, stylesFooter.bottom]} fixed>
          <Footer />
        </View>
      </Page>
      {props.chkConfirmation ? (
        <PrintConfirmationPDF
          customer={state.customer}
          streetCustomer={state.streetCustomer}
          zipCodeCustomer={state.zipCodeCustomer}
          cityCustomer={state.cityCustomer}
          date={props.date}
          bv={state.bv}
          offerNr={props.offerNr}
          version={props.version}
          versionOld={props.versionOld}
        />
      ) : (
        ""
      )}
       {props.chkWiederruf ? (
        <PrintWiderrufPDF
          customer={state.customer}
          streetCustomer={state.streetCustomer}
          zipCodeCustomer={state.zipCodeCustomer}
          cityCustomer={state.cityCustomer}
          date={props.date}
          bv={props.bv}
          offerNr={props.offerNr}
          version={props.version}
          versionOld={props.versionOld}
        />
      ) : (
        ""
      )}
             {props.chkWiederrufformular ? (
        <PrintWiderrufformularPDF
          customer={state.customer}
          streetCustomer={state.streetCustomer}
          zipCodeCustomer={state.zipCodeCustomer}
          cityCustomer={state.cityCustomer}
          date={props.date}
          bv={props.bv}
          offerNr={props.offerNr}
          version={props.version}
          versionOld={props.versionOld}
        />
      ) : (
        ""
      )}
    </Document>
  );
}

export default OfferPrint;
