import React, { Component } from "react";

import "../css/bootstrap-grid.css";
import "../css/App.css";
import "../css/index.css";
import "../css/mediaQuerys.css";

import "@fortawesome/fontawesome-free-solid";
 

class HoursReportingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      year: this.props.year,
      month: this.props.month,
      date: this.props.date,
      payed: this.props.payed,
      beginning: this.props.beginning,
      end: this.props.end,
      pause: this.props.pause,
      driveTime: this.props.driveTime,
      fahrtArbeit: this.props.fahrtArbeit,
      bv: this.props.bv,
      bvId: this.props.bvId,
      result: this.props.result,
      item: this.props.item,
      idx: this.props.idx
    };
  }


  convertDate = date => {
    if(date === undefined){
      return date;
    }
    else{
      if (date.includes("-")) {
        var dateNew = date.split("-");
        dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
        return dateNew;
      } else {
        return date;
      }
  }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-break  " key={this.state.idx}>

          <div className="print">
            <div className=" row">
              <div className="col-9">
                <h4>Stundenbericht</h4>
              </div>
              <div className="col-3">
                <img src={require("../img/logo.png")} alt="" />
              </div>
            </div>
          </div>
          <div className="print">
            <div className=" row">
              <div className="col-6">
                <h3>
                  Name: {this.state.lastName} {this.state.firstName}
                </h3>
                <h3>
                  Zeitraum: {this.state.month} {this.state.year}
                </h3>
                <br/>
              </div>
              <div className="col-6 unterschrift">
                <hr/>
                <p>Unterschrift Mitarbeiter</p>
              </div>
            </div>
          </div>

          <div className={this.state.hideList ? "hide " : "  wage-accounting"}>
            <div className="no-print center">
              <h3>
                Name: {this.state.lastName} {this.state.firstName}
              </h3>
              <h3>
                Zeitraum: {this.state.month} {this.state.year}
              </h3>
            </div>
            <table>
              <thead>
                <span className={this.state.hideSpan ? "row hide" : "row"}>
                  <p className="col-2 max-100">
                    <span>Datum:</span>
                  </p>
                  <p className="col-1">
                    <span>Bez. h:</span>
                  </p>
                  <p className="col-1  max-85">
                    <span>Beginn:</span>
                  </p>
                  <p className="col-1 max-85">
                    <span>Ende:</span>
                  </p>
                  <p className="col-1 max-85">
                    <span>Pause:</span>
                  </p>
                  <p className="col-1">
                    <span>Fahrt-/Arbeitszeit:</span>
                  </p>
                  <p className="col-1 max-85">
                    <span>Bv Id:</span>
                  </p>
                  <p className="col-3">
                    <span>Baustelle:</span>
                  </p>
                  <p className="col-1  max-85">
                    <span>Ergebnis:</span>
                  </p>
                </span>
              </thead>


              {this.state.item.map((item, ix) => {
                return (
                  <div key={ix}
                  >
                    <span className="row hide-span">
                      <p className="col-2 max-100">
                        {this.convertDate(item.date)}
                      </p>
                      <p className="col-1">
                        {item.payed}
                      </p>
                      <p className="col-1 max-60">
                        {item.beginning}
                      </p>
                      <p className="col-1 max-60">
                        {item.end}
                      </p>
                      <p className="col-1 max-60">
                        {item.pause}
                      </p>
                      <p className="col-1">
                        {item.fahrtArbeit}
                      </p>
                      <p className="col-1 max-60">
                        {item.bvId}
                      </p>
                      <p className="col">
                        {item.bv}
                      </p>
                      <p className="col-1 max-85">
                        {item.result}
                      </p>
                    </span>
                  </div>
                );
              })}
              <tfoot class="print report-footer"><p>{this.state.lastName} {this.state.firstName} (Seiten: {this.state.item.length >= 26 ? "2" : "1" })</p></tfoot>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default HoursReportingList;
