import React, { useState, useEffect } from "react";
import "@fortawesome/fontawesome-free-solid";
import moment from "moment";
import CustomFontRegular from "../fonts/Lunchtype22-Regular.ttf";
import CustomFontBold from "../fonts/Lunchtype22-Medium.ttf";
var currencyFormatter = require("currency-formatter");

import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Link,
  Line,
  Svg,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "CustomFont", // Font family name
  fonts: [
    { src: CustomFontRegular, fontWeight: "normal" },
    { src: CustomFontBold, fontWeight: "bold" },
  ],
});
function PrintOpenBillbook(props) {
  const [state, setState] = useState({});

  useEffect(() => {}, []);
  const currentDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${date}.${month}.${year}`;
  };
  const convertPrice = (price) => {
    return currencyFormatter.format(price, { locale: "de-DE" });
  };

  const checkEmpty = (data) => {
    if (data === "" || data === undefined) {
      return "-";
    }
    return data;
  };
  const checkDate = (date) => {
    if (date === undefined || date === "") {
      return "-";
    } else {
      return date;
    }
  };
  const styles = StyleSheet.create({
    page: {
      flexWrap: "wrap",
      fontSize: "7pt",
      flexDirection: "row",
      paddingTop: 35,
      paddingBottom: 35,
      paddingLeft: 35,
      paddingRight: 35,
      lineHeight: "1.5pt",
      fontFamily: "CustomFont",
      fontWeight: "normal",
    },
    view: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      marginBottom: "0.5mm",
    },
    logo: {
      width: 110,
    },
    alignRight: {
      alignItems: "flex-end",
      width: "100%",
    },
    borderBottom: {
      borderBottom: "1px solid black",
    },
    block: {
      display: "block",
      marginRight: 5,
    },
    bold: {
      fontWeight: "bold",
    },
    width1_5cm: {
      width: "1.5cm",
    },
    width2cm: {
      width: "2cm",
    },
    width2_5cm: {
      width: "2.5cm",
    },
    width3cm: {
      width: "3cm",
    },
    width4cm: {
      width: "4cm",
    },
    width5cm: {
      width: "5cm",
    },
    width9cm: {
      width: "8.5cm",
    },
    font6pt: {
      fontSize: "6pt",
    },
    font8pt: {
      fontSize: "9pt",
    },
    marginB30: {
      marginBottom: 30,
    },
    marginB50: {
      marginBottom: 50,
    },
    marginB10: {
      marginBottom: 10,
    },
    h1: {
      fontSize: "18pt",
      fontWeight: "bold",
      marginTop: "10px",
    },
    h2: {
      fontSize: "12pt",
      fontWeight: "bold",
    },
    width70: {
      width: "70%",
    },
    width30: {
      width: "30%",
    },
    marginB5: {
      marginBottom: 5,
    },
    marginB30: {
      marginBottom: 30,
    },
    posBottom: {
      position: "absolute",
      bottom: "0",
      right: 30,
      fontSize: "8px",
      width: "2cm",
      textAlign: "right",
      height: "1.4cm",
    },
    alignRight: {
      alignItems: "flex-end",
      width: "100%",
    },
    marginT50: {
      marginTop: "-90px",
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.alignRight, styles.marginB30]} fixed>
          <Image style={styles.logo} src={require("../img/logo.png")} />
        </View>
        <View style={[styles.view, styles.marginT50]}>
          <View style={styles.width70}>
            <Text style={styles.h1}>Offene Rechnungen - {currentDate()}</Text>
            <Text style={styles.h2}>
              Gesamtsumme: {convertPrice(props.openBillsValue)}
            </Text>
          </View>
        </View>

        <View>
          {props.billbookOpenAll.map((item, idx) => (
            <View
              key={idx}
              style={[styles.view, styles.marginB30]}
              wrap={false}
            >
              <View
                style={[styles.view, styles.borderBottom, styles.marginB10]}
              >
                <View style={[styles.block, styles.width2cm]}>
                  <Text style={styles.font6pt}>Rechnr.</Text>
                  <Text style={[styles.bold, styles.font8pt]}>
                    {item.rechnungsNr}
                  </Text>
                </View>
                <View style={[styles.block, styles.width2cm]}>
                  <Text style={styles.font6pt}>Rech.-Datum</Text>
                  <Text style={[styles.bold, styles.font8pt]}>
                    {checkDate(item.rechnungsdatum) !== "-"
                      ? moment(item.rechnungsdatum).format("DD.MM.YYYY")
                      : "-"}
                  </Text>
                </View>
                <View style={[styles.block, styles.width9cm]}>
                  <Text style={styles.font6pt}>Kunde</Text>
                  <Text style={[styles.bold, styles.font8pt]}>
                    {item.customerId} -{" "}
                    {props.readCustomerNameById(item.customerId, false)}
                  </Text>
                </View>
                <View style={[styles.block, styles.width5cm]}>
                  <Text style={styles.font6pt}>Bauvorhaben</Text>
                  <Text style={[styles.bold, styles.font8pt]}>
                    {item.bvNr} - {props.readBvNameById(item.bvNr, false)}
                  </Text>
                </View>
              </View>
              <View style={[styles.block, styles.width2_5cm, styles.marginB5]}>
                <Text style={styles.font6pt}>Rech.-Betrag</Text>
                <Text style={styles.font8pt}>
                  {convertPrice(item.rechnungsbetrag)}
                </Text>
              </View>
              <View style={[styles.block, styles.width1_5cm, styles.marginB5]}>
                <Text style={styles.font6pt}>Skonto</Text>
                <Text style={styles.font8pt}>{checkEmpty(item.skonto)}</Text>
              </View>
              <View style={[styles.block, styles.width3cm, styles.marginB5]}>
                <Text style={styles.font6pt}>Betrag skontiert</Text>
                <Text style={styles.font8pt}>
                  {checkEmpty(convertPrice(item.betragSkonto))}
                </Text>
              </View>
              <View style={[styles.block, styles.width3cm, styles.marginB5]}>
                <Text style={styles.font6pt}>Betrag eingegangen (netto)</Text>
                <Text style={styles.font8pt}>
                  {checkEmpty(convertPrice(item.betragEingang))}
                </Text>
              </View>
              <View style={[styles.block, styles.width3cm, styles.marginB5]}>
                <Text style={styles.font6pt}>Betrag eing. Datum</Text>
                <Text style={styles.font8pt}>
                  {checkDate(item.betragEingangsDatum) !== "-"
                    ? moment(item.betragEingangsDatum).format("DD.MM.YYYY")
                    : "-"}
                </Text>
              </View>
              <View style={[styles.block, styles.width2cm, styles.marginB5]}>
                <Text style={styles.font6pt}>Zahlungsziel</Text>
                <Text style={styles.font8pt}>
                  {checkEmpty(item.zahlungsziel)}
                </Text>
              </View>

              <View style={[styles.block, styles.width2cm, styles.marginB5]}>
                <Text style={styles.font6pt}>SR/AZ</Text>
                <Text style={styles.font8pt}>{checkEmpty(item.sr_az)}</Text>
              </View>
              <View style={[styles.block, styles.width1_5cm, styles.marginB5]}>
                <Text style={styles.font6pt}>AzNr</Text>
                <Text style={styles.font8pt}>{checkEmpty(item.azNr)}</Text>
              </View>
              <View style={[styles.block, styles.width2_5cm, styles.marginB5]}>
                <Text style={styles.font6pt}>Zahlungsdatum</Text>
                <Text style={styles.font8pt}>
                  {checkDate(item.zahlungsdatum) !== "-"
                    ? moment(item.zahlungsdatum).format("DD.MM.YYYY")
                    : "-"}
                </Text>
              </View>
              <View style={[styles.block, styles.width3cm, styles.marginB5]}>
                <Text style={styles.font6pt}>Zahlungsdatum VOB</Text>
                <Text style={styles.font8pt}>
                  {checkDate(item.zahlungsdatumVOB) !== "-"
                    ? moment(item.zahlungdatumVOB).format("DD.MM.YYYY")
                    : "-"}
                </Text>
              </View>
              <View style={[styles.block, styles.width3cm, styles.marginB5]}>
                <Text style={styles.font6pt}>1.Mahnung</Text>
                <Text style={styles.font8pt}>
                  {checkDate(item.mahnung1) !== "-"
                    ? moment(item.mahnung1).format("DD.MM.YYYY")
                    : "-"}
                </Text>
              </View>
              <View style={[styles.block, styles.width3cm, styles.marginB5]}>
                <Text style={styles.font6pt}>2.Mahnung</Text>
                <Text style={styles.font8pt}>
                  {checkDate(item.mahnung2) !== "-"
                    ? moment(item.mahnung2).format("DD.MM.YYYY")
                    : "-"}
                </Text>
              </View>
              <View style={[styles.block, styles.width3cm, styles.marginB5]}>
                <Text style={styles.font6pt}>3.Mahnung</Text>
                <Text style={styles.font8pt}>
                  {checkDate(item.mahnung3) !== "-"
                    ? moment(item.mahnung3).format("DD.MM.YYYY")
                    : "-"}
                </Text>
              </View>
              <View style={[styles.block, styles.width1cm, styles.marginB5]}>
                <Text style={styles.font6pt}>Bezahlt</Text>
                <Text style={styles.font8pt}>{checkEmpty(item.bezahlt)}</Text>
              </View>
            </View>
          ))}
        </View>
        <Text
          style={styles.posBottom}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}

export default PrintOpenBillbook;
