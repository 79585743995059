import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

   class BillsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search : this.props.search,
      showOpen: this.props.showOpen
    };
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.search !== this.props.search) {
      this.setState({
       });
    }
  }
  handleSearch = e => {
    this.setState({
      search: e.target.value
    });
    this.props.handleSearch(e);
  };

  render() {
    return (
      <React.Fragment>
      <section id="showBillbooks" className="display-item no-print">
        <h4>Übersicht <span className={this.props.hideModalBillbook ? "" : "hide"}>offene</span> Rechnungen</h4>
        <div className="show-items-btn row">
           <div className="col-6 offset-3">
            <input
              className="search"
              type="text"
              name="search"
              placeholder="Suchen"
              onChange={this.handleSearch}
              onKeyPress={this.props.onKeyPress}
              value={this.state.search}
            />
          </div>
           <div className="col-3">
            <button
              onClick={this.props.loadOpenBillbook}
              className={
                this.props.hideModalBillbook
                  ? "hide"
                  : "btn-create-item margin-zero"
              }
            >
              {" "}
              <FontAwesomeIcon icon="file-alt" /> offene Rechnungen
            </button>

            <button
              onClick={this.props.showBillbook}
              className={
                this.props.hideModalBillbook
                  ? "btn-create-item margin-zero"
                  : "hide"
              }
            >
              {" "}
              <FontAwesomeIcon icon="file-alt" /> letzte Rechnungen
            </button>
          </div>
        </div>
      </section>

      </React.Fragment>
    );
  }
}
export default BillsSearch;
