import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";
import CSVReader from "react-csv-reader";
import BaseUrl from "../config/config.js";
import ModalGeo from "./ModalGeo.js";
var $ = require("jquery");

class LegalBreak extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breaks: [
        {
          id: "",
          von: "",
          bis: "",
          pauseMin: "",
        },
      ],
      saved: false,
      removed: false,
    };
  }
  componentDidMount = () => {
    this.readLegalBreak();
  };
  readLegalBreak = () => {
    return fetch(BaseUrl + "api/hoursreporting/readLegalBreak.php")
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.records);
        this.setState({
          breaks: responseJson.records,
        });
      })
      .catch((error) => {});
  };
  handleAddBreakds = () => {
    this.setState({
      breaks: this.state.breaks.concat([
        {
          von: "",
          bis: "",
          pauseMin: "",
        },
      ]),
    });
  };
  handleRemoveBreaks = (idx, id) => () => {
    this.setState({
      breaks: this.state.breaks.filter((s, sidx) => idx !== sidx),
    });
    console.log(id);
    this.delete(id);
  };
  handleChangeBreaks = (idx) => (evt) => {
    const changedBreaks = this.state.breaks.map((item, sidx) => {
      if (idx !== sidx) return item;
      return { ...item, [evt.target.name]: evt.target.value };
    });
    this.setState({ breaks: changedBreaks });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let legalbreaks;

    const wait = this.state.breaks.map(
      (items, idx) => (
        (legalbreaks = {
          id: items.id === undefined ? "create" : items.id,
          von: items.von,
          bis: items.bis,
          pauseMin: items.pauseMin,
        }),
        this.saveLegalBreaks(legalbreaks)
      )
    );
    Promise.all([wait]).then((values) => {
                     this.setState({
          saved: true,
        });
       setTimeout(() => {
        this.setState({
          saved: false
        });
      }, 3000);
    });
  };

  saveLegalBreaks = (legalbreaks) => {
    $.ajax({
      url: BaseUrl + "api/hoursreporting/createLegalBreaks.php",
      type: "POST",
      dataType: "json",
      data: JSON.stringify(legalbreaks),
      success: function (response) {
        this.setState({});
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  delete = (idx) => {
    let deleteItem = {
      id: idx,
    };
    $.ajax({
      url: BaseUrl + "api/hoursreporting/deleteLegalBreak.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function (response) {
                this.setState({
          removed: true,
        });
       setTimeout(() => {
        this.setState({
          removed: false
        });
      }, 3000);
 
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  render() {
    return (
      <React.Fragment>
        <section id="insertHoursReporting" className="no-print">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home/stundenbericht">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--greenSeyband)" icon="coffee" />{" "}
                Gesetzliche Pausen
              </h1>
            </div>
          </div>
          <h4>Übersicht</h4>
          <form className="legalbreaks" onSubmit={this.handleSubmit}>
            <fieldset>
              {this.state.breaks.map((items, idx) => (
                <div key={idx} className={`row legalbreaks span-row-${idx}`}>
                  <div className=" col-2  ">
                    <label>
                      <span>Von h:</span>
                      <input
                        type="number"
                        name="von"
                        placeholder={`Von #${idx + 1}`}
                        onChange={this.handleChangeBreaks(idx)}
                        value={items.von}
                      />
                    </label>
                  </div>
                  <div className="col-2   ">
                    <label>
                      <span>Bis h:</span>
                      <input
                        type="number"
                        name="bis"
                        placeholder={`Bis #${idx + 1}`}
                        onChange={this.handleChangeBreaks(idx)}
                        value={items.bis}
                      />
                    </label>
                  </div>
                  <div className="col-2 ">
                    <label>
                      <span>Pause in Min:</span>
                      <input
                        disabled={this.state.isDisabled}
                        type="time"
                        name="pauseMin"
                        placeholder={`Pause #${idx + 1}`}
                        onChange={this.handleChangeBreaks(idx)}
                        value={items.pauseMin}
                      />
                    </label>
                  </div>

                  <div className="col-1 text-right">
                    <label>
                      <span>&nbsp;</span>
                      <button
                        type="button"
                        onClick={this.handleRemoveBreaks(idx, items.id)}
                        className="btn-remove"
                      >
                        -
                      </button>
                    </label>
                  </div>
                </div>
              ))}
              <div className="legalbreaks row">
                <div className="offset-6 col-1 text-right">
                  <button
                    type="button"
                    onClick={this.handleAddBreakds}
                    className="btn-add-item"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="legalbreaks row">
                <div className="col-7">
                  <button className="btn-create-item fullwidth">
                    Pausen speichern <FontAwesomeIcon icon="save" />
                  </button>
                </div>
              </div>
              <div className="legalbreaks row">
                <p className={this.state.saved ? "show" : "hide"}>
                  <FontAwesomeIcon icon="check" /> Eingabe wurde gespeichert
                </p>
                <p className={this.state.removed ? "show" : "hide"}>
                  <FontAwesomeIcon icon="check" /> Eingabe wurde gelöscht
                </p>
              </div>
            </fieldset>
          </form>
        </section>
      </React.Fragment>
    );
  }
}

export default LegalBreak;
