import React, { Component } from "react";
import ModalDeliveryNotes from "../deliveryNote/ModalDeliveryNotes";
import DeliveryNotesFormInputTop from "../deliveryNote/DeliveryNotesFormInputTop";
import DeliveryNotesSearch from "../deliveryNote/DeliveryNotesSearch";
import "@fortawesome/fontawesome-free-solid";
import BaseUrl from "../config/config.js";

class Notes extends Component {
  constructor() {
    super();
    this.state = {
      date: "",
      notesNr: "",
      supplier: "",
      bv: "",
      bvHelp: "",
      bvId: "",
      supplierId: "",
      itemsAll: [],
      bvAll: [],
      bvAllInput: [],
      bvComplete: [],
      supplierAll: [],
      errorSearch: false,
      search: "",
      showItem: [],
      showItemCopy: [],
      articles: [],
      searchArr: [],
      bvAllFound: "",
      refresh: false,
    };
  }

  componentDidMount = () => {
    Promise.all([
      this.readBvAll(),
      this.readsuppliers(),
      this.readBvComplete(),
    ]).then(() => {
      this.loadItems();
    });
  };
  readBvAll = () => {
    return fetch(BaseUrl + "api/all/readBVNotDone.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          bvAll: responseJson.records[0],
          bvAllInput: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };
  readBvComplete = () => {
    return fetch(BaseUrl + "api/all/readBvComplete.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          bvComplete: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };
  readsuppliers = () => {
    return fetch(BaseUrl + "api/all/readSupplierNames.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          supplierAll: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };
  loadItems = () => {
    let helpFunc = [];
    return fetch(BaseUrl + "api/deliveryNote/readDeliveryNotesPerNotesNr.php")
      .then((response) => response.json())
      .then((responseJson) => {
        Object.keys(responseJson.records).map((notesNr, i) => {
          Object.keys(responseJson.records[notesNr]).map((bvId, idx) => {
            const article = responseJson.records[notesNr][bvId].map(
              (i, sidx) => {
                return {
                  key: sidx,
                  date: this.convertDateToNorm(i.date),
                  id: i.id,
                  itemId: i.itemId,
                  itemDsc: i.itemDsc,
                  itemAmnt: i.itemAmnt,
                  itemUnit: i.itemUnit,
                };
              }
            );
            const notes = responseJson.records[notesNr][bvId]
              .slice(0, 1)
              .map((i, ix) => {
                return {
                  key: idx,
                  date: this.convertDateToNorm(i.date),
                  id: i.id,
                  notesNr: i.notesNr,
                  supplier: i.supplier,
                  supplierId: i.supplierId,
                  itemBv: i.bv,
                  itemBvId: i.bvId,
                  articles: article,
                };
              });
            return (helpFunc = [...helpFunc, ...notes]);
          });
        });
        this.setState({
          showItem: this.sortByDate(helpFunc),
          showItemCopy: this.sortByDate(helpFunc),
        });
      });
  };
  updateList = (note, id) => {
    let addToArray = this.state.showItem.filter((item) => item.id !== id);
    addToArray = [...addToArray, note];
    const wait = this.setState({
      showItem: [],
    });
    Promise.all([wait]).then(() => {
      this.setState({
        showItem: this.sortByDate(addToArray),
      });
    });
  };
  convertDateToNorm = (date) => {
    var dateNew = "";
    if (date.includes(".")) {
      dateNew = date.split(".");
      dateNew = dateNew[2] + "-" + dateNew[1] + "-" + dateNew[0];
      return dateNew;
    } else {
      dateNew = date.split("-");
      dateNew = dateNew[0] + "-" + dateNew[1] + "-" + dateNew[2];
      return dateNew;
    }
  };

  sortByDate = (array) => {
    array.sort((a, b) => {
      let x = a.date.split(".").reverse().join();
      let y = b.date.split(".").reverse().join();
      if (x > y) {
        return -1;
      }
      if (x < y) {
        return 1;
      }
      return 0;
    });
    array.sort((a, b) => {
      let x = a.id;
      let y = b.id;
      if (x > y) {
        return -1;
      }
      if (x < y) {
        return 1;
      }
      return 0;
    });
    return array;
  };

  readLastNote = () => {
    let helpFunc = [];

    return fetch(BaseUrl + "api/deliveryNote/readLastNote.php ")
      .then((response) => response.json())
      .then((responseJson) => {
        Object.keys(responseJson.records).map((notesNr, i) => {
          Object.keys(responseJson.records[notesNr]).map((bvId, idx) => {
            const article = responseJson.records[notesNr][bvId].map(
              (i, sidx) => {
                return {
                  key: sidx,
                  date: this.convertDateToNorm(i.date),
                  id: i.id,
                  itemId: i.itemId,
                  itemDsc: i.itemDsc,
                  itemAmnt: i.itemAmnt,
                  itemUnit: i.itemUnit,
                };
              }
            );
            const notes = responseJson.records[notesNr][bvId]
              .slice(0, 1)
              .map((i, ix) => {
                return {
                  key: idx,
                  date: this.convertDateToNorm(i.date),
                  id: i.id,
                  notesNr: i.notesNr,
                  supplier: i.supplier,
                  supplierId: i.supplierId,
                  itemBv: i.bv,
                  itemBvId: i.bvId,
                  articles: article,
                };
              });
            helpFunc = [...this.state.showItem, ...notes];
            Promise.all(notes).then((values) => {
              this.sortByDate(helpFunc);
            });
            const sorted = this.state.showItem.map((index) => {
              return this.setState({
                showItem: [],
              });
            });

            Promise.all(sorted).then((values) => {
              this.setState({
                showItem: helpFunc,
                showItemCopy: helpFunc,
              });
            });
            return this;
          });
        });
      });
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.search(e.target.value);
      this.setState({
        search: e.target.value,
      });
    }
  };

  search = (search) => {
    let searchHelpFunc = [];
    this.setState({
      showItem: [],
      refresh: true,
    });
    let convertEingabe = search.replace("+", "%2B");
    fetch(
      BaseUrl + "api/deliveryNote/searchNotes.php?eingabe=" + convertEingabe
    )
      .then((response) => response.json())
      .then((responseJson) => {
        Object.keys(responseJson.records).map((notesNr, i) => {
          Object.keys(responseJson.records[notesNr]).map((bvId, idx) => {
            const article = responseJson.records[notesNr][bvId].map(
              (i, sidx) => {
                return {
                  key: sidx,
                  date: this.convertDateToNorm(i.date),
                  id: i.id,
                  itemId: i.itemId,
                  itemDsc: i.itemDsc,
                  itemAmnt: i.itemAmnt,
                  itemUnit: i.itemUnit,
                };
              }
            );
            const notes = responseJson.records[notesNr][bvId]
              .slice(0, 1)
              .map((i, ix) => {
                return {
                  key: idx,
                  date: this.convertDateToNorm(i.date),
                  id: i.id,
                  notesNr: i.notesNr,
                  supplier: i.supplier,
                  supplierId: i.supplierId,
                  itemBv: i.bv,
                  itemBvId: i.bvId,
                  articles: article,
                };
              });
            return (searchHelpFunc = [...searchHelpFunc, ...notes]);
          });
        });
        this.setState({
          refresh: false,
          showItem: this.sortByDate(searchHelpFunc),
        });
      });
  };
  readSupplierNameById = (id) => {
    let found = this.state.supplierAll.find(
      (name) => name.supplierId === parseInt(id)
    );
    if (found === undefined) {
      return "";
    } else {
      return found.supplierName;
    }
  };

  readBvCompleteById = (id) => {
    let foundComplete = this.state.bvComplete.find(
      (name) => name.bvId === parseInt(id)
    );
    if (foundComplete === undefined) {
      return "";
    } else {
      this.state.bvAll.concat({ bv: foundComplete.bv, bvId: id });
      return foundComplete.bv;
    }
  };
  readOldBvById = (id) => {
    let foundComplete = this.state.bvComplete.find(
      (name) => name.bvId === parseInt(id)
    );
    if (foundComplete === undefined) {
      return "";
    } else {
      this.setState({
        bvAllInput: this.state.bvAll.concat({ bv: foundComplete.bv, bvId: id }),
        bvAllFound: foundComplete.bv,
      });
    }
  };
  handleSearch = (e) => {
    if (e.target.value === "") {
      const reset = this.state.showItem.map((index) => {
        return this.setState({
          showItem: [],
        });
      });

      Promise.all(reset).then((values) => {
        this.setState({
          showItem: this.state.showItemCopy,
          search: "",
          errorSearch: false,
        });
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <DeliveryNotesFormInputTop
          id={this.state.id}
          date={this.state.date}
          notesNr={this.state.notesNr}
          supplier={this.state.supplier}
          supplierId={this.state.supplierId}
          bv={this.state.bv}
          bvId={this.state.bvId}
          articles={this.state.articles}
          search={this.state.search}
          handleSearch={this.handleSearch}
          onKeyPress={this.onKeyPress}
          readLastNote={this.readLastNote}
          bvAllInput={this.state.bvAllInput}
          supplierAll={this.state.supplierAll}
          readOldBvById={this.readOldBvById}
          bvComplete={this.state.bvComplete}
          bvAllFound={this.state.bvAllFound}
          updateList={this.updateList}
        />
        <section id="showItems" className="display-item">
          <h4>Übersicht Lieferscheine</h4>
          <DeliveryNotesSearch
            search={this.state.search}
            onKeyPress={this.onKeyPress}
            handleSearch={this.handleSearch}
          />
          <div className="text-center">
            <div className={this.state.refresh ? "lds-roller" : "hide"}>
              <div></div>
            </div>
          </div>
          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          <ul className="notes-list">
            {this.state.showItem.map((item, idx) => {
              return (
                <ModalDeliveryNotes
                  onChange={this.handleChange}
                  key={idx}
                  idxCopy={item.id}
                  id={item.id}
                  date={item.date}
                  notesNr={item.notesNr}
                  notesNrOld={item.notesNr}
                  supplier={this.readSupplierNameById(item.supplierId)}
                  supplierId={item.supplierId}
                  bv={this.readBvCompleteById(item.itemBvId)}
                  bvId={item.itemBvId}
                  articles={item.articles}
                  bvAll={[
                    ...new Set(
                      this.state.bvAll.concat({
                        bv: this.readBvCompleteById(item.itemBvId),
                        bvId: item.itemBvId,
                      })
                    ),
                  ]}
                  supplierAll={this.state.supplierAll}
                  search={this.state.search}
                  readBvCompleteById={this.readBvCompleteById}
                />
              );
            })}
          </ul>
        </section>
      </React.Fragment>
    );
  }
}
export default Notes;
