import React, { Component } from "react";
import ModalHoursReporting from "../hoursreporting/ModalHoursReporting";
import HoursReportingFormInputTop from "../hoursreporting/HoursReportingFormInputTop";
import HoursReportingList from "../hoursreporting/HoursReportingList";
 import "@fortawesome/fontawesome-free-solid";
 import BaseUrl from "../config/config.js";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class HoursReporting extends Component {
  constructor() {
    super();
    this.state = {
      name: "-",
      firstName: "",
      lastName: "",
      years: "-",
      month: "-",
      day: "",
      itemsAll: [],
      errorSearch: false,
      errorInput: false,
      hideSpan: true,
      search: "",
      btnAddItem: true,
      useSearch: false,
      showHoursReport: [],
      showHoursReportList: [],
      showHoursReportCopy: [],
      hoursMonth: [],
      calcAll: "",
      searchArr: [],
      showMissed: false,
      disablePrint: true,
      employeesNames: [],
      yearsAll: [],
      hoursComplete: "",
      monthNames: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember"
      ],
       hideSearch: true,
      hidePrintSingle: true,
      hidePrintAll: true,
      hidePrintAllButton: true,
      bvAll: [],
      bvHelp: "",
       helpReportList : [],
       bvAllInput:[]
    };
  }

  componentDidMount = () => {
    this.readEmployeesNames();
    this.readYears();
      this.readBvComplete();
    this.readBvAll();
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      errorInput: false
    });

    if (e.target.name === "month" && e.target.value !== "Monat") {
      this.setState({
        hidePrintAll: false
      });
     }

    if (
      e.target.name === "name" &&
      e.target.value !== "-" &&
      (this.state.years !== "-" && this.state.month !== "-")
    ) {
      this.setState({
        hidePrintAll: false,
        hidePrintAllButton: true,
        hidePrintSingle: false,
        hideSearch: false,
        showHoursReport: [],
        disablePrint: true,
        hideSpan: true,
     });
    }
    if (
      e.target.name === "years" &&
      e.target.value !== "-" &&
      (this.state.name !== "-" && this.state.month !== "-")
    ) {
      this.setState({
        hidePrintAll: false,
        hidePrintAllButton: true,
        hidePrintSingle: false,
        hideSearch: false,
       });
    }
    if (
      e.target.name === "month" &&
      e.target.value !== "-" &&
      (this.state.name !== "-" && this.state.years !== "-")
    ) {
      this.setState({
        hidePrintAll: false,
        hidePrintAllButton: true,
        hidePrintSingle: false,
        hideSearch: false,
       });
     }
    if (
      e.target.name === "years" &&
      e.target.value !== "-" &&
      (this.state.name === "-" && this.state.month !== "-")
    ) {
      this.setState({
        hidePrintAll: false,
        hidePrintSingle: false,
        disablePrint: true,
        hidePrintAllButton: false,
        hideSearch: true,
        hideSpan: true,
       });
    }
    if (
      e.target.name === "month" &&
      e.target.value !== "-" &&
      (this.state.name === "-" && this.state.years !== "-")
    ) {
      this.setState({
         hidePrintAll: false,
        hidePrintSingle: false,
        disablePrint: true,
        hidePrintAllButton: false,
        hideSearch: true,
        hideSpan: true,
       });

    }
    if (
      e.target.name === "month" &&
      e.target.value !== "-" &&
      (this.state.name === "-" && this.state.years === "-" || e.target.name === "years" && e.target.value === "-")
    ) {
      this.setState({
         hidePrintAll: true,
        hidePrintSingle: true,
        disablePrint: true,
        hidePrintAllButton: true,
        hideSearch: true,
        hideSpan: true,
       });
     }
    if (e.target.name === "name" && e.target.value === "-" && this.state.years !== "-" && this.state.month !== "-") {
      this.setState({
        hidePrintAll: false,
        hidePrintSingle: false,
        disablePrint: true,
        hidePrintAllButton: false,
        hideSearch: true,
        hideSpan: true,
        showHoursReport:[]
       });
    }
    if (e.target.name === "name" && e.target.value === "-" && this.state.years === "-" && this.state.month === "-" ||
        e.target.name === "month" && e.target.value === "-" && this.state.name === "-" && this.state.years === "-" ||
        e.target.name === "years" && e.target.value === "-" && this.state.name === "-" && this.state.month === "-" ||
        e.target.name === "years" && e.target.value === "-" && this.state.name === "-" && this.state.month !== "-" ||
        e.target.name === "month" && e.target.value === "-" && this.state.name === "-" && this.state.years !== "-" ||
        e.target.name === "month" && e.target.value === "-" && this.state.name !== "-" && this.state.years !== "-" ||
      e.target.name === "years" && e.target.value === "-" && this.state.name !== "-" && this.state.month !== "-") {
      this.setState({
        hidePrintAll: false,
        hidePrintSingle: false,
        disablePrint: true,
        hidePrintAllButton: true,
        hideSearch: true,
        hideSpan: true,
       });
    }
  };
  readBvAll = () => {
    return fetch(BaseUrl + "api/all/readBVNotDone.php")
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          bvAll: responseJson.records[0],
          bvAllInput: responseJson.records[0]
        });
      })
      .catch(error => {});
  };
  readBvComplete = () => {
    return fetch(BaseUrl + "api/all/readBvComplete.php")
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          bvComplete: responseJson.records[0]
        });
      })
      .catch(error => {});
  };
  readBvCompleteById = id => {
    let foundComplete = this.state.bvComplete.find(name => parseInt(name.bvId) === parseInt(id));
    if (foundComplete === undefined) {
      return "";
    } else {
      this.state.bvAll.concat({ bv: foundComplete.bv, bvId: id });
      return foundComplete.bv;
    }
  };
  readOldBvById = id => {
    let foundComplete = this.state.bvComplete.find(name => parseInt(name.bvId) === parseInt(id));
    if (foundComplete === undefined) {
      return "";
    } else {
      this.setState({
        bvAllInput: this.state.bvAll.concat({ bv: foundComplete.bv, bvId: id }),
        bvAllFound: foundComplete.bv
      });
    }
  };

  onKeyPress = e => {
    this.search(e.target.value);
    this.setState({
      hideSpan: false
    });
  };
  onKeyPressPrint = e => {
    document.title =
      this.state.lastName +
      "_" +
      this.state.firstName +
      "(" +
      this.state.month +
      "-" +
      this.state.years +
      ")";
    window.print();
    document.title = "Kosten-und Leistungsrechnung";
    return false;
  };
  onKeyPressPrintAll = e => {
    Promise.all([this.searchAll(e.target.value)]).then(values => {

    });

    return false;
  };
  readEmployeesNames = () => {
    return fetch(BaseUrl + "api/hoursreporting/readEmployeesNames.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            employeesNames: responseJson.records
          });
        });
      })
      .catch(error => {});
  };
  readYears = () => {
    return fetch(BaseUrl + "api/hoursreporting/readYears.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            yearsAll: responseJson.records
          });
        });
      })
      .catch(error => {});
  };

  timeToMins = time => {
    var b = time.split(":");
    return b[0] * 60 + +b[1];
  };

  timeFromMins = mins => {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    var h = (mins / 60) | 0;
    var m = mins % 60;
    return z(h) + ":" + z(m);
  };

  addTimes = (t0, t1) => {
    return this.timeFromMins(this.timeToMins(t0) + this.timeToMins(t1));
  };
  calcHoursMonth = () => {

    const wait = this.state.employeesNames.map(name => {
      if (name.lastName + " " + name.firstName === this.state.name) {
        this.setState({
          firstName: name.firstName,
          lastName: name.lastName
        });
      }
      return this;
    });
    Promise.all(wait).then(values => {
      return fetch(
        BaseUrl + "api/hoursreporting/hoursMonth.php?lastName=" +
          this.state.lastName +
          "&firstName=" +
          this.state.firstName +
          "&year=" +
          this.state.years +
          "&month=" +
          this.state.month
      )
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.message === "No items found.") {
            this.setState({
              hoursMonth: []
            });
          } else {
            var help = "00:00";
            responseJson.records.map((item, i) => {
              return help = this.addTimes(help, responseJson.records[i].result);
            });
            this.setState({
              hoursComplete: help
            });
          }
        })
        .catch(error => {});
    });
  };
  search = eingabe => {
    this.setState({
      showHoursReport:[],
      showHoursReportList: []
    })
    const wait = this.state.employeesNames.map(name => {
      if (name.lastName + " " + name.firstName === this.state.name) {
        this.setState({
          firstName: name.firstName,
          lastName: name.lastName
        });
      }
      return true;
    });

    Promise.all(wait).then(values => {
      return fetch(
        BaseUrl + "api/hoursreporting/searchHoursReporting.php?lastName=" +
          this.state.lastName +
          "&firstName=" +
          this.state.firstName +
          "&year=" +
          this.state.years +
          "&month=" +
          this.state.month
      )
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.message === "No items found.") {
            this.setState({
              showHoursReport: [],
              errorSearch: true,
              hideSpan: true,
              disablePrint: true
            });
          } else {
            this.setState({
              errorSearch: false,
              disablePrint: false,
              showHoursReport: responseJson.records
            });
            this.calcHoursMonth();
          }
        })
        .catch(error => {});
    });
  };
  searchAll = eingabe => {
    this.setState({
      helpReportList:[],
      showHoursReportList: []
    })
    const wait = this.state.employeesNames.map(name => {
      return fetch(
        BaseUrl + "api/hoursreporting/searchHoursReporting.php?lastName=" +
          name.lastName +
          "&firstName=" +
          name.firstName +
          "&year=" +
          this.state.years +
          "&month=" +
          this.state.month
      )
        .then(response => response.json())
        .then(responseJson => {
           if (responseJson.message === "No items found.") {
            this.setState({
              errorSearch: true,
              hideSpan: true,
              disabledPrint: true,
              hideList: false
            });
          } else {

            this.state.helpReportList = [...this.state.helpReportList,responseJson.records];

          }
        })
        .catch(error => {});
    });

    Promise.all(wait).then(values => {
      this.setState({
        errorSearch: false,
        disabledPrint: false,
        hideList: false,
         hidePrintAll: true,
         showHoursReportList: this.state.helpReportList
      });
      document.title =
        "Stundenberichte-Mitarbeiter" +
        "(" +
        this.state.month +
        "-" +
        this.state.years +
        ")";
      window.print();
      document.title = "Kosten-und Leistungsrechnung";
    });
  };
  readBvNameById = (id, bool) => {
    let found = this.state.bvAllInput.find(name => name.bvId === id);
    if (found === undefined) {
      this.readOldBvById(id);
      setTimeout(() => {
        this.setState({
          bv: this.state.bvAllFound
        });
      }, 200);
    }
    if (bool === true && found !== undefined) {
      return this.setState({
        bv: found.bv
      });
    } else if (bool === false && found !== undefined) {
      return found.bv;
    }

  };
  render() {
    return (
      <React.Fragment>
        <HoursReportingFormInputTop
          id={this.state.id}
          name={this.state.date}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          year={this.state.year}
          month={this.state.month}
          bvAll={this.state.bvAll}
          bvAllInput={this.state.bvAllInput}
          readOldBvById={this.readOldBvById}
          bvComplete={this.bvComplete}
          bvAllFound={this.state.bvAllFound}
        />
        <section id="showHoursReports" className="display-item content">
          <h4 className="no-print">Stundenbericht</h4>
          <div className={this.state.hidePrintAll ? "hide" : "print row"}>
            <div className="col-9">
              <h4>Stundenbericht</h4>
            </div>
            <div className="col-3">
              <img src={require("../img/logo.png")} alt="" />
            </div>
          </div>
          <div className="no-print row">
            <div className="col-2 offset-3">
              <select
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
              >
                <option key="-1" value="-">
                  Name
                </option>
                {this.state.employeesNames.map((names, i) => (
                  <option key={i} value={names.name}>
                    {names.lastName} {names.firstName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-1">
              <select
                name="years"
                value={this.state.years}
                onChange={this.handleChange}
              >
                <option key="-1" value="-">
                  Jahr
                </option>
                {this.state.yearsAll.map((year, i) => (
                  <option key={i} value={year.years}>
                    {year.years}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-2">
              <select
                name="month"
                value={this.state.month}
                onChange={this.handleChange}
              >
                <option key="-1" value="-">
                  Monat
                </option>
                {this.state.monthNames.map((month, i) => (
                  <option key={i} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-1 hoursreporting">
              <button
                type="button"
                onClick={this.onKeyPress}
                className="btn-add-item"
                disabled={this.state.hideSearch}
              >
                <FontAwesomeIcon icon="search" />
              </button>
            </div>
            <div className="col-1 hoursreporting">
              <button
                type="button"
                disabled={this.state.disablePrint}
                onClick={this.onKeyPressPrint}
                className="btn-add-item"
              >
                <FontAwesomeIcon icon="print" />
              </button>
            </div>
            <div className="col-1 hoursreporting">
              <button
                type="button"
                onClick={this.onKeyPressPrintAll}
                className="btn-add-item"
                disabled={this.state.hidePrintAllButton}
              >
                <FontAwesomeIcon icon="file-pdf" />
              </button>
            </div>
          </div>
          <div className={this.state.hidePrintAll ? "hide" : "print row"}>
            <div className="col-6">
              <h3>
                Name: {this.state.lastName} {this.state.firstName}
              </h3>
              <h3>
                Zeitraum: {this.state.month} {this.state.years}
              </h3>
              <br />
            </div>
            <div className="col-6 unterschrift">
              <hr />
              <p>Unterschrift Mitarbeiter</p>
            </div>
          </div>
          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          <ul className="print-font">
            <span className={this.state.hideSpan ? "row hide" : "row"}>
              <p className="col-2 max-150">
                <span>Datum:</span>
              </p>
              <p className="col-1">
                <span>Bezahlte h:</span>
              </p>
              <p className="col-1 max-85">
                <span>Beginn:</span>
              </p>
              <p className="col-1  max-85">
                <span>Ende:</span>
              </p>
              <p className="col-1 max-85">
                <span>Pause:</span>
              </p>
              <p className="col-1">
                <span>Fahrt-/Arbeitszeit:</span>
              </p>
              <p className="col-1 max-85">
                <span>Baustelle Id:</span>
              </p>
              <p className="col">
                <span>Baustelle:</span>
              </p>
              <p className="col-1  max-85">
                <span>Ergebnis:</span>
              </p>
            </span>
            {this.state.showHoursReport.map((item, idx) => {
              return (
                <ModalHoursReporting
                  onChange={this.handleChange}
                  key={idx}
                  id={item.id}
                  firstName={item.firstName}
                  lastName={item.lastName}
                  year={item.year}
                  month={item.month}
                  date={item.date}
                  payed={item.payed}
                  beginning={item.beginning}
                  end={item.end}
                  pause={item.pause}
                  driveTime={item.driveTime}
                  fahrtArbeit={item.fahrtArbeit}
                  bvId={item.bvId}
                  bv={this.readBvCompleteById(item.bvId,false)}
                  result={item.result}
                  item={item}
                  bvAll={[
                    ...new Set(
                      this.state.bvAll.concat({
                        bv: this.readBvCompleteById(item.itemBvId),
                        bvId: item.itemBvId
                      })
                    )
                  ]}
                  bvHelp={this.state.bvHelp}
                  searchBvComplete={this.searchBvComplete}
                  bvAllInput={this.state.bvAllInput}
                  readOldBvById={this.readOldBvById}
                  bvAllFound={this.state.bvAllFound}
                />
              );
            })}

            {this.state.showHoursReportList.map((item, idx) => {
               return (
                <HoursReportingList
                  key={idx}
                  id={item.id}
                  firstName={item[0].firstName}
                  lastName={item[0].lastName}
                  year={this.state.years}
                  month={this.state.month}
                  date={item.date}
                  payed={item.payed}
                  beginning={item.beginning}
                  end={item.end}
                  pause={item.pause}
                  driveTime={item.driveTime}
                  fahrtArbeit={item.fahrtArbeit}
                  bvId={item.bvId}
                  bv={item.bv}
                  result={item.result}
                  item={item}
                />
              );
            })}

            <span className={this.state.disablePrint ? "hide" : "show"}>
              <div className="offset-8 col-3 hoursComplete">
                <hr />
                <p>Gesamtstunden: </p>
                <hr />
              </div>
              <div className="col-1 hoursComplete">
                <hr />
                <p>{this.state.hoursComplete}</p>
                <hr />
              </div>
            </span>
          </ul>
        </section>
      </React.Fragment>
    );
  }
}
export default HoursReporting;
