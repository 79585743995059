import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalBody } from "reactstrap";
import { QRCodeCanvas } from "qrcode.react";
import {Buffer} from 'buffer';
 const IntranetUrl = process.env.NODE_ENV === 'development' ? 'https://localhost/' : 'https://intranet.seyband.com/';
class GenerateQrCode extends Component {
  constructor() {
    super();
    this.state = {
      employeesAll: [],
      firstName: "",
      lastName: "",
      employeesLength: 0,
     };
  }

  componentDidMount = () => {
    this.loadEmployees();
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true,
      });
    }
  };
  loadEmployees = () => {
    return fetch(BaseUrl + "api/employees/readEmployees.php")
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          return this.setState({
            employeesAll: responseJson.records,
            employeesLength: responseJson.records.length,
          });
        });
      })
      .catch((error) => {});
  };
  openQrModal = (lastName, firstName) => (e) => {
    e.preventDefault();

    this.setState({
      modal: true,
      firstName: firstName,
      lastName: lastName,
    });
  };
  toggle = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        modal: false,
      });
    } else {
      this.setState({
        modal: !this.state.modal,
      });
    }
  };
  createUID = () => {
     const MY_STRING =
      this.state.lastName.toLowerCase() +
      "_" +
      this.state.firstName.toLowerCase();  
  
    let link = IntranetUrl+"zeiterfassung/"+ Buffer.from(MY_STRING, 'utf8').toString('hex'); 
    console.log(link)
    return link;
  };
  makeTable = () => {
    let help = this.state.employeesLength;
    help = Math.round(help / 2);
    return help;
  };
  print = (e) => {
    document.title = "QR-Code " + this.state.lastName + " " + this.state.firstName;
    window.print();
        document.title = "Kosten- und Leistungsrechnung";

  };
  render() {
    return (
      <React.Fragment>
        <section id="qrCode" className="no-print">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--redBtn)" icon="qrcode" />{" "}
                Mitarbeiter QR-Code erstellen
              </h1>
            </div>
          </div>
          <form className="qrCode" id="resetTable" onSubmit={this.handleSubmit}>
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="row qr-code-row">
                <div className="col-md-6">
                  {this.state.employeesAll.map((item, ix) => {
                    if (ix < this.makeTable()) {
                      return (
                        <div key={ix} className="row">
                          <div className="col-md-9">
                            <p>
                              <b>{ix + 1}</b> | {item.lastName} {item.firstName}
                            </p>
                          </div>
                          <div className="col-md-3">
                            <button
                              onClick={this.openQrModal(
                                item.lastName,
                                item.firstName
                              )}
                            >
                              {" "}
                              <FontAwesomeIcon size="2x" icon="qrcode" />
                            </button>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="col-md-6">
                  {this.state.employeesAll.map((item, ix) => {
                    if (ix >= this.makeTable()) {
                      return (
                        <div key={ix} className="row">
                          <div className="col-md-9">
                            <p>
                              <b>{ix + 1}</b> | {item.lastName} {item.firstName}
                            </p>
                          </div>
                          <div className="col-md-3">
                            <button
                              onClick={this.openQrModal(
                                item.lastName,
                                item.firstName
                              )}
                            >
                              {" "}
                              <FontAwesomeIcon size="2x" icon="qrcode" />
                            </button>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </fieldset>
          </form>
        </section>
        <Modal
          className="modal-qr-code"
          isOpen={this.state.modal}
          toggle={() => this.toggle()}
        >
          <div className=" no-print">
            <div className="modal-header">
              <p>
                QR-Code für:{" "}
                <b>
                  <br />
                  {this.state.lastName} {this.state.firstName}
                </b>
              </p>

              <button className="close" onClick={this.toggle}>
                {" "}
                <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
              </button>
            </div>
          </div>
          <ModalBody>
            <div className="border-code">
              {this.state.modal ?  (
                              <QRCodeCanvas
                id={"code"}
                value={this.createUID()}
                size={300}
                level={"H"}
              />
              ) : ""}
 
              <h4 className="print">Seyband Trockenbau</h4>
              <h3 className="print">
                - {this.state.lastName} {this.state.firstName} -
              </h3>
            </div>
            <div className="no-print btn-modal row">
              <div className="col">
                <button onClick={this.toggle} className="btn-red">
                  Abbrechen <FontAwesomeIcon icon="times-circle" />
                </button>
              </div>
              <div className="col">
                <button onClick={this.print} className="btn-green">
                  Drucken <FontAwesomeIcon icon={["fas", "print"]} />
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default GenerateQrCode;
