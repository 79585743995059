import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
import { mwst } from "../global/units.js";
var $ = require("jquery");
var moment = require("moment-business-days");

class BillbookInputFormTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      rechnungsNr: this.props.lastRechnungsNr,
      rechnungsdatum: this.props.rechnungsdatum,
      bv: this.props.bv,
      bvNr: this.props.bvNr,
      rechnungsbetrag: this.props.rechnungsbetrag,
      skonto: this.props.skonto,
      betragSkonto: this.props.betragSkonto,
      steuersatz: this.props.steuersatz,
      betragEingang: this.props.betragEingang,
      betragEingangDatum: this.props.betragEingangDatum,
      zahlungsziel: this.props.zahlungsziel,
      sr_az: this.props.sr_az,
      azNr: this.props.azNr,
      zahlungsdatum: this.props.zahlungsdatum,
      zahlungsdatumVOB: this.props.zahlungsdatumVOB,
      mahnung1: this.props.mahnung1,
      mahnung2: this.props.mahnung2,
      mahnung3: this.props.mahnung3,
      bezahlt: this.props.bezahlt,
      search: this.props.search,
      billbookAll: [],
      errorSearch: false,
      errorInput: false,
      btnAddItem: true,
      useSearch: false,
      createdItem: [],
      searchRes: [],
      searchNewArray: [],
      searchArr: [],
      searchHelp: [],
      bvAll: this.props.bvComplete,
      isDisabled: true,
      isDisabledSRAZ: true,
      inputType: "text",
      customerName: "",
      customerId: "",
      hideModalBillbook: this.props.hideModalBillbook,
      lastRechnungsNr: this.props.lastRechnungsNr,
      rechnungsNrExists: false,
      bvComplete: this.props.bvComplete,
      modal: false,
      calcNettoModal: "",
      bruttoWertBerrechnen:"",
    };
  }
  componentDidMount = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true,
      });
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.value === "AZ") {
      this.setState({
        isDisabledSRAZ: false,
      });
    }
    if (e.target.value === "SR") {
      this.setState({
        isDisabledSRAZ: true,
        azNr: "",
      });
    }
    if (e.target.name === "customerName") {
      this.setState({
        bv: "",
        bvNr: "",
        bvAll: [],
      });
      this.readCustomerIdByName(e.target.value, true);
    }

    if (e.target.name === "customerName" && e.target.value === "-") {
      this.setState({
        customerId: "",
      });
    }
  };
  handleChangeCheck = (e) => {
    this.setState({
      bezahlt: !this.state.bezahlt,
    });
  };
  handleChangeBV = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "bv" && e.target.value === "-") {
      this.setState({
        bvNr: "",
      });
    }
    this.readBvIdByName(e.target.value, true);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let item = {
      rechnungsNr: this.state.rechnungsNr,
      rechnungsdatum: this.state.rechnungsdatum,
      customerId: this.state.customerId,
      customerName: this.state.customerName,
      bv: this.state.bv,
      bvNr: this.state.bvNr,
      rechnungsbetrag: this.state.rechnungsbetrag.replace(/,/g, "."),
      skonto: this.state.skonto.replace(/,/g, "."),
      betragSkonto: this.state.betragSkonto,
      steuersatz: this.state.steuersatz.toString().replace(/,/g,'.'),
      betragEingang: this.state.betragEingang,
      betragEingangDatum: this.state.betragEingangDatum,
      zahlungsziel: this.state.zahlungsziel,
      sr_az: this.state.sr_az,
      azNr: this.state.azNr,
      zahlungsdatum: this.state.zahlungsdatum,
      zahlungsdatumVOB: this.state.zahlungsdatumVOB,
      mahnung1: this.state.mahnung1,
      mahnung2: this.state.mahnung2,
      mahnung3: this.state.mahnung3,
      bezahlt: this.state.bezahlt,
    };
    $.ajax({
      url: BaseUrl + "api/billbook/createBillbook.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(item),
      success: function (response) {
        var parsedData = JSON.parse(response);
        if (parsedData.text !== "klappt") {
          this.setState({
            rechnungsNrExists: true,
            addToList: false,
          });
          setTimeout(() => {
            this.setState({
              rechnungsNrExists: false,
            });
          }, 5000);
        } else {
          this.setState({
            rechnungsNr: parseInt(this.state.rechnungsNr) + 1,
            rechnungsdatum: "",
            customerName: "",
            customerId: "",
            bv: "",
            bvNr: "",
            rechnungsbetrag: "",
            skonto: "",
            betragSkonto: "",
            steuersatz: "",
            calcNettoModal: "",
            bruttoWertBerrechnen:"",
            betragEingang: "",
            betragEingangDatum: "",
            zahlungsziel: "",
            sr_az: "",
            azNr: "",
            zahlungsdatum: "",
            zahlungsdatumVOB: "",
            mahnung1: "",
            mahnung2: "",
            mahnung3: "",
            bezahlt: "",
          });
          this.funcAddToList(item);
        }
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  funcAddToList = (item) => {
    this.props.loadLastBillbook({
      id: parseInt(this.props.billbookAll[0].id) + 1,
      rechnungsNr: item.rechnungsNr,
      rechnungsdatum: item.rechnungsdatum,
      customerId: item.customerId,
      bvNr: item.bvNr,
      rechnungsbetrag: item.rechnungsbetrag.replace(/,/g, "."),
      skonto: item.skonto.replace(/,/g, "."),
      betragSkonto: item.betragSkonto,
      betragEingang: item.betragEingang,
      steuersatz: item.steuersatz,
      betragEingangDatum: item.betragEingangDatum,
      zahlungsziel: item.zahlungsziel,
      sr_az: item.sr_az,
      azNr: item.azNr,
      zahlungsdatum: item.zahlungsdatum,
      zahlungsdatumVOB: item.zahlungsdatumVOB,
      mahnung1: item.mahnung1,
      mahnung2: item.mahnung2,
      mahnung3: item.mahnung3,
      bezahlt: item.bezahlt,
    });
  };

  autocompleteDate = (e) => {
    if (this.state.rechnungsdatum === "") {
    } else {
      if (e.keyCode === 9) {
        var vobDays = 0;
        if (this.state.sr_az === "SR") {
          vobDays = 31;
        }
        if (this.state.sr_az === "AZ") {
          vobDays = 22;
        }
        let calcDate = moment(this.state.rechnungsdatum, "YYYY-MM-DD")
          .businessAdd(this.state.zahlungsziel)
          .format("YYYY-MM-DD");
        let calcDateVOB = moment(this.state.rechnungsdatum, "YYYY-MM-DD")
          .businessAdd(vobDays)
          .format("YYYY-MM-DD");
        this.setState({
          zahlungsdatum: calcDate,
          zahlungsdatumVOB: calcDateVOB,
        });
      }
    }
  };
  handleSearch = (e) => {
    this.setState({
      search: e.target.value,
    });

    if (e.target.value === "") {
      const reset = this.state.showBillbook.map((index) => {
        return this.setState({
          showBillbook: [],
        });
      });

      Promise.all(reset).then((values) => {
        this.setState({
          showBillbook: this.state.showBillbookCopy,
          errorSearch: false,
        });
      });
    }
  };
  autocompleteSkonto = (e) => {
    if (e.keyCode === 9) {
      this.calcSkonto();
    }
  };
  calcSkonto = () => {
    let price = this.state.rechnungsbetrag.replace(",", ".");
    if (this.state.rechnungsbetrag === "") {
      price = 0;
    }
    let sk = this.state.skonto.replace(",", ".");
    let erg = (parseFloat(price) - parseFloat((price * sk) / 100)).toFixed(2);
    this.setState({
      betragSkonto: erg,
    });
  };
 
  autocomplete = (e) => {
    if (e.keyCode === 9 && e.target.name === "customerId") {
      this.setState({
        bv: "",
        bvNr: "",
        bvAll: [],
      });
      this.readCustomerNameById(e.target.value, true);
    }
    if (e.keyCode === 9 && e.target.name === "bvNr") {
      this.readBvNameById(e.target.value, true);
    }
  };

  readBvCompleteById = (id) => {
    let foundComplete = this.state.bvComplete.find(
      (name) => name.bvId === parseInt(id)
    );
    if (foundComplete.bv === undefined) {
      return "";
    } else {
      this.state.bvAll.concat({ bv: foundComplete.bv, bvId: id });
      return foundComplete.bv;
    }
  };
  readBvNameById = (id, bool) => {
    let found = this.state.bvAll.find((name) => name.bvId === parseInt(id));
    if (found === undefined) {
      this.readBvCompleteById(id);
      setTimeout(() => {
        this.setState({
          bv: this.props.bvAllFound,
        });
      }, 200);
    }
    if (bool === true && found !== undefined) {
      this.setState({
        bv: found.bv,
      });
    } else if (bool === false && found !== undefined) {
      return found.bv;
    }
  };
  readBvIdByName = (name, bool) => {
    let found = this.state.bvAll.find((id) => id.bv === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        bvNr: found.bvId,
      });
    } else {
      return found.bvId;
    }
  };
  readCustomerNameById = (id, bool) => {
    let found = this.props.customerAll.find((name) => name.customerId === id);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      this.getBv(id);

      return this.setState({
        customerName: found.customerName,
      });
    } else {
      return found.customerName;
    }
  };
  readCustomerIdByName = (name, bool) => {
    let found = this.props.customerAll.find((id) => id.customerName === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      this.getBv(parseInt(found.customerId));

      return this.setState({
        customerId: found.customerId,
      });
    } else {
      return found.customerId;
    }
  };
  getBv = (id) => {
    this.state.bvAll = [];
    return fetch(BaseUrl + "api/billbook/searchBV.php?id=" + id)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "No items found.") {
          return this.setState({
            isDisabled: true,
          });
        } else {
          return this.setState({
            bvAll: responseJson.records[0],
            isDisabled: false,
          });
        }
      })
      .catch((error) => {});
  };
  toggle = (e) => {
    e.preventDefault();
    this.setState({
      modal: !this.state.modal,
    });
  };
  calcNetto = (e) => {
    if (e.keyCode === 9) {
      let netto = 0;
      netto = parseFloat(this.state.bruttoWertBerrechnen.replace(',', '.')) / (1 + e.target.value.replace(',', '.') / 100);
      this.setState({
        calcNettoModal: netto.toFixed(2),
      });
    }
  };
  writeCalcNetto = () => {
    this.setState({
      betragEingang: this.state.calcNettoModal,
      modal: false
    });
  };
  render() {
    return (
      <React.Fragment>
        <section id="insertItem" className="no-print">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--greenSeyband)" icon="book" />{" "}
                Rechnungsbuch
              </h1>
            </div>
          </div>
          <h4>Rechnung Hinzufügen</h4>
          <form onSubmit={this.handleSubmit} className="billsbook">
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="row">
                <div className="col-md-3">
                  <p>Rechnungs-Nr</p>
                  <input
                    required
                    type="text"
                    name="rechnungsNr"
                    onChange={this.handleChange}
                    value={this.state.rechnungsNr}
                  />
                </div>
                <div className="col-md-3">
                  <p>Rechnungsdatum</p>
                  <input
                    required
                    name="rechnungsdatum"
                    onChange={this.handleChange}
                    value={this.state.rechnungsdatum}
                    type="date"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-2 tab">
                  <p>Kunden-Id</p>
                  <input
                    required
                    type="text"
                    name="customerId"
                    placeholder="-"
                    onChange={this.handleChange}
                    value={this.state.customerId}
                    onKeyDown={this.autocomplete}
                  />
                  <span>↹</span>
                </div>
                <div className="col-md-4">
                  <p>Kunde</p>
                  <select
                    name="customerName"
                    value={this.state.customerName}
                    onChange={this.handleChange}
                  >
                    <option value="-">-</option>
                    {this.props.customerAll.map((customer, i) => (
                      <option key={i} value={customer.customerName}>
                        {customer.customerName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-2 tab">
                  <p>Bv-Nr</p>
                  <input
                    required
                    type="text"
                    name="bvNr"
                    placeholder="0"
                    onChange={this.handleChange}
                    value={this.state.bvNr}
                    onKeyDown={this.autocomplete}
                  />
                  <span>↹</span>
                </div>
                <div className="col-md-3">
                  <p>Bv</p>
                  <select
                    name="bv"
                    value={this.state.bv}
                    onChange={this.handleChangeBV}
                  >
                    <option value="-">-</option>
                    {this.state.bvAll.map((bv, i) => (
                      <option key={i} value={bv.bv}>
                        {bv.bv}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-3 tab">
                  <p>Rechnungsbetrag</p>
                  <input
                    required
                    type="text"
                    name="rechnungsbetrag"
                    onChange={this.handleChange}
                    value={this.state.rechnungsbetrag}
                  />
                  <span>↹</span>
                </div>
                <div className="tab col-md-1">
                  <p>Skonto</p>
                  <input
                    type="text"
                    name="skonto"
                    onChange={this.handleChange}
                    value={this.state.skonto}
                    onKeyDown={this.autocompleteSkonto}
                  />
                  <span>↹</span>
                </div>
                <div className="col ">
                  <p>Betrag skontiert</p>
                  <input
                    type="text"
                    name="betragSkonto"
                    onChange={this.handleChange}
                    value={this.state.betragSkonto}
                  />
                </div>

                <div className="col ">
                  <p>Betrag eingegangen in € (Netto)</p>
                  <input
                    type="text"
                    name="betragEingang"
                    onChange={this.handleChange}
                    value={this.state.betragEingang}
                  />
                </div>
                <div className="col-1 pos-rel fs-75">
                  <button onClick={this.toggle} className="btn-create-item">
                  <FontAwesomeIcon icon="calculator" />
                  </button>
                  <Modal className="modal-netto-brutto" isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalBody>
                      <div className="row">
                        <h3>Nettobetrag berechnen</h3>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p>Bruttowert</p>
                          <input
                            type="text"
                            value={this.state.bruttoWertBerrechnen}
                            name="bruttoWertBerrechnen"
                            onChange={this.handleChange}
                          />
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-12 tab">
                          <p>Steuersatz</p>
                          <input
                            type="text"
                            name="steuersatz"
                            value={this.state.steuersatz}
                            onChange={this.handleChange}
                            onKeyDown={this.calcNetto}
                          />
                                <span>↹</span>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-12">
                          <p>Ergebnis</p>
                          <input
                          readOnly
                          disabled
                            type="text"
                            name="steuersatz"
                            value={this.state.calcNettoModal}
                          />
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-12">
                        <button
                          className="btn-create-item"
                          onClick={this.writeCalcNetto}
                        >
                          Übernehmen
                        </button>
                        </div>
                      </div>
 
                    </ModalBody>
                  </Modal>
                </div>
              </div>
              <div className="row">
                <div className="col-3 ">
                  <p>Betrag Eingegangen Datum</p>
                  <input
                    type="date"
                    name="betragEingangDatum"
                    onChange={this.handleChange}
                    value={this.state.betragEingangDatum}
                  />
                </div>
                <div className="tab col-md-1">
                  <p>Zahlungsziel Tage</p>
                  <input
                    required
                    type="text"
                    name="zahlungsziel"
                    onChange={this.handleChange}
                    value={this.state.zahlungsziel}
                    onKeyDown={this.autocompleteDate}
                  />
                  <span>↹</span>
                </div>
                <div className="col-md-1">
                  <select
                    required
                    name="sr_az"
                    onChange={this.handleChange}
                    value={this.state.sr_az}
                  >
                    <option value="SR">SR</option>
                    <option value="AZ">AZ</option>
                  </select>
                </div>
                <div className="col-md-1">
                  <p>AZ-Nr</p>
                  <input
                    type="text"
                    name="azNr"
                    disabled={this.state.isDisabledSRAZ}
                    onChange={this.handleChange}
                    value={this.state.azNr}
                  />
                </div>
                <div className="col-md-3">
                  <p>Zahlungsdatum</p>
                  <input
                    required
                    type="date"
                    name="zahlungsdatum"
                    onChange={this.handleChange}
                    value={this.state.zahlungsdatum}
                  />
                </div>
                <div className="col-md-3">
                  <p>Zahlungsdatum VOB</p>
                  <input
                    type="date"
                    name="zahlungsdatumVOB"
                    onChange={this.handleChange}
                    value={this.state.zahlungsdatumVOB}
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-3">
                  <p>Mahung 1</p>
                  <input
                    type="date"
                    name="mahnung1"
                    onChange={this.handleChange}
                    value={this.state.mahnung1}
                  />
                </div>
                <div className="col-md-3">
                  <p>Mahung 2</p>
                  <input
                    type="date"
                    name="mahnung2"
                    onChange={this.handleChange}
                    value={this.state.mahnung2}
                  />
                </div>
                <div className="col-md-3">
                  <p>Mahung 3</p>
                  <input
                    type="date"
                    name="mahnung3"
                    onChange={this.handleChange}
                    value={this.state.mahnung3}
                  />
                </div>
                <div className="col-md-2 inline">
                  <input
                    type="checkbox"
                    name="bezahlt"
                    id="done"
                    placeholder="Fertig"
                    onChange={this.handleChangeCheck}
                    checked={this.state.bezahlt}
                  />
                  <label>Bezahlt</label>
                </div>
              </div>
              <div className="row">
                <div className="offset-9 col-md-3">
                  <button className="btn-create-item">
                    Rechnung speichern <FontAwesomeIcon icon="save" />
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
          <p className={this.state.errorInput ? "show errorInput" : "hide"}>
            Bitte Bezeichnung ausfüllen
          </p>
        </section>
        <div
          className={
            this.state.rechnungsNrExists
              ? "errorBvIdExists"
              : "hideExist errorBvIdExists"
          }
        >
          <FontAwesomeIcon icon="times-circle" />
          <br />
          RechnungsNr. existiert bereits
        </div>
      </React.Fragment>
    );
  }
}
export default BillbookInputFormTop;
