import React, { Component } from "react";
import ModalSpecificationList from "./ModalSpecificationList.js";
import SpecificationListFormInputTop from "./SpecificationListFormInputTop.js";
 import "@fortawesome/fontawesome-free-solid";
 
import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
var $ = require("jquery");

class SpecificationList extends Component {
  constructor() {
    super();
    this.state = {
      bv: "",
      bvId: "",
      customerId: "",
      customer: "",
      bvAll: [],
      sl: [],
      slCopy: [],
      errorSearch: false,
      search: "",
      useSearch: false,
      supplierAll: [],
      supplier: "",
      supplierId: "",
      hideFields: true,
      showEmpty: false,
      hideSearch: true,
      sl: [],
      bvComplete:[],
      bvAllFound:""
    };
  }

  componentDidMount = () => {
    this.loadBvNotDone();
    this.readBvComplete();
    this.readsuppliers();
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if (e.target.name === "supplier") {
      this.readSupplierId(e.target.value);
    }
    if (e.target.name === "supplier" && e.target.value === "-") {
      this.setState({
        supplierId: ""
      });
    }
  };
  handleChangeArtGroup = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  loadBvNotDone = () => {
    return fetch(BaseUrl + "api/all/readBVNotDone.php")
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          bvAll: responseJson.records[0]
        });
      })
      .catch(error => {});
  };
  readOldBvById = id => {
    let foundComplete = this.state.bvComplete.find(name => name.bvId === id);
    if (foundComplete.bv === undefined) {
      return "";
    } else {
      this.setState({
        bvAll: this.state.bvAll.concat({ bv: foundComplete.bv, bvId: id }),
        bvAllFound: foundComplete.bv
      });
    }
  };
  readBvComplete = () => {
    return fetch(BaseUrl + "api/all/readBvComplete.php")
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          bvComplete: responseJson.records[0]
        });
      })
      .catch(error => {});
  };
  onKeyPress = e => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };
  handleSearch = e => {
    this.setState({
      search: e.target.value
    });

    if (e.target.value === "") {
      this.setState({
        sl: this.state.slCopy,
        errorSearch: false
      });
    }
  };

  search = eingabe => {
    let convertEingabe = eingabe.replace(/ /g, "%").replace("+", "%2B");

    if (eingabe === "") {
      this.setState({
        sl: this.state.slCopy,
        errorSearch: false
      });
    } else {
      this.setState({
        sl: []
      });
      return fetch(
        BaseUrl +
          "api/specificationList/searchSpecificationList.php?eingabe=" +
          convertEingabe
      )
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.message === "No items found.") {
            this.setState({
              sl: [],
              errorSearch: true
            });
          } else {
            this.setState({
              errorSearch: false,
              sl: responseJson.records
            });
          }
        })
        .catch(error => {});
    }
  };

  readsuppliers = () => {
    return fetch(BaseUrl + "api/all/readSupplierNames.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return (this.state.supplierAll = this.state.supplierAll.concat(item));
        });
        this.setState({
          supplierAll: this.state.supplierAll
        });
      })
      .catch(error => {});
  };
  readSupplierId = eingabe => {
    return fetch(BaseUrl + "api/all/readSupplierId.php?eingabe=" + eingabe)
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            supplierId: item.supplierId
          });
        });
      })
      .catch(error => {});
  };
  getSupplier = evt => {
    const convertInput = evt.target.value.replace(/ /g, " ");
    return fetch(BaseUrl + "api/all/readSupplier.php?eingabe=" + convertInput)
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            supplier: item.supplierName
          });
        });
      })
      .catch(error => {});
  };
  loadSpecificationList = bvId => {
    return fetch(
      BaseUrl + "api/specificationList/loadSpecificationList.php?bvId=" + bvId
    )
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.message === "No items found.") {
          this.setState({
            sl: [],
            hideFields: false,
            showEmpty: true,
            hideSearch: true
          });
        } else {
          this.setState({
            hideFields: false,
            showEmpty: false,
            hideSearch: false,
            sl: responseJson.records,
            slCopy: responseJson.records
          });
        }
      })
      .catch(error => {});
  };
  loadLastSl = (bvId, lastEntrys) => {

    if(this.state.sl.length !== 0){

    return fetch(
      BaseUrl +
        "api/specificationList/loadLastSpecificationList.php?bvId=" +
        bvId +
        "&lastEntrys=" +
        lastEntrys
    )
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map((item, i) => {
          this.state.sl.unshift(responseJson.records[i]);
        });
        return this.setState({
          sl: this.state.sl
        });
      })
      .catch(error => {});
    }
    else{
      this.loadSpecificationList(bvId);
    }
  };
  autocompleteBvSupplier = e => {
    if (e.keyCode === 9 && e.target.name === "supplierId") {
      this.getSupplier(e);
    }
  };
  resetSL = () => {
    this.setState({
      sl: [],
      slCopy: [],
      hideFields: true,
      hideSearch: true
    });
  };
  render() {
    return (
      <React.Fragment>
        <SpecificationListFormInputTop
          bv={this.state.bv}
          bvId={this.state.bvId}
          bvAll={this.state.bvAll}
          loadSpecificationList={this.loadSpecificationList}
          hideFields={this.state.hideFields}
          resetSL={this.resetSL}
          loadLastSl={this.loadLastSl}
          readOldBvById={this.readOldBvById}
          bvAllFound={this.state.bvAllFound}
        />

        <section
          id="showItems"
          className={this.state.hideSearch ? "hide" : "show display-item"}
        >
          <h4>Übersicht Artikel</h4>
          <div className="show-items-btn row">
            <div className="col-12 offset-6">
              <input
                className="search"
                type="text"
                name="search"
                placeholder="Suchen"
                onChange={this.handleSearch}
                onKeyPress={this.onKeyPress}
                value={this.state.search}
              />
            </div>
          </div>
          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          <ul className="items-list">
            {this.state.sl.map(item => {
              return (
                <ModalSpecificationList
                  key={item.id}
                  id={item.id}
                  pos={item.pos}
                  dsc={item.dsc}
                  unit={item.unit}
                  pricePiece={item.pricePiece}
                  targetQuantity={item.targetQuantity}
                  search={this.state.search}
                />
              );
            })}
          </ul>
        </section>
        <section className={this.state.showEmpty ? "show errorEmpty" : "hide"}>
          <p>Keine Daten für das Bauvorhaben vorhanden.</p>
        </section>
      </React.Fragment>
    );
  }
}

export default SpecificationList;
