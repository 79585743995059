import React, { Component } from "react";
import ModalCustomer from "../customer/ModalCustomer";
import CustomerFormInputTop from "../customer/CustomerFormInputTop";
import BaseUrl from "../config/config.js";
import "@fortawesome/fontawesome-free-solid";

class Customer extends Component {
  constructor() {
    super();
    this.state = {
      customerId: "",
      customerName: "",
      street: "",
      zipCode: "",
      city: "",
      errorSearch: false,
      errorInput: false,
      search: "",
      btnAddItem: true,
      useSearch: false,
      showCustomer: [],
      showCustomerCopy: [],
      cpAll: [],
      searchArr: [],
      showMissed: false
    };
  }

  componentDidMount = () => {
    this.loadItems();
   };
   sortBySupplierId = array => {
     array.sort((a, b) => {
       let x = a.customerId;
       let y = b.customerId;
       if (x > y) {
         return -1;
       }
       if (x < y) {
         return 1;
       }
       return 0;
     });
     return array;
   };
  loadItems = () => {
    let helpFunc = [];


          return fetch(
            BaseUrl + "api/customer/readCustomerPerCustomerId.php")
            .then(response => response.json())
            .then(responseJson => {
              responseJson.records.map((item, sidx) => {
                Object.keys(item).map((customerId, idx) => {
                  const article = item[customerId].map((i, ix) => {
                    return {
                  key: sidx,
                  customerId: i.customerId,
                  id: i.id,
                  cpName: i.cpName,
                  cpTel: i.cpTel,
                  cpMail: i.cpMail,
                 };
              });
              const notes = item[customerId].slice(0, 1).map((i, ix) => {
                return {
                    key: sidx,
                    customerId: i.customerId,
                    id: i.id,
                    customerName: i.customerName,
                    street: i.street,
                    zipCode: i.zipCode,
                    city: i.city,
                    cpAll: article
                  };
                });

              return helpFunc = [...helpFunc, ...notes];
            });
            return this;
          });
          this.setState({
            showCustomerCopy: this.sortBySupplierId(helpFunc),
            showCustomer: this.sortBySupplierId(helpFunc)
          });
        });
    };
  readLastCustomer = () => {
    let helpFunc = [];

    return fetch(BaseUrl + "api/customer/readLastCustomer.php ")
      .then(response => response.json())
      .then(responseJson => {
        const article = responseJson.records.map((item, sidx) => {
          return {
            key: sidx,
            customerId: item.customerId,
            id: item.id,
            cpName: item.cpName,
            cpTel: item.cpTel,
            cpMail: item.cpMail,
          };
        });
        const notes = responseJson.records.slice(0, 1).map((item, sidx) => {
          return {
            key: sidx,
            customerId: item.customerId,
            id: item.id,
            customerName: item.customerName,
            street: item.street,
            zipCode: item.zipCode,
            city: item.city,
            cpAll: article
          };
        });
        helpFunc = [...this.state.showCustomer, ...notes];
        Promise.all(notes).then(values => {
          helpFunc.sort((a, b) => {
            let x = a.customerId
              .split(".")
              .reverse()
              .join();
            let y = b.customerId
              .split(".")
              .reverse()
              .join();
            if (x > y) {
              return -1;
            }
            if (x < y) {
              return 1;
            }
            return 0;
          });
        });
        const sorted = this.state.showCustomer.map(index => {
          return this.setState({
            showCustomer: []
          });
        });

        Promise.all(sorted).then(values => {
          this.setState({
            showCustomer: helpFunc,
            showCustomerCopy: helpFunc
          });
        });
      });
  };

  onKeyPress = e => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };

  search = search => {
    let searchHelpFunc = [];

    let convertEingabe = search.replace("+", "%2B");
    fetch(
      BaseUrl + "api/customer/searchCustomer.php?eingabe=" +
        convertEingabe
    )
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.message === "No items found.") {
          this.setState({
            showCustomer: [],
            errorSearch: true
          });
        } else {
          responseJson.records.map((item, sidx) => {
            return true;
          });

          this.setState({
            showCustomer: [],
            searchArr: responseJson.records,
            errorSearch: false
          });
        }
        const query = this.state.searchArr.map((item, idx) => {
          return fetch(
            BaseUrl + "api/customer/searchCustomerPerNr.php?nr=" +
              item.customerId +
              "&eingabe=" +
              convertEingabe
          )
            .then(response => response.json())
            .then(responseJson => {
              const searchArticle = responseJson.records.map((item, sidx) => {
                return {
                  key: sidx,
                  customerId: item.customerId,
                  id: item.id,
                  cpName: item.cpName,
                  cpTel: item.cpTel,
                  cpMail: item.cpMail,
                 };
              });
              const searchNotes = responseJson.records
                .slice(0, 1)
                .map((item, sidx) => {
                  return {
                    key: sidx,
                    customerId: item.customerId,
                    id: item.id,
                    customerName: item.customerName,
                    street: item.street,
                    zipCode: item.zipCode,
                    city: item.city,
                    cpAll: searchArticle
                  };
                });

              searchHelpFunc = [...searchHelpFunc, ...searchNotes];
            });
        });
        Promise.all(query).then(values => {
          searchHelpFunc.sort((a, b) => {
            let x = a.customerId
              .split(".")
              .reverse()
              .join();
            let y = b.customerId
              .split(".")
              .reverse()
              .join();
            if (x > y) {
              return -1;
            }
            if (x < y) {
              return 1;
            }
            return 0;
          });
          this.setState({
            showCustomer: searchHelpFunc
          });
        });
      });
  };
  handleSearch = e => {
    this.setState({
      search: e.target.value
    });

    if (e.target.value === "") {
      const reset = this.state.showCustomer.map(index => {
        return this.setState({
          showCustomer: []
        });
      });

      Promise.all(reset).then(values => {
        this.setState({
          showCustomer: this.state.showCustomerCopy,
          errorSearch: false
        });
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <CustomerFormInputTop
          id={this.state.id}
          customerId={this.state.customerId}
          customerName={this.state.customerName}
          street={this.state.street}
          zipCode={this.state.zipCode}
          city={this.state.city}
          cpAll={this.state.cpAll}
          search={this.state.search}
          handleSearch={this.handleSearch}
          onKeyPress={this.onKeyPress}
          readLastCustomer={this.readLastCustomer}
        />
        <section id="showCustomers" className="display-item">
          <h4>Übersicht Kunden</h4>
          <div className="show-items-btn row">
            <div className="col-12 offset-6">
              <input
                className="search"
                type="text"
                name="search"
                placeholder="Suchen"
                onChange={this.handleSearch}
                onKeyPress={this.onKeyPress}
                value={this.state.search}
              />
            </div>
          </div>
          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          <ul className="notes-list">
            {this.state.showCustomer.map((item, idx) => {
              return (
                <ModalCustomer
                  onChange={this.handleChange}
                  key={idx}
                  id={item.id}
                  customerId={item.customerId}
                  customerName={item.customerName}
                  customerIdOld={item.customerIdOld}
                  street={item.street}
                  zipCode={item.zipCode}
                  city={item.city}
                  cpAll={item.cpAll}
                  search={this.state.search}
                />
              );
            })}
          </ul>
        </section>
      </React.Fragment>
    );
  }
}
export default Customer;
