import React, { Component } from "react";
import CalcDataModal from "./CalcDataModal.js";
 import "@fortawesome/fontawesome-free-solid";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
var $ = require("jquery");

class CalcData extends Component {
  constructor() {
    super();
    this.state = {
      jahr: "",
      arbeitstage:"",
      stunden_tag:"",
      stunden_jahr:"",
      rentenvers: "",
      arbeitslosenvers: "",
      krankenvers: "",
      pflegevers: "",
      insolvenzgeldumlage:"",
      umlage_erstattung:"",
      krankheitstage:"",
      krankheitstageProzent:"",
      bezFeiertage:"",
      bgHauptumlage:"",
      bgLastenverteilung:"",
      soka:"",
      kosten_h:"",
      gk:"",
      sk_h:"",
      gewinn:"",
      angebotspreis:"",
      itemsAll: [],
      itemsAllCopy: [],
      errorSearch: false,
      errorInput: false,
      search: "",
       useSearch: false,
       disabledFieldset: false
    };
  }

  componentDidMount = () => {
    this.loadCalcData();
       if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
        this.setState({
          disabledFieldset: true
        });
      }
   };
   handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      errorInput: false
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.dsc === "") {
      this.setState({
        errorInput: true
      });
    } else {
      this.setState({
        errorInput: false
      });
      let item = {
        jahr: this.state.jahr.replace(/,/g, "."),
        arbeitstage: this.state.arbeitstage.replace(/,/g, "."),
        stunden_tag: this.state.stunden_tag.replace(/,/g, "."),
        stunden_jahr: this.state.stunden_jahr.replace(/,/g, "."),
        rentenvers: this.state.rentenvers.replace(/,/g, "."),
        arbeitslosenvers: this.state.arbeitslosenvers.replace(/,/g, "."),
        krankenvers: this.state.krankenvers.replace(/,/g, "."),
        pflegevers: this.state.pflegevers.replace(/,/g, "."),
        insolvenzgeldumlage: this.state.insolvenzgeldumlage.replace(/,/g, "."),
        umlage_erstattung: this.state.umlage_erstattung.replace(/,/g, "."),
        krankheitstage: this.state.krankheitstage.replace(/,/g, "."),
        krankheitstageProzent: this.state.krankheitstageProzent.replace(/,/g, "."),
        bezFeiertage: this.state.bezFeiertage.replace(/,/g, "."),
        bgHauptumlage: this.state.bgHauptumlage.replace(/,/g, "."),
        bgLastenverteilung:this.state.bgLastenverteilung.replace(/,/g, "."),
        soka: this.state.soka.replace(/,/g, "."),
        kosten_h: this.state.kosten_h.replace(/,/g, "."),
        gk: this.state.gk.replace(/,/g, "."),
        sk_h: this.state.sk_h.replace(/,/g, "."),
        gewinn: this.state.gewinn.replace(/,/g, "."),
        angebotspreis: this.state.angebotspreis.replace(/,/g, ".")
      };

      $.ajax({
        url: BaseUrl + "api/calcHourlyWage/createCalcData.php",
        type: "POST",
        dataType: "text",
        data: JSON.stringify(item),
        success: function(response) {
          this.setState({
            jahr: "",
            arbeitstage:"",
            stunden_tag:"",
            stunden_jahr:"",
            rentenvers: "",
            arbeitslosenvers: "",
            krankenvers: "",
            pflegevers: "",
            insolvenzgeldumlage:"",
            umlage_erstattung:"",
            krankheitstage:"",
            krankheitstageProzent:"",
            bezFeiertage:"",
            bgHauptumlage:"",
            bgLastenverteilung:"",
            soka:"",
            kosten_h:"",
            gk:"",
            sk_h:"",
            gewinn:"",
            angebotspreis:"",
          });
        }.bind(this),
        error: function(xhr, resp, text) {}
      });
      return fetch(BaseUrl + "api/calcHourlyWage/readLastCalcData.php")
        .then(response => response.json())
        .then(responseJson => {
          responseJson.records.map(item => {
            this.state.itemsAll.unshift(responseJson.records[0]);
            return this.setState({
              itemsAll: this.state.itemsAll
            });
          });
        })
        .catch(error => {});
    }
  };
  loadCalcData = () => {
    return fetch(BaseUrl + "api/calcHourlyWage/readCalcData.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            itemsAll: responseJson.records,
            itemsAllCopy: responseJson.records
          });
        });
      })
      .catch(error => {});
  };
  readLastCalcData = e => {

    e.preventDefault();
    fetch(BaseUrl + "api/calcHourlyWage/readLastCalcData.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {

            return this.setState({
            jahr: item.jahr,
            arbeitstage: item.arbeitstage,
            stunden_tag: item.stunden_tag,
            stunden_jahr: item.stunden_jahr,
            rentenvers: item.rentenvers,
            arbeitslosenvers: item.arbeitslosenvers,
            krankenvers: item.krankenvers,
            pflegevers: item.pflegevers,
            insolvenzgeldumlage: item.insolvenzgeldumlage,
            umlage_erstattung: item.umlage_erstattung,
            krankheitstage: item.krankheitstage,
            krankheitstageProzent: item.krankheitstageProzent,
            bezFeiertage: item.bezFeiertage,
            bgHauptumlage: item.bgHauptumlage,
            bgLastenverteilung:item.bgLastenverteilung,
            soka: item.soka,
            kosten_h: item.kosten_h,
            gk: item.gk,
            sk_h: item.sk_h,
            gewinn: item.gewinn,
            angebotspreis: item.angebotspreis
          });
          });
      })
      .catch(error => {});
  }

  render() {
    return (
      <React.Fragment>
        <section id="insertCalcHourlyWage">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home/stundenkalkulation">
                <FontAwesomeIcon icon="arrow-left" /> Zurück
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--redBtn)" icon="database" />{" "}
                Stundenkalkulation Stammdaten
              </h1>
            </div>
          </div>
          <h4>Stammdaten Hinzufügen</h4>
          <form onSubmit={this.handleSubmit}>
          <fieldset disabled={this.state.disabledFieldset}>

            <div className="row calcData">
              <label className="col-6"><span>Berechnungsjahr:</span>
                <input
                required
                  type="text"
                  name="jahr"
                  placeholder="z.b. 2018"
                  onChange={this.handleChange}
                  value={this.state.jahr}
                />
                </label>
                <label className="col-6"><span>
                Arbeitstage:</span>
                 <input
                 required
                  type="text"
                  name="arbeitstage"
                  placeholder="z.B. 220"
                  onChange={this.handleChange}
                  value={this.state.arbeitstage}
                />
                </label>
                <label className="col-6"><span>
                Stunden pro Tag:</span>
                 <input
                 required
                  type="text"
                  name="stunden_tag"
                  placeholder="z.B. 7,5"
                  onChange={this.handleChange}
                  value={this.state.stunden_tag}
                />
                </label>
                <label className="col-6"><span>
                Stunden pro Jahr:</span>
                 <input required
                  type="text"
                  name="stunden_jahr"
                  placeholder="z.B. 1650"
                  onChange={this.handleChange}
                  value={this.state.stunden_jahr}
                />
                </label>
                <label className="col-6"><span>
                Rentenversicherung:</span>
                 <input required
                  type="text"
                  name="rentenvers"
                  placeholder="00,00 %"
                  onChange={this.handleChange}
                  value={this.state.rentenvers}
                />
                </label>
                <label className="col-6"><span>
                Arbeitslosenversicherung:</span>
                <input required
                  type="text"
                  name="arbeitslosenvers"
                  placeholder="00,00%"
                  onChange={this.handleChange}
                  value={this.state.arbeitslosenvers}
                />
                </label>
                <label className="col-6"><span>
                  Krankenversicherung:</span>
                <input required
                  type="text"
                  name="krankenvers"
                  placeholder="00,00%"
                  onChange={this.handleChange}
                  value={this.state.krankenvers}
                />
                </label>
                <label className="col-6"><span>
                Pflegeversicherung:</span>
                <input required
                  type="text"
                  name="pflegevers"
                  placeholder="00,00%"
                  onChange={this.handleChange}
                  value={this.state.pflegevers}
                />
                </label>
                <label className="col-6"><span>
                Insolvenzgeldumlage:</span>
                <input required
                  type="text"
                  name="insolvenzgeldumlage"
                  placeholder="00,00%"
                  onChange={this.handleChange}
                  value={this.state.insolvenzgeldumlage}
                />
                </label>
                <label className="col-6"><span>
                Umlage- und Erstattungssätze:</span>
                <input required
                  type="text"
                  name="umlage_erstattung"
                  placeholder="00,00%"
                  onChange={this.handleChange}
                  value={this.state.umlage_erstattung}
                />
                </label>
                <label className="col-6"><span>
                Anzahl Krankheitstage:</span>
                <input required
                  type="text"
                  name="krankheitstage"
                  placeholder="z.B. 3"
                  onChange={this.handleChange}
                  value={this.state.krankheitstage}
                />
                </label>
                <label className="col-6"><span>
                Krankheitstage %:</span>
                <input required
                  type="text"
                  name="krankheitstageProzent"
                  placeholder="00,00%"
                  onChange={this.handleChange}
                  value={this.state.krankheitstageProzent}
                />
                </label>
                <label className="col-6"><span>
                bezFeiertage:</span>
                <input required
                  type="text"
                  name="bezFeiertage"
                  placeholder="z.B. 13"
                  onChange={this.handleChange}
                  value={this.state.bezFeiertage}
                />
                </label>
                <label className="col-6"><span>
                BG-Hauptumlage:</span>
                <input required
                  type="text"
                  name="bgHauptumlage"
                  placeholder="00,00%"
                  onChange={this.handleChange}
                  value={this.state.bgHauptumlage}
                />
                </label>
                <label className="col-6"><span>
                BG-Lastenverteilung:</span>
                <input required
                  type="text"
                  name="bgLastenverteilung"
                  placeholder="00,00%"
                  onChange={this.handleChange}
                  value={this.state.bgLastenverteilung}
                />
                </label>
                <label className="col-6"><span>
                Sozialkasse:</span>
                <input required
                  type="text"
                  name="soka"
                  placeholder="00,00%"
                  onChange={this.handleChange}
                  value={this.state.soka}
                />
                </label>
                <label className="col-6"><span>
                Kosten pro Stunde:</span>
                <input
                  type="text"
                  name="kosten_h"
                  placeholder="00,00 €"
                  onChange={this.handleChange}
                  value={this.state.kosten_h}
                />
                </label>
                <label className="col-6"><span>
                Gemeinkosten:</span>
                <input required
                  type="text"
                  name="gk"
                  placeholder="0.000,00 €"
                  onChange={this.handleChange}
                  value={this.state.gk}
                />
                </label>
                <label className="col-6"><span>
                Selbskosten pro Stunde:</span>
                <input
                  type="text"
                  name="sk_h"
                  placeholder="00,00 €"
                  onChange={this.handleChange}
                  value={this.state.sk_h}
                />
                </label>
                <label className="col-6"><span>
                Gewinn:</span>
                <input required
                  type="text"
                  name="gewinn"
                  placeholder="00,00%"
                  onChange={this.handleChange}
                  value={this.state.gewinn}
                />
                </label>
                <label className="col-6"><span>
                Angebotspreis:</span>
                <input
                  type="text"
                  name="angebotspreis"
                  placeholder="00,00 €"
                  onChange={this.handleChange}
                  value={this.state.angebotspreis}
                />
                </label>
                <br/>
                </div>
                <div className="row">
                <div className="col-4">
                  <button onClick={this.readLastCalcData} className="btn-create-item">
                    Stammdaten übernehmen <FontAwesomeIcon icon="redo" />
                  </button>
                </div>
              <div className="col-8">
                <button className="btn-create-item">
                  Stammdaten speichern <FontAwesomeIcon icon="save" />
                </button>
              </div>
              </div>
              </fieldset>
           </form>
          <p className={this.state.errorInput ? "show errorInput" : "hide"}>
            Bitte Bezeichnung ausfüllen
          </p>
        </section>
        <section id="showItems" className="display-item">
          <h4>Übersicht Stammdaten</h4>

          <ul className="items-list">
            {this.state.itemsAll.map(item => {
              return (
                <CalcDataModal
                  onChange={this.handleChange}
                  key={item.id}
                  id={item.id}
                  jahr={item.jahr}
                  arbeitstage={item.arbeitstage}
                  stunden_tag={item.stunden_tag}
                  stunden_jahr={item.stunden_jahr}
                  rentenvers={item.rentenvers}
                  arbeitslosenvers={item.arbeitslosenvers}
                  krankenvers={item.krankenvers}
                  pflegevers={item.pflegevers}
                  insolvenzgeldumlage={item.insolvenzgeldumlage}
                  umlage_erstattung={item.umlage_erstattung}
                  krankheitstage={item.krankheitstage}
                  krankheitstageProzent={item.krankheitstageProzent}
                  bezFeiertage={item.bezFeiertage}
                  bgHauptumlage={item.bgHauptumlage}
                  bgLastenverteilung={item.bgLastenverteilung}
                  soka={item.soka}
                  kosten_h={item.kosten_h}
                  gk={item.gk}
                  sk_h={item.sk_h}
                  gewinn={item.gewinn}
                  angebotspreis={item.angebotspreis}
                />
              );
            })}
          </ul>
        </section>
      </React.Fragment>
    );
  }
}

export default CalcData;
