import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../config/config.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var $ = require("jquery");
var Highlight = require("react-highlighter");

class ModalSupplier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      id: this.props.id,
      supplierId: this.props.supplierId,
      supplierIdOld: this.props.supplierIdOld,
      supplierName: this.props.supplierName,
      street: this.props.street,
      zipCode: this.props.zipCode,
      city: this.props.city,
      showUpdate: true,
      showDelete: false,
      hideNotes: false,
      hideArticle: false,
      supAll: this.props.supAll
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        modal: false
      });
    } else {
      this.setState({
        modal: !this.state.modal
      });
    }
  };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleChangeCheck = e => {
    this.setState({
      done: !this.state.done
    });
  };
  updatesupplier = e => {
    let updatesupplier;
    this.state.supAll.map((items, idx) => {
      if (items.id === "undefined") {
         updatesupplier = {
          id: "0",
          supplierId: this.state.supplierId,
          supplierIdOld: this.state.supplierIdOld,
          supplierName: this.state.supplierName,
          street: this.state.street,
          zipCode: this.state.zipCode,
          city: this.state.city,
          supName: items.supName,
          supTel: items.supTel,
          supMail: items.supMail
        };
      } else {
         updatesupplier = {
          id: items.id,
          supplierId: this.state.supplierId,
          supplierIdOld: this.state.supplierIdOld,
          supplierName: this.state.supplierName,
          street: this.state.street,
          zipCode: this.state.zipCode,
          city: this.state.city,
          supName: items.supName,
          supTel: items.supTel,
          supMail: items.supMail
        };
      }
      $.ajax({
        url: BaseUrl + "api/supplier/updateSupplier.php",
        type: "POST",
        dataType: "text",
        data: JSON.stringify(updatesupplier),
        success: function(response) {
          this.setState({
            custemorOld: this.state.custemorId,
            modal: !this.state.modal
          });
        }.bind(this),
        error: function(xhr, resp, text) {}
      });
      return this;
    });

    e.preventDefault();
  };

  removesupPerNr = idx => e => {
    e.preventDefault();
    let deletesupplier = {
      id: this.state.id
    };

    $.ajax({
      url: BaseUrl + "api/supplier/deleteSupplier.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deletesupplier),
      success: function(response) {
        this.setState({
          supAll: this.state.supAll.filter((s, sidx) => idx !== sidx)
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  handleRemoveDeliveryItem = idx => () => {
    this.setState({
      supAll: this.state.supAll.filter((s, sidx) => idx !== sidx)
    });
  };
  removesupplier = () => {
    let deletesupplier = {
      id: this.state.supplierId
    };
    $.ajax({
      url: BaseUrl + "api/supplier/deleteSupplier.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deletesupplier),
      success: function(response) {
        this.setState({
          modal: !this.state.modal,
          hideNotes: true
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  handleAddDeliveryItem = () => {
    this.setState({
      supAll: this.state.supAll.concat([
        { supName: "", supTel: "", supMail: "" }
      ])
    });
  };
  handleChangesup = idx => evt => {
    const newsupChange = this.state.supAll.map((item, sidx) => {
      if (idx !== sidx) return item;
      return { ...item, [evt.target.name]: evt.target.value };
    });
    this.setState({ supAll: newsupChange });
  };

  render() {
    return (
      <React.Fragment>
        <li
          onClick={this.toggle}
          className={this.state.hideNotes ? "hide " : ""}
        >
          <div>
            <span className="row list-header show0">
              <p className="col-2">
                <span>Lieferanten-Nr</span>
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.supplierId}
                </Highlight>
              </p>
              <p className="col">
                <span>Lieferantenname</span>
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.supplierName}
                </Highlight>
              </p>
              <p className="col">
                <span>Strasse</span>
                {this.state.street}
              </p>
              <p className="col-1">
                <span>Plz</span>
                {this.state.zipCode}
              </p>
              <p className="col">
                <span>Ort</span>
                {this.state.city}
              </p>
            </span>
          </div>

          {this.state.supAll.map((i, idx) => {
            return (
              <div className={this.state.hideArticle ? "hide " : ""} key={idx}>
                <span className={`row fullwidth hidetext show${idx}`}>
                  <p className="col-md-3">
                    <span>Name</span>
                    <Highlight id="font-normal" search={this.props.search}>
                      {i.supName}
                    </Highlight>
                  </p>
                  <p className="col-md-2">
                    <span>Telefonnummer</span>
                    {i.supTel}
                  </p>
                  <p className="col-md-4">
                    <span>Email</span>
                    {i.supMail}
                  </p>
                </span>
              </div>
            );
          })}
        </li>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal-header">
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <p>
                Lieferant bearbeiten?<br />
              </p>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <p>
                Lieferant wirklich Löschen?<br />
              </p>

              <span>Dieser Vorgang kann nicht Rückgänging gemacht werden</span>
            </div>
            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <form className="row">
                <div className="col-md-2">
                  <p>Lieferanten-Nr</p>

                  <input
                    type="text"
                    name="supplierId"
                    placeholder="Lieferantnnummer"
                    onChange={this.handleChange}
                    value={this.state.supplierId}
                  />
                </div>
                <div className="col-md-3">
                  <p>Lieferantnname</p>

                  <input
                    type="text"
                    name="supplierName"
                    placeholder="Lieferantnname"
                    onChange={this.handleChange}
                    value={this.state.supplierName}
                  />
                </div>
                <div className="col-md-3">
                  <p>Straße</p>

                  <input
                    type="text"
                    name="street"
                    placeholder="Straße"
                    onChange={this.handleChange}
                    value={this.state.street}
                  />
                </div>
                <div className="col-md-2">
                  <p>Plz</p>

                  <input
                    type="text"
                    name="zipCode"
                    placeholder="Postleitzahl"
                    onChange={this.handleChange}
                    value={this.state.zipCode}
                  />
                </div>
                <div className="col-md-2">
                  <p>Ort</p>

                  <input
                    type="text"
                    name="city"
                    placeholder="Ort"
                    onChange={this.handleChange}
                    value={this.state.city}
                  />
                </div>
              </form>

              <form className="row">

                {this.state.supAll.map((i, idx) => {
                  return (
                    <div
                      key={idx}
                      className={
                        this.state.hideArticle
                          ? "hide row fullwidth"
                          : "row fullwidth"
                      }
                    >
                      <div className="col-md-1">
                        <button
                          onClick={this.removesupPerNr(idx)}
                          className="x-red"
                        >
                          x
                        </button>
                      </div>
                      <div className="col-md-2">
                        <p>{`Name #${idx + 1}`}</p>
                        <input
                          type="text"
                          name="supName"
                          placeholder="Name"
                          onChange={this.handleChangesup(idx)}
                          value={i.supName}
                        />
                      </div>
                      <div className="col-md-5">
                        <p>{`Telefonnummer #${idx + 1}`}</p>
                        <input
                          type="text"
                          name="supTel"
                          placeholder="Telefonnummer"
                          onChange={this.handleChangesup(idx)}
                          value={i.supTel}
                        />
                      </div>
                      <div className="col-md-4">
                        <p>{`Email #${idx + 1}`}</p>
                        <input
                          type="text"
                          name="supMail"
                          placeholder="Email"
                          onChange={this.handleChangesup(idx)}
                          value={i.supMail}
                        />
                      </div>
                    </div>
                  );
                })}
              </form>
              <div className="row">
                <div className="col-11" />
                <div className="col-1">
                  <button
                    type="button"
                    onClick={this.handleAddDeliveryItem}
                    className="btn-add-blue"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Löschen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.updatesupplier} className="btn-blue">
                    Ändern <FontAwesomeIcon icon={["far", "edit"]} />
                  </button>
                </div>
              </div>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Nein <FontAwesomeIcon icon="ban" />
                  </button>
                </div>

                <div className="col">
                  <button onClick={this.removesupplier} className="btn-blue">
                    Ja <FontAwesomeIcon icon="check-circle" />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalSupplier;
