import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Modal, ModalBody } from "reactstrap";

class ModalSelectItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalSelect: this.props.modalSelect,
      multipleItems: this.props.multipleItems,
     };
  }

  toggle = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        modalSelect: false,
      });
    } else {
      this.props.resetModal();
      this.setState({
        modalSelect: !this.state.modalSelect,
      });
    }
  };
  selectItem = (e) => {
    this.props.fillDescUnit(e.target.value, this.props.idx);
    this.props.fillSupplier(e.target.value, this.getSupplierName(e.target.value));
    this.toggle();
  };
  getSupplierName = (id) => {
    let found = this.props.supplierAll.find((name) => name.supplierId === id);
 
    return found.supplierName;
  };
  render() {
    return (
      <React.Fragment>
        <Modal
          className="center-modal"
          isOpen={this.state.modalSelect}
          toggle={this.toggle}
        >
          <div className="modal-header">
            <p>Gewünschten Artikel Auswählen</p>

            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody className="selectItem">
            <div className="row title">
              <div className="col-md-1">
                <span>Lief.Nr.:</span>
              </div>
              <div className="col-md-2">
                <span>Lieferant:</span>
              </div>
              <div className="col-md-2">
                <span>ArtikelNr:</span>
              </div>
              <div className="col-md-4">
                <span>Artikelbezeichnung:</span>
              </div>
              <div className="col-md-1">
                <span>Einheit:</span>
              </div>
            </div>
            {this.props.multipleItems.map((item, idx) => {
              return (
                <div className="row align-flex-center" key={idx}>
                  <div className="col-md-1">{item.supplierId}</div>
                  <div className="col-md-2">
                    {this.getSupplierName(item.supplierId)}
                  </div>
                  <div className="col-md-2">{item.artNr}</div>
                  <div className="col-md-4">{item.dsc}</div>
                  <div className="col-md-1">{item.unit}</div>
                  <div className="col-md-2">
                    <button value={item.supplierId} onClick={this.selectItem}>
                      Auswählen{" "}
                      <FontAwesomeIcon icon={["far", "check-square"]} />
                    </button>
                  </div>
                </div>
              );
            })}
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalSelectItem;
