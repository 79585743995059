import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { Modal, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BaseUrl from "../config/config.js";
var $ = require("jquery");
var Highlight = require("react-highlighter");
class ModalEmployees extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      id: this.props.id,
      employeeNr: this.props.employeeNr,
      lastName: this.props.lastName,
      firstName: this.props.firstName,
      birthday: this.props.birthday,
      street: this.props.street,
      zipCode: this.props.zipCode,
      place: this.props.place,
      taxNr: this.props.taxNr,
      taxClass: this.props.taxClass,
      religion: this.props.religion,
      nationality: this.props.nationality,
      zvkNr: this.props.zvkNr,
      socialNr: this.props.socialNr,
      iban: this.props.iban,
      jobDesc: this.props.jobDesc,
      categorie: this.props.categorie,
      hourlyWage: this.props.hourlyWage,
      employedSince: this.props.employedSince,
      employedTill: this.props.employedTill,
      healthInsurance: this.props.healthInsurance,
      bgTarif: this.props.bgTarif,
      active: this.props.active,
      special: this.props.special,
      pantsSize: this.props.pantsSize,
      shirtSize: this.props.shirtSize,
      shoeSize: this.props.shoeSize,
      showUpdate: true,
      showDelete: false,
      hideItem: false,
      dataAll: [],
      namesWageAll: [],
      showCalcData: false
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        modal: false
      });
    } else {
      this.setState({
        modal: !this.state.modal
      });
    }
  };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if (e.target.name === "hourlyWage") {
      this.setState({
        [e.target.name]: e.target.value.replace(",", ".")
      });
    }
    if (
      e.target.name === "hourlyWage" &&
      this.state.hourlyWage !== e.target.value
    ) {
      console.log(2);
      this.loadData();
      this.loadNameHourlyWage();
      this.setState({
        showCalcData: true
      });
    }
  };
  handleChangeCheck = e => {
    this.setState({
      active: !this.state.active
    });
  };
  updateEmployee = e => {
    let updateEmployees;
    if (this.state.active === true || this.state.active === "x") {
      updateEmployees = {
        id: this.state.id,
        employeeNr: this.state.employeeNr,
        lastName: this.state.lastName,
        firstName: this.state.firstName,
        birthday: this.state.birthday,
        street: this.state.street,
        zipCode: this.state.zipCode,
        place: this.state.place,
        taxNr: this.state.taxNr,
        taxClass: this.state.taxClass,
        religion: this.state.religion,
        nationality: this.state.nationality,
        zvkNr: this.state.zvkNr,
        socialNr: this.state.socialNr,
        iban: this.state.iban,
        jobDesc: this.state.jobDesc,
        categorie: this.state.categorie,
        hourlyWage: this.state.hourlyWage.replace(/,/g, "."),
        employedSince: this.state.employedSince,
        employedTill: this.state.employedTill,
        healthInsurance: this.state.healthInsurance,
        bgTarif: this.state.bgTarif,
        active: "x",
        special: this.state.special,
        pantsSize: this.state.pantsSize,
        shirtSize: this.state.shirtSize,
        shoeSize: this.state.shoeSize
      };
    } else {
      updateEmployees = {
        id: this.state.id,
        employeeNr: this.state.employeeNr,
        lastName: this.state.lastName,
        firstName: this.state.firstName,
        birthday: this.state.birthday,
        street: this.state.street,
        zipCode: this.state.zipCode,
        place: this.state.place,
        taxNr: this.state.taxNr,
        taxClass: this.state.taxClass,
        religion: this.state.religion,
        nationality: this.state.nationality,
        zvkNr: this.state.zvkNr,
        socialNr: this.state.socialNr,
        iban: this.state.iban,
        jobDesc: this.state.jobDesc,
        categorie: this.state.categorie,
        hourlyWage: this.state.hourlyWage.replace(/,/g, "."),
        employedSince: this.state.employedSince,
        employedTill: this.state.employedTill,
        healthInsurance: this.state.healthInsurance,
        bgTarif: this.state.bgTarif,
        active: "",
        special: this.state.special,
        pantsSize: this.state.pantsSize,
        shirtSize: this.state.shirtSize,
        shoeSize: this.state.shoeSize
      };
    }

    // submit form data to api
    $.ajax({
      url: BaseUrl + "api/employees/updateEmployees.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(updateEmployees),
      success: function(response) {
        this.setState({
          modal: !this.state.modal
        });
        if (this.props.hourlyWage === this.state.hourlyWage) {
          return false;
        } else {
          this.calcEverything();
        }
      }.bind(this),
      error: function(xhr, resp, text) {
        // show error to console
      }
    });

    e.preventDefault();
  };

  removeEmployee = () => {
    let deleteEmployees = {
      id: this.state.id
    };

    $.ajax({
      url: BaseUrl + "api/employees/deleteEmployees.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteEmployees),
      success: function(response) {
        this.setState({
          modal: !this.state.modal,
          hideItem: true
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  loadNameHourlyWage = () => {
    return fetch(BaseUrl + "api/calcHourlyWage/readNameWage.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            namesWageAll: responseJson.records
          });
        });
      })
      .catch(error => {});
  };
  loadData = () => {
    let jahr = new Date().getFullYear();
    fetch(BaseUrl + "api/calcHourlyWage/readCalcData.php?eingabe=" + jahr)
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
           return this.setState({
            dataAll: responseJson.records
          });
        });
      })
      .catch(error => {});
  };
  calcEverything = () => {
     let bruttolohn = (
      this.state.hourlyWage *
      this.state.dataAll[0].arbeitstage *
      this.state.dataAll[0].stunden_tag
    ).toFixed(2);
    let rente = (
      (bruttolohn * this.state.dataAll[0].rentenvers) /
      100 /
      2
    ).toFixed(2);
    let arbeitslosen = (
      (bruttolohn * this.state.dataAll[0].arbeitslosenvers) /
      100 /
      2
    ).toFixed(2);
    let kranken = (
      (bruttolohn * this.state.dataAll[0].krankenvers) /
      100 /
      2
    ).toFixed(2);
    let pflege = (
      (bruttolohn * this.state.dataAll[0].pflegevers) /
      100 /
      2
    ).toFixed(2);
    let insolvenz = (
      (bruttolohn * this.state.dataAll[0].insolvenzgeldumlage) /
      100 /
      2
    ).toFixed(2);
    let umlage = (
      (bruttolohn * this.state.dataAll[0].umlage_erstattung) /
      100 /
      2
    ).toFixed(2);

    let lohnkosten = (
      parseFloat(bruttolohn) +
      parseFloat(rente) +
      parseFloat(arbeitslosen) +
      parseFloat(kranken) +
      parseFloat(pflege) +
      parseFloat(insolvenz) +
      parseFloat(umlage)
    ).toFixed(2);
    let kranktage = (
      this.state.dataAll[0].krankheitstage *
      8 *
      (lohnkosten / (37 * 52)) *
      (this.state.dataAll[0].krankheitstageProzent / 100)
    ).toFixed(2);
    let feiertage = (
      (lohnkosten / this.state.dataAll[0].arbeitstage) *
      this.state.dataAll[0].bezFeiertage
    ).toFixed(2);
    let jahreslohn_stunde = (
      parseFloat(lohnkosten) +
      parseFloat(kranktage) +
      parseFloat(feiertage)
    ).toFixed(2);

    let bgHaupt = (
      (jahreslohn_stunde * this.state.dataAll[0].bgHauptumlage) /
      100
    ).toFixed(2);
    let bgLast = (
      (jahreslohn_stunde * this.state.dataAll[0].bgLastenverteilung) /
      100
    ).toFixed(2);
    let sozka = (
      (jahreslohn_stunde * this.state.dataAll[0].soka) /
      100
    ).toFixed(2);
    let kostenJahr = (
      parseFloat(jahreslohn_stunde) +
      parseFloat(bgHaupt) +
      parseFloat(bgLast) +
      parseFloat(sozka)
    ).toFixed(2);

    let kostenStunde = (
      kostenJahr / this.state.dataAll[0].stunden_jahr
    ).toFixed(2);
    let gemeink = (
      this.state.dataAll[0].gk /
      (this.state.namesWageAll.length * this.state.dataAll[0].stunden_jahr)
    ).toFixed(2);

    let skStunde = (parseFloat(kostenStunde) + parseFloat(gemeink)).toFixed(2);
    let gew = ((skStunde * this.state.dataAll[0].gewinn) / 100).toFixed(2);
    let angebot = (parseFloat(skStunde) + parseFloat(gew)).toFixed(2);
    let help;
    if (this.state.active === true) {
      help = {
        jahr: this.state.dataAll[0].jahr,
        lastName: this.state.lastName,
        firstName: this.state.firstName,
        arbeitstage: this.state.dataAll[0].arbeitstage,
        stunden_tag: this.state.dataAll[0].stunden_tag,
        stunden_jahr: this.state.dataAll[0].stunden_jahr,
        stundenlohn: this.state.hourlyWage,
        lohnBrutto: bruttolohn,
        rentenvers: rente,
        arbeitslosenvers: arbeitslosen,
        krankenvers: kranken,
        pflegevers: pflege,
        insolvenzgeldumlage: insolvenz,
        umlage_erstattung: umlage,
        lohnInklNebenkosten: lohnkosten,
        krankheitstage: kranktage,
        bezFeiertage: feiertage,
        jahresstundenlohn: jahreslohn_stunde,
        bgHauptumlage: bgHaupt,
        bgLastenverteilung: bgLast,
        soka: sozka,
        kosten_jahr: kostenJahr,
        kosten_h: kostenStunde,
        gk: gemeink,
        sk_h: skStunde,
        gewinn: gew,
        angebotspreis: angebot,
        active: "x",
        special: this.state.special
      };
    } else {
      help = {
        jahr: this.state.dataAll[0].jahr,
        lastName: this.state.lastName,
        firstName: this.state.firstName,
        arbeitstage: this.state.dataAll[0].arbeitstage,
        stunden_tag: this.state.dataAll[0].stunden_tag,
        stunden_jahr: this.state.dataAll[0].stunden_jahr,
        stundenlohn: this.state.hourlyWage,
        lohnBrutto: bruttolohn,
        rentenvers: rente,
        arbeitslosenvers: arbeitslosen,
        krankenvers: kranken,
        pflegevers: pflege,
        insolvenzgeldumlage: insolvenz,
        umlage_erstattung: umlage,
        lohnInklNebenkosten: lohnkosten,
        krankheitstage: kranktage,
        bezFeiertage: feiertage,
        jahresstundenlohn: jahreslohn_stunde,
        bgHauptumlage: bgHaupt,
        bgLastenverteilung: bgLast,
        soka: sozka,
        kosten_jahr: kostenJahr,
        kosten_h: kostenStunde,
        gk: gemeink,
        sk_h: skStunde,
        gewinn: gew,
        angebotspreis: angebot,
        active: "",
        special: this.state.special
      };
    }
    $.ajax({
      url: BaseUrl + "api/calcHourlyWage/updateCalcEmployee.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(help),
      success: function(response) {
        this.savedcalchourlywage(skStunde, angebot);
        this.setState({
          modal: !this.state.modal,
          showCalcData: false
        });
      }.bind(this),
      error: function(xhr, resp, text) {
        // show error to console
      }
    });
  };
  savedcalchourlywage = (skStunde, angebot) => {
    const saved = {
      lastName: this.state.lastName,
      firstName: this.state.firstName,
      date: new Date().toJSON().slice(0, 10),
      sk_h: skStunde,
      angebotspreis: angebot
    };
    $.ajax({
      url: BaseUrl + "api/calcHourlyWage/createSavedCalcHourlyWage.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(saved),
      success: function(response) {}.bind(this),
      error: function(xhr, resp, text) {}
    });
  };

  convertDate = date => {
    if (date === null || date === "0000-00-00" || date === undefined) {
      return date;
    }

    if (date.includes("-")) {
      var dateNew = date.split("-");
      dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  convertDateNormal = date => {
    if (date === null || date === "0000-00-00" || date === undefined) {
      return date;
    }

    if (date.includes(".")) {
      var dateNew = date.split(".");
      dateNew = dateNew[2] + "-" + dateNew[1] + "-" + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };

  render() {
    return (
      <React.Fragment>
        <li
          onClick={this.toggle}
          className={this.state.hideItem ? "hide " : " "}
          key={this.state.id}
        >
          <div className=" fullwidth showSpan employee">
            <div className="row list-header show0">
              <p className="col-1">
                <span>Mitarbeiter-Nr</span>
                {this.state.employeeNr}
              </p>
              <p className="col-10">
                <span>Name</span>
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.lastName}
                </Highlight>
                &nbsp;
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.firstName}
                </Highlight>
              </p>
              <p className=" col-1">
                <span>Aktiv</span>
                {this.state.active ? "✔" : ""}
              </p>
            </div>
            <div className="row">
              <p className="col-2">
                <span>Geburtstag</span>
                <br />
                {this.convertDate(this.state.birthday)}
              </p>
              <p className="col-2">
                <span>Staatsangehörigkeit</span>
                <br />
                {this.state.nationality}
              </p>
              <p className="col-2">
                <span>Religion</span>
                <br />
                {this.state.religion}
              </p>
              <p className="col-2">
                <span>Tätigkeit</span>
                <br />
                {this.state.jobDesc}
              </p>
              <p className="col-2">
                <span>Kategorie</span>
                <br />
                {this.state.categorie}
              </p>
              <p className="col-1">
                <span>Angestellt seit</span>
                <br />
                {this.convertDate(this.state.employedSince)}
              </p>
              <p className="col-1">
                <span>Angestellt bis</span>
                <br />
                {this.convertDate(this.state.employedTill)}
              </p>
            </div>
            <div className="row">
              <p className="col-2">
                <span>Krankenkasse</span>
                <br />
                {this.state.healthInsurance}
              </p>
              <p className="col-4">
                <span>Anschrift</span>
                <br />
                {this.state.street}, {this.state.zipCode} {this.state.place}
              </p>
              <p className="col-3">
                <span>IBAN</span>
                <br />
                {this.state.iban}
              </p>
              <p className="col-2">
                <span>Steuernummer</span>
                <br />
                {this.state.taxNr}
              </p>
              <p className="col-1">
                <span>Steuerklasse</span>
                <br />
                {this.state.taxClass}
              </p>
              <p className="col-2">
                <span>ZVK-Nummer</span>
                <br />
                {this.state.zvkNr}
              </p>
              <p className="col-2">
                <span>Sozialversicherungs-Nr</span>
                <br />
                {this.state.socialNr}
              </p>

              <p className="col-1">
                <span>Stundenlohn</span>
                <br />
                {this.state.hourlyWage}
              </p>
              <p className="col-1">
                <span>BG-Tarif</span>
                <br />
                {this.state.bgTarif}
              </p>
              <p className="col-2">
                <span>Sonderberechnung</span>
                <br />
                {this.state.special}
              </p>

              <p className="col">
                <span>Hosengröße</span>
                <br />
                {this.state.pantsSize}
              </p>
              <p className="col">
                <span>Oberteilgröße</span>
                <br />
                {this.state.shirtSize}
              </p>
              <p className="col">
                <span>Schuhgröße</span>
                <br />
                {this.state.shoeSize}
              </p>
            </div>
          </div>
        </li>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal-header">
            <p className={this.state.showUpdate ? "show" : "hide"}>
              Mitarbeiter Bearbeiten
            </p>
            <p className={this.state.showDelete ? "show" : "hide"}>
              Mitarbeiter wirklich Löschen?
              <br />
              <span>Dieser Vorgang kann nicht Rückgänging gemacht werden</span>
            </p>
            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <form>
                <div className="row">
                  <div className="col-md-2">
                    <p>Mitarbeiter-Nr</p>
                    <input
                      type="text"
                      name="employeeNr"
                      placeholder="MitarbeiterNr"
                      onChange={this.handleChange}
                      value={this.state.employeeNr}
                    />
                  </div>
 
                  <div className="col-md-4">
                    <p>Nachname</p>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Nachname"
                      onChange={this.handleChange}
                      value={this.state.lastName}
                    />
                  </div>
                  <div className="col-md-4">
                    <p>Vorname</p>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Vorname"
                      onChange={this.handleChange}
                      value={this.state.firstName}
                    />
                  </div>
                  <div className="col-md-2">
                    <p>Geburtstag</p>
                    <input
                      type="date"
                      name="birthday"
                      placeholder="Geburtstag"
                      onChange={this.handleChange}
                      value={this.convertDateNormal(this.state.birthday)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <p>Nationalität</p>
                    <input
                      type="text"
                      name="nationality"
                      placeholder="Nationalität"
                      onChange={this.handleChange}
                      value={this.state.nationality}
                    />
                  </div>
                  <div className="col-md-2">
                    <p>Religion</p>
                    <input
                      type="text"
                      name="religion"
                      placeholder="Religion"
                      onChange={this.handleChange}
                      value={this.state.religion}
                    />
                  </div>
                  <div className="col-md-3">
                    <p>Krankenkasse</p>
                    <input
                      type="text"
                      name="healthInsurance"
                      placeholder="Krankenkasse"
                      onChange={this.handleChange}
                      value={this.state.healthInsurance}
                    />
                  </div>
                  <div className="col-md-4">
                    <p>IBAN</p>
                    <input
                      type="text"
                      name="iban"
                      placeholder="IBAN"
                      onChange={this.handleChange}
                      value={this.state.iban}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <p>Kategorie</p>
                    <select
                      name="categorie"
                      onChange={this.handleChange}
                      value={this.state.categorie}
                    >
                      <option key="-1" value="-">
                        -
                      </option>
                      <option key={1} value="Vorarbeiter">
                        Vorarbeiter
                      </option>
                      <option key={2} value="Facharbeiter">
                        Facharbeiter
                      </option>
                      <option key={3} value="Hilfsarbeiter">
                        Hilfsarbeiter
                      </option>
                      <option key={4} value="Meister">
                        Meister
                      </option>
                      <option key={5} value="Auszubildender">
                        Auszubildender
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2">
                    <p>Tätigkeit</p>
                    <input
                      type="text"
                      name="jobDesc"
                      placeholder="Tätigkeit"
                      onChange={this.handleChange}
                      value={this.state.jobDesc}
                    />
                  </div>
                  <div className="col-md-3">
                    <p>Angestellt seit</p>
                    <input
                      type="date"
                      name="employedSince"
                      placeholder="Angestellt seit"
                      onChange={this.handleChange}
                      value={this.convertDateNormal(this.state.employedSince)}
                    />
                  </div>
                  <div className="col-md-3">
                    <p>Angestellt bis</p>
                    <input
                      type="date"
                      name="employedTill"
                      placeholder="Angestellt bis"
                      onChange={this.handleChange}
                      value={this.convertDateNormal(this.state.employedTill)}
                    />
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-2">
                    <p>Straße</p>
                    <input
                      type="text"
                      name="street"
                      placeholder="Straße"
                      onChange={this.handleChange}
                      value={this.state.street}
                    />
                  </div>
                  <div className="col-md-1">
                    <p>Plz</p>
                    <input
                      type="text"
                      name="zipCode"
                      placeholder="Postleitzahl"
                      onChange={this.handleChange}
                      value={this.state.zipCode}
                    />
                  </div>
                  <div className="col-md-2">
                    <p>Ort</p>
                    <input
                      type="text"
                      name="place"
                      placeholder="Ort"
                      onChange={this.handleChange}
                      value={this.state.place}
                    />
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <p>Steuernummer</p>
                    <input
                      type="text"
                      name="taxNr"
                      placeholder="Steuernummer"
                      onChange={this.handleChange}
                      value={this.state.taxNr}
                    />
                  </div>
                  <div className="col">
                    <p>Steuerklasse</p>
                    <input
                      type="text"
                      name="taxClass"
                      placeholder="Steuerklasse"
                      onChange={this.handleChange}
                      value={this.state.taxClass}
                    />
                  </div>
                  <div className="col">
                    <p>ZVK-Nummer</p>
                    <input
                      type="text"
                      name="zvkNr"
                      placeholder="ZVK-Nummer"
                      onChange={this.handleChange}
                      value={this.state.zvkNr}
                    />
                  </div>
                  <div className="col">
                    <p>Sozialversicherungs-Nr</p>
                    <input
                      type="text"
                      name="socialNr"
                      placeholder="SozialversicherungsNr"
                      onChange={this.handleChange}
                      value={this.state.socialNr}
                    />
                  </div>
                </div>
                <hr />

                <div className="row">
                  <div className="col-md-2">
                    <p>Stundenlohn</p>
                    <input
                      type="text"
                      name="hourlyWage"
                      placeholder="Stundenlohn"
                      onChange={this.handleChange}
                      value={this.state.hourlyWage}
                    />
                  </div>
                  <div className="col-md-2">
                    <p>BG-Tarif</p>
                    <input
                      type="text"
                      name="bgTarif"
                      placeholder="Bg Tarif"
                      onChange={this.handleChange}
                      value={this.state.bgTarif}
                    />
                  </div>
                  <div className="col-md-2 inline">
                    <input
                      type="checkbox"
                      name="active"
                      id="active"
                      placeholder="Aktiv"
                      onChange={this.handleChangeCheck}
                      checked={this.state.active}
                    />
                    <label>Aktiv</label>
                  </div>
                  <div className="col-md-2">
                    <p>Sonderberechnung</p>
                    <select
                      name="special"
                      value={this.state.special}
                      onChange={this.handleChange}
                    >
                      <option value="nein">Nein</option>
                      <option value="ja">ja</option>
                    </select>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-2">
                    <p>Hosengröße</p>
                    <select
                      name="pantsSize"
                      value={this.state.pantsSize}
                      onChange={this.handleChange}
                    >
                      <option value="-">-</option>
                      <option value="40">40</option>
                      <option value="42">42</option>
                      <option value="44">44</option>
                      <option value="46">46</option>
                      <option value="48">48</option>
                      <option value="50">50</option>
                      <option value="52">52</option>
                      <option value="54">54</option>
                      <option value="56">56</option>
                      <option value="58">58</option>
                      <option value="60">60</option>
                    </select>
                  </div>
                  <div className="col-md-2">
                    <p>Oberteilgröße</p>
                    <select
                      name="shirtSize"
                      value={this.state.shirtSize}
                      onChange={this.handleChange}
                    >
                      <option value="-">-</option>
                      <option value="XS">XS</option>
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="2XL">2XL</option>
                      <option value="3XL">3XL</option>
                      <option value="4XL">4XL</option>
                      <option value="5XL">5XL</option>
                      <option value="6XL">6XL</option>
                      <option value="7XL">7XL</option>
                    </select>
                  </div>
                  <div className="col-md-2">
                    <p>Schuhgröße</p>
                    <select
                      name="shoeSize"
                      value={this.state.shoeSize}
                      onChange={this.handleChange}
                    >
                      <option value="-">-</option>
                      <option value="36">36</option>
                      <option value="37">37</option>
                      <option value="38">38</option>
                      <option value="39">39</option>
                      <option value="40">40</option>
                      <option value="41">41</option>
                      <option value="42">42</option>
                      <option value="43">43</option>
                      <option value="44">44</option>
                      <option value="45">45</option>
                      <option value="46">46</option>
                      <option value="47">47</option>
                      <option value="48">48</option>
                      <option value="49">49</option>
                    </select>
                  </div>
                </div>
              </form>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Löschen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.updateEmployee} className="btn-blue">
                    <div className={this.state.showCalcData ? "show" : "hide"}>
                      Ändern u. Stundensatz berechnen{" "}
                      <FontAwesomeIcon icon={["far", "edit"]} />
                    </div>
                    <div className={this.state.showCalcData ? "hide" : "show"}>
                      Ändern <FontAwesomeIcon icon={["far", "edit"]} />
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Nein <FontAwesomeIcon icon="ban" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.removeEmployee} className="btn-blue">
                    Ja <FontAwesomeIcon icon="check-circle" />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalEmployees;
