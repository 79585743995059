import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
import { CSVLink, CSVDownload } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var $ = require("jquery");

class SqlQuerys extends Component {
  constructor() {
    super();
    this.state = {
      tableName: "",
      tableNamesAll: [],
      columnNames: [],
      bvAll: [],
      customerAll: [],
      supplierAll: [],
      bv: "",
      statedefault: [
        {
          azNr: "",
          bv: "",
          bvNr: "",
          bvId: "",
          bezahlt: "",
          betragSkonto: "",
          rechnungsNr: "",
          rechnungsdatum: "",
          customerId: "",
          customer: "",
          customerName: "",
          betragEingang: " ",
          betragEingangDatum: "",
          mahnung1: "",
          mahnung2: "",
          mahnung3: "",
          rechnungsbetrag: "",
          rechnungsdatum: "",
          rechnungsNr: "",
          skonto: "",
          sr_az: "",
          zahlungsdatum: "",
          zahlungsdatumVOB: "",
          zahlungsziel: "",
          billsNr: "",
          date: "",
          discount: "",
          itemId: "",
          itemAmnt: "",
          itemDsc: "",
          itemUnit: "",
          notesNr: "",
          priceComplete: "",
          pricePiece: "",
          supplier: "",
          supplierId: "",
          supplierName: "",
          city: "",
          constBeg: "",
          constEnd: "",
          done: "",
          orderNr: "",
          street: "",
          streetNr: "",
          zipCode: "",
          cpTel: "",
          supTel: "",
          cpName: "",
          supName: "",
          cpMail: "",
          supMail: "",
          directionWorkNr: "",
          beginning: "",
          driveTime: "",
          end: "",
          firstName: "",
          lastName: "",
          pause: "",
          result: "",
          month: "",
          payed: "",
          year: "",
          artGroup: "",
          artNr: "",
          dsc: "",
          internArtNr: "",
          unit: "",
          amnt: "",
          buildingEnd: "",
          buildingStart: "",
          cityCustomer: "",
          discountComplete: "",
          nr: "",
          offerNr: "",
          paragraph: "",
          pos: "",
          streetCustomer: "",
          termOfPayment: "",
          version: "",
          zipCodeCustomer: "",
          calcPrice: "",
          category: "",
        },
      ],
      showError: false,
    };
  }

  componentDidMount = () => {
    this.loadTableNames();
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true,
      });
    }
  };
  readBvComplete = () => {
    return fetch(BaseUrl + "api/all/readBvComplete.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          bvAll: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };
  readsuppliers = () => {
    return fetch(BaseUrl + "api/all/readSupplierNames.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          supplierAll: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };
  readCustomersNames = () => {
    return fetch(BaseUrl + "api/all/readCustomersNames.php")
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          return (this.state.customerAll = this.state.customerAll.concat(item));
        });
        this.setState({
          customerAll: this.state.customerAll,
        });
      })
      .catch((error) => {});
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    const changed = this.state.statedefault.map((i) => {
      return { ...i, [e.target.name]: e.target.value };
    });
    this.setState({ statedefault: changed });
    if (e.target.value === "" || e.target.value === "-") {
      this.state.columnNames.map((item) => {
        if (item.COLUMN_NAME === e.target.name) {
          this.setState({
            statedefault: [
              {
                [item.COLUMN_NAME]: undefined,
              },
            ],
            [item.COLUMN_NAME]: undefined,
          });
        }
      });
    }
    if (e.target.name === "tableName") {
      this.loadColumnNames(e.target.value);
      this.resetAllInput();
    }

    if (e.target.name === "bv") {
      this.readBvIdByName(e.target.value, true);
    }
    if (e.target.name === "customer") {
      this.readCustomerIdByName(e.target.value, true);
    }
    if (e.target.name === "supplier") {
      this.readSupplierIdByName(e.target.value, true);
    }
  };
  handleChangeNames = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "bv") {
      this.readBvIdByName(e.target.value, true);
    }
    if (e.target.name === "customer") {
      this.readCustomerIdByName(e.target.value, true);
    }
    if (e.target.name === "supplier") {
      this.readSupplierIdByName(e.target.value, true);
    }
  };
  readSupplierNameById = (id, bool) => {
    let found = this.state.supplierAll.find((name) => parseInt(name.supplierId) === parseInt(id));
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        supplier: found.supplierName,
        statedefault: [
          {
            supplier: found.supplierName,
            bvNr: this.state.bvNr,
            bvId: this.state.bvId,
            customerId: this.state.customerId,
          },
        ],
      });
    }
  };
  readSupplierIdByName = (name, bool) => {
    let found = this.state.supplierAll.find((id) => id.supplierName === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        supplierId: found.supplierId,
        statedefault: [
          {
            supplierId: found.supplierId,
            bvNr: this.state.bvNr,
            bvId: this.state.bvId,
            customerId: this.state.customerId,
          },
        ],
      });
    }
  };
  readBvIdByName = (name, bool) => {
    let found = this.state.bvAll.find((id) => id.bv === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        bv: name,
        bvId: found.bvId,
        bvNr: found.bvId,
        statedefault: [
          {
            supplierId: this.state.supplierId,
            bvNr: found.bvId,
            bvId: found.bvId,
            customerId: this.state.customerId,
          },
        ],
      });
    }
  };
  readBvNameById = (id, bool) => {
    let found = this.state.bvAll.find((name) => parseInt(name.bvId) === parseInt(id));
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        bv: found.bv,
        statedefault: [
          {
            bv: found.bv,
          },
        ],
      });
    }
  };
  readCustomerIdByName = (name, bool) => {
    let found = this.state.customerAll.find((id) => id.customerName === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        customerId: found.customerId,
        statedefault: [
          {
            supplierId: this.state.supplierId,
            bvNr: this.state.bvNr,
            bvId: this.state.bvId,
            customerId: found.customerId,
          },
        ],
      });
    } else {
      return found.bvId;
    }
  };
  readCustomerNameById = (id, bool) => {
    let found = this.state.customerAll.find((name) => parseInt(name.customerId) === parseInt(id));
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        customer: found.customerName,
        statedefault: [
          {
            customer: found.customerName,
          },
        ],
      });
    }
  };
  resetAllInput = () => {
    this.state.columnNames.map((item) => {
      this.setState({
        statedefault: [
          {
            [item.COLUMN_NAME]: undefined,
          },
        ],
        [item.COLUMN_NAME]: undefined,
        supplierName: undefined,
        supplier: "",
        customer: "",
        customerName: undefined,
        customerId: undefined,
        supplierId: undefined,
        bvId: undefined,
        bvNr: undefined,
        bv: "",
        getQueryAnswer: undefined,
      });
    });
  };
  loadTableNames = () => {
    return fetch(BaseUrl + "api/sqlquerys/readTableNames.php")
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          return this.setState({
            tableNamesAll: responseJson.records[0],
            [responseJson.records[0].COLUMN_NAME]: "",
          });
        });
      })
      .catch((error) => {});
  };
  reset = () => {
    document.getElementById("resetTable").reset();
  };
  loadColumnNames = (tableNames) => {
    this.reset();

    return fetch(BaseUrl + "api/sqlquerys/readColumnNames.php?tableName=" + tableNames)
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records[0].map((item) => {
          if (item.COLUMN_NAME === "bvId" || item.COLUMN_NAME === "bvNr") {
            this.readBvComplete();
          }
          if (item.COLUMN_NAME === "customerId") {
            this.loadCustomersNames();
          }
          if (item.COLUMN_NAME === "supplierId") {
            this.loadSuppliersName();
          }
        });
        this.setState({
          columnNames: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };

  loadCustomersNames = () => {
    return fetch(BaseUrl + "api/all/readCustomersNames.php")
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          return (this.state.customerAll = this.state.customerAll.concat(item));
        });
        this.setState({
          customerAll: this.state.customerAll,
        });
      })
      .catch((error) => {});
  };
  loadSuppliersName = () => {
    return fetch(BaseUrl + "api/all/readSupplierNames.php")
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          return (this.state.supplierAll = this.state.supplierAll.concat(item));
        });
        this.setState({
          supplierAll: this.state.supplierAll,
        });
      })
      .catch((error) => {});
  };

  autocomplete = (e) => {
    if (e.keyCode === 9 && e.target.name === "supplierId") {
      this.readSupplierNameById(e.target.value, true);
    }
    if (e.keyCode === 9 && e.target.name === "customerId") {
      this.readCustomerNameById(e.target.value, true);
    }
    if ((e.keyCode === 9 && e.target.name === "bvId") || (e.keyCode === 9 && e.target.name === "bvNr")) {
      this.readBvNameById(e.target.value, true);
    }
  };
  replaceAll = (item) => {
    let replaceName = {
      azNr: "Az-Nr",
      bv: "Bv",
      bvNr: "Bv-Id",
      bvId: "Bv-Id",
      bezahlt: "Bezahlt",
      betragSkonto: "Betrag skonto",
      rechnungsNr: "Rechnungs-Nr",
      rechnungsdatum: "Rechnungsdatum",
      customerId: "Kunden-Id",
      customerName: "Kunde",
      customer: "Kunde",
      betragEingang: "Betrag eingegangen",
      betragEingangDatum: "Betrag eingegangen (Datum)",
      mahnung1: "Mahnung 1",
      mahnung2: "Mahnung 2",
      mahnung3: "Mahnung 3",
      rechnungsbetrag: "Rechnungsbetrag",
      rechnungsdatum: "Rechnungsdatum",
      rechnungsNr: "Rechnungs-Nr",
      skonto: "Skonto",
      sr_az: "Sr-Az",
      zahlungsdatum: "Zahlungsdatum",
      zahlungsdatumVOB: "Zahlungsdatum VOB",
      zahlungsziel: "Zahlungsziel",
      billsNr: "Rechnungs-Nr",
      date: "Datum",
      discount: "Rabatt",
      itemId: "Artikel-Nr",
      itemAmnt: "Menge",
      itemDsc: "Bezeichnung",
      itemUnit: "Einheit",
      notesNr: "Lieferschein-Nr",
      notesDate: "Lieferschein Datum",
      priceComplete: "Gesamtpreis",
      pricePiece: "Preis/Stk",
      supplier: "Lieferant",
      supplierId: "Lieferanten-Id",
      supplierName: "Lieferant",
      city: "Ort",
      constBeg: "Baubeginn",
      constEnd: "Bauende",
      done: "Fertig",
      orderNr: "Auftrags-Nr",
      street: "Straße",
      streetNr: "Hausnummer",
      zipCode: "Plz",
      cpTel: "Telefon-Nr",
      supTel: "Telefon-Nr",
      cpName: "Name",
      supName: "Name",
      cpMail: "Email",
      supMail: "Email",
      directionWorkNr: "Regiebericht-Nr",
      beginning: "Beginn",
      driveTime: "Fahrtzeit",
      end: "Ende",
      firstName: "Vorname",
      lastName: "Nachname",
      pause: "Pause",
      result: "Ergebnis",
      month: "Monat",
      payed: "Bezahlt",
      year: "Jahr",
      artGroup: "Artikelgruppe",
      artNr: "Artikel-Nr",
      dsc: "Bezeichung",
      internArtNr: "Artkel-Nr intern",
      unit: "Einheit",
      amnt: "Menge",
      buildingEnd: "Baubeginn",
      buildingStart: "Bauende",
      cityCustomer: "Ort",
      discountComplete: "Nachlass",
      nr: "Nummer",
      offerNr: "Angebots-Nr",
      paragraph: "13b",
      pos: "Position",
      streetCustomer: "Straße",
      termOfPayment: "Zahlungsziel",
      version: "Version",
      zipCodeCustomer: "Plz",
      calcPrice: "Kalk. Preis",
      category: "Kategorie",
      targetQuantity: "Sollmenge",
      dateSaved: "Datum Gespeichert",
      dateFrom: "Datum Von",
      dateTo: "Datum Bis", 
      gewerk: "Gewerk",
      leistung: "Leistung",
      vorkommnisse: "Vorkommnisse",
      diaryId: "Bautagebuch Id"
    };
    return item.replace(item, replaceName[item]);
  };
  replaceTableName = (item) => {
    let replaceName = {
      billbook: "Rechnungsbuch",
      bills: "Rechnungen",
      bv: "Bauvorhaben",
      customer: "Kundenstamm",
      deliverynotes: "Lieferschein",
      hoursreporting: "Stundenbericht",
      items: "Artikelstamm",
      offer: "Angebot",
      products: "Produktstamm",
      supplier: "Lieferantenstamm",
      specificationlist: "Leistungsverzeichnis",
      construction_diary: "Bautagebuch"
    };
    return item.replace(item, replaceName[item]);
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };
  handleSearch = (e) => {
    this.setState({
      search: e.target.value,
    });

    if (e.target.value === "") {
      this.setState({
        SqlQueryssAll: this.state.SqlQueryssAllCopy,
        errorSearch: false,
      });
    }
  };
  showTableNames = () => {
    return this.state.tableNamesAll.map((item, i) => {
      if (
        item.TABLE_NAME === "calchourlywagedata" ||
        item.TABLE_NAME === "calchourlywage" ||
        item.TABLE_NAME === "directionworkextrainfo" ||
        item.TABLE_NAME === "employees" ||
        item.TABLE_NAME === "offerbasedata" ||
        item.TABLE_NAME === "savedcalchourlywage" ||
        item.TABLE_NAME === "termofpayment" ||
        item.TABLE_NAME === "users" ||
        item.TABLE_NAME === "directionworkbills" ||
        item.TABLE_NAME === "directionworkdeliverynote" ||
        item.TABLE_NAME === "directionworkhours" ||
        item.TABLE_NAME === "construction_diary_hours" ||
        item.TABLE_NAME === "construction_diary_deliverynotes"
      ) {
        return "";
      } else {
        return (
          <option key={i + ""} value={item.TABLE_NAME}>
            {this.replaceTableName(item.TABLE_NAME)}
          </option>
        );
      }
    });
  };
  show = () => {
    return this.state.columnNames.map((item, i) => {
      if (item.COLUMN_NAME === "id" || item.COLUMN_NAME === "diaryId" || item.COLUMN_NAME === "vorkommnisse" || item.COLUMN_NAME === "leistung" || item.COLUMN_NAME === "gewerk") {
        return "";
      }
      if (item.COLUMN_NAME === "bvId" || item.COLUMN_NAME === "bvNr") {
        return (
          <div key={i + ""} className="col-6 group-sql">
            <div className="col-6 tab">
              <p>{this.replaceAll(item.COLUMN_NAME)}</p>

              <input
                type="text"
                name={item.COLUMN_NAME}
                onChange={this.handleChange}
                value={this.state.statedefault[0][item.COLUMN_NAME]}
                onKeyDown={this.autocomplete}
              />
              <span>↹</span>
            </div>

            <div key={i + ""} className="col-6">
              <p>Bv</p>

              <select type="text" name="bv" onChange={this.handleChangeNames} value={this.state.bv}>
                <option key="-1" value="-">
                  -
                </option>
                {this.state.bvAll.map((item, i) => (
                  <option key={i + ""} value={item.bv}>
                    {item.bv}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      }
      if (item.COLUMN_NAME === "customerId") {
        return (
          <div key={i + ""} className="col-6 group-sql">
            <div className="col-6 tab">
              <p>{this.replaceAll(item.COLUMN_NAME)}</p>

              <input
                type="text"
                name={item.COLUMN_NAME}
                onChange={this.handleChange}
                value={this.state.statedefault[0][item.COLUMN_NAME]}
                onKeyDown={this.autocomplete}
              />
              <span>↹</span>
            </div>
            <div key={i + ""} className="col-6">
              <p>Kunde</p>

              <select type="text" name="customer" onChange={this.handleChangeNames} value={this.state.customer}>
                <option key="-1" value="-">
                  -
                </option>
                {this.state.customerAll.map((item, i) => (
                  <option key={i + ""} value={item.customerName}>
                    {item.customerName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      }
      if (item.COLUMN_NAME === "supplierId") {
        return (
          <div key={i + ""} className="col-6 group-sql">
            <div className="col-6 tab">
              <p>{this.replaceAll(item.COLUMN_NAME)}</p>

              <input
                type="text"
                name={item.COLUMN_NAME}
                onChange={this.handleChange}
                value={this.state.statedefault[0][item.COLUMN_NAME]}
                onKeyDown={this.autocomplete}
              />
              <span>↹</span>
            </div>
            <div key={i + ""} className="col-6">
              <p>Lieferant</p>

              <select type="text" name="supplier" onChange={this.handleChange} value={this.state.supplier}>
                <option key="-1" value="-">
                  -
                </option>
                {this.state.supplierAll.map((item, i) => (
                  <option key={i + ""} value={this.supplierName}>
                    {item.supplierName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      }

      if (
        item.COLUMN_NAME === "date" ||
        item.COLUMN_NAME === "rechnungsdatum" ||
        item.COLUMN_NAME === "zahlungsdatum" ||
        item.COLUMN_NAME === "zahlungsdatumVOB" ||
        item.COLUMN_NAME === "betragEingangDatum" ||
        item.COLUMN_NAME === "dateSaved" ||
        item.COLUMN_NAME === "notesDate" 
      ) {
        return (
          <div key={i + ""} className="col-3">
            <div className="tooltip">
              <p>
                {this.replaceAll(item.COLUMN_NAME)} <FontAwesomeIcon icon="info-circle" />{" "}
              </p>
              <span className="tooltiptext">
                <b>{"Erklärung:"}</b>
                <br />
                {"GLEICH: dd.mm.yyyy"}
                <br />
                {"AB: >= dd.mm.yyyy"}
                <br />
                {"VON-BIS: >= dd.mm.yyyy <= dd.mm.yyyy"}
              </span>
            </div>
            <input type="text" name={item.COLUMN_NAME} onChange={this.handleChange} value={this.state.statedefault[0][item.COLUMN_NAME]} />
          </div>
        );
      } else {
        return (
          <div key={i + ""} className="col-3">
            <p>{this.replaceAll(item.COLUMN_NAME)}</p>
            <input type="text" name={item.COLUMN_NAME} onChange={this.handleChange} value={this.state.statedefault[0][item.COLUMN_NAME]} />
          </div>
        );
      }
    });
  };
  convertDate = (date) => {
    date = date.replace(/ /g, "");

    if (date.includes("<=")) {
      date = date.split("<=");
      var dateNew1 = date[0].split(".");
      var dateNew2 = date[1].split(".");
      dateNew1 = dateNew1[2] + "-" + dateNew1[1] + "-" + dateNew1[0];
      dateNew2 = dateNew2[2] + "-" + dateNew2[1] + "-" + dateNew2[0];

      return "BETWEEN " + "'" + dateNew1.replace(/ /g, "") + "'" + " AND " + "'" + dateNew2.replace(/ /g, "") + "'";
    } else {
      date = date.replace(" ", "");
      var dateNew = date.split(".");
      dateNew = dateNew[2] + "-" + dateNew[1] + "-" + dateNew[0];
      return dateNew;
    }
  };
  createQuery = (value) => (e) => {
    e.preventDefault();
    this.setState({
      showError: false,
    });
    let query = ``;
    if (value !== "complete") {
      if (this.state.bv != "") {
        let bvIdCheck = this.state.bvNr === undefined ? this.state.bvId : this.state.bvNr
        query = `SELECT X.bv,${this.state.tableName}.* FROM ${this.state.tableName}, (SELECT bv from bv WHERE bvId = ${bvIdCheck}) AS X WHERE`;

      } else {
        query = `SELECT * FROM ${this.state.tableName} WHERE`;
      }

      let countUp = 0;

      this.state.columnNames.map((name) => {
        if (this.state[name.COLUMN_NAME] === undefined) {
          countUp += 1;
        }
        if (this.state[name.COLUMN_NAME] !== undefined) {
          if (
            name.COLUMN_NAME === "date" ||
            name.COLUMN_NAME === "rechnungsdatum" ||
            name.COLUMN_NAME === "zahlungsdatum" ||
            name.COLUMN_NAME === "zahlungsdatumVOB" ||
            name.COLUMN_NAME === "betragEingangDatum" ||
            name.COLUMN_NAME === "dateSaved" ||
            name.COLUMN_NAME === "notesDate"
          ) {
            query += ` AND ${name.COLUMN_NAME} >= "${this.convertDate(this.state[name.COLUMN_NAME].replace(">=", ""))}"`;
            if (this.state[name.COLUMN_NAME].length >= 16) {
              query = query.replace(">=", "").replace(/"/g, "");
            }
            if (this.state[name.COLUMN_NAME].length === 10) {
              query = `SELECT * FROM ${this.state.tableName} WHERE ${name.COLUMN_NAME} = "${this.convertDate(this.state[name.COLUMN_NAME])}"`;
            }
          } else {
            if (
              name.COLUMN_NAME === "bv" ||
              name.COLUMN_NAME === "supplier" ||
              name.COLUMN_NAME === "supplierName" ||
              name.COLUMN_NAME === "customer" ||
              name.COLUMN_NAME === "customerName"
            ) {
              query += "";
            } else {
              query += ` AND ${name.COLUMN_NAME} LIKE "${this.state[name.COLUMN_NAME]}"`;
            }
          }
          if(this.state.tableName === "deliverynotes"){
            query += ' GROUP BY id';
          }
        }
      });
      if (countUp === this.state.columnNames.length) {
        query = `SELECT * FROM ${this.state.tableName}`;
      }
      query = query.replace("AND", "");
    } else {
      query = `SELECT * FROM ${this.state.tableName}`;
    }
    this.getQuery(query);
  };
  replaceDot = (price) => {
    return price.replace(".", ",");
  };
  getQuery = (query) => {
    let helpFunc = [];
    let add = "";
    this.setState({
      getQueryAnswer: undefined,
    });
    return fetch(BaseUrl + "api/sqlquerys/getQueryAnswer.php?query=" + query)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "No items found.") {
          this.setState({
            showError: true,
            getQueryAnswer: undefined,
          });
        } else {
           if (this.state.tableName === "bills") {
             setTimeout(() => {
              responseJson.records[0].map((i) => {
                add = {
                  id: i.id,
                  billsNr: i.billsNr,
                  date: i.date,
                  notesNr: i.notesNr,
                  notesDate: i.notesDate,
                  supplierId: i.supplierId,
                  itemId: i.itemId,
                  itemDsc: i.itemDsc,
                  itemAmnt: this.replaceDot(i.itemAmnt),
                  itemUnit: i.itemUnit,
                  bvId: i.bvId === undefined ? i.bvNr : i.bvId,
                  pricePiece: this.replaceDot(i.pricePiece),
                  discount: i.discount,
                  priceComplete: this.replaceDot(i.priceComplete),
                };

                helpFunc = [...helpFunc, add];
              });

              this.setState({
                getQueryAnswer: helpFunc,
              });
            }, 2000);
          } 
          else if (this.state.tableName === "deliverynotes") {
            setTimeout(() => {
              responseJson.records[0].map((i) => {
                add = {
                  id: i.id,
                  date: i.date,
                  notesNr: i.notesNr,
                  supplierId: i.supplierId,
                  itemId: i.itemId,
                  itemDsc: i.itemDsc,
                  itemAmnt: this.replaceDot(i.itemAmnt),
                  itemUnit: i.itemUnit,
                  bvId: i.bvId === undefined ? i.bvNr : i.bvId,
                };

                helpFunc = [...helpFunc, add];
              });

              this.setState({
                getQueryAnswer: helpFunc,
              });
            }, 2000);
          } else if (this.state.tableName === "billbook") {
            setTimeout(() => {
              responseJson.records[0].map((i) => {
                add = {
                  id: i.id,
                  rechnungsNr: i.rechnungsNr,
                  rechnungsdatum: i.rechnungsdatum,
                  customerId: i.customerId,
                  bvNr: i.bvNr,
                  rechnugsbetrag: this.replaceDot(i.rechnungsbetrag),
                  skonto: i.skonto,
                  betragSkonto: this.replaceDot(i.betragSkonto),
                  betragEingang: this.replaceDot(i.betragEingang),
                  betragEingangDatum: i.betragEingangDatum,
                  sr_az: i.sr_az,
                  azNr: i.azNr,
                  zahlungsdatum: i.zahlungsdatum,
                  zahlungsdatumVOB: i.zahlungsdatumVOB,
                  mahnung1: i.mahnung1,
                  mahnung2: i.mahnung2,
                  mahnung3: i.mahnung3,
                  bezahlt: i.bezahlt,
                };

                helpFunc = [...helpFunc, add];
              });

              this.setState({
                getQueryAnswer: helpFunc,
              });
            }, 2000);
          } else if (this.state.tableName === "offer") {
            setTimeout(() => {
              responseJson.records[0].map((i) => {
                add = {
                  id: i.id,
                  offerNr: i.offerNr,
                  version: i.version,
                  date: i.date,
                  customerId: i.customerId,
                  streetCustomer: i.streetCustomer,
                  zipCodeCustomer: i.zipCodeCustomer,
                  cityCustomer: i.cityCustomer,
                  bv: i.bv,
                  buildingStart: i.buildingEnd,
                  buildingEnd: i.buildingEnd,
                  paragraph: i.paragraph,
                  termOfPayment: i.termOfPayment,
                  discount: i.discount,
                  pos: i.pos,
                  nr: i.nr,
                  dsc: i.dsc,
                  amnt: i.amnt,
                  unit: i.unit,
                  pricePiece: this.replaceDot(i.pricePiece),
                  priceComplete: this.replaceDot(i.priceComplete)
                };

                helpFunc = [...helpFunc, add];
              });

              this.setState({
                getQueryAnswer: helpFunc,
              });
            }, 2000);
          } else {
            setTimeout(() => {
              this.setState({
                getQueryAnswer: responseJson.records[0],
              });
            }, 2000);
          }
        }
      })
      .catch((error) => {});
  };

  lengthAnswers = () => {
    if (this.state.getQueryAnswer !== undefined) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    return (
      <React.Fragment>
        <section id="sqlquerys">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--redBtn)" icon="database" /> Abfragen
              </h1>
            </div>
          </div>
          <form className="SqlQuerys" id="resetTable" onSubmit={this.handleSubmit}>
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="col-md-2">
                <p>Tabellenname</p>
                <select name="tableName" value={this.state.tableName} onChange={this.handleChange}>
                  <option key="-1" value="-">
                    -
                  </option>
                  {this.state.tableNamesAll.length > 0 ? this.showTableNames() : ""}
                </select>
              </div>
              <div className="row">{this.state.columnNames.length > 0 ? this.show() : ""}</div>
              <div className="row">
                <div className="col-3">
                  <button onClick={this.createQuery("complete")} className="btn-create-item">
                    Komplette DB drucken
                  </button>
                </div>
                <div className="offset-6 col-3">
                  <button onClick={this.createQuery("notComplete")} className="btn-create-item">
                    Abfrage Starten
                  </button>
                </div>
                <div className={this.state.showError ? "hide" : "show"}>
                  {this.lengthAnswers() ? (
                    <div className="offset-9 col-3">
                      <CSVLink
                        className="btn-create-item fa-3x"
                        data={this.state.getQueryAnswer}
                        separator={";"}
                        filename={`Abfrage-${this.replaceTableName(this.state.tableName)}.csv`}
                      >
                        <FontAwesomeIcon icon="download" />{" "}
                      </CSVLink>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className={this.state.showError ? "show errorEmpty offset-9 col-3" : "hide"}>
                  <h5>Abfrage liefert kein Ergebnis</h5>
                </div>
              </div>
            </fieldset>
          </form>
        </section>
      </React.Fragment>
    );
  }
}

export default SqlQuerys;
