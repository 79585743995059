import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CSVLink, CSVDownload } from "react-csv";
var $ = require("jquery");

class WorkerQuerys extends Component {
  constructor() {
    super();
    this.state = {
      dateFrom: "",
      dateTo: "",
      getQueryAnswer: undefined,
      countComplete: [],
      showErrorDate: false,
      sortBy: "arbeitsstunden",
      months: [
        { name: "Januar", id: "01" },
        { name: "Februar", id: "02" },
        { name: "März", id: "03" },
        { name: "April", id: "04" },
        { name: "Mai", id: "05" },
        { name: "Juni", id: "06" },
        { name: "Juli", id: "07" },
        { name: "August", id: "08" },
        { name: "September", id: "09" },
        { name: "Oktober", id: "10" },
        { name: "November", id: "11" },
        { name: "Dezember", id: "12" },
      ],
      wait: false
    };
  }

  componentDidMount = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  reset = () => {
    document.getElementById("resetTable").reset();
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };
  handleSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };
  getMonthsNumbers = () => {
    let monthFrom = "";
    let monthTo = "";

    monthFrom = this.state.dateFrom.split("-");
    monthFrom = monthFrom[1];
    monthTo = this.state.dateTo.split("-");
    monthTo = this.state.dateTo ? monthTo[1] : 12;

    let help = this.state.months.filter((monthId) => monthId.id >= monthFrom && monthId.id <= monthTo);
    return help;
  };
  getYears = (what) => {
    let yearFrom = "";
    let yearTo = "";
    if (what === "from") {
      yearFrom = this.state.dateFrom.split("-");
      yearFrom = yearFrom[0];
      return yearFrom;
    } else if (what === "to") {
      yearTo = this.state.dateTo.split("-");
      yearTo = yearTo[0];
      return yearTo;
    } else return false;
  };
  createQuery = (value) => (e) => {
    let query = "";
    let dateToString = "";
    let queryMonth = "";
    let monthsBetween = this.getMonthsNumbers();
    let yearFrom = this.getYears("from");
    let yearTo = this.getYears("to");
    let queryMonthText ="";
    e.preventDefault();
    this.setState({
      showError: false,
    });
    if (this.state.dateTo) {
      dateToString = ` AND date <= '${this.state.dateTo}'`;
    } else {
      dateToString = "";
    }
    queryMonth = monthsBetween.map((item) => {
      queryMonthText += ` OR (month = '${item.name}' AND year = '${yearFrom}')`;
    });
    query = `(SELECT CONCAT(lastName, ' ', firstName) As name,
                      COUNT(DISTINCT case WHEN payed = "" OR payed = "-" THEN date end) as arbeitstage,  
                      SUM(payed = "Feiertag") as feiertag, 
                      SUM(payed = "Krank") as krank, 
                      SUM(payed = "Kurzarbeit") as kurzarbeit, 
                      SUM(payed = "Unbezahlt") as unbezahlt,
                      SUM(payed = "Urlaub") as urlaub, 
                      SUM(TIME_TO_SEC(CONVERT(result , TIME))) as arbeitsstunden
                      FROM hoursreporting WHERE date >= '${this.state.dateFrom}'${dateToString} ${queryMonthText} GROUP BY name) 
  `;

    this.getQuery(query);
  };
  secondsToHm = (d) => {
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var helpedM = m < 10 ? "0" + m : m;
    return h + ":" + helpedM;
  };
  getQuery = (query) => {
    if (this.state.dateFrom) {
      let help = [];
      let complete = [];
                  let gesamtArbeitstage = 0;
                  let gesamtFeiertage = 0;
                  let gesamtKrank = 0;
                  let gesamtKurzarbeit = 0;
                  let gesamtUnbezahlt = 0;
                  let gesamtUrlaub = 0;
                  let gesamtArbeitsstunden = 0.0;
      this.setState({
        getQueryAnswer: undefined,
        showErrorDate: false,
        wait: true
      });
      return fetch(BaseUrl + "api/workerquerys/getQueryAnswerWorker.php?query=" + query)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message === "No items found.") {
            this.setState({
              showError: true,
              getQueryAnswer: undefined,
            });
          } else {
            responseJson.records[0].map((item, i) => {
              help = [
                ...help,
                {
                  name: item.name,
                  arbeitstage: item.arbeitstage,
                  feiertag: item.feiertag,
                  krank: item.krank,
                  kurzarbeit: item.kurzarbeit,
                  unbezahlt: item.unbezahlt,
                  urlaub: item.urlaub,
                  arbeitsstunden: this.secondsToHm(item.arbeitsstunden),
                },
              ];
              gesamtArbeitstage = gesamtArbeitstage + parseInt(item.arbeitstage);
              gesamtFeiertage = gesamtFeiertage + parseInt(item.feiertag);
              gesamtKrank = gesamtKrank + parseInt(item.krank);
              gesamtKurzarbeit = gesamtKurzarbeit + parseInt(item.kurzarbeit);
              gesamtUnbezahlt = gesamtUnbezahlt + parseInt(item.unbezahlt);
              gesamtUrlaub = gesamtUrlaub + parseInt(item.urlaub);
              gesamtArbeitsstunden = gesamtArbeitsstunden + parseInt(item.arbeitsstunden);
            });
             setTimeout(() => {
              this.setState({
                getQueryAnswer: help,
                countComplete: [{
                   name: "Gesamtsumme",
                  arbeitstage: gesamtArbeitstage,
                  feiertag: gesamtFeiertage,
                  krank: gesamtKrank,
                  kurzarbeit: gesamtKurzarbeit,
                  unbezahlt: gesamtUnbezahlt,
                  urlaub: gesamtUrlaub,
                  arbeitsstunden: this.secondsToHm(gesamtArbeitsstunden),
                }],
                wait: false
              });
            }, 2000);
          }
        })
        .catch((error) => {});
    } else {
      this.setState({
        showErrorDate: true,
       });
    }
  };

  lengthAnswers = () => {
    if (this.state.getQueryAnswer !== undefined) {
      return true;
    } else {
      return false;
    }
  };
  sortList = (e) => {
    e.preventDefault();

    this.setState({
      sortBy: e.target.name,
    });
  };
  sorting = (a, b) => {
    if (this.state.sortBy === "name") {
      return a[this.state.sortBy] > b[this.state.sortBy] ? 1 : -1;
    } else if (this.state.sortBy === "arbeitsstunden") {
      return parseInt(b[this.state.sortBy]) > parseInt(a[this.state.sortBy]) ? 1 : -1;
    } else {
      return b[this.state.sortBy] - a[this.state.sortBy];
    }
  };
  render() {
    return (
      <React.Fragment>
        <section id="workerQuerys">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--redBtn)" icon="database" /> Mitarbeiter-Abfragen
              </h1>
            </div>
          </div>
          <form className="workerQuerys" id="resetTable" onSubmit={this.handleSubmit}>
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="row">
                <div className="col-2">
                  <p>Datum von</p>
                  <input required type="date" name="dateFrom" placeholder="Datum von" onChange={this.handleChange} value={this.state.dateFrom} />
                </div>
                <div className="col-2">
                  <p>Datum bis</p>
                  <input type="date" name="dateTo" placeholder="Datum von" onChange={this.handleChange} value={this.state.dateTo} />
                </div>
              </div>
              <div className="row">
                <div className="col-12 margin-workerQuerys">
                  {this.state.wait ? (
                    <div className="wait">
                      <div className="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.lengthAnswers() ? (
                    <div className="row border-bottom-workerQuerys">
                      <div className="col-2">
                        <button name="name" onClick={this.sortList}>
                          Name <FontAwesomeIcon icon="sort-amount-down" />
                        </button>
                      </div>
                      <div className="col">
                        <button name="arbeitstage" onClick={this.sortList}>
                          Arbeitstage <FontAwesomeIcon icon="sort-amount-down" />
                        </button>
                      </div>
                      <div className="col">
                        <button name="feiertag" onClick={this.sortList}>
                          Feiertag <FontAwesomeIcon icon="sort-amount-down" />
                        </button>
                      </div>
                      <div className="col">
                        <button name="krank" onClick={this.sortList}>
                          Krank <FontAwesomeIcon icon="sort-amount-down" />
                        </button>
                      </div>
                      <div className="col">
                        <button name="kurzarbeit" onClick={this.sortList}>
                          Kurzarbeit <FontAwesomeIcon icon="sort-amount-down" />
                        </button>
                      </div>
                      <div className="col">
                        <button name="unbezahlt" onClick={this.sortList}>
                          Unbezahlt <FontAwesomeIcon icon="sort-amount-down" />
                        </button>
                      </div>
                      <div className="col">
                        <button name="urlaub" onClick={this.sortList}>
                          Urlaub <FontAwesomeIcon icon="sort-amount-down" />
                        </button>
                      </div>
                      <div className="col-2 text-right">
                        <button name="arbeitsstunden" onClick={this.sortList}>
                          Arbeitsstunden <FontAwesomeIcon icon="sort-amount-down" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.lengthAnswers()
                    ? this.state.getQueryAnswer
                        .sort((a, b) => this.sorting(a, b))
                        .map((item, i) => {
                          return (
                            <div key={i}>
                              <div className="row">
                                <div className="col-2">{item.name}</div>
                                <div className="col">{item.arbeitstage}</div>
                                <div className="col">{item.feiertag}</div>
                                <div className="col">{item.krank}</div>
                                <div className="col">{item.kurzarbeit}</div>
                                <div className="col">{item.unbezahlt}</div>
                                <div className="col">{item.urlaub}</div>
                                <div className="col-2 text-right">{item.arbeitsstunden}</div>
                              </div>
                            </div>
                          );
                        })
                    : ""}
                  {this.lengthAnswers()
                    ? this.state.countComplete.map((item, i) => {
                        return (
                          <div key={i}>
                            <div className="row border-top-workerQuerys">
                              <div className="col-2">{item.name}</div>
                              <div className="col">{item.arbeitstage}</div>
                              <div className="col">{item.feiertag}</div>
                              <div className="col">{item.krank}</div>
                              <div className="col">{item.kurzarbeit}</div>
                              <div className="col">{item.unbezahlt}</div>
                              <div className="col">{item.urlaub}</div>
                              <div className="col-2 text-right">{item.arbeitsstunden}</div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                  {this.lengthAnswers() ? (
                    <div className="offset-9 col-3">
                      <CSVLink
                        className="btn-create-item fa-3x"
                        data={this.state.getQueryAnswer}
                        separator={";"}
                        filename={`Abfrage-Mitarbeiter.csv`}
                      >
                        <FontAwesomeIcon icon="download" />{" "}
                      </CSVLink>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="offset-9 col-3">
                  <button onClick={this.createQuery("notComplete")} className="btn-create-item">
                    Abfrage Starten
                  </button>
                </div>

                <div className={this.state.showError ? "show errorEmpty offset-9 col-3" : "hide"}>
                  <h5>Abfrage liefert kein Ergebnis</h5>
                </div>
                <div className={this.state.showErrorDate ? "show errorEmpty offset-9 col-3" : "hide"}>
                  <h5>Bitte "Datum von" eingeben </h5>
                </div>
              </div>
            </fieldset>
          </form>
        </section>
      </React.Fragment>
    );
  }
}

export default WorkerQuerys;
