import React, { Component } from "react";

import "../css/bootstrap-grid.css";
import "../css/App.css";
import "../css/index.css";
import "../css/mediaQuerys.css";
 
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class HoursReportingWageAccountingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      years: this.props.years,
      month: this.props.month,
      showHoursReport: this.props.showHoursReport,
      hideList: this.props.hideList,
      i: this.props.i
    };
  }


  convertDate = date => {
    if (date === null || date === "0000-00-00" || date === undefined) {
      return date;
    }

    if (date.includes("-")) {
      var dateNew = date.split("-");
      dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  convertDateNormal = date => {
    if (date === null || date === "0000-00-00" || date === undefined) {
      return date;
    }

    if (date.includes(".")) {
      var dateNew = date.split(".");
      dateNew = dateNew[2] + "-" + dateNew[1] + "-" + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-break">
          <div className="print">
            <div className=" row">
              <div className="col-9">
                <h4>Stundenbericht</h4>
              </div>
              <div className="col-3">
                <img src={require("../img/logo.png")} alt="" />
              </div>
            </div>
          </div>
          <div className="print">
            <div className=" row">
              <div className="col-12">
                <h3>
                  Name: {this.state.lastName} {this.state.firstName}
                </h3>
                <h3>
                  Zeitraum: {this.state.month} {this.state.years}
                </h3>
                <br />
              </div>
            </div>
          </div>

          <div className={this.state.hideList ? "hide " : "  wage-accounting"}>
            <div className="no-print center">
              <h3>
                Name: {this.state.lastName} {this.state.firstName}
              </h3>
              <h3>
                Zeitraum: {this.state.month} {this.state.years}
              </h3>
            </div>
            <ul className="print-font   offset-3 col-6">
              <span className={this.state.hideSpan ? "row hide" : "row"}>
                <p className="col-4">
                  <span>Datum:</span>
                </p>
                <p className="col-4">
                  <span>Bezahlte h:</span>
                </p>
                <p className="col-4">
                  <span>Ergebnis:</span>
                </p>
              </span>

              {this.state.i.map((item, ix) => {
                return (
                  <div key={ix} className="row ">
                    <p key={ix} className="col-4">
                      {this.convertDate(item.date)}
                    </p>
                    <p key={ix + 1} className="col-4">
                      {item.payed}
                    </p>
                    <p key={ix + 2} className="col-4">
                      {item.result} h
                    </p>
                  </div>
                );
              })}
            </ul>
          </div>
        </div>
        </React.Fragment>
    );
  }
}
export default HoursReportingWageAccountingList;
