import React, { Component } from "react";
import ModalSavedCalcHourlyWage from "./ModalSavedCalcHourlyWage.js";
 import "@fortawesome/fontawesome-free-solid";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";

class SavedCalcHourlyWage extends Component {
  constructor() {
    super();
    this.state = {
      id:"",
      lastName: "",
      firstName: "",
      date: "",
      sk_h: "",
      angebotspreis: "",
      SavedCalcHourlyWagesAll: [],
      SavedCalcHourlyWagesAllCopy: [],
      errorSearch: false,
      errorInput: false,
      search: "",
       useSearch: false
    };
  }

  componentDidMount = () => {
    this.loadSavedCalcHourlyWages();
  };
  componentWillUnmount = () => {};
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      errorInput: false
    });
  };

  loadSavedCalcHourlyWages = () => {
    return fetch(BaseUrl + "api/calcHourlyWage/readSavedCalcHourlyWages.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(SavedCalcHourlyWage => {
          return this.setState({
            SavedCalcHourlyWagesAll: responseJson.records,
            SavedCalcHourlyWagesAllCopy: responseJson.records
          });
        });
      })
      .catch(error => {});
  };

  onKeyPress = e => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };
  handleSearch = e => {
    this.setState({
      search: e.target.value
    });

    if (e.target.value === "") {
      this.setState({
        SavedCalcHourlyWagesAll: this.state.SavedCalcHourlyWagesAllCopy,
        errorSearch: false
      });
    }
  };

  search = eingabe => {
    let convertEingabe = eingabe.replace(/ /g, "%").replace("+", "%2B");

    if (eingabe === "") {
      this.setState({
        SavedCalcHourlyWagesAll: this.state.SavedCalcHourlyWagesAllCopy,
        errorSearch: false
      });
    } else {
      this.setState({
        SavedCalcHourlyWagesAll: []
      });
      return fetch(
        BaseUrl + "api/calcHourlyWage/searchSavedCalcHourlyWages.php?eingabe=" + convertEingabe
      )
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.message === "No items found.") {
            this.setState({
              SavedCalcHourlyWagesAll: [],
              errorSearch: true
            });
          } else {
            this.setState({
              SavedCalcHourlyWagesAll: responseJson.records
            });
          }
        })
        .catch(error => {});
    }
  };
  render() {
    return (
      <React.Fragment>
        <section id="insertCalcHourlyWage">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home/stundenkalkulation">
                <FontAwesomeIcon icon="arrow-left" /> Zurück
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--redBtn)" icon="list-ol" />{" "}
                Übersicht Mitarbeiterkosten/h
              </h1>
            </div>
          </div>

        </section>
        <section id="showSavedCalcHourlyWages" className="display-item">
           <div className="show-items-btn row">
            <div className="col-12 offset-6">
              <input
                className="search"
                type="text"
                name="search"
                placeholder="Suchen"
                onChange={this.handleSearch}
                onKeyPress={this.onKeyPress}
                value={this.state.search}
              />
            </div>
          </div>
          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          <ul className="items-list">
            {this.state.SavedCalcHourlyWagesAll.map(SavedCalcHourlyWage => {
              return (
                <ModalSavedCalcHourlyWage
                  onChange={this.handleChange}
                  key={SavedCalcHourlyWage.id}
                  id={SavedCalcHourlyWage.id}
                  lastName={SavedCalcHourlyWage.lastName}
                  firstName={SavedCalcHourlyWage.firstName}
                  date={SavedCalcHourlyWage.date}
                  sk_h={SavedCalcHourlyWage.sk_h}
                  angebotspreis={SavedCalcHourlyWage.angebotspreis}
                />
              );
            })}
          </ul>
        </section>
      </React.Fragment>
    );
  }
}

export default SavedCalcHourlyWage;
