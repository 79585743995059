import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../config/config.js";
var $ = require("jquery");
var Highlight = require('react-highlighter');
 var currencyFormatter = require("currency-formatter");
class ModalSpecificationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      id: this.props.id,
      pos: this.props.pos,
      dsc: this.props.dsc,
      unit: this.props.unit,
      pricePiece: this.props.pricePiece,
      targetQuantity: this.props.targetQuantity,

      showUpdate: true,
      showDelete: false,
      hideItem: false,
     };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    if(sessionStorage.getItem("userData").indexOf("admin") === -1){
       this.setState({
       modal:false
     })

   }
   else{
     this.setState({
       modal: !this.state.modal
     });
   }
  };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if(e.target.name === "supplier"){
       this.readSupplierId(e.target.value);
    }
    if(e.target.name === "pricePiece"){
      this.setState({
              [e.target.name]: e.target.value.replace(/,/g, '.')
      })
    }
  };

  updateItem = e => {
    let updateSL = {
      id: this.state.id,
      pos: this.state.pos,
      dsc: this.state.dsc,
      unit: this.state.unit,
      pricePiece: this.state.pricePiece,
      targetQuantity: this.state.targetQuantity
    };

     $.ajax({
      url: BaseUrl + "api/specificationList/updateSpecificationList.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(updateSL),
      success: function(response) {
        this.setState({
          modal: !this.state.modal
        });
      }.bind(this),
      error: function(xhr, resp, text) {
        }
    });

    e.preventDefault();
  };

  removeItem = () => {
     let deleteItem = {
      id: this.state.id
    };

    $.ajax({
      url: BaseUrl + "api/specificationList/deleteSpecificationList.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function(response) {
        this.setState({
          modal: !this.state.modal,
          hideItem: true
        });
      }.bind(this),
      error: function(xhr, resp, text) {

      }
    });
  };
  readBVId = eingabe => {
    return fetch(
      BaseUrl + "api/all/readBvId.php?eingabe=" +
        eingabe
    )
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
           return this.setState({
            bvId: item.bvId,
           });
        });

      })
      .catch(error => {});
  };
  readSupplierId = eingabe => {
    return fetch(
      BaseUrl + "api/all/readSupplierId.php?eingabe=" +
        eingabe
    )
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
           return this.setState({
            supplierId: item.supplierId,
           });
        });

      })
      .catch(error => {});
  };
  autocompleteBvSupplier = e => {
    if (e.keyCode === 9 && e.target.name === "supplierId") {
      this.getSupplier(e);
    }
  };
  getSupplier = evt => {
    const convertInput = evt.target.value.replace(/ /g, " ");
    return fetch(
      BaseUrl + "api/all/readSupplier.php?eingabe=" +
        convertInput
    )
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            supplier: item.supplierName,
           });
        });

      })
      .catch(error => {});
  };
  convertPrice = price => {
    return currencyFormatter.format(price, { locale: "de-DE" });
  };
  render() {
     return (
      <React.Fragment>
        <li
          onClick={this.toggle}
          className={this.state.hideItem ? "hide " : " "}
          key={this.state.id}
        >
          <div className="row fullwidth showSpan">
            <p className="col"><span>Pos</span><br/><Highlight id="font-normal"  search={this.props.search}>{this.state.pos}</Highlight></p>
            <p className="col-6"><span>Artikelbeschreibung</span><br/> <Highlight id="font-normal"  search={this.props.search}>{this.state.dsc}</Highlight> </p>
            <p className="col"><span>Einheit</span><br/>{this.state.unit}</p>
            <p className="col"><span>Einzelpreis</span><br/>{this.convertPrice(this.state.pricePiece)}</p>
            <p className="col"><span>Sollmenge</span><br/><Highlight id="font-normal"  search={this.props.search}>{this.state.targetQuantity}</Highlight></p>

          </div>
        </li>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal-header">
            <p className={this.state.showUpdate ? "show" : "hide"}>
              Artikel Bearbeiten
            </p>
            <p className={this.state.showDelete ? "show" : "hide"}>
              Artikel wirklich Löschen?<br />
              <span>Dieser Vorgang kann nicht Rückgänging gemacht werden</span>
            </p>
            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <form>
                <div className="row">
                  <div className="col-md-2">
                    <p>Pos</p>
                    <input
                      type="text"
                      name="pos"
                      onChange={this.handleChange}
                      value={this.state.pos}
                    />
                  </div>
                  <div className="col-md-8">
                    <p>Artikelbezeichnung</p>
                    <input
                      type="text"
                      name="dsc"
                      onChange={this.handleChange}
                      value={this.state.dsc}
                    />
                  </div>
                  <div className="col-md-2">
                    <p>Einheit</p>
                    <select
                      name="unit"
                      onChange={this.handleChange}
                      value={this.state.unit}
                    >
                      <option key="-1" value="-">
                        -
                      </option>
                      <option key={1} value="Beutel">
                        Beutel
                      </option>
                      <option key={2} value="Bund">
                        Bund
                      </option>
                      <option key={3} value="Dose(n)">
                        Dose(n)
                      </option>
                      <option key={4} value="Eimer">
                        Eimer
                      </option>
                      <option key={5} value="Flasche(n)">
                        Flasche(n)
                      </option>
                      <option key={6} value="Gebinde">
                        Gebinde
                      </option>
                      <option key={7} value="Gramm">
                        Gramm
                      </option>
                      <option key={8} value="Karton">
                        Karton
                      </option>
                      <option key={9} value="KG">
                        KG
                      </option>
                      <option key={10} value="Kübel">
                        Kübel
                      </option>
                      <option key={11} value="LE">
                        LE
                      </option>
                      <option key={12} value="Lfm.">
                        Lfm.
                      </option>
                      <option key={13} value="Liter">
                        Liter
                      </option>
                      <option key={14} value="m2">
                        m2
                      </option>
                      <option key={15} value="m3">
                        m3
                      </option>
                      <option key={16} value="Minuten">
                        Minuten
                      </option>
                      <option key={17} value="Paar">
                        Paar
                      </option>
                      <option key={18} value="Pack">
                        Pack
                      </option>
                      <option key={19} value="Paket">
                        Paket
                      </option>
                      <option key={20} value="Palette(n)">
                        Palette(n)
                      </option>
                      <option key={21} value="Pauschal">
                        Pauschal
                      </option>
                      <option key={22} value="Rolle(n)">
                        Rolle(n)
                      </option>
                      <option key={23} value="Sack">
                        Sack
                      </option>
                      <option key={24} value="Satz">
                        Satz
                      </option>
                      <option key={25} value="Set">
                        Set
                      </option>
                      <option key={26} value="Stück">
                        Stück
                      </option>
                      <option key={27} value="Stunden">
                        Stunden
                      </option>
                      <option key={28} value="Tag/e">
                        Tag/e
                      </option>
                      <option key={29} value="Tonne(n)">
                        Tonne(n)
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row">

                  <div className="col-2">
                    <p>Preis/Stk</p>
                    <input
                      type="text"
                      name="pricePiece"
                      onChange={this.handleChange}
                      value={this.state.pricePiece}
                    />
                  </div>
                  <div className="col-2">
                    <p>Sollmenge</p>
                    <input
                      type="text"
                      name="targetQuantity"
                      onChange={this.handleChange}
                      value={this.state.targetQuantity}
                    />
                  </div>
                </div>
              </form>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Löschen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.updateItem} className="btn-green">
                    Ändern <FontAwesomeIcon icon={["far", "edit"]} />
                  </button>
                </div>
              </div>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Nein <FontAwesomeIcon icon="ban" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.removeItem} className="btn-green">
                    Ja <FontAwesomeIcon icon="check-circle" />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalSpecificationList;
