import React, { Component } from "react";
import ModalSupplier from "../supplier/ModalSupplier";
import SupplierFormInputTop from "../supplier/SupplierFormInputTop";
import "@fortawesome/fontawesome-free-solid";

import BaseUrl from "../config/config.js";

class Supplier extends Component {
  constructor() {
    super();
    this.state = {
      supplierId: "",
      supplierName: "",
      street: "",
      zipCode: "",
      city: "",
      errorSearch: false,
      errorInput: false,
      search: "",
      btnAddItem: true,
      useSearch: false,
      showsupplier: [],
      showsupplierCopy: [],
      supAll: [],
      searchArr: [],
      showMissed: false
    };
  }

  componentDidMount = () => {
    this.loadItems();
  };
  sortBySupplierId = array => {
    array.sort((a, b) => {
      let x = a.supplierId;
      let y = b.supplierId;
      if (x > y) {
        return -1;
      }
      if (x < y) {
        return 1;
      }
      return 0;
    });
    return array;
  };
  loadItems = () => {
    let helpFunc = [];

    return fetch(BaseUrl + "api/supplier/readSupplierPerSupplierId.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map((item, sidx) => {

          Object.keys(item).map((supplierId, idx) => {
            const article = item[supplierId].map((i, ix) => {
              return {
                key: sidx,
                supplierId: i.supplierId,
                id: i.id,
                supName: i.supName,
                supTel: i.supTel,
                supMail: i.supMail
              };
            });
            const notes = item[supplierId].slice(0, 1).map((i, ix) => {
              return {
                key: sidx,
                supplierId: i.supplierId,
                id: i.id,
                supplierName: i.supplierName,
                street: i.street,
                zipCode: i.zipCode,
                city: i.city,
                supAll: article
              };
            });

            return helpFunc = [...helpFunc, ...notes];
          });
          return this;
        });
        this.setState({
          showsupplierCopy: this.sortBySupplierId(helpFunc),
          showsupplier: this.sortBySupplierId(helpFunc)
        });
      });
  };
  readLastSupplier = () => {
    let helpFunc = [];

    return fetch(BaseUrl + "api/supplier/readLastSupplier.php ")
      .then(response => response.json())
      .then(responseJson => {
        const article = responseJson.records.map((item, sidx) => {
          return {
            key: sidx,
            supplierId: item.supplierId,
            id: item.id,
            supName: item.supName,
            supTel: item.supTel,
            supMail: item.supMail
          };
        });
        const notes = responseJson.records.slice(0, 1).map((item, sidx) => {
          return {
            key: sidx,
            supplierId: item.supplierId,
            id: item.id,
            supplierName: item.supplierName,
            street: item.street,
            zipCode: item.zipCode,
            city: item.city,
            supAll: article
          };
        });
        helpFunc = [...this.state.showsupplier, ...notes];
        Promise.all(notes).then(values => {
          helpFunc.sort((a, b) => {
            let x = a.supplierId
              .split(".")
              .reverse()
              .join();
            let y = b.supplierId
              .split(".")
              .reverse()
              .join();
            if (x > y) {
              return -1;
            }
            if (x < y) {
              return 1;
            }
            return 0;
          });
        });
        const sorted = this.state.showsupplier.map(index => {
          return this.setState({
            showsupplier: []
          });
        });

        Promise.all(sorted).then(values => {
          this.setState({
            showsupplier: helpFunc,
            showsupplierCopy: helpFunc
          });
        });
      });
  };

  onKeyPress = e => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };

  search = search => {
    let searchHelpFunc = [];

    let convertEingabe = search.replace("+", "%2B");
    fetch(BaseUrl + "api/supplier/searchSupplier.php?eingabe=" + convertEingabe)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.message === "No items found.") {
          this.setState({
            showsupplier: [],
            errorSearch: true
          });
        } else {
          responseJson.records.map((item, sidx) => {
            return true;
          });

          this.setState({
            showsupplier: [],
            searchArr: responseJson.records,
            errorSearch: false
          });
        }
        const query = this.state.searchArr.map((item, idx) => {
          return fetch(
            BaseUrl +
              "api/supplier/searchSupplierPerNr.php?nr=" +
              item.supplierId +
              "&eingabe=" +
              convertEingabe
          )
            .then(response => response.json())
            .then(responseJson => {
              const searchArticle = responseJson.records.map((item, sidx) => {
                return {
                  key: sidx,
                  supplierId: item.supplierId,
                  id: item.id,
                  supName: item.supName,
                  supTel: item.supTel,
                  supMail: item.supMail
                };
              });
              const searchNotes = responseJson.records
                .slice(0, 1)
                .map((item, sidx) => {
                  return {
                    key: sidx,
                    supplierId: item.supplierId,
                    id: item.id,
                    supplierName: item.supplierName,
                    street: item.street,
                    zipCode: item.zipCode,
                    city: item.city,
                    supAll: searchArticle
                  };
                });

              searchHelpFunc = [...searchHelpFunc, ...searchNotes];
            });
        });
        Promise.all(query).then(values => {
          searchHelpFunc.sort((a, b) => {
            let x = a.supplierId
              .split(".")
              .reverse()
              .join();
            let y = b.supplierId
              .split(".")
              .reverse()
              .join();
            if (x > y) {
              return -1;
            }
            if (x < y) {
              return 1;
            }
            return 0;
          });
          this.setState({
            showsupplier: searchHelpFunc
          });
        });
      });
  };
  handleSearch = e => {
    this.setState({
      search: e.target.value
    });

    if (e.target.value === "") {
      const reset = this.state.showsupplier.map(index => {
        return this.setState({
          showsupplier: []
        });
      });

      Promise.all(reset).then(values => {
        this.setState({
          showsupplier: this.state.showsupplierCopy,
          errorSearch: false
        });
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <SupplierFormInputTop
          id={this.state.id}
          supplierId={this.state.supplierId}
          supplierName={this.state.supplierName}
          street={this.state.street}
          zipCode={this.state.zipCode}
          city={this.state.city}
          supAll={this.state.supAll}
          search={this.state.search}
          handleSearch={this.handleSearch}
          onKeyPress={this.onKeyPress}
          readLastSupplier={this.readLastSupplier}
          showSupplier={this.state.showSupplier}
        />
        <section id="showsuppliers" className="display-item">
          <h4>Übersicht Lieferanten</h4>
          <div className="show-items-btn row">
            <div className="col-12 offset-6">
              <input
                className="search"
                type="text"
                name="search"
                placeholder="Suchen"
                onChange={this.handleSearch}
                onKeyPress={this.onKeyPress}
                value={this.state.search}
              />
            </div>
          </div>
          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          <ul className="notes-list">
            {this.state.showsupplier.map((item, idx) => {
              return (
                <ModalSupplier
                  onChange={this.handleChange}
                  key={idx}
                  id={item.id}
                  supplierId={item.supplierId}
                  supplierName={item.supplierName}
                  supplierIdOld={item.supplierIdOld}
                  street={item.street}
                  zipCode={item.zipCode}
                  city={item.city}
                  supAll={item.supAll}
                  search={this.state.search}
                />
              );
            })}
          </ul>
        </section>
      </React.Fragment>
    );
  }
}
export default Supplier;
