import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../config/config.js";
var $ = require("jquery");
var moment = require("moment-business-days");
var Highlight = require('react-highlighter');
var currencyFormatter = require("currency-formatter");

class ModalBillbook extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalNettoBrutto: false,
      modalDelete: false,
      backdrop: true,
      id: this.props.id,
      rechnungsNr: this.props.rechnungsNr,
      rechnungsdatum: this.props.rechnungsdatum,
      customerId: this.props.customerId,
      customerName: this.props.customerName,
      bv: this.props.bv,
      bvHelp: this.props.bvHelp,
      bvNr: this.props.bvNr,
      rechnungsbetrag: this.props.rechnungsbetrag,
      skonto: this.props.skonto,
      betragSkonto: this.props.betragSkonto,
      steuersatz: this.props.steuersatz,
      betragEingang: this.props.betragEingang.replace(/,/g,'.'),
      betragEingangDatum: this.props.betragEingangDatum,
      zahlungsziel: this.props.zahlungsziel,
      sr_az: this.props.sr_az,
      azNr: this.props.azNr,
      zahlungsdatum: this.props.zahlungsdatum,
      zahlungsdatumVOB: this.props.zahlungsdatumVOB,
      mahnung1: this.props.mahnung1,
      mahnung2: this.props.mahnung2,
      mahnung3: this.props.mahnung3,
      bezahlt: this.props.bezahlt,
      showUpdate: true,
      showDelete: false,
      hideItem: false,
      bvAll: this.props.bvAll,
      calcNettoModal: this.props.betragEingang.replace(/,/g,'.'),
      bruttoWertBerrechnen: ""
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if (e.target.value === "AZ") {
      this.setState({
        isDisabledSRAZ: false
      });
    } else {
      this.setState({
        isDisabledSRAZ: true
      });
    }

  };
  handleChangeCheck = e => {
    this.setState({
      bezahlt: !this.state.bezahlt
    });
  };
  handleChangeBV = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if(e.target.name === "bv" && e.target.value === "-"){
      this.setState({
        bvNr: ""
      })
    }
    this.state.bvAll.map(bv => {
      if (e.target.value === bv.bv) {
        this.setState({
          bvNr: bv.bvId,
          kunde: bv.client
        });
      }
      return this;
     });
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
     if(sessionStorage.getItem("userData").indexOf("admin") === -1){
       this.setState({
       modal:false
     })

   }
   else{
     this.setState({
       modal: !this.state.modal
     });
   }
  };
  toggleNettoBrutto = (e) => {
    e.preventDefault();
    let help = (this.state.betragEingang * (1 + this.state.steuersatz / 100)).toFixed(2) 
     this.setState({
      modalNettoBrutto: !this.state.modalNettoBrutto,
      bruttoWertBerrechnen: help
    });
 
 };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if (e.target.name === "customerName") {
      this.readCustomerIdByName(e.target.value,true);
      this.setState({
        bvNr: "",
      });
     }

    if (e.target.name === "customerName" && e.target.value === "-") {
      this.setState({
        customerId: "",
      });
    }

    if(e.target.name === "betragEingang"){
      this.setState({
          betragEingang: e.target.value.replace(/,/g,'.')
      });
    }
    if(e.target.name === "rechnungsbetrag"){
      this.setState({
        rechnungsbetrag: e.target.value.replace(/,/g,'.')

      })
    }
  };
  updateItem = e => {
 
     let updateItem = {
      id: this.state.id,
      rechnungsNr: this.state.rechnungsNr,
      rechnungsdatum: this.state.rechnungsdatum,
      customerId: this.state.customerId,
      bvNr: this.state.bvNr,
      rechnungsbetrag: this.state.rechnungsbetrag.replace(/,/g,'.'),
      skonto: this.state.skonto.replace(/,/g,'.'),
      betragSkonto: this.state.betragSkonto.replace(/,/g,'.'),
      steuersatz: this.state.steuersatz.toString().replace(/,/g,'.'),
      betragEingang: this.state.betragEingang.replace(/,/g,'.'),
      betragEingangDatum: this.state.betragEingangDatum,
      zahlungsziel: this.state.zahlungsziel,
      sr_az: this.state.sr_az,
      azNr: this.state.azNr,
      zahlungsdatum: this.state.zahlungsdatum,
      zahlungsdatumVOB: this.state.zahlungsdatumVOB,
      mahnung1: this.state.mahnung1,
      mahnung2: this.state.mahnung2,
      mahnung3: this.state.mahnung3,
      bezahlt: this.state.bezahlt
    };

     $.ajax({
      url: BaseUrl + "api/billbook/updateBillbook.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(updateItem),
      success: function(response) {
        if(this.props.openBool){
          this.props.updateOpenBillbook(this.state.id,updateItem);

        }
 
        this.setState({
          modal: !this.state.modal
        });
      }.bind(this),
      error: function(xhr, resp, text) {
       }
    });

    e.preventDefault();
  };

  removeItem = () => {
    let deleteItem = {
      id: this.state.id
    };

    $.ajax({
      url: BaseUrl + "api/billbook/deleteBillbook.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function(response) {
        this.setState({
          modal: !this.state.modal,
          hideItem: true
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };


  autocompleteDate = e => {
    if (e.keyCode === 9) {
       if (this.state.sr_az === "SR") {
        this.vobDays = 31;
      }
      if (this.state.sr_az === "AZ") {
        this.vobDays = 22;
      }
      let calcDate = moment(this.state.rechnungsdatum, "YYYY-MM-DD")
        .businessAdd(this.state.zahlungsziel)
        .format("YYYY-MM-DD");
      let calcDateVOB = moment(this.state.rechnungsdatum, "YYYY-MM-DD")
        .businessAdd(this.vobDays)
        .format("YYYY-MM-DD");
      this.setState({
        zahlungsdatum: calcDate,
        zahlungsdatumVOB: calcDateVOB
      });
    }
  };
  convertDate = date => {
    if(date.includes('-')){
    var dateNew = date.split("-");
    dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
    return dateNew;
  }
  else{
    return date;
  }
  };
  convertDateToNorm = date => {
    var dateNew = "";
    if(date.includes('.')){
       dateNew = date.split(".");
      dateNew = dateNew[2] + "-" + dateNew[1] + "-" + dateNew[0];
      return dateNew;
    }
    else{
      dateNew = date.split("-");
      dateNew = dateNew[0] + "-" + dateNew[1] + "-" + dateNew[2];
      return dateNew;
    }

  };

  readCustomerNameById = (id, bool) => {
     let found = this.props.customerAll.find((name) => name.customerId ===  id);
    console.log(found);
    console.log(id);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
       this.getBv(id);
      return this.setState({
        customerName: found.customerName
      });
    } else {
      return found.customerName;
    }
  };
  readCustomerIdByName = (name, bool) => {
    let found = this.props.customerAll.find(id => id.customerName === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      this.getBv(found.customerId);
      return this.setState({
        customerId: found.customerId
      });
    } else {
      return found.customerId;
    }
  };
  autocomplete = e => {
       if (e.keyCode === 9 && e.target.name === "customerId") {
         this.setState({
           bv: "",
           bvNr: "",
           bvAll:[]
         })
        this.readCustomerNameById(e.target.value, true);
      }
      if (e.keyCode === 9 && e.target.name === "bvNr") {
        this.readBvNameById(e.target.value, true);
      }

  };
  autocompleteSkonto = e => {
    if (e.keyCode === 9) {
      this.calcSkonto();
    }
  };
  calcSkonto = () => {
    let price = this.state.rechnungsbetrag.replace(/,/g,".");
    if (this.state.rechnungsbetrag === "") {
      price = 0;
    }
    let sk = this.state.skonto;
    let erg = (parseFloat(price) - parseFloat((price * sk) / 100)).toFixed(2);
    this.setState({
      betragSkonto: erg
    });
  };
 
  readBvNameById = (id, bool) => {
    let found = this.state.bvAll.find(name => name.bvId === parseInt(id));
    if (found === undefined) {
      this.state.readBvCompleteById(id);
      setTimeout(() => {
        this.setState({
          bv: this.props.bvAllFound
        });
      }, 200);
    }
    if (bool === true && found !== undefined) {
      return this.setState({
        bv: found.bv
      });
    } else if (bool === false && found !== undefined) {
      return found.bv;
    }
  };
  readBvIdByName = (name, bool) => {
    let found = this.state.bvAll.find(id => id.bv === name);
     if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        bvNr: found.bvId
      });
    } else {
      return found.bvId;
    }
  };
  convertPrice = price => {
    return currencyFormatter.format(price, { locale: "de-DE" });
  };
  getBv = id => {
    this.state.bvAll=[];
     return fetch(
      BaseUrl + "api/billbook/searchBV.php?id=" +
        id
    )
      .then(response => response.json())
      .then(responseJson => {
        if(responseJson.message === "No items found."){
          return this.setState({
             isDisabled: true
          });
        }
        else{
          return this.setState({
            bvAll: responseJson.records[0],
            isDisabled: false
          });
        }
      })
      .catch(error => {});
  };
  calcNetto = (e) => {
    if (e.keyCode === 9) {
      let netto = 0;
      netto = parseFloat(this.state.bruttoWertBerrechnen.replace(',', '.')) / (1 + e.target.value.replace(',', '.') / 100);
      this.setState({
        calcNettoModal: netto.toFixed(2),
      });
    }
  };
  writeCalcNetto = () => {
    this.setState({
      betragEingang: this.state.calcNettoModal,
      modalNettoBrutto: false
    });
  };
  render() {
     return (
      <React.Fragment>
        <li
          onClick={this.toggle}
          className={this.state.hideItem ? "hide " : " "}
          key={this.state.id}
        >
          <div className="row fullwidth list-header showSpan">
            <p className="col-md-2">
              <span>Rechnungs-Nr</span>
              <br />
              <Highlight id="font-normal"  search={this.props.search}>
                {this.state.rechnungsNr}
              </Highlight>
            </p>
            <p className="col-md-2">
              <span>Rechnungsdatum</span>
              <br />
              <Highlight id="font-normal"  search={this.props.search}>
                {this.convertDate(this.state.rechnungsdatum)}
              </Highlight>
            </p>
 
            <p className="col-md-4">
              <span>Kunde</span>
              <br />
              <Highlight id="font-normal"  search={this.props.search}>
                ({this.state.customerId}) {this.state.customerName}
              </Highlight>
            </p>
 
            <p className="col-md-4">

              <span>Bv</span>
              <br />
              <Highlight id="font-normal"  search={this.props.search}>
                ({this.state.bvNr}) {this.state.bv}
              </Highlight>
            </p>

          </div>
          <div className="row fullwidth showSpan">
            <p className="col-md-2">
              <span>Rechnungsbetrag (Brutto)</span>
              <br />
              {this.convertPrice(this.state.rechnungsbetrag)}
            </p>
 
            <p className="col-md-1">
              <span>Skonto</span>
              <br />
              {this.state.skonto}
            </p>
            <p className="col-md-2">
              <span>Betrag skontiert</span>
              <br />
              {this.convertPrice(this.state.betragSkonto)}
            </p>
 
            <p className="col-md-2">
              <span>Betrag eingegangen € (netto)</span>
              <br />
              {this.convertPrice(this.state.betragEingang)}
            </p>
            <p className="col-md-2">
              <span>Betrag eingegangen Datum</span>
              <br />
              {this.convertDate(this.state.betragEingangDatum)}
            </p>
            <p className="col-md-1">
              <span>Zahlungsziel</span>
              <br />
              {this.convertDate(this.state.zahlungsziel)}
            </p>
          </div>
          <div className="row fullwidth showSpan">
 
            <p className="col-md-1">
              <span>SR/AZ</span>
              <br />
              {this.state.sr_az}
            </p>
            <p className="col-md-1">
              <span>AzNr</span>
              <br />
              {this.state.azNr}
            </p>
            <p className="col-md-2">
              <span>Zahlungsdatum</span>
              <br />
              {this.convertDate(this.state.zahlungsdatum)}
            </p>
            <p className="col-md-1">
              <span>Zahlungsdatum VOB</span>
              <br />
              {this.convertDate(this.state.zahlungsdatumVOB)}
            </p>
            <p className="col-md-2">
              <span>Mahnung 1</span>
              <br />
              {this.convertDate(this.state.mahnung1)}
            </p>
            <p className="col-md-2">
              <span>Mahnung 2</span>
              <br />
              {this.convertDate(this.state.mahnung2)}
            </p>
            <p className="col-md-2">
              <span>Mahnung 3</span>
              <br />
              {this.convertDate(this.state.mahnung3)}
            </p>
            <p className="col">
              <span>Bezahlt</span>
              <br />
              {this.state.bezahlt ? "✔" : ""}
            </p>
          </div>
        </li>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal-header">
            <p className={this.state.showUpdate ? "show" : "hide"}>
              Rechnung Bearbeiten
            </p>
            <p className={this.state.showDelete ? "show" : "hide"}>
              Rechnung wirklich Löschen?<br />
              <span>Dieser Vorgang kann nicht Rückgänging gemacht werden</span>
            </p>
            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <form>
                <div className="row">
                  <div className="col-md-3">
                    <p>Rechnungs-Nr</p>
                    <input
                      required
                      type="text"
                      name="rechnungsNr"
                      placeholder="Rechnungsnummmer"
                      onChange={this.handleChange}
                      value={this.state.rechnungsNr}
                    />
                  </div>
                  <div className="col-md-3">
                    <p>Rechnungsdatum</p>
                    <input
                      required
                      type="date"
                      name="rechnungsdatum"
                      placeholder="Rechnungsdatum"
                      onChange={this.handleChange}
                      value={this.convertDateToNorm(this.state.rechnungsdatum)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 tab">
                    <p>Kunden-Id</p>
                    <input
                      required
                      type="text"
                      name="customerId"
                      placeholder="-"
                      onChange={this.handleChange}
                      value={this.state.customerId}
                      onKeyDown={this.autocomplete}
                    />
                    <span>↹</span>
                  </div>
                  <div className="col-md-4">
                    <p>Kunde</p>
                    <select
                      name="customerName"
                      value={this.state.customerName}
                      onChange={this.handleChange}
                    >
                      <option value="-">-</option>
                      {this.props.customerAll.map((customer, i) => (
                        <option key={i} value={customer.customerName}>
                          {customer.customerName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-2 tab">
                    <p>Bv-Nr</p>
                    <input
                      required
                      type="text"
                      name="bvNr"
                      placeholder="BauvorhabenNr"
                      onChange={this.handleChange}
                      value={this.state.bvNr}
                      onKeyDown={this.autocomplete}
                    />
                    <span>↹</span>
                  </div>
                  <div className="col">
                    <p>Bv</p>
                    <select
                      name="bv"
                      disabled={this.state.isDisabled}
                      value={this.state.bv}
                      onChange={this.handleChangeBV}
                    >
                      <option key ="0" value ="-">-</option>
                      {this.state.bvAll.map((bv, i) => (
                        <option key={i} value={bv.bv}>
                          {bv.bv} ({bv.bvId})
                        </option>
                      ))}
                    </select>
                  </div>

                </div>
                <hr />
                <div className="row">
                  <div className="col-md-3 tab">
                    <p>Rechnungsbetrag (Brutto)</p>
                    <input
                      required
                      type="text"
                      name="rechnungsbetrag"
                      placeholder="Rechnungsbetrag"
                      onChange={this.handleChange}
                      value={this.state.rechnungsbetrag}
                       />
                      <span>↹</span>
                  </div>
 
                  <div className="tab col-md-1">
                    <p>Skonto</p>
                    <input
                      type="text"
                      name="skonto"
                      placeholder="Skonto"
                      onChange={this.handleChange}
                      value={this.state.skonto}
                      onKeyDown={this.autocompleteSkonto}
                    />
                    <span>↹</span>
                  </div>
                  <div className="col-md-3">
                    <p>Betrag skontiert</p>
                    <input
                      type="text"
                      name="betragSkonto"
                      placeholder="Betrag skontiert"
                      onChange={this.handleChange}
                      value={this.state.betragSkonto}
                    />
                  </div>
 
                  <div className="col-md-3">
                    <p>Betrag eingegangen € (netto)</p>
                    <input
                      type="text"
                      name="betragEingang"
                      placeholder="Betrag eingegangen € (netto)"
                      onChange={this.handleChange}
                      value={this.state.betragEingang}
                    />
                  </div>
                  <div className="col m-t-0"> 
                  <button onClick={this.toggleNettoBrutto} className="btn-create-item">
                  <FontAwesomeIcon icon="calculator" /> 
                  </button>
                  <Modal className="modal-netto-brutto" isOpen={this.state.modalNettoBrutto} toggle={this.toggleNettoBrutto}>
                    <ModalBody>
                      <div className="row">
                        <h3>Nettobetrag berechnen</h3>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p>Bruttowert</p>
                          <input
                            type="text"
                            value={this.state.bruttoWertBerrechnen}
                            name="bruttoWertBerrechnen"
                            onChange={this.handleChange}
                          />
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-12 tab">
                          <p>Steuersatz</p>
                          <input
                            type="text"
                            name="steuersatz"
                            value={this.state.steuersatz}
                            onChange={this.handleChange}
                            onKeyDown={this.calcNetto}
                          />
                                <span>↹</span>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-12">
                          <p>Ergebnis</p>
                          <input
                          readOnly
                            type="text"
                            name="calcNettoModal"
                            value={this.state.calcNettoModal}
                          />
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-12">
                        <button
                          className="btn-create-item"
                          onClick={this.writeCalcNetto}
                        >
                          Übernehmen
                        </button>
                        </div>
                      </div>
 
                    </ModalBody>
                  </Modal>
                  </div>
                  
                </div>
                <div className="row">
                <div className="col-md-3">
                    <p>Betrag eingegangen Datum</p>
                    <input
                      type="date"
                      name="betragEingangDatum"
                      onChange={this.handleChange}
                      value={this.state.betragEingangDatum}
                    />
                  </div>
                  <div className="tab col-md-1">
                    <p>Zahlungsziel</p>
                    <input
                      required
                      type="text"
                      name="zahlungsziel"
                      placeholder="Zahlungsziel"
                      onChange={this.handleChange}
                      value={this.state.zahlungsziel}
                      onKeyDown={this.autocompleteDate}
                    />
                    <span>↹</span>
                  </div>
                  <div className="col-md-1">
                    <p>SR/AZ</p>
                    <select
                      required
                      name="sr_az"
                      onChange={this.handleChange}
                      value={this.state.sr_az}
                    >
                      <option value="SR">SR</option>
                      <option value="AZ">AZ</option>
                    </select>
                  </div>
                  <div className="col-md-1">
                    <p>AzNr</p>
                    <input
                      type="text"
                      name="azNr"
                      placeholder="AzNr"
                      disabled={this.state.isDisabledSRAZ}
                      onChange={this.handleChange}
                      value={this.state.azNr}
                    />
                  </div>
                  <div className="col-md-3">
                    <p>Zahlungsdatum</p>
                    <input
                      required
                      type="date"
                      name="zahlungsdatum"
                      placeholder="Zahlungsdatum"
                      onChange={this.handleChange}
                      value={this.convertDateToNorm(this.state.zahlungsdatum)}
                    />
                  </div>
                  <div className="col-md-3">
                    <p>Zahlungsdatum VOB</p>
                    <input
                      type="date"
                      name="zahlungsdatumVOB"
                      placeholder="Zahlungsdatum VOB"
                      onChange={this.handleChange}
                      value={this.convertDateToNorm(this.state.zahlungsdatumVOB)}
                    />
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-3">
                    <p>Mahung 1</p>
                    <input
                      type="date"
                      name="mahnung1"
                      placeholder="Mahnung 1"
                      onChange={this.handleChange}
                      value={this.convertDateToNorm(this.state.mahnung1)}
                    />
                  </div>
                  <div className="col-md-3">
                    <p>Mahung 2</p>
                    <input
                      type="date"
                      name="mahnung2"
                      placeholder="Mahnung 2"
                      onChange={this.handleChange}
                      value={this.convertDateToNorm(this.state.mahnung2)}
                    />
                  </div>
                  <div className="col-md-3">
                    <p>Mahung 3</p>
                    <input
                      type="date"
                      name="mahnung3"
                      placeholder="Mahnung 3"
                      onChange={this.handleChange}
                      value={this.convertDateToNorm(this.state.mahnung3)}
                    />
                  </div>
                  <div className="col-md-2 inline">
                    <input
                      type="checkbox"
                      name="bezahlt"
                      id="done"
                      placeholder="Fertig"
                      onChange={this.handleChangeCheck}
                      checked={this.state.bezahlt}
                    />
                    <label>Bezahlt</label>
                  </div>
                </div>
              </form>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Löschen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.updateItem} className="btn-green">
                    Ändern <FontAwesomeIcon icon={["far", "edit"]} />
                  </button>
                </div>
              </div>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Nein <FontAwesomeIcon icon="ban" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.removeItem} className="btn-green">
                    Ja <FontAwesomeIcon icon="check-circle" />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalBillbook;
