import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
 import {globalUnits} from "../global/units.js";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var $ = require("jquery");

class BillsInputFormTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      date: this.props.date,
      billsNr: this.props.billsNr,
      notesNr: this.props.notesNr,
      supplier: this.props.supplier,
      supplierId: this.props.supplierId,
      bv: this.props.bv,
      bvAll: this.props.bvAll,
      bvId: this.props.bvId,
      showItem: this.props.showItem,
      billArticles: this.props.billArticles,
      search: this.props.search,
      itemsAll: [],
      errorSearch: false,
      disabledFieldset: false,
      supplierAll: []
    };
  }

  componentDidMount = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true
      });
    }
   };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if (e.target.name === "bv" && e.target.value === "-") {
      this.setState({
        bvId: ""
      });
    }
    if (e.target.name === "supplier" && e.target.value === "-") {
      this.setState({
        supplierId: ""
      });
    }
    if (e.target.name === "bv") {
      this.readBvIdByName(e.target.value, true);
    }
    if (e.target.name === "supplier") {
      this.readSupplierIdByName(e.target.value, true);
    }
  };
  autocompleteBvSupplier = e => {
    if (e.keyCode === 9 && e.target.name === "bvId") {
      this.readBvNameById(e.target.value, true);
    }
    if (e.keyCode === 9 && e.target.name === "supplierId") {
      this.readSupplierNameById(e.target.value, true);
    }
  };
  readBvNameById = (id, bool) => {
    let found = this.props.bvAllInput.find(name => name.bvId === parseInt(id));
    if (found === undefined) {
      this.props.readOldBvById(id);
      setTimeout(() => {
        this.setState({
          bv: this.props.bvAllFound
        });
      }, 200);
    }
    else if (bool === true && found !== undefined) {
      return this.setState({
        bv: found.bv
      });
    } else if (bool === false && found !== undefined) {
      return found.bv;
    }
  };

  readSupplierNameById = (id, bool) => {
    let found = this.props.supplierAll.find(name => name.supplierId === parseInt(id));
    if (found === undefined) {
      return "";
    }
    else if (bool === true) {
      return this.setState({
        supplier: found.supplierName
      });
    } else {
       return found.supplierName;
    }
  };
  readSupplierIdByName = (name, bool) => {
    let found = this.props.supplierAll.find(id => id.supplierName === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        supplierId: found.supplierId
      });
    } else {
      return found.supplierId;
    }
  };
  readBvIdByName = (name, bool) => {
    let found = this.props.bvAllInput.find(id => id.bv === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        bvId: found.bvId
      });
    } else {
      return found.bvId;
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    let bill;
    if (
      this.state.date === "" ||
      this.state.billsNr === "" ||
      this.state.supplier === "" ||
      this.state.bv === ""
    ) {
      this.setState({
        showMissed: true
      });
    } else {
      this.setState({
        showMissed: false
      });

      this.state.billArticles.map(
        (items, idx) => (
          (bill = {
            date: this.state.date,
            notesDate: this.state.notesDate,
            billsNr: this.state.billsNr.trim(),
            notesNr: this.state.notesNr.trim(),
            supplierId: this.state.supplierId,
            itemId: items.itemId,
            itemDsc: items.itemDsc,
            itemAmnt: items.itemAmnt.replace(/,/g, "."),
            itemUnit: items.itemUnit,
            pricePiece: items.pricePiece.replace(/,/g, "."),
            discount: items.discount.replace(/,/g, "."),
            priceComplete: items.priceComplete.replace(/,/g, "."),
            bvId: this.state.bvId
          }),
          this.saveBill(bill)
        )
      );
      this.props.readLastBill();
    }
  };

  saveBill = bill => {
    $.ajax({
      url: BaseUrl + "api/bills/createBill.php",
      type: "POST",
      dataType: "json",
      data: JSON.stringify(bill),
      success: function(response) {
        this.setState({
          date: "",
          notesDate: "",
          billsNr: "",
          notesNr: "",
          supplier: "",
          supplierId: "",
          bv: "",
          bvId: "",
          billArticles: [
            {
              itemId: "",
              itemDsc: "",
              itemAmnt: "",
              itemUnit: "",
              pricePiece: "",
              discount: "",
              priceComplete: ""
            }
          ]
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };

  handleAddArticle = () => {
    this.setState({
      billArticles: this.state.billArticles.concat([
        {
          itemId: "",
          itemDsc: "",
          itemAmnt: "",
          itemUnit: "",
          pricePiece: "",
          discount: "",
          priceComplete: ""
        }
      ])
    });
  };

  handleRemoveArticle = idx => () => {
    this.setState({
      billArticles: this.state.billArticles.filter((s, sidx) => idx !== sidx)
    });
  };
  handleChangeArticle = idx => evt => {
    const newBillChange = this.state.billArticles.map((item, sidx) => {
      if (idx !== sidx) return item;
      return { ...item, [evt.target.name]: evt.target.value };
    });
    this.setState({ billArticles: newBillChange });
  };
  calcPriceComplete = idx => e => {
    if (e.keyCode === 9) {
      const newItemAutocomplete = this.state.billArticles.map((item, sidx) => {
        if (idx !== sidx) return item;
        let calcPrice = "";
        if (
          item.discount === 0 ||
          item.discount === "" ||
          item.discount === undefined
        ) {
          item.discount = "0";

          calcPrice =
            parseFloat(item.pricePiece.replace(/,/g, ".")) *
            parseFloat(item.itemAmnt.replace(/,/g, "."));
        } else {
          if (item.itemAmnt === "") {
            item.itemAmnt = "0";
          }
          calcPrice =
            (parseFloat(item.pricePiece.replace(/,/g, ".")) -
              parseFloat(item.pricePiece.replace(/,/g, ".")) *
                parseFloat(item.discount.replace(/,/g, ".") / 100)) *
            parseFloat(item.itemAmnt.replace(/,/g, "."));
        }

        return {
          ...item,
          itemId: item.itemId,
          itemDsc: item.itemDsc,
          itemAmnt: item.itemAmnt,
          itemUnit: item.itemUnit,
          pricePiece: item.pricePiece,
          discount: item.discount,
          priceComplete: calcPrice.toFixed(2)
        };
      });
      return this.setState({ billArticles: newItemAutocomplete });
    }
  };

  autocompleteTab = idx => e => {
    if (e.keyCode === 9) {
      this.getNotesData(idx, e);
    }
  };
  getNotesData = (idx, evt) => {
    const convertInput = evt.target.value.replace(/ /g, " ");
    return fetch(
      BaseUrl + "api/bills/readNotesData.php?eingabe=" + convertInput
    )
      .then(response => response.json())
      .then(responseJson => {
         responseJson.records[0].map(item => {

             this.setState({
            notesDate: item.date,
            supplier: this.readSupplierNameById(item.supplierId, false),
            supplierId: item.supplierId,
            itemId: item.itemId,
            itemDsc: item.itemDsc,
            itemUnit: item.itemUnit,
            itemAmnt: item.itemAmnt,
            pricePiece: "",
            discount: "",
            priceComplete: "",
            bv: this.readBvNameById(item.bvId,false),
            bvId: item.bvId
          });
        });
         const newItemAutocomplete = responseJson.records[0].map(
          (item, sidx) => {
            if (idx !== sidx) return item;

            return {
              ...item,
              itemId: item.itemId,
              itemDsc: item.itemDsc,
              itemAmnt: item.itemAmnt,
              itemUnit: item.itemUnit,
              pricePiece: "",
              discount: "",
              priceComplete: ""
            };
          }
        );
        this.setState({ billArticles: newItemAutocomplete });
      })
      .catch(error => {});
  };
  autocompleteTabItem = idx => e => {
    if (e.keyCode === 9) {
      this.getDscUnit(idx, e);
    }
  };
  getDscUnit = (idx, evt) => {
    const convertInput = evt.target.value.replace(/ /g, " ");
    return fetch(BaseUrl + "api/bills/readItemDesc.php?eingabe=" + convertInput)
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            itemDsc: item.dsc,
            itemUnit: item.unit
          });
        });

        const newItemAutocomplete = this.state.billArticles.map(
          (item, sidx) => {
            if (idx !== sidx) return item;

            return {
              ...item,
              itemId: item.itemId,
              itemDsc: this.state.itemDsc,
              itemUnit: this.state.itemUnit,
              pricePiece: "",
              discount: "",
              priceComplete: ""
            };
          }
        );
        return this.setState({ billArticles: newItemAutocomplete });
      })
      .catch(error => {});
  };
  handleSearch = e => {
    this.setState({
      search: e.target.value
    });

    if (e.target.value === "") {
      const reset = this.state.showItem.map(index => {
        return this.setState({
          showItem: []
        });
      });

      Promise.all(reset).then(values => {
        this.setState({
          showItem: this.state.showItemCopy,
          errorSearch: false
        });
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <section id="insertItem" className="billsinputformtop">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon
                  color="var(--greenSeyband)"
                  icon="money-bill-alt"
                />{" "}
                Rechnungen
              </h1>
            </div>
          </div>
          <h4>Rechnungen Hinzufügen</h4>
          <form className="bills" onSubmit={this.handleSubmit}>
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="row">
                <div className="col-2">
                  <p>Datum</p>
                  <input
                    required
                    type="date"
                    name="date"
                    placeholder="Datum"
                    min="2017-01-01"
                    onChange={this.handleChange}
                    value={this.state.date}
                  />
                </div>

                <div className="col-4">
                  <p>Rechnungs-Nr</p>
                  <input
                    required
                    type="text"
                    name="billsNr"
                    onChange={this.handleChange}
                    value={this.state.billsNr}
                  />
                </div>
              </div>
              <hr />

              <div className="row">
              <div className="col-2 tab">
                  <p>Lieferschein Datum</p>
                  <input
                     type="date"
                    name="notesDate"
                    onChange={this.handleChange}
                    value={this.state.notesDate}
                  />
                </div>
                <div className="col-2 tab">
                  <p>Lieferschein-Nr</p>
                  <input
                     type="text"
                    name="notesNr"
                    onChange={this.handleChange}
                    value={this.state.notesNr}
                    onKeyDown={this.autocompleteTab()}
                  />
                  <span>↹</span>
                </div>
                <div className="col-1 tab">
                  <p>Lieferanten-Nr</p>
                  <input
                    required
                    type="text"
                    name="supplierId"
                    placeholder="-"
                    onChange={this.handleChange}
                    value={this.state.supplierId}
                    onKeyDown={this.autocompleteBvSupplier}
                  />
                  <span>↹</span>
                </div>
                <div className="col-2">
                  <p>Lieferant</p>
                  <select
                    name="supplier"
                    value={this.state.supplier}
                    onChange={this.handleChange}
                  >
                    <option value="-">-</option>
                    {this.props.supplierAll.map((supplier, i) => (
                      <option key={i} value={supplier.supplierName}>
                        {supplier.supplierName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-1 tab">
                  <p>Bv-Nr</p>
                  <input
                    required
                    type="text"
                    name="bvId"
                    placeholder="-"
                    onChange={this.handleChange}
                    value={this.state.bvId}
                    onKeyDown={this.autocompleteBvSupplier}
                  />
                  <span>↹</span>
                </div>
                <div className="col-3">
                  <p>Bv</p>
                  <select
                    name="bv"
                    value={this.state.bv}
                    onChange={this.handleChange}
                  >
                    <option key="-1" value="-">
                      -
                    </option>
                    {this.props.bvAllInput.map((bv, i) => (
                      <option key={i} value={bv.bv}>
                        {bv.bv}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <hr />

              {this.state.billArticles.map((items, idx) => (
                <div key={idx} className="row">
                  <div className="row fullwidth">
                    <div className="col-2 tab">
                      <p>{`Artikel-Nr #${idx + 1}`}</p>
                      <input
                        type="text"
                        name="itemId"
                        key={idx}
                        onChange={this.handleChangeArticle(idx)}
                        onKeyDown={this.autocompleteTabItem(idx)}
                        value={items.itemId}
                      />
                      <span>↹</span>
                    </div>
                    <div className="col-7">
                      <p>{`Artikelbezeichnung #${idx + 1}`}</p>
                      <input
                        type="text"
                        name="itemDsc"
                        key={idx}
                        onChange={this.handleChangeArticle(idx)}
                        value={items.itemDsc}
                      />
                    </div>
                    <div className="col-1">
                      <p>{`Menge #${idx + 1}`}</p>
                      <input
                        type="text"
                        name="itemAmnt"
                        key={idx}
                        onChange={this.handleChangeArticle(idx)}
                        value={items.itemAmnt}
                      />
                    </div>
                    <div className="col-1">
                      <p>{`Einheit #${idx + 1}`}</p>
                      <select
                        name="itemUnit"
                        onChange={this.handleChangeArticle(idx)}
                        value={items.itemUnit}
                      >
                                               {globalUnits.map((unit, idx) => {
                        return(
                        <option key={idx} value={unit}>
                          {unit}
                        </option>
                        )
                        })}
                      </select>
                    </div>
                    <div className="col-1">
                      <button
                        type="button"
                        onClick={this.handleRemoveArticle(idx)}
                        className="btn-remove"
                      >
                        -
                      </button>
                    </div>
                  </div>
                  <div className="row fullwidth">
                    <div className="col-2">
                      <p>{`Preis/stk #${idx + 1}`}</p>
                      <input
                        type="text"
                        name="pricePiece"
                        key={idx}
                        onChange={this.handleChangeArticle(idx)}
                        value={items.pricePiece}
                      />
                    </div>
                    <div className="col-2 tab">
                      <p>{`Rabatt #${idx + 1}`}</p>
                      <input
                        key={idx}
                        type="text"
                        name="discount"
                        onChange={this.handleChangeArticle(idx)}
                        value={items.discount}
                        onKeyDown={this.calcPriceComplete(idx)}
                      />
                      <span>↹</span>
                    </div>
                    <div className="col-2">
                      <p>{`Gesamtpreis #${idx + 1}`}</p>
                      <input
                        type="text"
                        name="priceComplete"
                        key={idx}
                        onChange={this.handleChangeArticle(idx)}
                        value={items.priceComplete}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div className="row">
                <div className="col-11" />
                <div className="col-1">
                  <button
                    type="button"
                    onClick={this.handleAddArticle}
                    className="btn-add-item"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="item-btn row">
                <button className="btn-add-item fullwidth">
                  Rechnung speichern <FontAwesomeIcon icon="save" />
                </button>
              </div>
            </fieldset>
          </form>
        </section>

      </React.Fragment>
    );
  }
}
export default BillsInputFormTop;
