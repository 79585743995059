import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
 var currencyFormatter = require("currency-formatter");
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Evaluation extends Component {
  constructor() {
    super();
    this.state = {
      artNr: "",
      dsc: "",
      unit: "",
      supplier: "",
      internArtNr: "",
      bvId: "",
      bvAll: [],
      hoursSingleHelp: "00:00",
      hoursAll: "00:00",
      evaluationsAll: [],
      evaluationsAllCopy: [],
      errorSearch: false,
      search: "",
      useSearch: false,
      costNettoHelp: 0.0,
      costBruttoHelp: 0.0,
      costNettoHelpAll: 0.0,
      costBruttoHelpAll: 0.0,
      costNetto: 0.0,
      costBrutto: 0.0,
      materialCost: 0.0,
      costComplete: 0.0,
      earnings: 0.0,
      profitLoss: 0.0,
      green: null
    };
  }

  componentDidMount = () => {
    this.readBVAll();
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

    if (e.target.name === "bv") {
      this.readBvIdByName(e.target.value,true);
    }
  };
  readBVAll = () => {
    return fetch(BaseUrl + "api/all/readBvComplete.php")
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          bvAll: responseJson.records[0]
        });
      })
      .catch(error => {});
  };
  autocompleteBv = e => {
    if (e.keyCode === 9) {
      this.readBvNameById(e.target.value, true);
    }
  };
  readBvNameById = (id, bool) => {
    let found = this.state.bvAll.find(name => name.bvId === parseInt(id));
    if (found === undefined) {
      return "";
    }
    if (bool === true && found !== undefined) {
      return this.setState({
        bv: found.bv
      });
    } else if (bool === false && found !== undefined) {
      return found.bv;
    }
  };
  readBvIdByName = (name, bool) => {
    let found = this.state.bvAll.find(id => id.bv === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        bvId: found.bvId
      });
    } else {
      return found.bvId;
    }
  };
  // getBv = evt => {
  //   const convertInput = evt.target.value.replace(/ /g, " ");
  //   return fetch(BaseUrl + "api/all/readBv.php?eingabe=" + convertInput)
  //     .then(response => response.json())
  //     .then(responseJson => {
  //       responseJson.records.map(item => {
  //         return this.setState({
  //           bv: item.bv
  //         });
  //       });
  //     })
  //     .catch(error => {});
  // };
  //
  // readBVId = eingabe => {
  //   return fetch(BaseUrl + "api/all/readBvId.php?eingabe=" + eingabe)
  //     .then(response => response.json())
  //     .then(responseJson => {
  //       responseJson.records.map(item => {
  //         return this.setState({
  //           bvId: item.bvId
  //         });
  //       });
  //     })
  //     .catch(error => {});
  // };
  timeToMins = time => {
    var b = time.split(":");
    return b[0] * 60 + +b[1];
  };

  timeFromMins = mins => {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    var h = (mins / 60) | 0;
    var m = mins % 60;
    return z(h) + ":" + z(m);
  };

  addTimes = (t0, t1) => {
    this.state.hoursSingleHelp = this.timeFromMins(
      this.timeToMins(t0) + this.timeToMins(t1)
    );
  };
  addTimesAll = (t0, t1) => {
    this.state.hoursAll = this.timeFromMins(
      this.timeToMins(t0) + this.timeToMins(t1)
    );
  };

  calcMaterialCost = (bv, bvId) => {
    return new Promise(() => {
      fetch(
        BaseUrl + "api/evaluation/searchMaterialCost.php?bvId=" +
          bvId
      )
        .then(response => response.json())
        .then(responseJson => {
          responseJson.records.map((item, idx) => {
            return this.state.materialCost += parseFloat(item.priceComplete);
          });
          this.setState({
            materialCost: this.state.materialCost
          });
        })
        .catch(error => {});
    });
  };
  calcEarnings = (bv, bvId) => {
    return fetch(
      BaseUrl + "api/evaluation/searchEarnings.php?bvId=" +
        bvId
    )
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map((item, idx) => {
          if (item.betragEingang === "") {
          } else {
            this.state.earnings += parseFloat(item.betragEingang);
          }
          return this;
        });
        this.setState({
          earnings: this.state.earnings
        });
      })
      .catch(error => {});
  };
  convertDate = date => {
    if (date === null || date === "0000-00-00" || date === undefined) {
      return date;
    }

    if (date.includes("-")) {
      var dateNew = date.split("-");
      dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  convertDateNormal = date => {
    if (date === null || date === "0000-00-00" || date === undefined) {
      return date;
    }

    if (date.includes(".")) {
      var dateNew = date.split(".");
      dateNew = dateNew[2] + "-" + dateNew[1] + "-" + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
 getHoursAll = () => {

    const convertInput = this.state.bv.replace(/ /g, " ");

           fetch(
          BaseUrl + "api/evaluation/searchNames.php?id=" +
            this.state.bvId
        )
          .then(response => response.json())
          .then(responseJson => {
            if (responseJson.message === "No items found.") {
              this.setState({
                hoursAll: 0.0,
                earnings: 0.0
              });
            }
            this.state.hoursSingleHelp = "00:00";

            responseJson.records.map(name => {
              return fetch(
                BaseUrl + "api/evaluation/searchHoursPerNameAndYear.php?id=" +
                  this.state.bvId +
                  "&lastName=" +
                  name.lastName +
                  "&firstName=" +
                  name.firstName +
                  "&year=" +
                  name.year
              )
                .then(response => response.json())
                .then(responseJson => {
                  responseJson.records.map((item, idx) => {
                     this.addTimes(this.state.hoursSingleHelp, item.result);
                     return this.addTimesAll(this.state.hoursAll, item.result);
                  });
                   this.setState({
                  hoursAll: this.state.hoursAll
                });
                })
                 .catch(error => {});
            });
          })
          .catch(error => {});
  };

  onClickHoursAll = () => {
    this.state.hoursAll = "00:00";
    this.state.materialCost = 0.0;
    this.state.earnings = 0.0;

     this.getHoursAll() ;

     this.calcMaterialCost(this.state.bv.replace(/ /g, " "), this.state.bvId);
    this.calcEarnings(this.state.bv.replace(/ /g, " "), this.state.bvId);
  };
  convertPrice = price => {
    return currencyFormatter.format(price, { locale: "de-DE" });
  };
  render() {
    return (
      <React.Fragment>
        <section id="insertEvaluation">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--blue)" icon="chart-bar" />{" "}
                Auswertung pro BV
              </h1>
            </div>
          </div>
        </section>
        <section id="showEvaluations" className="evaluation">
          <div className="wrapper-btn-text  row">
            <div className="offset-3 col-2 tab">
            <p>Bv-Nr</p>
              <input
                type="text"
                name="bvId"
                onChange={this.handleChange}
                value={this.state.bvId}
                onKeyDown={this.autocompleteBv}
              />
              <span>↹</span>
            </div>
            <div className="col-3">
              <p>Bv</p>
              <select
                name="bv"
                value={this.state.bv}
                onChange={this.handleChange}
              >
                <option key="-1" value="-">
                  -
                </option>
                {this.state.bvAll.map((bv, i) => (
                  <option key={i} value={bv.bv}>
                    {bv.bv}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-1">
              <button
                type="button"
                onClick={this.onClickHoursAll}
                className="btn-add-blue"
              >
                <FontAwesomeIcon icon="search" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-8 offset-2">
              <table>
                <tbody>
                  <tr>
                    <td>Gesamtstunden:</td>
                    <td>{this.state.hoursAll} h</td>
                  </tr>
                  <tr>
                    <td>Materialkosten:</td>
                    <td>{this.convertPrice(this.state.materialCost)}</td>
                  </tr>
                  <tr>
                    <td>Einnahmen:</td>
                    <td>{this.convertPrice(this.state.earnings)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Evaluation;
