import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import Textarea from "react-textarea-autosize";
import { Modal, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BaseUrl from "../config/config.js";

 var $ = require("jquery");
 class OfferBaseData extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      id:"",
      titleNew:"",
      paymentText:"",
      allPayments:[],
      addNew: false,
      saved: false,
      disableButton: true
    };
  }

  componentDidMount = () => {
    this.loadPayment();
      if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true
      });
    }
  };
  handleChange = e => {
     this.setState({
      [e.target.name]: e.target.value
    });

  };
  handleChangeSelect = e => {
    console.log(e.target.name);
     this.setState({
      [e.target.name]: e.target.value
    });
    if(e.target.value !== "newPayment"){
      this.loadSinglePayment(e.target.value);
      this.setState({
        disableButton: false,
        addNew: false,
        paymentText: "",
      })
    }
    if(e.target.value === "newPayment"){
      this.setState({
        addNew: true,
        paymentText: "",
        disableButton: true
      })
    }

  };
  handleSubmit = e => {
    e.preventDefault();

          this.updatePayment( );

  };

  updatePayment = e => {
    var updatePayment;

      if (this.state.addNew) {
         updatePayment = {
          id: "0",
          paymentName: this.state.titleNew,
          paymentText: this.state.paymentText
        };
      } else {
         updatePayment = {
          id: this.state.id,
          paymentName: this.state.paymentName,
          paymentText: this.state.paymentText
        };
      }
      $.ajax({
        url: BaseUrl + "api/all/updateTermOfPayment.php",
        type: "POST",
        dataType: "text",
        data: JSON.stringify(updatePayment),
        success: function(response) {
          this.setState({
            saved: true,
            paymentText:"",
            paymentName:" ",
            titleNew:""
          })
          this.loadPayment();
        }.bind(this),
        error: function(xhr, resp, text) {}
      });
   };
   deletePayment = () => {
      let deleteItem = {
       id: this.state.id
     };

     $.ajax({
       url: BaseUrl + "api/all/deleteTermOfPayment.php",
       type: "POST",
       dataType: "text",
       data: JSON.stringify(deleteItem),
       success: function(response) {

         this.setState({
           allPayments: this.state.allPayments.filter(item => {
    return item.id !== this.state.id
  }),
           modal: !this.state.modal,
          });
       }.bind(this),
       error: function(xhr, resp, text) {

       }
     });
   };
  loadPayment = () => {
    return fetch(BaseUrl + "api/all/readTermOfPayment.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(payment => {
          return this.setState({
            allPayments: responseJson.records

          });
        });
      })
      .catch(error => {});
  };
  loadSinglePayment = paymentName => {
    return fetch(BaseUrl + "api/all/readSingleTermOfPayment.php?paymentname=" + paymentName)
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(payment => {
          return this.setState({
            paymentText: payment.paymentText,
            id: payment.id
          });
        });
      })
      .catch(error => {});
  };
  onKeyPress = e => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = e => {
    e.preventDefault();
    if(sessionStorage.getItem("userData").indexOf("admin") === -1){
       this.setState({
       modal:false
     })

   }
   else{
     this.setState({
       modal: !this.state.modal
     });
   }
  };

  render() {
    return (
      <React.Fragment>
        <section id="baseData">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--yellow)" icon="percent" />{" "}
                Zahlungsziele Stammdaten
              </h1>
            </div>
          </div>

          <form className="baseData" onSubmit={this.handleSubmit}>
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="row">
                <div className="col-md-3">
                  <p>Zahlungsziel</p>
                  <select  value={this.state.paymentName} name="paymentName" onChange={this.handleChangeSelect}>
                    <option key="-1" value="-">-</option>
                    {this.state.allPayments.map((item, i) => {
                      return(
                        <option key={i} value={item.paymentName}>{item.paymentName}</option>
                      )
                    })}
                    <option key="neues Zahlungsziel" value="newPayment" name="newPayment">&#x2b; Neues Zahlungsziel</option>
                  </select>
                </div>
                <div className="col-md-9">
                  <div className={this.state.addNew ? "col-md-8 show padding-zero margin-bottom-input" : "hide"}>
                    <p>Titel Neues Zahlungsziel</p>
                    <input

                      type="text"
                      name="titleNew"
                      onChange={this.handleChange}
                      value={this.state.titleNew}
                    />
                  </div>

                  <div className="col-md-12 padding-zero">
                    <p>Zahlungsziel Text</p>

                    <Textarea
                      minRows={4}
                      name="paymentText"
                      onChange={this.handleChange}
                      value={this.state.paymentText}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className={this.state.saved ? "saved visible offset-8 col-md-4" : "saved invisible"}>
                  <p>  <FontAwesomeIcon color="var(--greenSeyband)" icon="check-circle" />{" "} Daten wurden gespeichert</p>
                </div>
              </div>
              <div className="row">
                <div className="offset-5 col-md-3">
                  <button disabled={this.state.disableButton}onClick={this.toggle} className="btn-remove-products">
                    Zahlungsziel löschen <FontAwesomeIcon icon="trash-alt" />
                  </button>
                </div>
                <div className="col-md-4">
                  <button className="btn-create-products">
                    Zahlungsziel speichern <FontAwesomeIcon icon="save" />
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <div className="modal-header">

              <p>
                Zahlungsziel wirklich Löschen?<br />
                <span>Dieser Vorgang kann nicht Rückgänging gemacht werden</span>
              </p>
              <button className="close" onClick={this.toggle}>
                {" "}
                <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
              </button>
            </div>
            <ModalBody>

              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggle} className="btn-red">
                    Nein <FontAwesomeIcon icon="ban" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.deletePayment} className="btn-green">
                      Ja <FontAwesomeIcon icon="check-circle" />
                    </button>
                  </div>
                </div>
              </ModalBody>
          </Modal>
        </section>

      </React.Fragment>
    );
  }
}

export default OfferBaseData;
