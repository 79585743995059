import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
import HoursList from "./HoursList";
import DeliveryNotesList from "./DeliveryNotesList";
import BillsList from "./BillsList";
import DirectionWorkListInput from "./DirectionWorkListInput";
import DirectionWorkListInputBottom from "./DirectionWorkListInputBottom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

var $ = require("jquery");

class DirectionWorkList extends Component {
  constructor() {
    super();
    this.state = {
      reset: false,
      hideSpan: true,
      workingHours: [],
      yearsAll: [],
      hoursComplete: "",
      workingHoursNew: [],
      showDeliveryItems: [],
      showDeliveryItemsNew: [],
      showBills: [],
      showBillsNew: [],
      directionWorkNr: "",
      hideLists: true,
      isNotEmpty: false,
      checkedAll: false,
      bvAll: [],
      supplierAll: [],
    };
  }
  componentDidMount = () => {
    this.readBVAll();
    this.readSupplierNames();
  };

  readAllInfos = (bvId, dateFrom, dateTo, year) => {
    this.setState({
      directionWorkNr: "",
    });
    let yearFrom = year + "-01-01";
    let yearTo = year + "-12-31";
    this.readWorkingHours(bvId, dateFrom, dateTo);
    this.loadDeliveryItems(bvId, yearFrom, yearTo);
    this.loadBills(bvId, yearFrom, yearTo);
    this.loadLastNr(bvId);
    this.setState({
      bv: this.readBvNameById(bvId, false),
      bvId: bvId,
      hideLists: false,
    });
  };
  readBVAll = () => {
    return fetch(BaseUrl + "api/all/readBvComplete.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          bvAll: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };
  readSupplierNames = () => {
    return fetch(BaseUrl + "api/all/readSupplierNames.php")
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          return (this.state.supplierAll = this.state.supplierAll.concat(item));
        });
        this.setState({
          supplierAll: this.state.supplierAll,
        });
      })
      .catch((error) => {});
  };
  readBvNameById = (id) => {
    let found = this.state.bvAll.find((name) => name.bvId === parseInt(id));
    if (found === undefined) {
      return "";
    } else {
      return found.bv;
    }
  };
  readSupplierNameById = (id) => {
    let found = this.state.supplierAll.find(
      (name) => name.supplierId === parseInt(id)
    );
    if (found === undefined) {
      return "";
    } else {
      return found.supplierName;
    }
  };

  sortByDate = (array) => {
    array.sort((a, b) => {
      let x = a.date.split(".").reverse().join();
      let y = b.date.split(".").reverse().join();
      if (x > y) {
        return -1;
      }
      if (x < y) {
        return 1;
      }
      return 0;
    });
    return array;
  };
  readWorkingHours = (bvId, dateFrom, dateTo) => {
    let searchHelpFunc = [];

    this.setState({
      workingHours: [],
    });

    return fetch(
      BaseUrl +
        "api/directionWork/searchWorkingHours.php?bvId=" +
        bvId +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "No items found.") {
          this.setState({
            workingHours: [],
          });
        } else {
          const wait = responseJson.records.map((item, sidx) => {
            Object.keys(item).map((notesNr, idx) => {
              const hours = item[notesNr].map((item, sidx) => {
                return {
                  id: item.id,
                  key: sidx,
                  date: item.date,
                  beginning: item.beginning,
                  end: item.end,
                  pause: item.pause,
                  driveTime: item.driveTime,
                  result: item.result,
                  checked: false,
                };
              });
              const directionHours = item[notesNr]
                .slice(0, 1)
                .map((item, ix) => {
                  return {
                    id: item.id,
                    lastName: item.lastName,
                    firstName: item.firstName,
                    bv: this.readBvNameById(item.bvId),
                    bvId: item.bvId,
                    hours: hours,
                  };
                });
              return (searchHelpFunc = [...searchHelpFunc, ...directionHours]);
            });
            return this;
          });
          Promise.all([wait]).then((result) => {
            this.setState({
              workingHours: searchHelpFunc,
            });
          });
        }
      });
  };
  loadDeliveryItems = (bvId, yearFrom, yearTo) => {
    let searchHelpFunc = [];
    this.setState({
      showDeliveryItems: [],
    });
    fetch(
      BaseUrl +
        "api/directionWork/searchNotes.php?bvId=" +
        bvId +
        "&yearFrom=" +
        yearFrom +
        "&yearTo=" +
        yearTo
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "No items found.") {
          this.setState({
            showDeliveryItems: [],
          });
        } else {
          responseJson.records.map((item, sidx) => {
            Object.keys(item).map((notesNr, idx) => {
              const article = item[notesNr].map((i, ix) => {
                return {
                  key: sidx,
                  date: i.date,
                  id: i.id,
                  itemId: i.itemId,
                  itemDsc: i.itemDsc,
                  itemAmnt: i.itemAmnt,
                  itemUnit: i.itemUnit,
                };
              });
              const notes = item[notesNr].slice(0, 1).map((i, ix) => {
                return {
                  key: idx,
                  date: i.date,
                  id: i.id,
                  notesNr: i.notesNr,
                  supplier: this.readSupplierNameById(i.supplierId),
                  supplierId: i.supplierId,
                  bv: this.readBvNameById(i.bvId),
                  bvId: i.bvId,
                  articles: article,
                };
              });
              return (searchHelpFunc = [...searchHelpFunc, ...notes]);
            });
            return this;
          });
          this.setState({
            showDeliveryItems: this.sortByDate(searchHelpFunc),
          });
        }
      });
  };
  loadBills = (bvId, yearFrom, yearTo) => {
    let helpFunc = [];
    this.setState({
      showBills: [],
    });
    return fetch(
      BaseUrl +
        "api/directionWork/searchBills.php?bvId=" +
        bvId +
        "&yearFrom=" +
        yearFrom +
        "&yearTo=" +
        yearTo
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "No items found.") {
          this.setState({
            showBills: [],
          });
        } else {
          responseJson.records.map((item, sidx) => {
            Object.keys(item).map((billsNr, idx) => {
              const searchArticle = item[billsNr].map((item, ix) => {
                return {
                  key: sidx,
                  date: item.date,
                  id: item.id,
                  itemId: item.itemId,
                  itemDsc: item.itemDsc,
                  itemAmnt: item.itemAmnt,
                  itemUnit: item.itemUnit,
                  pricePiece: item.pricePiece,
                  discount: item.discount,
                  priceComplete: item.priceComplete,
                };
              });
              const searchNotes = item[billsNr].slice(0, 1).map((item, ix) => {
                return {
                  key: sidx,
                  date: item.date,
                  id: item.id,
                  billsNr: item.billsNr,
                  notesNr: item.notesNr,
                  supplier: this.readSupplierNameById(item.supplier),
                  supplierId: item.supplierId,
                  bv: this.readBvNameById(item.bvId),
                  bvId: item.bvId,
                  articles: searchArticle,
                };
              });
              return (helpFunc = [...helpFunc, ...searchNotes]);
            });
            return this;
          });
          this.setState({
            showBills: this.sortByDate(helpFunc),
          });
        }
      });
  };
  addRemoveToSave = (allInfos, check, idx) => {
    if (check === "add") {
      this.state.workingHoursNew = [...this.state.workingHoursNew, ...allInfos];
    }

    if (check === "remove") {
      this.state.workingHoursNew = this.state.workingHoursNew.filter(
        (item) => item.id !== allInfos[0].id
      );
    }
  };
  addRemoveToSaveDeliveryNotes = (allInfos, check, idx) => {
    if (check === "add") {
      this.state.showDeliveryItemsNew = [
        ...this.state.showDeliveryItemsNew,
        ...allInfos,
      ];
    }
    if (check === "remove") {
      this.state.showDeliveryItemsNew = this.state.showDeliveryItemsNew.filter(
        (item) => item.id !== allInfos[0].id
      );
    }
  };
  addRemoveToSaveBills = (allInfos, check, idx) => {
    if (check === "add") {
      this.state.showBillsNew = [...this.state.showBillsNew, ...allInfos];
    }

    if (check === "remove") {
      this.state.showBillsNew = this.state.showBillsNew.filter(
        (item) => item.id !== allInfos[0].id
      );
    }
  };
  loadLastNr = (bvId) => {
    return fetch(BaseUrl + "api/directionWork/readLastNr.php?bvId=" + bvId)
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          let newId;
          if (item.max === null) {
            newId = 1;
          } else {
            newId = parseFloat(item.max) + 1;
          }
          return this.setState({
            directionWorkNr: newId,
            isNotEmpty: true,
          });
        });
      })
      .catch((error) => {});
  };

  handleSubmit = (nr) => (e) => {
    e.preventDefault();
    let bill;
    let hours;
    let deliveryNote;

    this.state.workingHoursNew.map(
      (items, idx) => (
        (hours = {
          directionWorkNr: nr,
          lastName: items.lastName,
          firstName: items.firstName,
          bvId: items.bvId,
          date: this.convertDate(items.date),
          beginning: items.beginning,
          end: items.end,
          pause: items.pause,
          driveTime: items.driveTime,
          result: items.result,
        }),
        this.saveHours(hours)
      )
    );

    this.state.showBillsNew.map(
      (items, idx) => (
        (bill = {
          directionWorkNr: nr,
          date: this.convertDate(items.date),
          billsNr: items.billsNr.trim(),
          notesNr: items.notesNr.trim(),
          bvId: items.bvId,
          supplierId: items.supplierId,
          itemId: items.itemId,
          itemDsc: items.itemDsc.replace(/,/g, "."),
          itemAmnt: items.itemAmnt.replace(/,/g, "."),
          itemUnit: items.itemUnit,
          pricePiece: items.pricePiece.replace(/,/g, "."),
          discount: items.discount.replace(/,/g, "."),
          priceComplete: items.priceComplete.replace(/,/g, "."),
        }),
        this.saveBills(bill)
      )
    );

    this.state.showDeliveryItemsNew.map(
      (items, idx) => (
        (deliveryNote = {
          directionWorkNr: nr,
          date: this.convertDate(items.date),
          notesNr: items.notesNr.trim(),
          supplierId: items.supplierId,
          bvId: items.bvId,
          itemId: items.itemId,
          itemDsc: items.itemDsc.replace(/,/g, "."),
          itemAmnt: items.itemAmnt.replace(/,/g, "."),
          itemUnit: items.itemUnit,
        }),
        this.saveDeliveryNotes(deliveryNote)
      )
    );

    this.resetEverything();
  };

  resetEverything = () => {
    this.setState({
      workingHoursNew: [],
      workingHours: [],
      showDeliveryItemsNew: [],
      showDeliveryItems: [],
      showBillsNew: [],
      showBills: [],
      hideLists: true,
      directionWorkNr: "",
      reset: false,
    });
  };

  saveHours = (hours) => {
    $.ajax({
      url: BaseUrl + "api/directionWork/createDirectionHours.php",
      type: "POST",
      dataType: "json",
      data: JSON.stringify(hours),
      success: function (response) {
        this.setState({
          reset: true,
        });
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };

  saveDeliveryNotes = (deliveryNotes) => {
    $.ajax({
      url: BaseUrl + "api/directionWork/createDirectionDeliveryNote.php",
      type: "POST",
      dataType: "json",
      data: JSON.stringify(deliveryNotes),
      success: function (response) {
        this.setState({
          reset: true,
        });
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };

  saveBills = (bills) => {
    $.ajax({
      url: BaseUrl + "api/directionWork/createDirectionBill.php",
      type: "POST",
      dataType: "json",
      data: JSON.stringify(bills),
      success: function (response) {
        this.setState({
          reset: true,
        });
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };

  convertDate = (date) => {
    if (date.includes(".")) {
      var dateNew = date.split(".");
      dateNew = dateNew[2] + "-" + dateNew[1] + "-" + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <section id="directionWork">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home/regiearbeit">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--yellow)" icon="eye" />{" "}
                Regiearbeiten
              </h1>
            </div>
          </div>
        </section>
        <section id="directionWorkList" className="display-item content">
          <DirectionWorkListInput
            bvId={this.state.bvId}
            reset={this.state.reset}
            readAllInfos={this.readAllInfos}
            bvAll={this.state.bvAll}
          />
          <div className={this.state.hideLists ? "hide " : ""}>
            <h2>
              Stundenübersicht
              <br />
              <span>
                ({this.state.bvId} - {this.state.bv})
              </span>
            </h2>
            <div className="scrollable-div">
              {this.state.workingHours
                ? this.state.workingHours.map((item, idx) => {
                    return (
                      <HoursList
                        key={idx}
                        id={item.id}
                        firstName={item.firstName}
                        lastName={item.lastName}
                        bv={this.readBvNameById(item.bv)}
                        bvId={item.bvId}
                        hours={item.hours}
                        addRemoveToSave={this.addRemoveToSave}
                        workingHoursNew={this.state.workingHoursNew}
                      />
                    );
                  })
                : ""}
            </div>

            <h2>
              Lieferscheine
              <br />
              <span>
                ({this.state.bvId} - {this.state.bv})
              </span>
            </h2>

            <div className="scrollable-div">
              {this.state.showDeliveryItems.map((item, idx) => {
                return (
                  <DeliveryNotesList
                    key={idx}
                    id={item.id}
                    date={item.date}
                    notesNr={item.notesNr}
                    supplier={this.readSupplierNameById(item.supplierId)}
                    supplierId={item.supplierId}
                    bv={this.readBvNameById(this.state.bvId)}
                    bvId={this.state.bvId}
                    articles={item.articles}
                    addRemoveToSaveDeliveryNotes={
                      this.addRemoveToSaveDeliveryNotes
                    }
                    checkedAll={this.state.checkedAll}
                  />
                );
              })}
            </div>
            <h2>
              Rechnungen
              <br />
              <span>
                ({this.state.bvId} - {this.state.bv})
              </span>
            </h2>

            <div className="scrollable-div">
              {this.state.showBills.map((item, idx) => {
                return (
                  <BillsList
                    key={idx}
                    id={item.id}
                    date={item.date}
                    billsNr={item.billsNr}
                    notesNr={item.notesNr}
                    supplier={this.readSupplierNameById(item.supplierId)}
                    supplierId={item.supplierId}
                    bv={this.readBvNameById(item.bvId)}
                    bvId={item.bvId}
                    articles={item.articles}
                    addRemoveToSaveBills={this.addRemoveToSaveBills}
                  />
                );
              })}
            </div>
            {this.state.isNotEmpty ? (
              <DirectionWorkListInputBottom
                directionWorkNr={this.state.directionWorkNr}
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
              />
            ) : (
              <p>Fehler</p>
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default DirectionWorkList;
