import React, { Component } from "react";
import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";

import BaseUrl from "../config/config.js";
var $ = require("jquery");
var Highlight = require('react-highlighter');

class CalcEmployeesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      id: this.props.id,
      jahr: this.props.jahr,
      lastName: this.props.lastName,
      firstName: this.props.firstName,
      arbeitstage: this.props.arbeitstage,
      stunden_tag: this.props.stunden_tag,
      stunden_jahr: this.props.stunden_jahr,
      stundenlohn: this.props.stundenlohn,
      lohnBrutto: this.props.lohnBrutto,
      rentenvers: this.props.rentenvers,
      arbeitslosenvers: this.props.arbeitslosenvers,
      krankenvers: this.props.krankenvers,
      pflegevers: this.props.pflegevers,
      insolvenzgeldumlage: this.props.insolvenzgeldumlage,
      umlage_erstattung: this.props.umlage_erstattung,
      lohnInklNebenkosten: this.props.lohnInklNebenkosten,
      krankheitstage: this.props.krankheitstage,
      krankheitstageProzent: this.props.krankheitstageProzent,
      bezFeiertage: this.props.bezFeiertage,
      jahresstundenlohn: this.props.jahresstundenlohn,
      bgHauptumlage: this.props.bgHauptumlage,
      bgLastenverteilung: this.props.bgLastenverteilung,
      soka: this.props.soka,
      kosten_jahr: this.props.kosten_jahr,
      kosten_h: this.props.kosten_h,
      gk: this.props.gk,
      sk_h: this.props.sk_h,
      gewinn: this.props.gewinn,
      angebotspreis: this.props.angebotspreis,
      showUpdate: true,
      showDelete: false,
      hideItem: false
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    if(sessionStorage.getItem("userData").indexOf("admin") === -1){
       this.setState({
       modal:false
     })

   }
   else{
     this.setState({
       modal: !this.state.modal
     });
   }
  };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  updateItem = e => {
    let updateItem = {
      id: this.state.id,
      jahr: this.state.jahr,
      arbeitstage: this.state.arbeitstage,
      stunden_tag: this.state.stunden_tag,
      stunden_jahr: this.state.stunden_jahr,
      rentenvers: this.state.rentenvers,
      arbeitslosenvers: this.state.arbeitslosenvers,
      krankenvers: this.state.krankenvers,
      pflegevers: this.state.pflegevers,
      insolvenzgeldumlage: this.state.insolvenzgeldumlage,
      umlage_erstattung: this.state.umlage_erstattung,
      krankheitstage: this.state.krankheitstage,
      krankheitstageProzent: this.state.krankheitstageProzent,
      bezFeiertage: this.state.bezFeiertage,
      bgHauptumlage: this.state.bgHauptumlage,
      bgLastenverteilung: this.state.bgLastenverteilung,
      soka: this.state.soka,
      kosten_h: this.state.kosten_h,
      gk: this.state.gk,
      sk_h: this.state.sk_h,
      gewinn: this.state.gewinn,
      angebotspreis: this.state.angebotspreis
    };

     // submit form data to api
    $.ajax({
      url: BaseUrl + "api/calcHourlyWage/updateCalcData.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(updateItem),
      success: function(response) {
        this.setState({
          modal: !this.state.modal
        });
      }.bind(this),
      error: function(xhr, resp, text) {
        // show error to console
      }
    });

    e.preventDefault();
  };

  removeItem = () => {
    let deleteItem = {
      id: this.state.id
    };

    $.ajax({
      url: BaseUrl + "api/calcHourlyWage/deleteCalcData.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function(response) {
        this.setState({
          modal: !this.state.modal,
          hideItem: true
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  render() {
    return (
      <React.Fragment>
        <li
          onClick={this.toggle}
          className={this.state.hideItem ? "hide " : " col-6 "}
          key={this.state.id}
        >
          <div className="list-header calcEmployee">
            <p>Name: <Highlight id="font-normal"  search={this.props.search}>{this.state.lastName}</Highlight>&nbsp;<Highlight id="font-normal"  search={this.props.search}>{this.state.firstName}</Highlight> ({this.state.jahr})</p>
           </div>
          <div className="row fullwidth ">
            <div className="col-12">
              <span>Arbeitstage: </span>
              {this.state.arbeitstage} Tage
            </div>
            <div className="col-12">
              <span>Stunden pro Tag: </span>
              {this.state.stunden_tag} h
            </div>
            <div className="col-12">
              <span>Stunden pro Jahr: </span>
              {this.state.stunden_jahr} h
            </div>
            <div className="col-12">
              <span>Rentenversicherung: </span>
              {this.state.rentenvers} €
            </div>
            <div className="col-12">
              <span>Arbeitslosenversicherung: </span>
              {this.state.arbeitslosenvers} €
            </div>
            <div className="col-12">
              <span>Krankenversicherung: </span>
              {this.state.krankenvers} €
            </div>
            <div className="col-12">
              <span>Pflegeversicherung: </span>
              {this.state.pflegevers} €
            </div>
            <div className="col-12">
              <span>Insolvenzgeldumlage: </span>
              {this.state.insolvenzgeldumlage} €
            </div>
            <div className="col-12">
              <span>Umlage- und Erstattungssätze: </span>
              {this.state.umlage_erstattung} €
            </div>
            <hr/>
            <div className="col-12">
              <span>Lohn inkl. Lohnnebenkosten: </span>
              {this.state.lohnInklNebenkosten} €
            </div>
            <div className="col-12">
              <span>Krankheitstage: </span>
              {this.state.krankheitstage} €
            </div>
            <div className="col-12">
              <span>Bezahlte Feiertage: </span>
              {this.state.bezFeiertage} €
            </div>
            <hr/>
            <div className="col-12">
              <span>Jahresstundenlohn: </span>
              {this.state.jahresstundenlohn} €
            </div>
            <div className="col-12">
              <span>BG-Hauptumlage: </span>
              {this.state.bgHauptumlage} €
            </div>
            <div className="col-12">
              <span>BG-Lastenverteilung: </span>
              {this.state.bgLastenverteilung} €
            </div>
            <div className="col-12">
              <span>Sozialkasse: </span>
              {this.state.soka} €
            </div>
            <hr/>
            <div className="col-12">
              <span>Kosten pro Jahr: </span>
              {this.state.kosten_jahr} €
            </div>
            <div className="col-12">
              <span>Kosten pro Stunde: </span>
              {this.state.kosten_h} €
            </div>
            <div className="col-12">
              <span>Gemeinkosten: </span>
              {this.state.gk} €
            </div>
            <hr/>
            <div className="col-12">
              <span>Selbskosten pro Stunde: </span>
              {this.state.sk_h} €
            </div>
            <div className="col-12">
              <span>Gewinn: </span>
              {this.state.gewinn} €
            </div>
            <hr/>
            <div className="col-12">
              <b><span>Angebotspreis: </span>
              {this.state.angebotspreis} €</b>
            </div>
          </div>
        </li>

      </React.Fragment>
    );
  }
}

export default CalcEmployeesModal;
