import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-solid";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
import ModalAddItem from "./ModalAddItem.js";
import ModalSelectItem from "./ModalSelectItem.js";
import ModalOpenSelectNote from "./ModalOpenSelectNote.js";
import { globalUnits } from "../global/units.js";
var $ = require("jquery");

class DeliveryNotesFormInputTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      date: this.props.date,
      notesNr: this.props.notesNr,
      supplier: this.props.supplier,
      supplierId: this.props.supplierId,
      bv: this.props.bv,
      bvId: this.props.bvId,
      showItem: this.props.showItem,
      articles: this.props.articles,
      search: this.props.search,
      bvAll: [],
      deliverdItems: [
        {
          itemId: "",
          itemDsc: "",
          itemAmnt: "",
          itemUnit: "",
          checkItemAvailable: false,
        },
      ],
      errorSearch: false,
      btnAddItem: true,
      useSearch: false,
      searchRes: [],
      searchNewArray: [],
      searchArr: [],
      searchHelp: [],
      supplierAll: [],
      disabledFieldset: false,
      getDataModal: false,
      multipleItems: [],
      showMultipleItems: false,
      notesNrInUse: false,
      showBanner: false,
      selectNotes: [],
      modalSelectNote: false,
      savedSelectNotes: [],
    };
  }
  componentDidMount = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true,
      });
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "bv" && e.target.value === "-") {
      this.setState({
        bvId: "",
      });
    }
    if (e.target.name === "supplier" && e.target.value === "-") {
      this.setState({
        supplierId: "",
      });
    }
    if (e.target.name === "bv") {
      this.readBvIdByName(e.target.value, true);
    }
    if (e.target.name === "supplier") {
      this.readSupplierIdByName(e.target.value, true);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let deliveryNote;
    let insert = false;
    let countUp = 0;
    let id = "";
    this.state.deliverdItems.map((items, idx) => {
      deliveryNote = {
        date: this.state.date,
        notesNr: this.state.notesNr.trim(),
        supplierId: this.state.supplierId,
        itemId: items.itemId,
        itemDsc: items.itemDsc,
        itemAmnt: items.itemAmnt.replace(/,/g, "."),
        itemUnit: items.itemUnit,
        bvId: this.state.bvId,
      };
      if (items.id === undefined) {
        this.saveDeliveryItem(deliveryNote);
        insert = true;
      } else {
        deliveryNote = {...deliveryNote, ...{id: items.id}};
        this.updateDeliveryItem(deliveryNote, items.id);
        id = this.state.deliverdItems[0].id;
        countUp += 1;
       }
    });
    if(insert){
      this.props.readLastNote();
    }
    if(this.state.deliverdItems.length === countUp){
         this.refreshListAfterUpdate(id);
 
 
     }
  };

  saveDeliveryItem = (deliveryNote) => {
    $.ajax({
      url: BaseUrl + "api/deliveryNote/createDeliveryNote.php",
      type: "POST",
      dataType: "json",
      data: JSON.stringify(deliveryNote),
      success: function (response) {
        this.resetInputFields();
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  updateDeliveryItem = (deliveryNote, id) => {
    $.ajax({
      url: BaseUrl + "api/deliveryNote/updateNote.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deliveryNote),
      success: function (response) {
         setTimeout(() => {
         this.resetInputFields();
        }, 1000);
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  removeNotePerNr = (id, idx)  => {
 
    let deleteItem = {
      id: id
    };
    $.ajax({
      url: BaseUrl + "api/deliveryNote/deleteNotePerNr.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function(response) {
        this.setState({
          articles: this.state.articles.filter((s, sidx) => idx !== sidx)
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  refreshListAfterUpdate = (id) => {
    let refreshNote = {
      date: this.state.date,
      id: id,
      notesNr: this.state.notesNr,
      supplier: this.state.supplier,
      supplierId: this.state.supplierId,
      itemBv: this.state.bv,
      itemBvId: this.state.bvId,
      articles: this.state.deliverdItems
    }
    this.props.updateList(refreshNote, id);
  }
  handleAddDeliveryItem = () => {
    this.setState({
      deliverdItems: this.state.deliverdItems.concat([
        { itemId: "", itemDsc: "", itemAmnt: "", itemUnit: "" },
      ]),
    });
  };

  handleRemoveDeliveryItem = (idx, id) => () => {
    this.setState({
      deliverdItems: this.state.deliverdItems.filter((s, sidx) => idx !== sidx),
    });
    if(id !== undefined){
      this.removeNotePerNr(id, idx);
    }
  };
  handleChangeDeliverd = (idx) => (evt) => {
    const newItemChange = this.state.deliverdItems.map((item, sidx) => {
      if (idx !== sidx) return item;
      return { ...item, [evt.target.name]: evt.target.value };
    });
    this.setState({ deliverdItems: newItemChange });
  };

  autocompleteBvSupplier = (e) => {
    if (e.keyCode === 9 && e.target.name === "bvId") {
      this.readBvNameById(e.target.value, true);
    }
    if (e.keyCode === 9 && e.target.name === "supplierId") {
      this.readSupplierNameById(e.target.value, true);
    }
  };
  readBvNameById = (id, bool) => {
    let found = this.props.bvAllInput.find((name) => name.bvId === parseInt(id));
    if (found === undefined) {
      this.props.readOldBvById(id);
      setTimeout(() => {
        if (bool) {
          this.setState({
            bv: this.props.bvAllFound,
          });
        } else {
          return this.props.bvAllFound;
        }
      }, 1000);
    }
    if (bool === true && found !== undefined) {
      this.setState({
        bv: found.bv,
      });
     } else if (bool === false && found !== undefined) {
      return found.bv;
    }
  };

  readSupplierNameById = (id, bool) => {
    let found = this.props.supplierAll.find((name) => name.supplierId === parseInt(id));
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        supplier: found.supplierName,
      });
    } else {
      return found.supplierName;
    }
  };
  readSupplierIdByName = (name, bool) => {
    let found = this.props.supplierAll.find((id) => id.supplierName === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        supplierId: found.supplierId,
      });
    } else {
      return found.supplierId;
    }
  };

  readBvIdByName = (name, bool) => {
    let found = this.props.bvAllInput.find((id) => id.bv === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        bvId: found.bvId,
      });
    } else {
      return found.bvId;
    }
  };

  autocompleteTab = (idx) => (e) => {
    if (e.keyCode === 9) {
      this.getDscUnit(idx, e);
    }
  };
  getDscUnit = (idx, evt) => {
    const convertInput = evt.target.value.replace(/ /g, " ");
    return fetch(
      BaseUrl + "api/deliveryNote/readItemDesc.php?eingabe=" + convertInput
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "No itms found.") {
          this.setState({
            itemDsc: "",
            itemUnit: "",
            checkItemAvailable: true,
          });
        } else {
          if (responseJson.records.length > 1 && this.state.supplierId === "") {
            this.state.multipleItems = responseJson.records;
            this.setState({
              showMultipleItems: true,
              checkItemAvailable: false,
            });
          } else if (
            responseJson.records.length > 1 &&
            this.state.supplierId !== ""
          ) {
            responseJson.records
              .filter((e) => e.supplierId === this.state.supplierId)
              .map((item) => {
                return this.setState({
                  itemDsc: item.dsc,
                  itemUnit: item.unit,
                  checkItemAvailable: false,
                });
              });
          } else {
            responseJson.records.map((item) => {
              return this.setState({
                itemDsc: item.dsc,
                itemUnit: item.unit,
                checkItemAvailable: item.dsc ? false : true,
              });
            });
          }
        }

        const newItemAutocomplete = this.state.deliverdItems.map(
          (item, sidx) => {
            if (idx !== sidx) return item;
            else {
              return {
                ...item,
                itemId: item.itemId,
                itemDsc: this.state.itemDsc,
                itemUnit: this.state.itemUnit,
                checkItemAvailable: this.state.checkItemAvailable,
              };
            }
          }
        );
        return this.setState({ deliverdItems: newItemAutocomplete });
      })
      .catch((error) => {});
  };
  fillDescUnit = (id, idx) => {
    let itemDsc = "";
    let itemUnit = "";
    this.state.multipleItems
      .filter((e) => e.supplierId === id)
      .map((item) => {
        itemDsc = item.dsc;
        itemUnit = item.unit;
      });
    const newItemAutocomplete = this.state.deliverdItems.map((item, sidx) => {
      if (idx !== sidx) return item;
      else {
        return {
          ...item,
          itemId: item.itemId,
          itemDsc: itemDsc,
          itemUnit: itemUnit,
          checkItemAvailable: false,
        };
      }
    });
    return this.setState({ deliverdItems: newItemAutocomplete });
  };
  fillSupplier = (id, name) => {
    this.setState({
      supplierId: id,
      supplier: name
    })
  }
  resetModal = () => {
    this.setState({
      showMultipleItems: false,
    });
  };
  resetInputFields = () => {
    this.setState({
      date: "",
      notesNr: "",
      supplierId: "",
      supplier: "",
      bv: "",
      bvId: "",
      deliverdItems: [
        { itemId: "", itemDsc: "", itemAmnt: "", itemUnit: "" },
      ],
    });
  }
  hideAddArticle = (idx, dsc, unit) => {
    const newItemAutocomplete = this.state.deliverdItems.map((item, sidx) => {
      if (idx !== sidx) return item;
      return {
        ...item,
        itemId: item.itemId,
        itemDsc: dsc,
        itemUnit: unit,
        checkItemAvailable: false,
      };
    });
    return this.setState({ deliverdItems: newItemAutocomplete });
  };

  checkNotesNr = (e) => {
    if (e.keyCode === 9) {
 
      return fetch(
        BaseUrl + "api/deliveryNote/checkNotesNr.php?notesNr=" + e.target.value
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message !== "No itms found.") {
            this.setState({
              deliverdItems: [
                {
                  itemId: "",
                  itemDsc: "",
                  itemAmnt: "",
                  itemUnit: "",
                  checkItemAvailable: false,
                },
              ],
              date: this.state.date,
              bv: "",
              bvId: "",
              supplier: "",
              supplierId: "",
            });
            let loadedItems = [];
            let notes = [];
            if (Object.keys(responseJson.records[0]).length > 1) {
              responseJson.records.map((item, sidx) => {
                Object.keys(item).map((notesNr, idx) => {
                  item[notesNr].slice(0, 1).map((i, ix) => {
                    let found = this.props.bvComplete.find(
                      (name) => name.bvId === i.bvId
                    );

                    notes = [
                      ...notes,
                      {
                        date: this.convertDate(i.date),
                        notesNr: i.notesNr,
                        supplier: this.readSupplierNameById(
                          i.supplierId,
                          false
                        ),
                        supplierId: i.supplierId,
                        bv: found.bv,
                        bvId: i.bvId,
                      },
                    ];
                  });
                });
              });
              this.setState({
                selectNotes: notes,
                modalSelectNote: true,
                savedSelectNotes: responseJson.records,
              });
            } else {
              responseJson.records.map((item, sidx) => {
                Object.keys(item).map((notesNr, idx) => {
                  item[notesNr].map((i, ix) => {
                    loadedItems = [
                      ...loadedItems,
                      {
                        id: i.id,
                        itemId: i.itemId,
                        itemDsc: i.itemDsc,
                        itemAmnt: i.itemAmnt,
                        itemUnit: i.itemUnit,
                      },
                    ];
                  });
                  item[notesNr].slice(0, 1).map((i, ix) => {
                    notes = [
                      ...notes,
                      {
                        date: this.state.date,
                        supplier: i.supplier,
                        supplierId: i.supplierId,
                        bv: i.bv,
                        bvId: i.bvId,
                      },
                    ];
                  });
                });
              });

              const load = this.setState({
                deliverdItems: loadedItems,
                date: notes[0].date,
                bv: this.readBvNameById(notes[0].bvId, true),
                bvId: notes[0].bvId,
                supplier: this.readSupplierNameById(notes[0].supplierId, false),
                supplierId: notes[0].supplierId,
              });
              Promise.all([load]).then((value) => {
                this.showInsertBanner();
              });
            }
          } else {
            console.log(this.state.date)
            this.setState({
              notesNrInUse: false,
              date: this.state.date
            });
          }
        })
        .catch((error) => {});
    }
  };
  showInsertBanner = () => {
    this.setState({
      showBanner: true,
    });
    setTimeout(
      function () {
        this.setState({ showBanner: false });
      }.bind(this),
      2500
    );
  };
  convertDate = (date) => {
    if (date.includes("-")) {
      var dateNew = date.split("-");
      dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  toggleSelectNote = () => {
    this.setState({
      modalSelectNote: !this.state.modalSelectNote,
    });
  };
  loadInputSelectNotes = (notesNr, id) => {
    let loadedItems = [];
    let notes = [];
    this.state.savedSelectNotes.map((item, sidx) => {
      Object.keys(item).map((notesNr, idx) => {
        if (item[notesNr][sidx].supplierId === id) {
          item[notesNr].map((i, ix) => {
            loadedItems = [
              ...loadedItems,
              {
                id: i.id,
                itemId: i.itemId,
                itemDsc: i.itemDsc,
                itemAmnt: i.itemAmnt,
                itemUnit: i.itemUnit,
              },
            ];
          });
          item[notesNr].slice(0, 1).map((i, ix) => {
            notes = [
              ...notes,
              {
                date: i.date,
                supplierId: i.supplierId,
                bvId: i.bvId,
                bv: this.readBvNameById(i.bvId, true),
              },
            ];
          });

          this.setState({
            deliverdItems: loadedItems,
            date: notes[0].date,
            bvId: notes[0].bvId,
            supplier: this.readSupplierNameById(notes[0].supplierId, false),
            supplierId: notes[0].supplierId,
            modalSelectNote: !this.state.modalSelectNote,
          });
        }
      });
    });
  };
  render() {
    return (
      <React.Fragment>
        <section id="insertItem">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--greenSeyband)" icon="inbox" />{" "}
                Lieferscheine
              </h1>
            </div>
          </div>
          <h4>Lieferschein Hinzufügen</h4>
          <div className="row">
            <form className="deliveryNote" onSubmit={this.handleSubmit}>
              <fieldset disabled={this.state.disabledFieldset}>
                <div className="row">
                  <div className="col-2">
                    <p>Datum</p>
                    <input
                      required
                      type="date"
                      name="date"
                      min="2017-01-01"
                      onChange={this.handleChange}
                      value={this.state.date}
                    />
                  </div>
                  <div
                    className={
                      this.state.notesNrInUse
                        ? "col-4 tab redBorder"
                        : "col-4 tab"
                    }
                  >
                    <p>Beleg Nr.</p>
                    <input
                      required
                      type="text"
                      name="notesNr"
                      onChange={this.handleChange}
                      value={this.state.notesNr}
                      onKeyDown={this.checkNotesNr}
                    />
                    <span>↹</span>
                  </div>
                  <div
                    className={
                      this.state.showBanner ? "showBanner" : "hideBanner"
                    }
                  >
                    <span>
                      <FontAwesomeIcon
                        color="var(--greenSeyband)"
                        icon="check-square"
                      />{" "}
                      Der Lieferschein war vorhanden und wurde automatisch
                      geladen
                    </span>
                  </div>
                  <div className="col-2 tab">
                    <p>Lieferanten Nr.</p>
                    <input
                      required
                      type="text"
                      name="supplierId"
                      onChange={this.handleChange}
                      value={this.state.supplierId}
                      onKeyDown={this.autocompleteBvSupplier}
                    />
                    <span>↹</span>
                  </div>
                  <div className="col-4">
                    <p>Lieferant</p>
                    <select
                      name="supplier"
                      value={this.state.supplier}
                      onChange={this.handleChange}
                    >
                      <option value="-">-</option>
                      {this.props.supplierAll.map((supplier, i) => (
                        <option key={i} value={supplier.supplierName}>
                          {supplier.supplierName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-2 tab">
                    <p>Bauvorhaben Nr.</p>
                    <input
                      required
                      type="text"
                      name="bvId"
                      onChange={this.handleChange}
                      value={this.state.bvId}
                      onKeyDown={this.autocompleteBvSupplier}
                    />
                    <span>↹</span>
                  </div>
                  <div className="col-6">
                    <p>BV</p>
                    <select
                      name="bv"
                      value={this.state.bv}
                      onChange={this.handleChange}
                    >
                      <option key="-1" value="-">
                        -
                      </option>
                      {this.props.bvAllInput.map((bv, i) => (
                        <option key={i} value={bv.bv}>
                          {bv.bv}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <hr />
                {this.state.deliverdItems.map((items, idx) => (
                  <div key={idx} className="row">
                    <div className="tab col-2">
                      <p>{`ArtikelNr. #${idx + 1}`}</p>
                      <input
                        type="text"
                        name="itemId"
                        onChange={this.handleChangeDeliverd(idx)}
                        value={items.itemId}
                        onKeyDown={this.autocompleteTab(idx)}
                      />
                      <span>↹</span>
                    </div>
                    <div
                      className={items.checkItemAvailable ? "col-6" : "col-7"}
                    >
                      <p>{`Artikelbezeichnung #${idx + 1}`}</p>
                      <input
                        type="text"
                        name="itemDsc"
                        onChange={this.handleChangeDeliverd(idx)}
                        value={items.itemDsc}
                      />
                    </div>
                    <div className="col-1">
                      <p>{`Menge #${idx + 1}`}</p>
                      <input
                        type="text"
                        name="itemAmnt"
                        onChange={this.handleChangeDeliverd(idx)}
                        value={items.itemAmnt}
                      />
                    </div>
                    <div className="col-1">
                      <p>{`Einheit #${idx + 1}`}</p>
                      <select
                        name="itemUnit"
                        onChange={this.handleChangeDeliverd(idx)}
                        value={items.itemUnit}
                      >
                        {globalUnits.map((unit, idx) => {
                          return (
                            <option key={idx} value={unit}>
                              {unit}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      className={items.checkItemAvailable ? "col-1" : "hide"}
                    >
                      <ModalAddItem
                        supplier={this.state.supplier}
                        supplierId={this.state.supplierId}
                        itemId={items.itemId}
                        itemDsc={items.itemDsc}
                        itemUnit={items.itemUnit}
                        supplierAll={this.props.supplierAll}
                        idx={idx}
                        hideAddArticle={this.hideAddArticle}
                      />
                    </div>

                    <div className="col-1">
                      <button
                        type="button"
                        onClick={this.handleRemoveDeliveryItem(idx, items.id)}
                        className="btn-remove"
                      >
                        -
                      </button>
                    </div>
                    {this.state.showMultipleItems ? (
                      <ModalSelectItem
                        modalSelect={true}
                        multipleItems={this.state.multipleItems}
                        fillDescUnit={this.fillDescUnit}
                        idx={idx}
                        resetModal={this.resetModal}
                        supplierAll={this.props.supplierAll}
                        fillSupplier={this.fillSupplier}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ))}
                <div className="row">
                  <div className="offset-11 col-1">
                    <button
                      type="button"
                      onClick={this.handleAddDeliveryItem}
                      className="btn-add-item"
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="item-btn">
                  <button className="btn-create-item fullwidth">
                    Lieferschein speichern <FontAwesomeIcon icon="save" />
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
          {this.state.modalSelectNote ? (
            <ModalOpenSelectNote
              modalSelectNote={this.state.modalSelectNote}
              selectNotes={this.state.selectNotes}
              toggleSelectNote={this.toggleSelectNote}
              loadInputSelectNotes={this.loadInputSelectNotes}
            />
          ) : (
            ""
          )}
        </section>
      </React.Fragment>
    );
  }
}

export default DeliveryNotesFormInputTop;
