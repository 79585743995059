import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import BaseUrl from "../../config/config.js";
var Highlight = require("react-highlighter");
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var $ = require("jquery");

class OfferbookList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offerNr: this.props.offerNr,
      version: this.props.version,
      date: this.props.date,
      customerId: this.props.customerId,
      customerName: this.props.customerName,
      bv: this.props.bv,
      buildingStart: this.props.buildingStart,
      buildingEnd: this.props.buildingEnd,
      angebotsstatus: this.props.angebotsstatus,
      idx: this.props.idx,
      search: this.props.search,
      saved: false,
      colorStatus: ""
    };
  }

  componentDidMount = () => {
    this.changeStatusColor();
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
     });
     setTimeout(() => {
      this.updateAngebotsstatus();
    }, 500);
  };
  updateAngebotsstatus = () => {
    let updateOfferStatus = {
      offerNr: this.state.offerNr,
      bv: this.state.bv,
      angebotsstatus: this.state.angebotsstatus,
    };

    $.ajax({
      url: BaseUrl + "api/offer/updateOfferStatus.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(updateOfferStatus),
      success: function (response) {
        this.setState({
          saved: true,
        });
        this.changeStatusColor();

      }.bind(this),
      error: function (xhr, resp, text) {},
    });
    this.setState({
      saved: true,
    });
    setTimeout(() => {
      this.changeSavedStats();
    }, 2000);
  };

  changeSavedStats = () => {
 
    this.setState({
      saved: false,
    });
  };

  changeStatusColor = () => {
     
    if(this.state.angebotsstatus === "Erhalten"){
      this.setState({
        colorStatus: "green"
      })
    }
    else if( this.state.angebotsstatus === "Verloren"){
      this.setState({
        colorStatus: "red"
      })
    }
    else{
      this.setState({
        colorStatus: ""
      })
    }
 
  }

  render() {
    return (
      <React.Fragment>
        <li
          key={this.props.idx}
          onClick={this.toggle}
          className={this.state.hideItem ? "hide " : " "}
        >
          <div className="row fullwidth showSpan">
            <Link
              className="row col-11"
              to={`/home/angebot/angebot-erstellen/${this.state.offerNr}/${this.state.version}`}
            >
              <p className="col offer-nr-width">
                <span>Angebots-Nr:</span>
                <br />
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.offerNr}
                </Highlight>
              </p>
              <p className="col-1 offer-version-width">
                <span>Version</span>
                <br />{" "}
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.version}
                </Highlight>{" "}
              </p>
              <p className="col offer-date-width">
                <span>Datum</span>
                <br />
                {this.state.date}
              </p>
              <p className="col-1 offer-customerId-width">
                <span>Kunden-ID</span>
                <br />
                {this.state.customerId}
              </p>
              <p className="col-3">
                <span>Kunde</span>
                <br />
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.customerName}
                </Highlight>
              </p>
              <p className="col">
                <span>Bv</span>
                <br />
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.bv}
                </Highlight>
              </p>
              <p className="col  offer-date-width">
                <span>Baubeginn</span>
                <br />
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.buildingStart}
                </Highlight>
              </p>
              <p className="col  offer-date-width">
                <span>Bauende</span>
                <br />
                <Highlight id="font-normal" search={this.props.search}>
                  {this.state.buildingEnd}
                </Highlight>
              </p>
            </Link>
            <p className={this.props.hideStatus ? "col select-offer" : "hide "}>
              <span>Angebotsstatus</span>
              <br />
              <select
                name="angebotsstatus"
                onChange={this.handleChange}
                value={this.state.angebotsstatus}
               >
                <option key="0" value="Offen">
                  Offen
                </option>
                <option key="1" value="Erhalten">
                  Erhalten
                </option>
                <option key="2" value="Verloren">
                  Verloren
                </option>
              </select>

              <FontAwesomeIcon
                className={
                  this.state.saved ? "offer-opacity-1" : "offer-opacity-0"
                }
                color="var(--green)"
                icon="check-circle"
              />
              <span className={"showStatusList " + this.state.colorStatus}></span>
            </p>
          </div>
        </li>
      </React.Fragment>
    );
  }
}

export default OfferbookList;
