import React, { useState, useEffect } from "react";
import "@fortawesome/fontawesome-free-solid";
import BaseUrl from "../../config/config.js";
import CustomFontRegular from "../../fonts/Lunchtype22-Regular.ttf";
import CustomFontBold from "../../fonts/Lunchtype22-Medium.ttf";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Link,
  Line,
  Svg,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "CustomFont", // Font family name
  fonts: [
    { src: CustomFontRegular, fontWeight: "normal" },
    { src: CustomFontBold, fontWeight: "bold" },
  ],
});
function PrintConfirmationPDF(props) {
  const [state, setState] = useState({
    id: "",
    name: "",
    strasse: "",
    plz: "",
    land: "",
    tel: "",
    telefax: "",
    mobil: "",
    mail: "",
    web: "",
    auftragsbestaetigung: "",
    customer: props.customer,
    streetCustomer: props.streetCustomer,
    zipCodeCustomer: props.zipCodeCustomer,
    cityCustomer: props.cityCustomer,
    version: props.version,
    versionOld: props.versionOld,
  });

  useEffect(() => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      setState((prevState) => ({
        ...prevState,
        disabledFieldset: true,
      }));
    }
    loadBaseData();
  }, []);
  const currentDate = () => {
    let newDate = new Date();
    let day = newDate.getDate();
    day = day < 10 ? "0" + day : day;
    let month = newDate.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let year = newDate.getFullYear();

    let cDate = day + "." + month + "." + year;

    return cDate;
  };
  const loadBaseData = () => {
    return fetch(BaseUrl + "api/baseData/readBaseData.php")
      .then((response) => response.json())
      .then((responseJson) => {
        const data = responseJson.records[0][0];

        setState((prevState) => ({
          ...prevState,
          id: data.id,
          name: data.name,
          strasse: data.strasse,
          plz: data.plz,
          land: data.land,
          tel: data.tel,
          telefax: data.telefax,
          mobil: data.mobil,
          mail: data.mail,
          web: data.web,
          auftragsbestaetigung: data.auftragsbestaetigung,
        }));
      })
      .catch((error) => {});
  };

  const convertDate = (date) => {
    if (date === null || date === "0000-00-00" || date === undefined) {
      return date;
    }

    if (date.includes("-")) {
      var dateNew = date.split("-");
      dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  const replaceText = (text) => {
    return text
      .replace("%angebotsNr%", props.offerNr + "-" + props.version)
      .replace("%angebotsDatum%", convertDate(props.date));
  };

  const styles = StyleSheet.create({
    page: {
      flexWrap: "wrap",
      fontSize: "7pt",
      flexDirection: "row",
      paddingTop: 35,
      paddingBottom: "4cm",
      paddingLeft: 35,
      paddingRight: 35,
      lineHeight: "1.5pt",
      fontFamily: "CustomFont",
      fontWeight: 300,
    },
    footerHeight: {
      height: "4cm",
    },
    view: {
      width: "100%",
    },
    width50: {
      width: "50%",
    },
    width70: {
      width: "70%",
    },
    width30: {
      width: "30%",
    },
    marginT25: {
      marginTop: "25px",
      display: "block",
      width: "100%",
    },
    marginT80: {
      marginTop: "80px",
      display: "block",
      width: "100%",
    },
    marginT30: {
      marginTop: "50px",
    },
    marginT10: {
      marginTop: 10,
    },
    marginT3: {
      marginTop: 3,
    },
    textRight: {
      textAlign: "right",
    },
    center: {
      textAlign: "center",
    },
    width33: {
      width: "33%",
    },
    alignRight: {
      alignItems: "flex-end",
      width: "100%",
    },
    image: {
      width: 100,
    },
    imageFirstPage: {
      width: 165,
    },

    pt7: {
      marginBottom: "7pt",
      fontSize: "7pt",
    },
    pt8: {
      fontSize: "8pt",
      fontWeight: "900",
    },
    pt9: {
      fontSize: "9pt",
    },
    lineHeigt3pt: {
      lineHeight: "1.5pt",
    },
    anschrift: {
      fontWeight: "900",
      lineHeight: "2pt",
      fontSize: "9pt",
    },
    h2: {
      fontSize: "18pt",
      marginBottom: "8px",
      fontWeight: 600,
      textAlign: "center",
      marginTop: "15px",
      marginBottom: "40px",
    },
    h3: {
      fontSize: "14pt",
      marginBottom: "8px",
      fontWeight: 600,
    },
    h4: {
      fontSize: "13pt",
      fontWeight: 600,
    },
  });

  const stylesList = StyleSheet.create({
    view: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      marginBottom: "0.5mm",
    },

    fullwidth: {
      width: "18cm",
    },
    none: {
      display: "none",
    },

    marginBottomMinus: {
      marginBottom: "-8px",
    },

    flexEnd: {
      justifyContent: "flex-end", // Aligns content to the bottom of the view
      alignItems: "flex-end",
    },

    bold: {
      fontWeight: 600,
    },
  });

  return (
    <Document> 
    <Page size="A4" style={styles.page} wrap>
      <View style={[stylesList.view]}>
        <View style={styles.alignRight} fixed>
          <Image
            style={styles.imageFirstPage}
            src={require("../../img/logo.png")}
          />
        </View>

        <View style={styles.width70}>
          <Text style={styles.pt7}>
            {state.name} · {state.strasse} ·{state.plz}
          </Text>
          <Text style={styles.anschrift}>{props.customer}</Text>
          <Text style={styles.anschrift}>{props.streetCustomer}</Text>
          <Text style={[styles.anschrift, styles.marginT10]}>
            {props.zipCodeCustomer} {props.cityCustomer}
          </Text>
        </View>
        <View style={[styles.width30, styles.pt8, styles.lineHeigt3pt]}>
          <Text>Telefon: {state.tel}</Text>
          <Text>Telefax: {state.telefax}</Text>
          <Text>Mobil: {state.mobil}</Text>
          <Text>Email: {state.mail}</Text>
          <Text>Internet: {state.web}</Text>
        </View>
        <View style={styles.marginT80}></View>
        <View style={styles.width33}>
          <Text>Ihr Zeichen</Text>
        </View>
        <View style={[styles.width33, styles.center]}>
          <Text>Unser Zeichen</Text>
        </View>
        <View style={[styles.width33, styles.textRight]}>
          <Text>Datum {currentDate()}</Text>
        </View>
        <View style={styles.marginT30}></View>
        <View style={styles.view}>
          <Text style={[styles.h2]}>Auftragsbestätigung</Text>
        </View>
        <View style={styles.view}>
          {state.auftragsbestaetigung.split("\n").map((item, key) => {
            return (
              <Text style={styles.pt9} key={key}>
                {replaceText(item)}
                {"\n"}
              </Text>
            );
          })}
        </View>
        <View style={[stylesList.view, styles.marginT80]}>
          <View style={styles.width50}>
            <Svg height="1" width="400">
              <Line
                x1="0"
                y1="0"
                x2="200"
                y2="00"
                strokeWidth={1}
                stroke="rgb(0,0,0)"
              />
            </Svg>
            <Text style={[styles.pt9, styles.marginT10]}>Ort, Datum</Text>
          </View>
          <View style={[styles.width50, styles.textRight]}>
            <Svg height="1" width="400">
              <Line
                x1="50"
                y1="0"
                x2="263"
                y2="00"
                strokeWidth={1}
                stroke="rgb(0,0,0)"
              />
            </Svg>
            <Text style={[styles.pt9, styles.marginT10]}>
              Handschriftliche Unterschrift
            </Text>
          </View>
        </View>
      </View>
    </Page>
    </Document>
  );
}

export default PrintConfirmationPDF;
