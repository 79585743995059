import React, { Component } from "react";

import "../css/bootstrap-grid.css";
import "../css/App.css";
import "../css/index.css";
import "../css/mediaQuerys.css";
import HoursReporting from "./HoursReporting";
import HoursReportingWageAccounting from "./HoursReportingWageAccounting";
import LegalBreak from "./LegalBreak";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "@fortawesome/fontawesome-free-solid";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const hoursReportMain = () => {
  return (
    <React.Fragment>
      <section id="insertHoursReporting" className="section-start">
        <div className=" wrapper-btn-text row">
          <div className="col-md-2">
            <Link to="/home">
              <FontAwesomeIcon icon="arrow-left" /> Home
            </Link>
          </div>
          <div className="col-md-8">
            <h1>
              <FontAwesomeIcon color="var(--greenSeyband)" icon="stopwatch" />{" "}
              Stundenbericht Übersicht
            </h1>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col">
            <Link
              className="btn-start"
              to="/home/stundenbericht/stundenbericht-eingabe"
            >
              <FontAwesomeIcon icon="sign-in-alt" />
              <br />
              Stundenbericht eingabe
            </Link>
          </div>
          <div className="col">
            <Link
              className="btn-start"
              to="/home/stundenbericht/stundenbericht-lohnbuero"
            >
              <FontAwesomeIcon icon="sign-out-alt" />
              <br />
              Stundenberichte Lohnbüro
            </Link>
          </div>
        </div>
        <div className="row legalbreak-main">
          <div className="col-6">
            <Link
              className="btn-start"
              to="/home/stundenbericht/gesetzliche-pausen"
            >
              <FontAwesomeIcon icon="coffee" />
              <br />
              Gesetzliche Pausen
            </Link>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const hoursReporting = () => {
  return <HoursReporting />;
};
const hoursReportingWageAccounting = () => {
  return <HoursReportingWageAccounting />;
};
const legalbreaks = () => {
  return <LegalBreak />;
};
class HoursReportMain extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/home/stundenbericht" component={hoursReportMain} />
        <Route
          path="/home/stundenbericht/stundenbericht-eingabe"
          component={hoursReporting}
        />
        <Route
          path="/home/stundenbericht/stundenbericht-lohnbuero"
          component={hoursReportingWageAccounting}
        />
        <Route
          path="/home/stundenbericht/gesetzliche-pausen"
          component={legalbreaks}
        />
      </React.Fragment>
    );
  }
}
export default HoursReportMain;
