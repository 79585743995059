import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";

 class SearchDeliveryNotesBills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search : this.props.search
    };
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.search !== this.props.search) {
      this.setState({
       });
    }
  }
  handleSearch = e => {
    this.setState({
      search: e.target.value
    });
    this.props.handleSearch(e);
  };

  render() {
    return (
      <React.Fragment>
         <div className="col-12 col-md-4 offset-md-4">
          <input
            className="search"
            type="text"
            name="search"
            placeholder="Suchen"
            onChange={this.handleSearch}
            onKeyPress={this.props.onKeyPress}
            value={this.state.search}
          />
        </div>
       </React.Fragment>
    );
  }
}
export default SearchDeliveryNotesBills;
