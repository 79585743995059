import React, { Component } from "react";
import ModalProjects from "./ModalProjects.js";
import BvSearch from "./BvSearch.js";
import ListBVOpen from "./ListBVOpen.js";
 import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var $ = require("jquery");

class BuildingProjects extends Component {
  constructor() {
    super();
    this.state = {
      bv: "",
      bvId: "",
      street: "",
      streetNr: "",
      city: "",
      zipCode: "",
      constBeg: "",
      constEnd: "",
      orderNr: "",
      done: false,
      bvsAll: [],
      customerAll: [],
      customerName: "",
      customerId: "",
      bvsAllCopy: [],
      errorSearch: false,
      search: "",
      BVOpenAll: [],
      hideModalBV: false,
      bvIdAll: [],
      addToList: false,
      bvIdExists: false
    };
  }

  componentDidMount = () => {
    Promise.all([this.readCustomersNames()]).then(() => {
      Promise.all([this.loadbvs()]).then(() => {
        this.loadLastId();
      });
    });
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true
      });
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if (e.target.name === "customerName") {
      this.readCustomerIdByName(e.target.value, true);
    }
    if (e.target.name === "customerName" && e.target.value === "-") {
      this.setState({
        customerId: ""
      });
    }
  };
  handleChangeCheck = e => {
    this.setState({
      done: !this.state.done
    });
  };
  handleSubmit = e => {
    e.preventDefault();
     if (this.state.done) {
      var createbv = {
        bv: this.state.bv,
        bvId: this.state.bvId,
        done: "x",
        customerId: this.state.customerId,
        street: this.state.street,
        streetNr: this.state.streetNr,
        zipCode: this.state.zipCode,
        city: this.state.city,
        constBeg: this.state.constBeg,
        constEnd: this.state.constEnd,
        orderNr: this.state.orderNr
      };
    } else {
      createbv = {
        bv: this.state.bv,
        bvId: this.state.bvId,
        done: this.state.done,
        customerId: this.state.customerId,
        street: this.state.street,
        streetNr: this.state.streetNr,
        zipCode: this.state.zipCode,
        city: this.state.city,
        constBeg: this.state.constBeg,
        constEnd: this.state.constEnd,
        orderNr: this.state.orderNr
      };
    }
    $.ajax({
      url: BaseUrl + "api/bv/createBV.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(createbv),
      success: function(response) {
         var parsedData = JSON.parse(response);
         if(parsedData.text !== "klappt"){
          this.setState({
            bvIdExists: true,
            addToList: false
          })
          setTimeout(() => {
            this.setState({
              bvIdExists: false
            })
          }, 5000);
        }
        else{
          this.setState({
            bv: "",
            bvId: parseInt(this.state.bvId) + 1,
            done: "",
            customerId: "",
            customerName: "",
            street: "",
            streetNr: "",
            city: "",
            zipCode: "",
            constBeg: "",
            constEnd: "",
            orderNr: "",
            addToList: true
          });
          this.funcAddToList(createbv);

          }
 
      }.bind(this),
      error: function(xhr, resp, text) {
 
      }
    });
  };
  funcAddToList = (bv) => {
 
      this.state.bvsAll.unshift({
        id: parseInt(this.state.bvsAll[0].id) + 1,
        bv: bv.bv,
        bvId: bv.bvId,
        done: bv.done,
        customerId: bv.customerId,
        street: bv.street,
        streetNr: bv.streetNr,
        zipCode: bv.zipCode,
        city: bv.city,
        constBeg: bv.constBeg,
        constEnd: bv.constEnd,
        orderNr: bv.orderNr
      });
  
      return this.setState({
        bvsAll: this.state.bvsAll
      });
  }
  loadbvs = () => {
    return fetch(BaseUrl + "api/bv/readBV.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records[0].map(item => {
          this.state.bvIdAll = [...this.state.bvIdAll, parseInt(item.bvId)];
        });
        return this.setState({
          bvsAll: responseJson.records[0],
          bvsAllCopy: responseJson.records[0]
        });
      })
      .catch(error => {});
  };
  loadOpenBV = () => {
    return fetch(BaseUrl + "api/bv/readOpenBV.php")
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          BVOpenAll: responseJson.records[0],
          hideModalBV: true
        });
      })
      .catch(error => {});
  };
  showBV = () => {
    this.setState({
      hideModalBV: false
    });
  };
  readCustomersNames = () => {
    return fetch(BaseUrl + "api/all/readCustomersNames.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records[0].map(item => {
          return (this.state.customerAll = this.state.customerAll.concat(item));
        });
        this.setState({
          customerAll: this.state.customerAll
        });
      })
      .catch(error => {});
  };
  readCustomerNameById = (id, bool) => {
    let found = this.state.customerAll.find(name => name.customerId === id);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        customerName: found.customerName
      });
    } else {
      return found.customerName;
    }
  };
  readCustomerIdByName = (name, bool) => {
    let found = this.state.customerAll.find(id => id.customerName === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        customerId: found.customerId
      });
    } else {
      return found.CustomerId;
    }
  };
  autocompleteCustomer = e => {
    if (e.keyCode === 9 && e.target.name === "customerId") {
      this.readCustomerNameById(e.target.value, true);
    }
  };
  loadLastId = () => {
    const max = Math.max(...this.state.bvIdAll.map(o => o), 0);
    this.setState({
      bvId: max + 1
    });
  };
  onKeyPress = e => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };
  handleSearch = e => {
    this.state.search = e.target.value;

    if (e.target.value === "") {
      this.setState({
        bvsAll: this.state.bvsAllCopy,
        errorSearch: false
      });
    }
  };

  search = eingabe => {
    var convertEingabe = eingabe.replace(/ /g, "%").replace("+", "%2B");
    if (eingabe === "") {
      this.setState({
        bvsAll: this.state.bvsAllCopy,
        errorSearch: false
      });
    } else {
      this.setState({
        bvsAll: []
      });

      return fetch(BaseUrl + "api/bv/searchBV.php?eingabe=" + convertEingabe)
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.message === "No bvs found.") {
            this.setState({
              bvsAll: [],
              errorSearch: true
            });
          } else {
            this.setState({
              bvsAll: responseJson.records[0]
            });
          }
        })
        .catch(error => {});
    }
  };
  updateOpenBv = (id, updateOpen) => {
     let elementIndex = this.state.bvsAll.findIndex(elem => elem.id == id);
    let bvsAll = [...this.state.bvsAll];
    bvsAll[elementIndex] = {...bvsAll[elementIndex],     bv: updateOpen.bv, bvId: updateOpen.bvId, city: updateOpen.city, constBeg: updateOpen.constBeg, constEnd: updateOpen.constEnd, customerId: updateOpen.customerId, done: updateOpen.done, id: id, orderNr: updateOpen.orderNr, street: updateOpen.street, streetNr: updateOpen.streetNr, zipCode: updateOpen.zipCode
     };
    this.setState({
      bvsAll: []
    })
    this.setState({
      bvsAll: bvsAll
    })
    }
  render() {
    return (
      <React.Fragment>
        <section id="insertbv">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--greenSeyband)" icon="industry" />{" "}
                Bauvorhaben
              </h1>
            </div>
            
          </div>
          <h4>Bauvorhaben Hinzufügen</h4>
          <form className="buildingProjects" onSubmit={this.handleSubmit}>
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="row">
                <div className="col-md-1">
                  <p>Bv-Nr</p>
                  <input
                    required
                    type="text"
                    name="bvId"
                    onChange={this.handleChange}
                    value={this.state.bvId}
                  />
                </div>
                <div className="col-md-5">
                  <p>Bv</p>
                  <input
                    required
                    type="text"
                    name="bv"
                    onChange={this.handleChange}
                    value={this.state.bv}
                  />
                </div>
                <div className="col-2 tab">
                  <p>Kunden-Id</p>
                  <input
                    required
                    type="text"
                    name="customerId"
                    placeholder="-"
                    onChange={this.handleChange}
                    value={this.state.customerId}
                    onKeyDown={this.autocompleteCustomer}
                  />
                  <span>↹</span>
                </div>
                <div className="col-md-4">
                  <p>Kunde</p>
                  <select
                    name="customerName"
                    value={this.state.customerName}
                    onChange={this.handleChange}
                  >
                    <option value="-">-</option>
                    {this.state.customerAll.map((customer, i) => (
                      <option key={i} value={customer.customerName}>
                        {customer.customerName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Straße</p>
                  <input
                    type="text"
                    name="street"
                    onChange={this.handleChange}
                    value={this.state.street}
                  />
                </div>
                <div className="col-md-1">
                  <p>Hausnummer</p>
                  <input
                    type="text"
                    name="streetNr"
                    onChange={this.handleChange}
                    value={this.state.streetNr}
                  />
                </div>

                <div className="col-md-2">
                  <p>Plz</p>
                  <input
                    type="text"
                    name="zipCode"
                    onChange={this.handleChange}
                    value={this.state.zipCode}
                  />
                </div>
                <div className="col-md-2">
                  <p>Ort</p>
                  <input
                    type="text"
                    name="city"
                    onChange={this.handleChange}
                    value={this.state.city}
                  />
                </div>
                <div className="col-md-3">
                  <p>Auftrags-Nr</p>
                  <input
                    type="text"
                    name="orderNr"
                    onChange={this.handleChange}
                    value={this.state.orderNr}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <p>Baubeginn</p>
                  <input
                    type="date"
                    name="constBeg"
                    onChange={this.handleChange}
                    value={this.state.constBeg}
                  />
                </div>
                <div className="col-md-3">
                  <p>Bauende</p>
                  <input
                    type="date"
                    name="constEnd"
                    onChange={this.handleChange}
                    value={this.state.constEnd}
                  />
                </div>
                <div className="col-md-2 inline">
                  <input
                    type="checkbox"
                    name="done"
                    id="done"
                    placeholder="Fertig"
                    onChange={this.handleChangeCheck}
                    checked={this.state.done}
                  />
                  <label>Erledigt</label>
                </div>
                <div className="offset-2 col-md-2">
                  <button className="btn-add-bv">
                    Bauvorhaben speichern <FontAwesomeIcon icon="save" />
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </section>
        <section id="showbvs" className="display-item">
          <h4>Übersicht Bauvorhaben</h4>
          <div className="show-items-btn row">
            <div className="col-6 offset-3">
              <div>
              <BvSearch
                search={this.state.search}
                onKeyPress={this.onKeyPress}
                handleSearch={this.handleSearch}
              />
              </div>
            </div>
            <div className="col-3">
              <button
                onClick={this.loadOpenBV}
                className={
                  this.state.hideModalBV
                    ? "hide"
                    : "btn-create-item margin-zero"
                }
              >
                {" "}
                <FontAwesomeIcon icon="file-alt" /> offene BV
              </button>

              <button
                onClick={this.showBV}
                className={
                  this.state.hideModalBV
                    ? "btn-create-item margin-zero"
                    : "hide"
                }
              >
                {" "}
                <FontAwesomeIcon icon="file-alt" /> alle BV
              </button>
            </div>
          </div>
          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          <div className={this.state.hideModalBV ? "hide" : ""}>
            <ul className="bvs-list">
              {this.state.bvsAll.map((comp, idx) => {
                   return (
                  <ModalProjects
                    onChange={this.handleChange}
                    key={comp.id}
                    id={comp.id}
                    bv={comp.bv}
                    bvId={comp.bvId}
                    done={comp.done}
                    customerId={comp.customerId}
                    customerName={this.readCustomerNameById(
                      comp.customerId,
                      false
                    )}
                    street={comp.street}
                    streetNr={comp.streetNr}
                    city={comp.city}
                    zipCode={comp.zipCode}
                    constBeg={comp.constBeg}
                    constEnd={comp.constEnd}
                    orderNr={comp.orderNr}
                    customerAll={this.state.customerAll}
                    search={this.state.search}
                    updateOpenBv={this.updateOpenBv}
                  />
                );
              })}
            </ul>
          </div>
          <ul className="items-list bv-list">
            <div className={this.state.hideModalBV ? "" : "hide"}>
              {this.state.BVOpenAll.map(comp => {
                return (
                  <ListBVOpen
                    key={comp.id}
                    bv={comp.bv}
                    bvId={comp.bvId}
                    id={comp.id}
                    done={comp.done}
                    customerId={comp.customerId}
                    customerName={this.readCustomerNameById(
                      comp.customerId,
                      false
                    )}
                    street={comp.street}
                    streetNr={comp.streetNr}
                    city={comp.city}
                    zipCode={comp.zipCode}
                    constBeg={comp.constBeg}
                    constEnd={comp.constEnd}
                    orderNr={comp.orderNr}
                    customerAll={this.state.customerAll}
                    search={this.state.search}
                    updateOpenBv={this.updateOpenBv}
                  />
                );
              })}
            </div>
          </ul>
          <div />
        </section>
        <div className={this.state.bvIdExists ? "errorBvIdExists" : "hideExist errorBvIdExists"}><FontAwesomeIcon icon="times-circle" /><br/>Bv-Nr existiert bereits</div>
      </React.Fragment>
    );
  }
}

export default BuildingProjects;
