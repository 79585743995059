import React, { Component } from "react";
import ModalEmployees from "./ModalEmployees.js";
 import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var $ = require("jquery");

class Employees extends Component {
  constructor() {
    super();
    this.state = {
      employeeNr: "",
      lastName: "",
      firstName: "",
      birthday: "",
      street: "",
      zipCode: "",
      place: "",
      taxNr: "",
      taxClass: "",
      religion: "",
      zvkNr: "",
      socialNr: "",
      iban: "",
      jobDesc: "",
      hourlyWage: "",
      employedSince: "",
      employedTill: "",
      healthInsurance: "",
      bgTarif: "",
      active:true,
      special: "",
      employeesAll: [],
      employeesAllCopy: [],
      errorInput: false,
      disabledFieldset: false,
      search:"",
      categorie: "",
      nationality:"",
      pantsSize:"",
      shirtSize:"",
      shoeSize:""
    };
  }

  componentDidMount = () => {
    this.loadEmployees();
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true
      });
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      errorInput: false
    });
  };
  handleChangeCheck = e => {
    this.setState({
      active: !this.state.active
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    let employees;
      if(this.state.active === true){
        employees = {
          employeeNr: this.state.employeeNr,
          lastName: this.state.lastName,
          firstName: this.state.firstName,
          birthday: this.state.birthday,
          street: this.state.street,
          zipCode: this.state.zipCode,
          place: this.state.place,
          taxNr: this.state.taxNr,
          taxClass: this.state.taxClass,
          religion: this.state.religion,
          zvkNr: this.state.zvkNr,
          socialNr: this.state.socialNr,
          iban: this.state.iban,
          jobDesc: this.state.jobDesc,
          hourlyWage: this.state.hourlyWage.replace(/,/g, "."),
          employedSince: this.state.employedSince,
          employedTill: this.state.employedTill,
          healthInsurance: this.state.healthInsurance,
          bgTarif: this.state.bgTarif,
          active: "x",
          special: this.state.special,
          categorie: this.state.categorie,
          nationality:this.state.nationality,
          pantsSize:this.state.pantsSize,
          shirtSize:this.state.shirtSize,
          shoeSize:this.state.shoeSize
        }
      }
      else{
      employees = {
        employeeNr: this.state.employeeNr,
        lastName: this.state.lastName,
        firstName: this.state.firstName,
        birthday: this.state.birthday,
        street: this.state.street,
        zipCode: this.state.zipCode,
        place: this.state.place,
        taxNr: this.state.taxNr,
        taxClass: this.state.taxClass,
        religion: this.state.religion,
        zvkNr: this.state.zvkNr,
        socialNr: this.state.socialNr,
        iban: this.state.iban,
        jobDesc: this.state.jobDesc,
        hourlyWage: this.state.hourlyWage.replace(/,/g, "."),
        employedSince: this.state.employedSince,
        employedTill: this.state.employedTill,
        healthInsurance: this.state.healthInsurance,
        bgTarif: this.state.bgTarif,
        active: "",
          special: this.state.special,
          categorie: this.state.categorie,
          nationality:this.state.nationality,
          pantsSize:this.state.pantsSize,
          shirtSize:this.state.shirtSize,
          shoeSize:this.state.shoeSize
      }
    };
      $.ajax({
        url: BaseUrl + "api/employees/createEmployees.php",
        type: "POST",
        dataType: "text",
        data: JSON.stringify(employees),
        success: function(response) {
          this.setState({
            employeeNr: "",
            lastName: "",
            firstName: "",
            birthday: "",
            street: "",
            zipCode: "",
            place: "",
            taxNr: "",
            taxClass: "",
            religion: "",
            zvkNr: "",
            socialNr: "",
            iban: "",
            jobDesc: "",
            hourlyWage: "",
            employedSince: "",
            employedTill:"",
            healthInsurance: "",
            bgTarif: "",
            active:"",
              special: "",
              categorie: "",
              nationality:"",
              pantsSize:"",
              shirtSize:"",
              shoeSize:""
          });
        }.bind(this),
        error: function(xhr, resp, text) {}
      });
      return fetch(BaseUrl + "api/employees/readLastEmployees.php")
        .then(response => response.json())
        .then(responseJson => {
          responseJson.records.map(item => {
            this.state.employeesAll.unshift(responseJson.records[0]);
            return this.setState({
              employeesAll: this.state.employeesAll
            });
           });
        })
        .catch(error => {});
  };
  loadEmployees = () => {
    return fetch(BaseUrl + "api/employees/readEmployees.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            employeesAll: responseJson.records,
            employeesAllCopy: responseJson.records
          });
         });

      })
      .catch(error => {});
  };

  onKeyPress = e => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };
  handleSearch = e => {
    this.setState({
      search: e.target.value
    });

    if (e.target.value === "") {
      this.setState({
        employeesAll: this.state.employeesAllCopy,
        errorSearch: false
      });
    }
  };

  search = eingabe => {
    let convertEingabe = eingabe.replace(/ /g, "%").replace("+", "%2B");

    if (eingabe === "") {
      this.setState({
        employeesAll: this.state.employeesAllCopy,
        errorSearch: false
      });
    } else {
      this.setState({
        employeesAll: []
      });
      return fetch(
        BaseUrl + "api/employees/searchEmployees.php?eingabe=" +
          convertEingabe
      )
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.message === "No items found.") {
            this.setState({
              employeesAll: [],
              errorSearch: true
            });
          } else {
            this.setState({
              employeesAll: responseJson.records
            });
          }
        })
        .catch(error => {});
    }
  };

  render() {
    return (
      <React.Fragment>
        <section id="insertEmployees">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--blue)" icon="users" />{" "}
                Mitarbeiter
              </h1>
            </div>
          </div>
          <h4>Mitarbeiter Hinzufügen</h4>
          <form className="employees"onSubmit={this.handleSubmit}>
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="row">
                <div className="col-md-2">
                <p>Mitarbeiter-Nr</p>
                  <input required
                    type="text"
                    name="employeeNr"
                    onChange={this.handleChange}
                    value={this.state.employeeNr}
                  />
                </div>
                <div className="col-md-4">
                <p>Vorname</p>
                  <input required
                    type="text"
                    name="firstName"
                    onChange={this.handleChange}
                    value={this.state.firstName}
                  />
                </div>
                <div className="col-md-4">
                <p>Nachname</p>
                  <input required
                    type="text"
                    name="lastName"
                    onChange={this.handleChange}
                    value={this.state.lastName}
                  />
                </div>
                <div className="col-md-2">
                  <p>Geburtstag</p>
                  <input required
                    type="date"
                    name="birthday"
                    onChange={this.handleChange}
                    value={this.state.birthday}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                <p>Nationalität</p>
                  <input required
                    type="text"
                    name="nationality"
                    onChange={this.handleChange}
                    value={this.state.nationality}
                  />
                </div>
                <div className="col-md-2">
                <p>Religion</p>
                  <input required
                    type="text"
                    name="religion"
                    onChange={this.handleChange}
                    value={this.state.religion}
                  />
                </div>

                <div className="col-md-2">
                <p>Krankenkasse</p>
                  <input required
                    type="text"
                    name="healthInsurance"
                    onChange={this.handleChange}
                    value={this.state.healthInsurance}
                  />
                </div>
                <div className="col-md-4">
                <p>IBAN</p>
                  <input required
                    type="text"
                    name="iban"
                    onChange={this.handleChange}
                    value={this.state.iban}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Kategorie</p>
                  <select
                    name="categorie"
                    onChange={this.handleChange}
                    value={this.state.categorie}
                  >
                    <option key="-1" value="-">
                      -
                    </option>
                    <option key={1} value="Vorarbeiter">
                      Vorarbeiter
                    </option>
                    <option key={2} value="Facharbeiter">
                      Facharbeiter
                    </option>
                    <option key={3} value="Hilfsarbeiter">
                      Hilfsarbeiter
                    </option>
                    <option key={4} value="Meister">
                      Meister
                    </option>
                    <option key={5} value="Auszubildender">
                      Auszubildender
                    </option>

                  </select>
                </div>
                <div className="col-md-2">
                <p>Tätigkeit</p>
                  <input required
                    type="text"
                    name="jobDesc"
                    onChange={this.handleChange}
                    value={this.state.jobDesc}
                  />
                </div>
                <div className="col-md-3">
                  <p>Angestellt seit</p>
                  <input required
                    type="date"
                    name="employedSince"
                    placeholder="Angestellt seit"
                    onChange={this.handleChange}
                    value={this.state.employedSince}
                  />
                </div>
                <div className="col-md-3">
                  <p>Angestellt bis</p>
                  <input
                    type="date"
                    name="employedTill"
                    placeholder="Angestellt bis"
                    onChange={this.handleChange}
                    value={this.state.employedTill}
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-2">
                <p>Straße</p>
                  <input required
                    type="text"
                    name="street"
                    onChange={this.handleChange}
                    value={this.state.street}
                  />
                </div>
                <div className="col-md-1">
                <p>Plz</p>
                  <input required
                    type="text"
                    name="zipCode"
                    onChange={this.handleChange}
                    value={this.state.zipCode}
                  />
                </div>
                <div className="col-md-2">
                <p>Ort</p>
                  <input required
                    type="text"
                    name="place"
                    onChange={this.handleChange}
                    value={this.state.place}
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col">
                <p>Steuernummer</p>
                  <input required
                    type="text"
                    name="taxNr"
                    onChange={this.handleChange}
                    value={this.state.taxNr}
                  />
                </div>
                <div className="col">
                <p>Steuerklasse</p>
                  <input required
                    type="text"
                    name="taxClass"
                    onChange={this.handleChange}
                    value={this.state.taxClass}
                  />
                </div>
                <div className="col">
                <p>ZVK-Nr</p>
                  <input required
                    type="text"
                    name="zvkNr"
                    onChange={this.handleChange}
                    value={this.state.zvkNr}
                  />
                </div>
                <div className="col">
                <p>Sozialversicherungs-Nr</p>
                  <input required
                    type="text"
                    name="socialNr"
                    onChange={this.handleChange}
                    value={this.state.socialNr}
                  />
                </div>
              </div>
              <hr />

              <div className="row">
                <div className="col-md-2">
                <p>Stundenlohn</p>
                  <input required
                    type="text"
                    name="hourlyWage"
                    onChange={this.handleChange}
                    value={this.state.hourlyWage}
                  />
                </div>
                <div className="col-md-2 inline">
                  <p>BG-Tarif</p>
                  <select  name="bgTarif" value = {this.state.bgTarif} onChange={this.handleChange}
                  >
                    <option value="-">-</option>
                    <option value="200">200</option>
                    <option value="800">800</option>
                    <option value="900">900</option>
                  </select>

                </div>
                <div className="col-md-2 inline">
                  <input
                    type="checkbox"
                    name="active"
                    id="active"
                    placeholder="Aktiv"
                    onChange={this.handleChangeCheck}
                    checked={this.state.active}
                  />
                  <label>Aktiv</label>
                </div>
                <div className="col-md-2 inline">
                  <p>Sonderberechnung</p>
                  <select  name="special" value = {this.state.special} onChange={this.handleChange}
                  >
                    <option value="nein">Nein</option>
                    <option value="ja">ja</option>
                  </select>

                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-2 inline">
                  <p>Hosengröße</p>
                  <select  name="pantsSize" value = {this.state.pantsSize} onChange={this.handleChange}
                  >
                    <option value="-">-</option>
                    <option value="40">40</option>
                    <option value="42">42</option>
                    <option value="44">44</option>
                    <option value="46">46</option>
                    <option value="48">48</option>
                    <option value="50">50</option>
                    <option value="52">52</option>
                    <option value="54">54</option>
                    <option value="56">56</option>
                    <option value="58">58</option>
                    <option value="60">60</option>
                  </select>
                </div>
                <div className="col-md-2 inline">
                  <p>Oberteilgröße</p>
                  <select  name="shirtSize" value = {this.state.shirtSize} onChange={this.handleChange}
                  >
                    <option value="-">-</option>
                    <option value="XS">XS</option>
                    <option value="S">S</option>
                    <option value="M">M</option>
                    <option value="L">L</option>
                    <option value="XL">XL</option>
                    <option value="2XL">2XL</option>
                    <option value="3XL">3XL</option>
                    <option value="4XL">4XL</option>
                    <option value="5XL">5XL</option>
                    <option value="6XL">6XL</option>
                    <option value="7XL">7XL</option>
                  </select>
                </div>
                <div className="col-md-2 inline">
                  <p>Schuhgröße</p>
                  <select  name="shoeSize" value = {this.state.shoeSize} onChange={this.handleChange}
                  >
                    <option value="-">-</option>
                    <option value="36">36</option>
                    <option value="37">37</option>
                    <option value="38">38</option>
                    <option value="39">39</option>
                    <option value="40">40</option>
                    <option value="41">41</option>
                    <option value="42">42</option>
                    <option value="43">43</option>
                    <option value="44">44</option>
                    <option value="45">45</option>
                    <option value="46">46</option>
                    <option value="47">47</option>
                    <option value="48">48</option>
                    <option value="49">49</option>
                  </select>
                </div>
                <div className="offset-3 col-md-3">
                  <button className="btn-create-item">
                    Mitarbeiter speichern <FontAwesomeIcon icon="save" />
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
          <p className={this.state.errorInput ? "show errorInput" : "hide"}>
            Bitte Bezeichnung ausfüllen
          </p>
        </section>
        <section id="showItems" className="display-item">
          <h4>Übersicht Mitarbeiter</h4>
          <div className="show-items-btn row">
            <div className="col-12 offset-6">
              <input
                className="search"
                type="text"
                name="search"
                placeholder="Suchen"
                onChange={this.handleSearch}
                onKeyPress={this.onKeyPress}
                value={this.state.search}
              />
            </div>
          </div>
          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          <ul className="items-list">
            {this.state.employeesAll.map(item => {
              return (
                <ModalEmployees
                  onChange={this.handleChange}
                  key={item.id}
                  id={item.id}
                  employeeNr={item.employeeNr}
                  lastName={item.lastName}
                  firstName={item.firstName}
                  birthday={item.birthday}
                  street={item.street}
                  zipCode={item.zipCode}
                  place={item.place}
                  taxNr={item.taxNr}
                  taxClass={item.taxClass}
                  religion={item.religion}
                  nationality={item.nationality}
                  zvkNr={item.zvkNr}
                  socialNr={item.socialNr}
                  iban={item.iban}
                  jobDesc={item.jobDesc}
                  categorie={item.categorie}
                  hourlyWage={item.hourlyWage}
                  employedSince={item.employedSince}
                  employedTill={item.employedTill}
                  healthInsurance={item.healthInsurance}
                  bgTarif={item.bgTarif}
                  active={item.active}
                  special={item.special}
                  search={this.state.search}
                  pantsSize={item.pantsSize}
                  shirtSize={item.shirtSize}
                  shoeSize={item.shoeSize}
                />
              );
            })}
          </ul>
        </section>
      </React.Fragment>
    );
  }
}

export default Employees;
