import React, { Component } from "react";

import "../css/bootstrap-grid.css";
import "../css/App.css";
import "../css/index.css";
import "../css/mediaQuerys.css";
import ConstructionDiaryListMain from "./constructionDiaryList/ConstructionDiaryListMain";
import ConstructionDiaryCreate from "./constructionDiaryCreate/ConstructionDiaryCreate";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "@fortawesome/fontawesome-free-solid";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const constructionDiary = () => {
  return (
    <React.Fragment>
      <section id="construction-diary" className="section-start">
      <div className="wrapper-btn-text row">
      <div className="col-md-2">
        <Link to="/home">
          <FontAwesomeIcon icon="arrow-left" /> Home
        </Link>
      </div>
      <div className="col-md-8">
        <h1>
        <FontAwesomeIcon color="var(--yellow)" icon="book-open" /> Bautagebuch
        </h1>
      </div>
    </div>
        <br />
        <div className="row">
          <div className="col">
            <Link className="btn-start-yellow" to="/home/bautagebuch/bautagebuch-liste">
              <FontAwesomeIcon icon="list" />
              <br />
              Bautagebuch-Liste
            </Link>
          </div>
          <div className="col">
            <Link className="btn-start-yellow" to="/home/bautagebuch/bautagebuch-erstellen/0">
              <FontAwesomeIcon icon="calculator" />
              <br />
              Bautagebuch erstellen
            </Link>
          </div>

        </div>
      </section>
    </React.Fragment>
  );
};


class ConstructionDiary extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/home/bautagebuch" component={constructionDiary} />
        <Route
          path="/home/bautagebuch/bautagebuch-liste"
          component={ConstructionDiaryListMain}
        />
        <Route
          path="/home/bautagebuch/bautagebuch-erstellen/:id"
          component={ConstructionDiaryCreate}
        />
 
      </React.Fragment>
    );
  }
}
export default ConstructionDiary;
