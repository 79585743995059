import React, { Component } from "react";
import "../css/bootstrap-grid.css";
import "../css/App.css";
import "../css/index.css";
import "../css/mediaQuerys.css";

import "@fortawesome/fontawesome-free-solid";

class DeliveryNotesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: this.props.idx,
      id: this.props.id,
      date: this.props.date,
      notesNr: this.props.notesNr,
      supplier: this.props.supplier,
      supplierId: this.props.supplierId,
      bv: this.props.bv,
      bvId: this.props.bvId,
      articles: this.props.articles,
      checkedAll: false
     };
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
   };
  handleChecked = () => {

     this.setState({checkedAll: !this.state.checkedAll});

   }
  convertDate = date => {
    if (date === undefined) {
      return date;
    } else {
      if (date.includes("-")) {
        var dateNew = date.split("-");
        dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
        return dateNew;
      } else {
        return date;
      }
    }
  };
  handleChangeToggle = idx => evt => {
    const notes = this.state.articles.map((item, sidx) => {
      if (idx !== sidx) return item;
      return { ...item, [evt.target.name]: evt.target.checked };
    });
    Promise.all([this.setState({ articles: notes })]).then(values => {
      const allInfos = {
        id: this.state.articles[idx].id,
        date: this.state.date,
        notesNr: this.state.notesNr,
        supplierId: this.state.supplierId,
        supplier: this.state.supplier,
        bv: this.state.bv,
        bvId: this.state.bvId,
        itemId: this.state.articles[idx].itemId,
        itemDsc: this.state.articles[idx].itemDsc,
        itemAmnt: this.state.articles[idx].itemAmnt,
        itemUnit: this.state.articles[idx].itemUnit
      };
      if (this.state.articles[idx].checked === true) {
        this.props.addRemoveToSaveDeliveryNotes(
          [allInfos],
          "add",
          this.state.articles[idx].id
        );
      }
      if (this.state.articles[idx].checked === false) {
        this.props.addRemoveToSaveDeliveryNotes(
          [allInfos],
          "remove",
          this.state.articles[idx].id
        );
      }
    });
  };
  handleChangeAll = e => {


      this.state.articles.map((item, idx) => {
       const allInfos = {
        id: item.id,
        date: this.state.date,
        notesNr: this.state.notesNr,
        supplierId: this.state.supplierId,
        supplier: this.state.supplier,
        bv: this.state.bv,
        bvId: this.state.bvId,
        itemId: item.itemId,
        itemDsc: item.itemDsc,
        itemAmnt: item.itemAmnt,
        itemUnit: item.itemUnit
      };
        if (this.state.checkedAll === true) {

         this.props.addRemoveToSaveDeliveryNotes(
          [allInfos],
          "add",
          this.state.articles
        );
      }
      if (this.state.checkedAll === false) {
         this.props.addRemoveToSaveDeliveryNotes(
          [allInfos],
          "remove",
          this.state.articles
        );
      }
      return this;
    })

  };

  render() {

    return (
       <React.Fragment>
        <div className="deliveryNoteList" key={this.state.id}>
          <li className={this.state.hideNotes ? "hide " : ""}>
            <div onClick={this.toggle}>
              <span className="row list-header show0">
              <p className="col-1 width-check">
                 <input
                  name="checkedAll"
                  type="checkbox"
                  checked={this.state.checkedAll ? this.handleChangeAll() : this.handleChangeAll() }
                  onChange={this.handleChecked}
                  />
              </p>
                <p className="col-2">
                  <span>Datum:</span>
                  <span id="font-normal">{this.convertDate(this.state.date)}</span>
                </p>
                <p className="col">
                  <span>LieferscheinNr:</span>
                  <span id="font-normal">{this.state.notesNr}</span>
                </p>
                <p className="col">
                  <span>Lieferant:</span>
                  <span id="font-normal">({this.state.supplierId}) {this.state.supplier}</span>
                </p>
                <p className="col-5">
                  <span>Bv:</span>
                  <span id="font-normal">({this.state.bvId}) {this.state.bv}</span>
                </p>
              </span>
            </div>

            {this.state.articles.map((i, idx) => {
              return (
                <div
                  className={
                    this.state.hideArticle ? "hide " : "workHours-list-checkbox"
                  }
                  key={idx}
                >
                  <span className={`row fullwidth hidetext show${idx}`}>
                    <p className="col-1 width-check">
                      <span>&nbsp;</span>
                      <input
                        name="checked"
                        type="checkbox"
                        onChange={this.handleChangeToggle(idx)}
                        checked={this.state.checkedAll ? 'true' : i.checked}
                      />
                    </p>
                    <p className="col-md-2 ">
                      <span>Artikelnummer</span>
                      <span id="font-normal">{i.itemId}</span>
                    </p>
                    <p onClick={this.toggle} className="col-md-7">
                      <span>Artikelbeschreibung:</span>

                      <span id="font-normal">{i.itemDsc}</span>
                    </p>
                    <p className="col-md-1">
                      <span>Menge:</span>
                      <span id="font-normal">{i.itemAmnt}</span>
                    </p>
                    <p className="col-md-1">
                      <span>Einheit:</span>
                      <span id="font-normal">{i.itemUnit}</span>
                    </p>
                  </span>
                </div>
              );
            })}
          </li>
        </div>

        <br clear="all" id="pagebreak" />
      </React.Fragment>
    );
  }
}
export default DeliveryNotesList;
