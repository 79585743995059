import React from "react";
import ReactDOM from "react-dom";
import "./css/bootstrap-grid.css";
import "./css/App.css";
import "./css/index.css";
import "./css/mediaQuerys.css";
import Login from "./services/login.js";
import "@fortawesome/fontawesome-free-solid";
import { BrowserRouter as Router } from "react-router-dom";
import { Route } from "react-router-dom";
import Home from "./home";

const home = () => {
  return <Home />;
};

const login = () => {
  return <Login />;
};
ReactDOM.render(
  <Router basename="/">
    <React.Fragment>
      <Route exact path="/" component={login} />
      <Route path="/home" component={home} />
    </React.Fragment>
  </Router>,
  document.getElementById("root")
);
