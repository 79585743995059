import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-solid";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
var $ = require("jquery");

class CustomerFormInputTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
    id: this.props.id,
    customerId: this.props.customerId,
    customerName: this.props.customerName,
    street: this.props.street,
    zipCode: this.props.zipCode,
    city: this.props.city,
    showItem: this.props.showItem,
     search: this.props.search,
    contactAll: [{ cpName: "", cpTel: "", cpMail: "",}],
    errorSearch: false,
    errorInput: false,
     btnAddItem: true,
    useSearch: false,
    searchRes: [],
    searchNewArray: [],
    searchArr: [],
    searchHelp: [],
    disabledFieldset: false
  }
}
componentDidMount = () => {
  this.loadLastId();    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
        this.setState({
          disabledFieldset: true
        });
      }
}
loadLastId = () => {
  return fetch(BaseUrl + "api/customer/readLastCustomerId.php")
    .then(response => response.json())
    .then(responseJson => {
      responseJson.records.map(item => {
        let newId = parseFloat(item.max) + 1;
        return this.setState({
          customerId: newId
          });
      });
    })
    .catch(error => {});
};
handleChange = e => {
  this.setState({
    [e.target.name]: e.target.value,
    errorInput: false
  });
};
handleSubmit = e => {
  e.preventDefault();
  let deliveryNote;

  this.state.contactAll.map(
    (items, idx) => (
      (deliveryNote = {
        customerId: this.state.customerId,
        customerName: this.state.customerName,
        street: this.state.street,
        cpName: items.cpName,
        cpTel: items.cpTel,
        cpMail: items.cpMail,
        zipCode: this.state.zipCode,
        city: this.state.city
      }),
      this.saveCustomer(deliveryNote)
     )
  );
  this.props.readLastCustomer();
 };

saveCustomer = deliveryNote => {
  this.setState({
    errorInput: false
  });

  $.ajax({
    url: BaseUrl + "api/customer/createCustomer.php",
    type: "POST",
    dataType: "json",
    data: JSON.stringify(deliveryNote),
    success: function(response) {
      this.setState({
        customerId: "",
        customerName: "",
        street: "",
        zipCode: "",
        city: "",
        contactAll: [
          { cpName: "", cpTel: "", cpMail: ""}
        ]
      });
    }.bind(this),
    error: function(xhr, resp, text) {}
  });
};

handleAddDeliveryItem = () => {
  this.setState({
    contactAll: this.state.contactAll.concat([
      { cpName: "", cpTel: "", cpMail: ""}
    ])
  });
};

handleRemoveCp = idx => () => {
  this.setState({
    contactAll: this.state.contactAll.filter((s, sidx) => idx !== sidx)
  });
};
handleChangeDeliverd = idx => evt => {
  const newCp = this.state.contactAll.map((cp, sidx) => {
    if (idx !== sidx) return cp
    return { ...cp, [evt.target.name]: evt.target.value };
  });
  this.setState({ contactAll: newCp });
};

render() {
  return (
    <React.Fragment>
      <section id="insertCustomer">
        <div className="wrapper-btn-text row">
          <div className="col-md-2">
            <Link to="/home">
              <FontAwesomeIcon icon="arrow-left" /> Home
            </Link>
          </div>
          <div className="col-md-8">
            <h1>
              <FontAwesomeIcon color="var(--blue)" icon="id-card" />{" "}
              Kundenstamm
            </h1>
          </div>
        </div>
        <h4>Kunde Hinzufügen</h4>
        <form onSubmit={this.handleSubmit} className="customer">
          <fieldset disabled={this.state.disabledFieldset}>

        <div className="row">
          <div className="col-3">
          <p>Kunden-Nr</p>
            <input
            required
              type="text"
              name="customerId"
              onChange={this.handleChange}
              value={this.state.customerId}
            />
          </div>
          <div className="col-4">
          <p>Kundenname</p>
            <input
            required
              type="text"
              name="customerName"
               onChange={this.handleChange}
              value={this.state.customerName}
            />
          </div>
          </div>
          <div className="row">
          <div className="col-3">
          <p>Strasse</p>
            <input
            required
              type="text"
              name="street"
              onChange={this.handleChange}
              value={this.state.street}
            />
          </div>
          <div className="col-2">
          <p>Plz</p>
            <input
            required
              type="text"
              name="zipCode"
              onChange={this.handleChange}
              value={this.state.zipCode}
            />
          </div>
          <div className="col-2">
          <p>Ort</p>
            <input
            required
              type="text"
              name="city"
              onChange={this.handleChange}
              value={this.state.city}

            />
          </div>
        </div>

        <hr />
        <h4>Ansprechpartner</h4>
        {this.state.contactAll.map((items, idx) => (
          <div key={idx} className="row">
            <div className="col-4">
            <p>{`Name #${idx + 1}`}</p>
              <input
                type="text"
                name="cpName"
                onChange={this.handleChangeDeliverd(idx)}
                value={items.cpName}
               />
            </div>
            <div className="col-3">
            <p>{`Telefonnummer #${idx + 1}`}</p>
              <input
                type="text"
                name="cpTel"
                onChange={this.handleChangeDeliverd(idx)}
                value={items.cpTel}
              />
            </div>
            <div className="col-4">
            <p>{`Email #${idx + 1}`}</p>
              <input
                type="text"
                name="cpMail"
                onChange={this.handleChangeDeliverd(idx)}
                value={items.cpMail}
              />
            </div>

            <div className="col-1">
              <button
                type="button"
                onClick={this.handleRemoveCp(idx)}
                className="btn-remove"
              >
                -
              </button>
            </div>
          </div>
        ))}
        <div className="row">
          <div className="col-11" />
          <div className="col-1">
            <button
              type="button"
              onClick={this.handleAddDeliveryItem}
              className="btn-add-item"
            >
              +
            </button>
          </div>
        </div>
        <div className="row">
        <div className="item-btn col-12">
          <button className="btn-add-item fullwidth">
            Kunde speichern <FontAwesomeIcon icon="save" />
          </button>
        </div>
        </div>
        </fieldset>
      </form>
    </section>
    </React.Fragment>
  );
}
}

export default CustomerFormInputTop;
