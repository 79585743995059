import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../config/config.js";

 var Highlight = require("react-highlighter");
 var $ = require("jquery");

class ListBVOpen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      id: this.props.id,
      bv: this.props.bv,
      bvId: this.props.bvId,
      done: this.props.done,
      customerId: this.props.customerId,
      customerName: this.props.customerName,
      street: this.props.street,
      streetNr: this.props.streetNr,
      city: this.props.city,
      zipCode: this.props.zipCode,
      constBeg: this.props.constBeg,
      constEnd: this.props.constEnd,
      orderNr: this.props.orderNr,
      hideItem: false,
      search: this.props.search,
      showUpdate: true,
      showDelete: false,
      done: this.props.done,
     };
  }
  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        modal: false
      });
    } else {
      this.setState({
        modal: !this.state.modal
      });
    }
  };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if (e.target.name === "customerName") {
      this.readCustomerIdByName(e.target.value,true);
    }
  };
  handleChangeCheck = e => {
    this.setState({
      done: !this.state.done
    });
  };
  convertDate = date => {
    if(date === "" || date === undefined){
      date = "";
    }
    if (date.includes("-")) {
      var dateNew = date.split("-");
      dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  convertDateToNorm = date => {
    var dateNew ="";
    if (date.includes(".")) {
      dateNew = date.split(".");
      dateNew = dateNew[2] + "-" + dateNew[1] + "-" + dateNew[0];
      return dateNew;
    } else {
      dateNew = date.split("-");
      dateNew = dateNew[0] + "-" + dateNew[1] + "-" + dateNew[2];
      return dateNew;
    }
  };
  updatebv = e => {
    let updatebv = {};
    if (this.state.done) {
      updatebv = {
        id: this.state.id,
        bv: this.state.bv,
        bvId: this.state.bvId,
        done: "x",
        customerId: this.state.customerId,
        street: this.state.street,
        streetNr: this.state.streetNr,
        zipCode: this.state.zipCode,
        city: this.state.city,
        constBeg: this.state.constBeg,
        constEnd: this.state.constEnd,
        orderNr: this.state.orderNr
      };
    } else {
      updatebv = {
        id: this.state.id,
        bv: this.state.bv,
        bvId: this.state.bvId,
        done: this.state.done,
        customerId: this.state.customerId,
        street: this.state.street,
        streetNr: this.state.streetNr,
        zipCode: this.state.zipCode,
        city: this.state.city,
        constBeg: this.state.constBeg,
        constEnd: this.state.constEnd,
        orderNr: this.state.orderNr
      };
    }

    // submit form data to api
    $.ajax({
      url: BaseUrl + "api/bv/updateBV.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(updatebv),
      success: function(response) {
        let updateOpen ={
        id: this.state.id,
        bv: this.state.bv,
        bvId: this.state.bvId,
        done: this.state.done,
        customerId: this.state.customerId,
        street: this.state.street,
        streetNr: this.state.streetNr,
        zipCode: this.state.zipCode,
        city: this.state.city,
        constBeg: this.state.constBeg,
        constEnd: this.state.constEnd,
        orderNr: this.state.orderNr
        }
        this.props.updateOpenBv(this.state.id,updateOpen);

        this.setState({
          modal: !this.state.modal
        });
       }.bind(this),
      error: function(xhr, resp, text) {
       }
    });

    e.preventDefault();
  };

  removebv = () => {
    let deletebv = {
      id: this.state.id
    };

    // submit form data to api
    $.ajax({
      url: BaseUrl + "api/bv/deleteBV.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deletebv),
      success: function(response) {
        this.setState({
          modal: !this.state.modal,
          hidebv: true
        });
      }.bind(this),
      error: function(xhr, resp, text) {
        // show error to console
      }
    });
  };
  readCustomerNameById = (id, bool) => {

     let found = this.props.customerAll.find(name => name.customerId === id);
     if (found === undefined) {

      return "";
    }
    if (bool === true) {
      return this.setState({
        customerName: found.customerName
      });
    } else {
      return found.customerName;
    }
  };
  readCustomerIdByName = (name, bool) => {
     let found = this.props.customerAll.find(id => id.customerName === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        customerId: found.customerId
      });
    } else {
      return found.CustomerId;
    }
  };
  autocompleteCustomer = e => {
    if (e.keyCode === 9 && e.target.name === "customerId") {
      this.readCustomerNameById(e.target.value, true);
    }
  };
  convertDate = date => {
    if (date === null || date === "0000-00-00" || date === undefined) {
      return date;
    }

    if (date.includes("-")) {
      var dateNew = date.split("-");
      dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  convertDateNormal = date => {
    if (date === null || date === "0000-00-00" || date === undefined) {
      return date;
    }

    if (date.includes(".")) {
      var dateNew = date.split(".");
      dateNew = dateNew[2] + "-" + dateNew[1] + "-" + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  render() {
    return (
      <React.Fragment>
      <li
        onClick={this.toggle}
        className={this.state.hideItem ? "hide" : ""}
        key={this.state.id}
      >
        <div className="showSpan">
          <div className="row fullwidth span list-header">
            <p className="col-md-1">
              <span>Bv-Nr:</span>
              <br />
              <Highlight id="font-normal"  search={this.props.search}>
                {this.state.bvId}
              </Highlight>
            </p>
            <p className="col-md-5">
              <span>Bv:</span>
              <br />
              <Highlight id="font-normal"  search={this.props.search}>
                {this.state.bv}
              </Highlight>
            </p>
            <p className="col-md-1">
              <span>Kunden-Id:</span>
              <br />
              <Highlight id="font-normal"  search={this.props.search}>
                {this.state.customerId}
              </Highlight>
            </p>
            <p className="col-md-4">
              <span>Kunde:</span>
              <br />
              <Highlight id="font-normal"  search={this.props.search}>
                {this.state.customerName}
              </Highlight>
            </p>
            <p className="col-md-1">
                <span>Erledigt</span>
                <br />
                {this.state.done ? "✔" : ""}
              </p>
          </div>
          <div className="row">
            <p className="col-md-4">
              <span>Anschrift:</span>
              <br />
              {this.state.street} {this.state.streetNr}, {this.state.zipCode} {this.state.city}
            </p>
            <p className="col-md-3">
              <span>Baubeginn:</span>
              <br />
              {this.convertDate(this.state.constBeg)}
            </p>
            <p className="col-md-3">
              <span>Bauende:</span>
              <br />
              {this.convertDate(this.state.constEnd)}
            </p>
            <p className="col-md-2">
              <span>Auftrags-Nr:</span>
              <br />
              {this.convertDate(this.state.orderNr)}
            </p>
          </div>
        </div>
      </li>
      <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal-header">
            <p className={this.state.showUpdate ? "show" : "hide"}>
              Bauvorhaben Bearbeiten
            </p>
            <p className={this.state.showDelete ? "show" : "hide"}>
              Bauvorhaben wirklich Löschen?<br />
              <span>Dieser Vorgang kann nicht Rückgänging gemacht werden</span>
            </p>
            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <form>
                <div className="row">
                  <div className="col-md-2">
                    <p>Bv-Nr</p>
                    <input
                      type="text"
                      name="bvId"
                      placeholder="BV-Nr"
                      onChange={this.handleChange}
                      value={this.state.bvId}
                    />
                  </div>
                  <div className="col-md-5">
                    <p>Bv</p>
                    <input
                      type="text"
                      name="bv"
                      placeholder="Bauvorhaben"
                      onChange={this.handleChange}
                      value={this.state.bv}
                    />
                  </div>
                  <div className="col-2 tab">
                    <p>Kunden-Id</p>
                    <input
                      required
                      type="text"
                      name="customerId"
                      placeholder="-"
                      onChange={this.handleChange}
                      value={this.state.customerId}
                      onKeyDown={this.autocompleteCustomer}
                    />
                    <span>↹</span>
                  </div>
                  <div className="col-md-3">
                    <p>Kunde</p>
                    <select
                      name="customerName"
                      value={this.state.customerName}
                      onChange={this.handleChange}
                    >
                      <option value="-">-</option>
                      {this.props.customerAll.map((customer, i) => (
                        <option key={i} value={customer.customerName}>
                          {customer.customerName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <p>Straße</p>
                    <input
                      type="text"
                      name="street"
                      placeholder="Straße"
                      onChange={this.handleChange}
                      value={this.state.street}
                    />
                  </div>
                  <div className="col-md-1">
                    <p>Hausnummer</p>
                    <input
                      type="text"
                      name="streetNr"
                      placeholder="Hausnummer"
                      onChange={this.handleChange}
                      value={this.state.streetNr}
                    />
                  </div>
                  <div className="col-md-3">
                    <p>Postleitzahl</p>
                    <input
                      type="text"
                      name="zipCode"
                      placeholder="PlZ"
                      onChange={this.handleChange}
                      value={this.state.zipCode}
                    />
                  </div>
                  <div className="col-md-3">
                    <p>Ort</p>
                    <input
                      type="text"
                      name="city"
                      placeholder="Ort"
                      onChange={this.handleChange}
                      value={this.state.city}
                    />
                  </div>
                  <div className="col-md-3">
                    <p>Auftrags-Nr</p>
                    <input
                      type="text"
                      name="orderNr"
                      placeholder="Auftrags-Nr"
                      onChange={this.handleChange}
                      value={this.state.orderNr}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <p>Baubeginn</p>
                    <input
                      type="date"
                      name="constBeg"
                      placeholder="Baubeginn"
                      onChange={this.handleChange}
                      value={this.convertDateNormal(this.state.constBeg)}
                    />
                  </div>
                  <div className="col-md-3">
                    <p>Bauende</p>
                    <input
                      type="date"
                      name="constEnd"
                      placeholder="Bauende"
                      onChange={this.handleChange}
                      value={this.convertDateNormal(this.state.constEnd)}
                    />
                  </div>
                  <div className="col-md-2 inline">
                    <input
                      type="checkbox"
                      name="done"
                      id="done"
                      placeholder="Fertig"
                      onChange={this.handleChangeCheck}
                      checked={this.state.done}
                    />
                    <label htmlFor="done">Erledigt</label>
                  </div>
                </div>
              </form>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Löschen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.updatebv} className="btn-green">
                    Ändern <FontAwesomeIcon icon={["far", "edit"]} />
                  </button>
                </div>
              </div>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Nein <FontAwesomeIcon icon="ban" />
                  </button>
                </div>

                <div className="col">
                  <button onClick={this.removebv} className="btn-green">
                    Ja <FontAwesomeIcon icon="check-circle" />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ListBVOpen;
