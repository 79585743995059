import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var $ = require("jquery");

class SupplierFormInputTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
    id: this.props.id,
    supplierId: this.props.supplierId,
    supplierName: this.props.supplierName,
    street: this.props.street,
    zipCode: this.props.zipCode,
    city: this.props.city,
    showItem: this.props.showItem,
     search: this.props.search,
    contactAll: [{ supName: "", supTel: "", supMail: "",}],
    errorSearch: false,
    errorInput: false,
     btnAddItem: true,
    useSearch: false,
    searchRes: [],
    searchNewArray: [],
    searchArr: [],
    searchHelp: [],
    disabledFieldset: false
  }
}
componentDidMount = () => {
  this.loadLastId();
  if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
    this.setState({
      disabledFieldset: true
    });
  }
}
loadLastId = () => {
  return fetch(BaseUrl + "api/supplier/readLastSupplierId.php")
    .then(response => response.json())
    .then(responseJson => {
      responseJson.records.map(item => {
        let newId = parseFloat(item.max) + 1;
        return this.setState({
          supplierId: newId
          });
      });
    })
    .catch(error => {});
};
handleChange = e => {
  this.setState({
    [e.target.name]: e.target.value,
    errorInput: false
  });
};
handleSubmit = e => {
  e.preventDefault();
  let deliveryNote;

  this.state.contactAll.map(
    (items, idx) => (
      (deliveryNote = {
        supplierId: this.state.supplierId,
        supplierName: this.state.supplierName,
        street: this.state.street,
        supName: items.supName,
        supTel: items.supTel,
        supMail: items.supMail,
        zipCode: this.state.zipCode,
        city: this.state.city
      }),
      this.savesupplier(deliveryNote)
     )
  );
  this.props.readLastSupplier();
 };

savesupplier = supplier => {
  this.setState({
    errorInput: false
  });

  $.ajax({
    url: BaseUrl + "api/supplier/createSupplier.php",
    type: "POST",
    dataType: "json",
    data: JSON.stringify(supplier),
    success: function(response) {
      this.setState({
        supplierId: "",
        supplierName: "",
        street: "",
        zipCode: "",
        city: "",
        contactAll: [
          { supName: "", supTel: "", supMail: ""}
        ]
      });
    }.bind(this),
    error: function(xhr, resp, text) {}
  });
};

handleAddDeliveryItem = () => {
  this.setState({
    contactAll: this.state.contactAll.concat([
      { supName: "", supTel: "", supMail: ""}
    ])
  });
};

handleRemovesup = idx => () => {
  this.setState({
    contactAll: this.state.contactAll.filter((s, sidx) => idx !== sidx)
  });
};
handleChangeDeliverd = idx => evt => {
  const newsup = this.state.contactAll.map((sup, sidx) => {
    if (idx !== sidx) return sup
    return { ...sup, [evt.target.name]: evt.target.value };
  });
  this.setState({ contactAll: newsup });
};

render() {
  return (
    <React.Fragment>
      <section id="insertSupplier">
        <div className="wrapper-btn-text row">
          <div className="col-md-2">
            <Link to="/home">
              <FontAwesomeIcon icon="arrow-left" /> Home
            </Link>
          </div>
          <div className="col-md-8">
            <h1>
              <FontAwesomeIcon color="var(--blue)" icon="id-card" />{" "}
              Lieferantenstamm
            </h1>
          </div>
        </div>
        <h4>Lieferant Hinzufügen</h4>
        <form onSubmit={this.handleSubmit} className="supplier">
          <fieldset disabled={this.state.disabledFieldset}>
            <div className="row">
              <div className="col-3">
              <p>Lieferanten-Nr</p>
                <input
                  required
                  type="text"
                  name="supplierId"
                  onChange={this.handleChange}
                  value={this.state.supplierId}
                />
              </div>
              <div className="col-4">
              <p>Lieferantenname</p>
                <input
                  required
                  type="text"
                  name="supplierName"
                  onChange={this.handleChange}
                  value={this.state.supplierName}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-3">
              <p>Strasse</p>
                <input
                  required
                  type="text"
                  name="street"
                  onChange={this.handleChange}
                  value={this.state.street}
                />
              </div>
              <div className="col-2">
              <p>Plz</p>
                <input
                  required
                  type="text"
                  name="zipCode"
                  onChange={this.handleChange}
                  value={this.state.zipCode}
                />
              </div>
              <div className="col-2">
              <p>Ort</p>
                <input
                  required
                  type="text"
                  name="city"
                  onChange={this.handleChange}
                  value={this.state.city}

                />
              </div>
            </div>

            <hr />
            <h4>Ansprechpartner</h4>
            {this.state.contactAll.map((items, idx) => (
              <div key={idx} className="row">
                <div className="col-4">
                <p>{`Name #${idx + 1}`}</p>
                  <input
                    type="text"
                    name="supName"
                    onChange={this.handleChangeDeliverd(idx)}
                    value={items.supName}
                  />
                </div>
                <div className="col-3">
                <p>{`Telefonnummer #${idx + 1}`}</p>
                  <input
                    type="text"
                    name="supTel"
                    onChange={this.handleChangeDeliverd(idx)}
                    value={items.supTel}
                  />
                </div>
                <div className="col-4">
                <p>{`Email #${idx + 1}`}</p>
                  <input
                    type="text"
                    name="supMail"
                    onChange={this.handleChangeDeliverd(idx)}
                    value={items.supMail}
                  />
                </div>

                <div className="col-1">
                  <button
                    type="button"
                    onClick={this.handleRemovesup(idx)}
                    className="btn-remove"
                  >
                    -
                  </button>
                </div>
              </div>
            ))}
            <div className="row">
              <div className="col-11" />
              <div className="col-1">
                <button
                  type="button"
                  onClick={this.handleAddDeliveryItem}
                  className="btn-add-blue"
                >
                  +
                </button>
              </div>
            </div>
            <div className="row">
              <div className="item-btn col-12">
                <button className="btn-add-blue fullwidth">
                  Lieferant speichern <FontAwesomeIcon icon="save" />
                </button>
              </div>
            </div>
          </fieldset>
      </form>
    </section>
    </React.Fragment>
  );
}
}

export default SupplierFormInputTop;
