import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../config/config.js";
var $ = require("jquery");
var Highlight = require("react-highlighter");

class ModalOpenSelectNote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalSelectNote: this.props.modalSelectNote,
      selectNotes: this.props.selectNotes,
    };
  }
  componentDidMount = () => {};
  ;

  loadSelectedNote = (notesNr, supplierId) => {
    this.props.loadInputSelectNotes(notesNr, supplierId);
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          key={this.props.id}
          isOpen={this.props.modalSelectNote}
          toggle={this.toggle}
        >
          <div className="modal-header">
            <p>
              Lieferschein auswählen
              <br />
            </p>

            <button className="close" onClick={this.props.toggleSelectNote}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody className="selectItem">
          <div className="row title">
                  <div className="col-md-2"><span>Datum</span> </div>
                  <div className="col-md-2"><span>Lieferscheinnummer</span></div>
                  <div className="col-md-2">
                  <span>Lieferant</span>
                  </div>
                  <div className="col-md-3">
                  <span>Bauvorhaben</span>
                  </div>
                  <div className="col-md-2">
 
                  </div>
                </div>
            {this.state.selectNotes.map((item, idx) => {
              return (
                <div key={idx} className="row align-flex-center">
                  <div className="col-md-2">{item.date}</div>
                  <div className="col-md-2">{item.notesNr}</div>
                  <div className="col-md-2">
                    {item.supplierId} - {item.supplier}
                  </div>
                  <div className="col-md-3">
                    {item.bvId} - {item.bv}
                  </div>
                  <div className="col-md-2">
                    <button
                      onClick={() =>
                        this.loadSelectedNote(item.notesNr, item.supplierId)
                      }
                    >
                      Auswählen <FontAwesomeIcon  icon={["far", "check-square"]} />
                    </button>
                  </div>
                </div>
              );
            })}

          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalOpenSelectNote;
