import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../config/config.js";
var $ = require("jquery");

class ModalSavedCalcHourlyWage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      id: this.props.id,
      lastName: this.props.lastName,
      firstName: this.props.firstName,
      date: this.props.date,
      sk_h: this.props.sk_h,
      angebotspreis: this.props.angebotspreis,
      showUpdate: true,
      showDelete: false,
      hideItem: false
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    if(sessionStorage.getItem("userData").indexOf("admin") === -1){
       this.setState({
       modal:false
     })

   }
   else{
     this.setState({
       modal: !this.state.modal
     });
   }
  };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  removeItem = () => {
     let deleteItem = {
      id: this.state.id
    };

    $.ajax({
      url: BaseUrl + "api/calcHourlyWage/deleteSavedCalcHourlyWages.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function(response) {
        this.setState({
          modal: !this.state.modal,
          hideItem: true
        });
      }.bind(this),
      error: function(xhr, resp, text) {

      }
    });
  };
  render() {
     return (
      <React.Fragment>
        <li
          onClick={this.toggle}
          className={this.state.hideItem ? "hide " : " "}
          key={this.state.id}
        >
        <div className="row fullwidth showSpan">
        <p className="col-md-2"><span>ID</span><br/>{this.state.id}</p>
          <p className="col-md-2"><span>Nachname</span><br/>{this.state.lastName}</p>
          <p className="col-md-2"><span>Vorname</span><br/>{this.state.firstName}</p>
          <p className="col-md-2"><span>Datum</span><br/>{this.state.date}</p>
          <p className="col-md-2"><span>Selbstkosten/h</span><br/>{this.state.sk_h}</p>
          <p className="col-md-2"><span>Angebotspreis</span><br/>{this.state.angebotspreis}</p>

          </div>
        </li>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal-header">
            <p className={this.state.showUpdate ? "show" : "hide"}>
              Gespeicherte Mitarbeiterkosten Löschen
            </p>
            <p className={this.state.showDelete ? "show" : "hide"}>
              Gespeicherte Mitarbeiterkosten wirklich Löschen?<br />
              <span>Dieser Vorgang kann nicht Rückgänging gemacht werden</span>
            </p>
            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div className={this.state.showUpdate ? "show" : "hide"}>

              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Löschen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
              </div>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Nein <FontAwesomeIcon icon="ban" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.removeItem} className="btn-green">
                    Ja <FontAwesomeIcon icon="check-circle" />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalSavedCalcHourlyWage;
