import React, { Component } from "react";
import "./css/bootstrap-grid.css";
import "./css/App.css";
import "./css/mediaQuerys.css";
import Notes from "./deliveryNote/Notes";
import Bills from "./bills/Bills";
import Item from "./items/Item";
import Products from "./products/Products";
import BuildingProjects from "./buildingProjects/BuildingProjects";
import Employees from "./employees/Employees";
import CalcMain from "./calcHourlyWage/CalcMain";
import Customer from "./customer/Customer";
import Supplier from "./supplier/Supplier";
import Billbook from "./billbook/Billbook";
import HoursReportMain from "./hoursreporting/HoursReportMain";
import Evaluation from "./evaluation/Evaluation";
import DirectionWorkMain from "./directionWork/DirectionWorkMain";
import OfferMain from "./offer/OfferMain";
import TermOfPayment from "./termOfPayment/TermOfPayment";
import SqlQuerys from "./sqlQuerys/SqlQuerys";
import WorkerQuerys from "./workerQuerys/WorkerQuerys";
import SpecificationList from "./specificationList/SpecificationList";
import DeliveryNotesBills from "./deliveryNotesBills/DeliveryNotesBills";
import ConstructionDiary from "./constructionDiary/ConstructionDiary";
 import "@fortawesome/fontawesome-free-solid";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GenerateQrCode from "./qrCodeEmployee/GenerateQrCode";
const home = () => {
  return (
    <React.Fragment>
      <section className="section-start">
        <img src={require("./img/logo.png")} alt="" />
        <h1>Kosten- und Leistungsrechnung</h1>
        <br />
        <div className="row">
          <div className="col-4">
            <Link className="btn-start" to="/home/lieferscheine">
              <FontAwesomeIcon icon="inbox" />
              <br />
              Lieferscheine
            </Link>
          </div>
          <div className="col-4">
            <Link className="btn-start" to="/home/rechnungen">
              <FontAwesomeIcon icon="money-bill-alt" />
              <br />
              Rechnungen
            </Link>
          </div>
          <div className="col-4">
            <Link className="btn-start" to="/home/artikel">
              <FontAwesomeIcon icon="list-ol" />
              <br />
              Artikelstamm
            </Link>
          </div>

          <div className="col-4">
            <Link className="btn-start" to="/home/stundenbericht">
              <FontAwesomeIcon icon="stopwatch" />
              <br />
              Stundenberichte
            </Link>
          </div>
          <div className="col-4">
            <Link className="btn-start" to="/home/rechnungsbuch">
              <FontAwesomeIcon icon="book" />
              <br />
              Rechnungsbuch
            </Link>
          </div>
          <div className="col-4">
            <Link className="btn-start" to="/home/bauvorhaben">
              <FontAwesomeIcon icon="industry" />
              <br />
              Bauvorhaben
            </Link>
          </div>
          <div className="col-4">
            <Link className="btn-start" to="/home/produktstamm">
              <FontAwesomeIcon icon="industry" />
              <br />
              Produktstamm
            </Link>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-4">
            <Link className="btn-start-blue" to="/home/kunde">
              <FontAwesomeIcon icon="id-card" />
              <br />
              Kundenstamm
            </Link>
          </div>
          <div className="col-4">
            <Link className="btn-start-blue" to="/home/lieferant">
              <FontAwesomeIcon icon="id-card" />
              <br />
              Lieferantenstamm
            </Link>
          </div>

          <div className="col-4">
            <Link className="btn-start-blue" to="/home/auswertung">
              <FontAwesomeIcon icon="chart-bar" />
              <br />
              Auswertung pro BV
            </Link>
          </div>

          <div className="col-4">
            <Link className="btn-start-blue" to="/home/mitarbeiter">
              <FontAwesomeIcon icon="users" />
              <br />
              Mitarbeiter
            </Link>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-4">
            <Link className="btn-start-yellow" to="/home/regiearbeit">
              <FontAwesomeIcon icon="pencil-alt" />
              <br />
              Regiearbeiten
            </Link>
          </div>
          <div className="col-4">
            <Link className="btn-start-yellow" to="/home/angebot">
              <FontAwesomeIcon icon="calculator" />
              <br />
              Angebot
            </Link>
          </div>
          <div className="col-4">
            <Link className="btn-start-yellow" to="/home/zahlungsziele">
              <FontAwesomeIcon icon="percent" />
              <br />
              Zahlungsziele
            </Link>
          </div>
          <div className="col-4">
            <Link className="btn-start-yellow" to="/home/leistungsverzeichnis">
              <FontAwesomeIcon icon="list-alt" />
              <br />
              Leistungsverzeichnis
            </Link>
          </div>
          <div className="col-4">
            <Link className="btn-start-yellow" to="/home/offene-lieferscheine">
              <FontAwesomeIcon icon="truck-loading" />
              <br />
              offene Lieferscheine
            </Link>
          </div>
          <div className="col-4">
            <Link className="btn-start-yellow" to="/home/bautagebuch">
              <FontAwesomeIcon icon="book-open" />
              <br />
              Bautagebuch
            </Link>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-4">
            <Link className="btn-start-red" to="/home/stundenkalkulation">
              <FontAwesomeIcon icon="clock" />
              <br />
              Stundenkalkulation
            </Link>
          </div>
          <div className="col-4">
            <Link className="btn-start-red" to="/home/datenbank-abfragen">
              <FontAwesomeIcon icon="database" />
              <br />
              Datenbank Abfragen
            </Link>
          </div>
          <div className="col-4">
            <Link className="btn-start-red" to="/home/mitarbeiter-abfragen">
              <FontAwesomeIcon icon="users" />
              <br />
              Mitarbeiter Abfragen
            </Link>
          </div>
                    <div className="col-4">
            <Link className="btn-start-red" to="/home/mitarbeiter-qr-code">
              <FontAwesomeIcon icon="qrcode" />
              <br />
              Mitarbeiter QR-Code
            </Link>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
const billbook = () => {
  return <Billbook />;
};
const bills = () => {
  return <Bills />;
};
const notes = () => {
  return <Notes />;
};
const item = () => {
  return <Item />;
};
const products = () => {
  return <Products />;
};
const buildingProjects = () => {
  return <BuildingProjects />;
};
const employees = () => {
  return <Employees />;
};
const calcMain = () => {
  return <CalcMain />;
};
const customer = () => {
  return <Customer />;
};
const supplier = () => {
  return <Supplier />;
};
const hoursReportMain = () => {
  return <HoursReportMain />;
};
const evaluation = () => {
  return <Evaluation />;
};
const directionWorkMain = () => {
  return <DirectionWorkMain />;
};
const offer = () => {
  return <OfferMain />;
};
const termOfPayment = () => {
  return <TermOfPayment />;
};
const sqlquerys = () => {
  return <SqlQuerys />;
};
const workerquerys = () => {
  return <WorkerQuerys />;
};
const specificationList = () => {
  return <SpecificationList />;
};
const deliveryNotesBills = () => {
  return <DeliveryNotesBills />;
};
const constructionDiary = () => {
  return <ConstructionDiary />;
};
const generateQrCode = () => {
  return <GenerateQrCode />;
};
class Home extends Component {
  render() {
    if (!sessionStorage.getItem("userData")) {
      return <Redirect to={"/"} />;
    }
    return (
      <React.Fragment>
        <Route exact path="/home" component={home} />
        <Route exact path="/home/rechnungen" component={bills} />
        <Route exact path="/home/lieferscheine" component={notes} />
        <Route exact path="/home/artikel" component={item} />
        <Route exact path="/home/produktstamm" component={products} />
        <Route exact path="/home/bauvorhaben" component={buildingProjects} />
        <Route exact path="/home/mitarbeiter" component={employees} />
        <Route exact path="/home/kunde" component={customer} />
        <Route exact path="/home/lieferant" component={supplier} />
        <Route path="/home/stundenkalkulation" component={calcMain} />
        <Route exact path="/home/rechnungsbuch" component={billbook} />
        <Route path="/home/stundenbericht" component={hoursReportMain} />
        <Route exact path="/home/auswertung" component={evaluation} />
        <Route path="/home/regiearbeit" component={directionWorkMain} />
        <Route path="/home/angebot" component={offer} />
        <Route path="/home/zahlungsziele" component={termOfPayment} />
        <Route path="/home/leistungsverzeichnis" component={specificationList} />
        <Route path="/home/offene-lieferscheine" component={deliveryNotesBills} />
        <Route path="/home/bautagebuch" component={constructionDiary} />
        <Route path="/home/datenbank-abfragen" component={sqlquerys} />
        <Route path="/home/mitarbeiter-abfragen" component={workerquerys} />
        <Route path="/home/mitarbeiter-qr-code" component={generateQrCode} />
      </React.Fragment>
    );
  }
}

export default Home;
