import React, { Component } from "react";

import "../css/bootstrap-grid.css";
import "../css/App.css";
import "../css/index.css";
import "../css/mediaQuerys.css";
import OfferBaseData from "./offerBaseData/OfferBaseData";
import OfferCreate from "./offerCreate/OfferCreate";
import Offerbook from "./offerbook/Offerbook";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "@fortawesome/fontawesome-free-solid";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const offerMain = () => {
  return (
    <React.Fragment>
      <section id="offerCreate" className="section-start">
        <div className=" wrapper-btn-text row">
          <div className="col-md-2">
            <Link to="/home">
              <FontAwesomeIcon icon="arrow-left" /> Home
            </Link>
          </div>
          <div className="col-md-8">
            <h1>
              <FontAwesomeIcon color="var(--yellow)" icon="calculator" />{" "}
              Angebot
            </h1>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col">
            <Link className="btn-start-yellow" to="/home/angebot/angebot-stammdaten">
              <FontAwesomeIcon icon="database" />
              <br />
              Angebot Stammdaten
            </Link>
          </div>
          <div className="col">
            <Link className="btn-start-yellow" to="/home/angebot/angebotsliste">
              <FontAwesomeIcon icon="list" />
              <br />
              Angebotsliste
            </Link>
          </div>
          <div className="col">
            <Link className="btn-start-yellow" to="/home/angebot/angebot-erstellen/0/0">
              <FontAwesomeIcon icon="calculator" />
              <br />
              Angebot erstellen
            </Link>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};


class OfferMain extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/home/angebot" component={offerMain} />
        <Route
          path="/home/angebot/angebot-stammdaten"
          component={OfferBaseData}
        />
        <Route
          path="/home/angebot/angebotsliste"
          component={Offerbook}
        />
        <Route
           path="/home/angebot/angebot-erstellen/:offerNr/:version"
        component={OfferCreate}
         />
      </React.Fragment>
    );
  }
}
export default OfferMain;
