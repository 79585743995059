import React, { useState, useEffect } from "react";
import "@fortawesome/fontawesome-free-solid";
import BaseUrl from "../../config/config.js";
import CustomFontRegular from "../../fonts/Lunchtype22-Regular.ttf";
import CustomFontBold from "../../fonts/Lunchtype22-Medium.ttf";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Link,
  Line,
  Svg,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "CustomFont", // Font family name
  fonts: [
    { src: CustomFontRegular, fontWeight: "normal" },
    { src: CustomFontBold, fontWeight: "bold" },
  ],
});

function PrintReport(props) {
  const [state, setState] = useState({
    reportNr: props.reportNr,
    customerName: props.customerName,
    bv: props.bv,
    workingHoursPrint: props.workingHoursPrint,
    hoursComplete: props.hoursComplete,
    hoursVorarbeiter: props.hoursVorarbeiter,
    hoursFacharbeiter: props.hoursFacharbeiter,
    hoursHilfsarbeiter: props.hoursHilfsarbeiter,
    serviceOverview: props.serviceOverview,
    notesBillSplit: props.notesBillSplit,
    deliveryNotes: props.deliveryNotes,
    bills: props.bills,
    supplier: props.supplier,
    notesNr: props.notesNr,
    readSupplierNameById: props.readSupplierNameById,
    printPrice: props.printPrice,
    equipment: props.equipment,
  });
  const [priceNoteComplete, setPriceNoteComplete] = useState(0);
  const [pageCurrentDelivery, setPageCurrentDelivery] = useState(0);
  let savedPageNr = 2;
  let savedPageNrBills = 2;
  let showMenu = false;
  let showMenuBills = false;

  useEffect(() => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      setState((prevState) => ({
        ...prevState,
        disabledFieldset: true,
      }));
    }
  }, []);
  const calcTime = (start, end, zero, pause, fahrt) => {
    if (pause === "") {
      pause = "00:00";
    }
    if (fahrt === "") {
      fahrt = "00:00";
    }
    if (pause && fahrt) {
      var start = start.split(":");
      var end = end.split(":");
      var zero = zero.split(":");
      var pause = pause.split(":");
      var fahrt = fahrt.split(":");

      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      var zeroDate = new Date(0, 0, 0, zero[0], zero[1], 0);
      var pauseDate = new Date(0, 0, 0, pause[0], pause[1], 0);
      var fahrtDate = new Date(0, 0, 0, fahrt[0], fahrt[1], 0);

      var diff =
        endDate.getTime() -
        startDate.getTime() -
        (pauseDate.getTime() - zeroDate.getTime()) -
        (fahrtDate.getTime() - zeroDate.getTime());
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);
      const result =
        (hours < 10 ? "0" : "") +
        hours +
        ":" +
        (minutes < 10 ? "0" : "") +
        minutes;

      return result;
    }
  };
  const convertDate = (date) => {
    if (date === null || date === "0000-00-00" || date === undefined) {
      return date;
    }

    if (date.includes("-")) {
      var dateNew = date.split("-");
      dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  const itemPriceComplete = (amnt, pricePiece) => {
    let complete = amnt * pricePiece;
    return complete === 0
      ? 0.0
      : complete.toLocaleString("de", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
  };
  const completePricePerNote = (articles) => {
    let calcComplete = 0;
    articles.map((item) => {
      calcComplete += item.itemAmnt * item.pricePiece;
    });

    const formattedValue = calcComplete.toLocaleString("de", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedValue;
  };
  const priceNotesComplete = () => {
    let help = 0;
    state.deliveryNotes.map((item, id) => {
      item.articles.map((i, id) => {
        help += i.itemAmnt * i.pricePiece;
      });
    });
    return help.toLocaleString("de", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const priceBillsComplete = () => {
    let help = 0;
    state.bills.map((item, id) => {
      item.articles.map((i, id) => {
        help += i.itemAmnt * i.pricePiece;
      });
    });
    return help.toLocaleString("de", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const handlePageChange = (pageNumber, id, split, what) => {
    if (what === "notes") {
      if (pageNumber !== savedPageNr || (id === 0 && split)) {
        savedPageNr = pageNumber;
        showMenu = true;
        return true;
      } else {
        showMenu = false;
        return false;
      }
    } else {
      if (pageNumber !== savedPageNrBills || (id === 0 && split) || id === 0) {
        savedPageNrBills = pageNumber;
        showMenuBills = true;
        return true;
      } else {
        showMenuBills = false;
        return false;
      }
    }
  };

  const NotesHeadline = (id, split, what) => {
    return (
      <View style={[stylesList.view, stylesList.pt9, styles.bold]}>
        <Text
          style={state.printPrice ? notes.dsc : notes.dscLong}
          render={({ pageNumber }) =>
            handlePageChange(pageNumber, id, split, what) &&
            `Artikelbeschreibung`
          }
        />
        <Text
          style={notes.amnt}
          render={({ pageNumber }) =>
            (what === "notes" ? showMenu : showMenuBills) && `Menge`
          }
        />
        <Text
          style={notes.unit}
          render={({ pageNumber }) =>
            (what === "notes" ? showMenu : showMenuBills) && `Einheit`
          }
        />
        {state.printPrice ? (
          <View style={[stylesList.viewInside]}>
            <Text
              style={notes.preisStk}
              render={({ pageNumber }) =>
                (what === "notes" ? showMenu : showMenuBills) && `Preis/Stk`
              }
            />
            <Text
              style={notes.preisGesamt}
              render={({ pageNumber }) =>
                (what === "notes" ? showMenu : showMenuBills) && `Gesamt`
              }
            />
          </View>
        ) : (
          ""
        )}
        <View
          render={({ pageNumber }) =>
            (what === "notes" ? showMenu : showMenuBills) && (
              <View style={{ height: "5px" }}>
                <Svg height="1" width="500">
                  <Line
                    x1="0"
                    y1="0"
                    x2="1000"
                    y2="00"
                    strokeWidth={2}
                    stroke="rgb(255,0,0)"
                  />
                </Svg>
              </View>
            )
          }
        />
      </View>
    );
  };

  const styles = StyleSheet.create({
 
    max65: {
      maxWidth: "60px"
    },
 
    width60: {
      width: "60%",
    },
    width70: {
      width: "70%",
    },
    width30: {
      width: "30%",
    },
 
    width10: {
      width: "10%",
    },
    width40: {
      width: "40%",
    },
    width45: {
      width: "45%",
    },
    width50: {
      width: "50%",
    },
    width50Underline: {
      width: "calc(50% - 100px)",
      marginRight: "30px",
    },
    marginTop30: {
      marginTop: 30,
    },
    page: {
      flexWrap: "wrap",
      fontSize: "11pt",
      flexDirection: "row",
      paddingTop: 35,
      paddingBottom: "2cm",
      paddingLeft: "1.25cm",
      paddingRight: "1cm",
      lineHeight: "1.5pt",
      fontFamily: "CustomFont",
      fontWeight: "normal",
      width: "18cm",
    },
    logo: {
      width: 165,
    },
    alignTop: {
      display: "flex",
      alignItems: "flex-start",
      alignSelf: "flex-start",
      alignContent: "flex-start",
    },
    h1: {
      fontSize: "24px",
      fontWeight: "bold",
      marginTop: 15,
    },
    h2: {
      fontSize: "13px",
      fontWeight: "bold",
      textTransform: "uppercase",
      marginTop: 40,
      marginBottom: 20,
    },
    h3: {
      fontSize: "11px",
      fontWeight: "bold",
      marginTop: 10,
      marginBottom: 5,
    },
    borderBottom: {
      borderBottom: "1px solid black",
      maxWidth: "7cm",
    },
    marginB5: {
      marginBottom: 5,
    },
    marginT5: {
      marginTop: 5,
    },
    bold: {
      fontWeight: "bold",
    },
 
    posBottom: {
      position: "absolute",
      bottom: "0",
      right: 30,
      fontSize: "8px",
      width: "2cm",
      textAlign: "right",
      height: "1.4cm",
    },
 
  });
  const stylesList = StyleSheet.create({
    view: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      marginBottom: "0.5mm",
    },
    viewInside: {
      display: "flex",
      flexDirection: "row",
    },
    pt9: {
      fontSize: "9pt",
    },
    borderBottom: {
      borderBottom: "1px solid black",
      marginBottom: 5,
    },
    underline: {
      borderBottom: "1px solid black",
      alignItems: "end",
      display: "flex",
      alignSelf: "end",
    },
    dashed: {
      borderBottom: "1px dashed black",
      width: "100%",
      margin: "25px 0",
    },
    borderTop: {
      borderTop: "1px solid black",
      marginTop: 2,
      paddingTop: 5,
    },
    right: {
      textAlign: "right",
    },
    marginT5: {
      marginTop: 5,
    },
    marginT35: {
      marginTop: 35,
    },
    marginT20: {
      marginTop: 20,
    },
    bold: {
      fontWeight: "bold",
    },
    paddingL10: {
      paddingLeft: "10px",
    },
  });
  const stylesHours = StyleSheet.create({
    date: {
      width: "2.5cm",
      paddingRight: 30,
    },
    arbeitszeit: {
      width: "3cm",
      paddingRight: 30,
    },
    stunden: {
      width: "4cm",
      paddingRight: 30,
    },
    pause: {
      width: "2cm",
      paddingRight: 30,
    },
    monteur: {
      width: "6cm",
    },
    width55: {
      width: "5.5cm",
      fontWeight: "bold",
    },
    width7: {
      width: "7cm",
     },
  });
  const notes = StyleSheet.create({
    dsc: {
      width: "11.6cm",
      paddingRight: 30,
    },
    dscLong: {
      width: "15.6cm",
      paddingRight: 30,
    },
    amnt: {
      width: "1.5cm",
      paddingRight: 10,
    },
    unit: {
      width: "1.4cm",
      paddingRight: 10,
    },
    preisStk: {
      width: "2cm",
      textAlign: "right",
    },
    preisGesamt: {
      width: "2cm",
      textAlign: "right",
    },
    sumNotes: {
      width: "16.5cm",
      textAlign: "right",
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={[stylesList.view]}>
          <View style={[styles.width70, stylesList.pt9]}>
            <Text style={styles.h1}>Regiebericht Nr.{state.reportNr}</Text>
            <View style={[styles.marginB5, stylesList.view,styles.borderBottom]}>
              <Text style={[ styles.width30, styles.max65]}>
                Auftraggeber:
              </Text>
              <Text style={[ styles.width70]} >
                {state.customerName}
              </Text>
            </View>
            <View style={[styles.marginT5,stylesList.view]}>
              <Text style={[ styles.width30, styles.max65]}>
              Bauvorhaben:
              </Text>
              <Text style={[ styles.width70]} >
                {state.bv}
              </Text>
            </View>
 
          </View>
          <View style={[styles.width30, styles.alignTop]}>
            <Image style={styles.logo} src={require("../../img/logo.png")} />
          </View>
         </View>
        {state.workingHoursPrint.length > 0 ? (
          <View style={[stylesList.view]}>
            <View>
              <Text style={styles.h2}>Stundenübersicht</Text>
            </View>
            <View
              style={[stylesList.view, stylesList.borderBottom, styles.bold]}
            >
              <Text style={stylesHours.date}>Datum</Text>
              <Text style={stylesHours.arbeitszeit}>Arbeitszeit</Text>
              <Text style={stylesHours.stunden}>Arbeitsstunden</Text>
              <Text style={stylesHours.pause}>Pause</Text>
              <Text style={stylesHours.monteur}>Monteur</Text>
            </View>
            {state.workingHoursPrint
              .sort(
                (a, b) =>
                  a.date > b.date &&
                  a.beginning > b.beginning &&
                  a.lastName > b.lastName
              )
              .map((hours, id) => {
                return (
                  <View key={id} style={[stylesList.view, stylesList.pt9]}>
                    <Text style={[stylesHours.date, stylesList.right]}>
                      {convertDate(hours.date)}
                    </Text>
                    <Text style={[stylesHours.arbeitszeit, stylesList.right]}>
                      {hours.beginning} - {hours.end}
                    </Text>
                    <Text style={[stylesHours.stunden]}>
                      {calcTime(
                        hours.beginning,
                        hours.end,
                        "00:00",
                        hours.pause,
                        hours.driveTime
                      )}
                    </Text>
                    <Text style={stylesHours.pause}>{hours.pause}</Text>
                    <Text style={stylesHours.monteur}>
                      {hours.lastName} {hours.firstName} ({hours.categorie})
                    </Text>
                  </View>
                );
              })}
            ;<View style={[stylesList.view, stylesList.borderBottom]}></View>
            <View style={[stylesList.view, stylesList.pt9]}>
              <Text style={stylesHours.width55}>Gesamt: </Text>
              <Text>{state.hoursComplete} h</Text>
            </View>
            <View style={[stylesList.view, stylesList.borderBottom, stylesHours.width7]}></View>
            <View style={[stylesList.view, stylesList.pt9]}>
              <Text style={[stylesHours.width55]}>
                Vorarbeiter:
              </Text>
              <Text>{state.hoursVorarbeiter} h</Text>
            </View>
            <View style={[stylesList.view, stylesList.pt9]}>
              <Text style={[stylesHours.width55]}>
                Hilfsarbeiter:{" "}
              </Text>
              <Text>{state.hoursHilfsarbeiter} h</Text>
            </View>
            <View style={[stylesList.view, stylesList.pt9]}>
              <Text style={[stylesHours.width55]}>
                Facharbeiter:
              </Text>
              <Text>{state.hoursFacharbeiter} h</Text>
            </View>
            {props.serviceOverview.length > 0 ? (
              <View wrap={false}>
                <Text style={styles.h2}>Leistungsübersicht</Text>
                <Text style={stylesList.pt9}>{props.serviceOverview}</Text>
              </View>
            ) : (
              ""
            )}
          </View>
        ) : (
          ""
        )}

        {state.deliveryNotes.length > 0 ? (
          <View>
            <Text style={styles.h2}>Materialverbrauch</Text>
            {state.deliveryNotes.map((item, idx) => {
              return (
                <View
                  key={idx}
                  style={[stylesList.view]}
                  wrap={props.printPrice ? false : true}
                >
                  {props.notesBillSplit ? (
                    <Text style={styles.h3}>
                      {item.supplier} - {item.notesNr}
                    </Text>
                  ) : (
                    ""
                  )}

                  {item.articles.map((i, id) => {
                    return (
                      <View
                        key={id}
                        style={[stylesList.view, stylesList.pt9]}
                        wrap={false}
                      >
                        {NotesHeadline(id, state.notesBillSplit, "notes")}
                        <Text
                          style={state.printPrice ? notes.dsc : notes.dscLong}
                        >
                          {i.itemDsc}
                        </Text>
                        <Text style={notes.amnt}>{i.itemAmnt}</Text>
                        <Text style={notes.unit}>{i.itemUnit}</Text>
                        {state.printPrice ? (
                          <View style={[stylesList.viewInside]}>
                            <Text style={notes.preisStk}>
                              {i.pricePiece === ""
                                ? "0.00"
                                : parseFloat(i.pricePiece).toFixed(2)}{" "}
                              €
                            </Text>
                            <Text style={notes.preisGesamt}>
                              {itemPriceComplete(i.pricePiece, i.itemAmnt)}€
                            </Text>
                          </View>
                        ) : (
                          ""
                        )}
                      </View>
                    );
                  })}
                  {props.notesBillSplit ? (
                    <View style={[stylesList.viewInside]}>
                      <Text style={[notes.sumNotes, styles.bold]}>Summe </Text>
                      <Text style={[notes.preisGesamt, styles.bold]}>
                        {completePricePerNote(item.articles)} €
                      </Text>
                    </View>
                  ) : (
                    ""
                  )}
                </View>
              );
            })}
            {props.printPrice ? (
              <View style={[stylesList.view, stylesList.borderTop]}>
                <Text style={[notes.sumNotes, styles.bold]}>Gesamtsumme </Text>
                <Text style={[notes.preisGesamt, styles.bold]}>
                  {priceNotesComplete()} €
                </Text>
              </View>
            ) : (
              ""
            )}
          </View>
        ) : (
          ""
        )}
        {props.bills[0].date.length > 0 ? (
          <View>
            <Text style={styles.h2}>Rechnungsübersicht</Text>
            {props.bills.map((item, idx) => {
              return (
                <View
                  key={idx}
                  style={[stylesList.view]}
                  wrap={state.printPrice ? false : true}
                >
                  {props.notesBillSplit ? (
                    <Text style={[styles.h3]}>
                      {item.supplier} - {item.billsNr}
                    </Text>
                  ) : (
                    ""
                  )}

                  {item.articles.map((i, id) => {
                    return (
                      <View
                        key={id}
                        style={[stylesList.view, stylesList.pt9]}
                        wrap={false}
                      >
                        {NotesHeadline(id, state.notesBillSplit, "bills")}
                        <Text
                          style={state.printPrice ? notes.dsc : notes.dscLong}
                        >
                          {i.itemDsc}
                        </Text>
                        <Text style={notes.amnt}>{i.itemAmnt}</Text>
                        <Text style={notes.unit}>{i.itemUnit}</Text>
                        {state.printPrice ? (
                          <View style={[stylesList.viewInside]}>
                            <Text style={notes.preisStk}>
                              {i.pricePiece === ""
                                ? "0.00"
                                : parseFloat(i.pricePiece).toFixed(2)}{" "}
                              €
                            </Text>
                            <Text style={notes.preisGesamt}>
                              {itemPriceComplete(i.pricePiece, i.itemAmnt)}€
                            </Text>
                          </View>
                        ) : (
                          ""
                        )}
                      </View>
                    );
                  })}
                  {props.notesBillSplit ? (
                    <View style={[stylesList.viewInside]}>
                      <Text style={[notes.sumNotes, styles.bold]}>Summe </Text>
                      <Text style={[notes.preisGesamt, styles.bold]}>
                        {completePricePerNote(item.articles)} €
                      </Text>
                    </View>
                  ) : (
                    ""
                  )}
                </View>
              );
            })}
            {props.printPrice ? (
              <View style={[stylesList.view, stylesList.borderTop]}>
                <Text style={[notes.sumNotes, styles.bold]}>Gesamtsumme </Text>
                <Text style={[notes.preisGesamt, styles.bold]}>
                  {priceBillsComplete()} €
                </Text>
              </View>
            ) : (
              ""
            )}
          </View>
        ) : (
          ""
        )}
        {props.equipment.length > 0 ? (
          <View>
            <Text style={styles.h2}>Geräteeinsatz</Text>
            <View style={[stylesList.view, stylesList.pt9]}>
              <Text>{props.equipment}</Text>
            </View>
          </View>
        ) : (
          ""
        )}
        <View style={[stylesList.view, styles.marginTop30]} wrap={false}>
          <Text style={[stylesList.dashed]}></Text>

          <View style={[stylesList.view]}>
            <Text style={styles.width40}>Für die Richtigkeit:</Text>
            <View style={[stylesList.view, styles.width60]}>
              <View style={styles.width60}>
                <Text style={[stylesList.underline]}>&nbsp;</Text>
              </View>
              <View style={styles.width10}>
                <Text> ,den </Text>
              </View>
              <View style={styles.width30}>
                <Text style={[stylesList.underline]}>&nbsp;</Text>
              </View>
            </View>
          </View>
          <View style={[stylesList.view, stylesList.marginT35]}>
            <View style={styles.width45}>
              <Text style={[stylesList.underline]}>&nbsp;</Text>
              <Text style={stylesList.marginT5}>Auftragnehmer</Text>
            </View>
            <View style={styles.width10}></View>
            <View style={styles.width45}>
              <Text style={[stylesList.underline]}>&nbsp;</Text>
              <Text style={stylesList.marginT5}>Auftraggeber</Text>
            </View>
          </View>

          <View>
            <Text style={[stylesList.pt9, stylesList.marginT20]}>
              <Text style={stylesList.bold}>Hinweis:</Text> Nach VOB §15 Abs. 4
              - Gelten nicht fristgemäß (6 Werktage) zurückgegeben Stundenzettel
              als anerkannt.
            </Text>
          </View>
        </View>

        <Text
          style={styles.posBottom}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}

export default PrintReport;
