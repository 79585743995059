import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
 import { Modal, ModalBody } from "reactstrap";
 import { globalUnits } from "../global/units.js";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

 import BaseUrl from "../config/config.js";
var $ = require("jquery");
var Highlight = require("react-highlighter");

class ModalItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      id: this.props.id,
      artNr: this.props.artNr,
      dsc: this.props.dsc,
      unit: this.props.unit,
      supplierId: this.props.supplierId,
      supplier: this.props.supplier,
      internArtNr: this.props.internArtNr,
      artGroup: this.props.artGroup,
      showUpdate: true,
      showDelete: false,
      hideItem: false,
     };
  }

   handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        modal: false
      });
    } else {
      this.setState({
        modal: !this.state.modal
      });
    }
  };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if (e.target.name === "supplier") {
      this.readSupplierIdByName(e.target.value, true);
    }
  };
  handleChangeArtGroup = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

    this.searchArtMax(e.target.value);
  };
  updateItem = e => {
    let updateItem = {
      id: this.state.id,
      artNr: this.state.artNr.trim(),
      dsc: this.state.dsc,
      unit: this.state.unit,
      supplierId: this.state.supplierId,
      internArtNr: this.state.internArtNr,
      artGroup: this.state.artGroup
    };

    // submit form data to api
    $.ajax({
      url: BaseUrl + "api/item/updateItem.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(updateItem),
      success: function(response) {
        this.setState({
          modal: !this.state.modal
        });
      }.bind(this),
      error: function(xhr, resp, text) {
        // show error to console
      }
    });

    e.preventDefault();
  };

  removeItem = () => {
    let deleteItem = {
      id: this.state.id
    };

    $.ajax({
      url: BaseUrl + "api/item/deleteItem.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function(response) {
        this.setState({
          modal: !this.state.modal,
          hideItem: true
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  readSupplierNameById = (id, bool) => {
    let found = this.props.supplierAll.find(name => name.supplierId === parseInt(id));
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        supplier: found.supplierName
      });
    } else {
      return found.supplierName;
    }
  };
  readSupplierIdByName = name => {
    let found = this.props.supplierAll.find(id => id.supplierName === name);
    return this.setState({
      supplierId: found.supplierId
    });
  };

  autocompleteBvSupplier = e => {
    if (e.keyCode === 9 && e.target.name === "supplierId") {
      this.readSupplierNameById(e.target.value, true);
    }
  };
  searchArtMax = eingabe => {
    if (eingabe === "") {
      this.setState({
        errorSearch: false
      });
    } else {
      return fetch(BaseUrl + "api/item/searchMaxArtNr.php?eingabe=" + eingabe)
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.message === "No items found.") {
            this.setState({
              internArtNr: "-"
            });
          } else {
            let countUp = parseInt(responseJson.records[0].max) + 1;
            this.setState({
              internArtNr: String(countUp).padStart(7, "0")
            });
          }
        })
        .catch(error => {});
    }
  };
  render() {
    return (
      <React.Fragment>
        <li
          onClick={this.toggle}
          className={this.state.hideItem ? "hide " : " "}
          key={this.state.id}
        >
          <div className="row fullwidth showSpan">
            <p className="col">
              <span>Artikel-Nr:</span>
              <br />
              <Highlight id="font-normal" search={this.props.search}>
                {this.state.artNr}
              </Highlight>
            </p>
            <p className="col-6">
              <span>Artikelbeschreibung</span>
              <br />{" "}
              <Highlight id="font-normal" search={this.props.search}>
                {this.state.dsc}
              </Highlight>{" "}
            </p>
            <p className="col">
              <span>Einheit</span>
              <br />
              {this.state.unit}
            </p>
            <p className="col">
              <span>Lieferant-Nr</span>
              <br />
              {this.state.supplierId}
            </p>
            <p className="col">
              <span>Lieferant</span>
              <br />
              <Highlight id="font-normal" search={this.props.search}>
                {this.state.supplier}
              </Highlight>
            </p>
            <p className="col">
              <span>ArtNr. intern</span>
              <br />
              <Highlight id="font-normal" search={this.props.search}>
                {this.state.artGroup} - {this.state.internArtNr}
              </Highlight>
            </p>
          </div>
        </li>
   <Modal isOpen={this.state.modal} toggle={this.toggle}>
             <div className="modal-header">
               <p className={this.state.showUpdate ? "show" : "hide"}>
                 Artikel Bearbeiten
               </p>
               <p className={this.state.showDelete ? "show" : "hide"}>
                 Artikel wirklich Löschen?
                 <br />
                 <span>Dieser Vorgang kann nicht Rückgänging gemacht werden</span>
               </p>
               <button className="close" onClick={this.toggle}>
                 {" "}
                 <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
               </button>
             </div>
             <ModalBody>
               <div className={this.state.showUpdate ? "show" : "hide"}>
                 <form>
                   <div className="row">
                     <div className="col-md-2">
                       <p>Artikel-Nr:</p>
                       <input
                         type="text"
                         name="artNr"
                         placeholder="Artikel-Nr"
                         onChange={this.handleChange}
                         value={this.state.artNr}
                       />
                     </div>
                     <div className="col-md-8">
                       <p>Artikelbezeichnung:</p>
                       <input
                         type="text"
                         name="dsc"
                         placeholder="Artikelbezeichnung"
                         onChange={this.handleChange}
                         value={this.state.dsc}
                       />
                     </div>
                     <div className="col-md-2">
                       <p>Einheit</p>
                       <select
                         name="unit"
                         onChange={this.handleChange}
                         value={this.state.unit}
                       >
                         {globalUnits.map((unit, idx) => {
                              return (
                                <option key={idx} value={unit}>
                                  {unit}
                                </option>
                              );
                            })}
                       </select>
                     </div>
                   </div>
                   <div className="row">
                     <div className="col-2 tab">
                       <p>Lieferanten-Nr</p>
                       <input
                         type="text"
                         name="supplierId"
                         placeholder="0"
                         onChange={this.handleChange}
                         value={this.state.supplierId}
                         onKeyDown={this.autocompleteBvSupplier}
                       />
                       <span>↹</span>
                     </div>
                     <div className="col-4">
                       <p>Lieferant</p>
                       <select
                         name="supplier"
                         value={this.state.supplier}
                         onChange={this.handleChange}
                       >
                         <option value="-">-</option>
                         {this.props.supplierAll.map((supplier, i) => (
                           <option key={i} value={supplier.supplierName}>
                             {supplier.supplierName}
                           </option>
                         ))}
                       </select>
                     </div>
                     <div className="col-md-4">
                       <p>Warengruppe</p>
                       <select
                         name="artGroup"
                         onChange={this.handleChangeArtGroup}
                         value={this.state.artGroup}
                       >
                      <option key="-1" value="-">
                      -
                    </option>
                    <option key={"w1"} value="01">
                      01 - Profile
                    </option>
                    <option key={"w2"} value="02">
                      02 - Gipsplatten
                    </option>
                    <option key={"w3"} value="03">
                      03 - Spachtel und Putz
                    </option>
                    <option key={"w4"} value="04">
                      04 - Montagezubehör
                    </option>
                    <option key={"w5"} value="05">
                      05 - Werkzeuge
                    </option>
                    <option key={"w6"} value="06">
                      06 - Büromaterial
                    </option>
                    <option key={"w7"} value="07">
                      07 - Logistikkosten
                    </option>
                    <option key={"w8"} value="08">
                      08 - Entsorgung
                    </option>
                    <option key={"w9"} value="09">
                      09 - Arbeitskleidung
                    </option>
                    <option key={"w10"} value="10">
                      10 - KFZ-Zubehör
                    </option>
                    <option key={"w11"} value="11">
                      11 - Dämmung
                    </option>
                    <option key={"w12"} value="12">
                      12 - Türen
                    </option>
                    <option key={"w13"} value="13">
                      13 - Zargen
                    </option>
                    <option key={"w14"} value="14">
                      14 - Drückergarnituren
                    </option>
                    <option key={"w15"} value="15">
                      15 - Bodenbeläge
                    </option>
                    <option key={"w16"} value="16">
                      16 - Farben und Lacke
                    </option>
                    <option key={"w17"} value="17">
                      17 - Fliesen und Fliesenkleber
                    </option>
                    <option key={"w18"} value="18">
                      18 - Holzplatten und Holzprofile
                    </option>
                       </select>
                     </div>
                     <div className="col-md-2">
                       <p>Art-Nr intern</p>
                       <input
                         type="text"
                         name="internArtNr"
                         placeholder="Artikelnummer intern"
                         onChange={this.handleChange}
                         value={this.state.internArtNr}
                       />
                     </div>
                   </div>
                 </form>
                 <div className=" btn-modal row">
                   <div className="col">
                     <button onClick={this.toggleDelete} className="btn-red">
                       Löschen <FontAwesomeIcon icon="trash" />
                     </button>
                   </div>
                   <div className="col">
                     <button onClick={this.updateItem} className="btn-green">
                       Ändern <FontAwesomeIcon icon={["far", "edit"]} />
                     </button>
                   </div>
                 </div>
               </div>
               <div className={this.state.showDelete ? "show" : "hide"}>
                 <div className=" btn-modal row">
                   <div className="col">
                     <button onClick={this.toggleDelete} className="btn-red">
                       Nein <FontAwesomeIcon icon="ban" />
                     </button>
                   </div>
                   <div className="col">
                     <button onClick={this.removeItem} className="btn-green">
                       Ja <FontAwesomeIcon icon="check-circle" />
                     </button>
                   </div>
                 </div>
               </div>
             </ModalBody>
           </Modal>
      </React.Fragment>
    );
  }
}

export default ModalItem;
