import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class DirectionWorkListInputBottom extends Component {
 


  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
     return (
      <React.Fragment>
        <section id="directionWorkList" className="display-item content  ">
          <div className="row row-width-90">
            <p className="offset-5 col-md-3">Regieberichts-Nr:</p>
            <div className="col-md-1">
              <input
                type="text"
                name="directionWorkNr"
                onChange={this.props.handleChange}
                value={this.props.directionWorkNr}
              />
            </div>
            <div className="col-3">
              <button
                type="button"
                onClick={this.props.handleSubmit(this.props.directionWorkNr)}
                className="btn-save-directionWork"
              >
                <FontAwesomeIcon icon="save" />
              </button>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default DirectionWorkListInputBottom;
