import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
import fontawesome from "@fortawesome/fontawesome";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseUrl from "../../config/config.js";
import moment from "moment";
var Highlight = require("react-highlighter");

var $ = require("jquery");

class ConstructionDiaryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
       customerId: this.props.customerId,
       customerName: this.props.customerName, 
       bvId:this.props.bvId,
       bv: this.props.bv,
       dateSaved: this.props.dateSaved,
       dateFrom: this.props.dateFrom,
       dateTo: this.props.dateTo
    };
  }

  componentDidMount = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true,
      });
    }
   };
 

  render() {
    return (
      <React.Fragment>
 
             <li
              key={this.props.idx}
              onClick={this.toggle}
              className={this.state.hideItem ? "hide " : " "}
            >
              <div className="row fullwidth showSpan">
                <Link
                  className="row "
                  to={`/home/bautagebuch/bautagebuch-erstellen/${this.state.bvId + "_" + this.state.dateSaved.replace(/-/g,"_")}`}
                >
                  <p className="col-1 offer-customerId-width">
                    <span>Kunden-ID</span>
                    <br />
                    <Highlight id="font-normal" search={this.props.search}>{this.state.customerId}</Highlight>
                  </p>
                  <p className="col-3">
                    <span>Kunde</span>
                    <br />
                    <Highlight id="font-normal" search={this.props.search}>
                      {this.state.customerName}
                      </Highlight>
                  </p>
                  <p className="col-1">
                    <span>BvId</span>
                    <br />
                    <Highlight id="font-normal" search={this.props.search}>
                      {this.state.bvId}
                      </Highlight>
                  </p>
                  <p className="col-4">
                    <span>Bv</span>
                    <br />
                    <Highlight id="font-normal" search={this.props.search}>
                      {this.state.bv}
                    </Highlight>
                  </p>

                  <p className="col-1">
                  <span>Datum</span>
                    <br />
                    <Highlight id="font-normal" search={this.props.search}>{moment(this.state.dateSaved).format('DD.MM.YYYY')}</Highlight>
                  </p>
                  <p className="col ">
                    <span>Von - Bis</span>
                    <br />
                     {moment(this.state.dateFrom).format('DD.MM.YYYY')} - {moment(this.state.dateTo).format('DD.MM.YYYY')}
                  </p>
                </Link>
              </div>
            </li>
 
      </React.Fragment>
    );
  }
}

export default ConstructionDiaryList;
