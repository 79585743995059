import React, { Component } from "react";
import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalBody } from "reactstrap";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
 const GEOCODE_URL = "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&location=";


class ModalGeo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoginLogout: "Login",
      loginLength: this.props.locLogin.length > 1,
      logoutLength: this.props.locLogout.length > 1,
      lat: Number(this.props.locLogin.split(",")[0]),
      long: Number(this.props.locLogin.split(",")[1]),
      adresse: ""
    };
  }
  componentDidMount = () => {
    if(this.state.loginLength){
          this.reverseGeoCoding(Number(this.props.locLogin.split(",")[0]),Number(this.props.locLogin.split(",")[1]) );

    }
    else{
      this.setState({
        showLoginLogout: "Logout",
        lat: Number(this.props.locLogout.split(",")[0]),
        long: Number(this.props.locLogout.split(",")[1]) 
      })
    }
    this.reverseGeoCoding(Number(this.props.locLogin.split(",")[0]),Number(this.props.locLogin.split(",")[1]) );
  }
  changeLoginLogout = (what) => (e) => {
    e.preventDefault();
    let helpLat = what === "Login"
          ? Number(this.props.locLogin.split(",")[0])
          : Number(this.props.locLogout.split(",")[0]);
    let helpLong = what === "Login"
          ? Number(this.props.locLogin.split(",")[1])
          : Number(this.props.locLogout.split(",")[1])
    this.setState({
      showLoginLogout: what,
      lat: helpLat,
      long: helpLong,
    });
      this.reverseGeoCoding(helpLat, helpLong);
  };
 async reverseGeoCoding(lat, long) {
    const data = await ( await fetch(GEOCODE_URL+`${long},${lat}`)).json();   
    const addressLabel = (data.address !== undefined) ? data.address.LongLabel : "Keine Adresse";
    this.setState({ adresse: addressLabel});
  }
 
  render() {
    return (
      <React.Fragment>
        <Modal
        className="geo-modal"
          isOpen={this.props.showGeoLoc}
          toggle={this.props.toggleGeoModal}
        >
          <div className="modal-header">
            <p>
              <FontAwesomeIcon icon={["fas", "map-marker-alt"]} /> Location{" "}
              {this.state.showLoginLogout}
            </p>
            <button className="close" onClick={this.props.toggleGeoModal}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            {(this.state.loginLength &&
              this.state.showLoginLogout === "Login") ||
            (this.state.logoutLength &&
              this.state.showLoginLogout === "Logout") ? (
              <MapContainer
                center={[this.state.lat, this.state.long]}
                zoom={60}
                scrollWheelZoom={true}
              >
                <TileLayer
                   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  position={[this.state.lat, this.state.long]}
                  icon={
                    new Icon({
                      iconUrl: markerIconPng,
                      iconSize: [25, 41],
                      iconAnchor: [12, 41],
                    })
                  }
                >
                  <Popup>{this.state.adresse}</Popup>
                </Marker>
              </MapContainer>
            ) : (
              "Keine Koordinaten vorhanden"
            )}
            <div className="btn-geo">
              <button
                className={
                  this.state.showLoginLogout === "Login" ? "active" : ""
                }
                disabled={!this.state.loginLength}
                onClick={this.changeLoginLogout("Login")}
              >
                Login
              </button>
              <button
                className={
                  this.state.showLoginLogout === "Logout" ? "active" : ""
                }
                disabled={!this.state.logoutLength}
                onClick={this.changeLoginLogout("Logout")}
              >
                Logout
              </button>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalGeo;
