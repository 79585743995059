import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
 import BaseUrl from "../../config/config.js";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class DirectionWorkReportInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bvAll: [],
      reportNrAll: [],
      reportNr: "",
      bv: "",
      bvId: "",
      hideSpan: true,
      hideLists: true,
      dateFrom: "",
      dateTo: "",
      year: new Date().getFullYear()
    };
  }
  componentDidMount = () => {
    this.readBVAll();
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

    if (e.target.name === "bv") {
      this.readBvIdByName(e.target.value);
    }
  };
  readBVAll = () => {
    return fetch(BaseUrl + "api/all/readBvComplete.php")
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          bvAll: responseJson.records[0]
        });
      })
      .catch(error => {});
  };
  readSupplierNames = () => {
    return fetch(BaseUrl + "api/all/readSupplierNames.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return (this.state.supplierAll = this.state.supplierAll.concat(item));
        });
        this.setState({
          supplierAll: this.state.supplierAll
        });
      })
      .catch(error => {});
  };
  readBvNameById = id => {
    let found = this.state.bvAll.find(name => name.bvId === parseInt(id));
    if (found === undefined) {
      return "";
    } else {
      this.loadAllNr(id);

      return this.setState({
        bv: found.bv
      });
    }
  };
  readBvIdByName = name => {
    let found = this.state.bvAll.find(id => id.bv === name);
    if (found === undefined) {
      return "";
    } else {
      this.loadAllNr(found.bvId);
      return this.setState({
        bvId: found.bvId
      });
    }
  };

  readSupplierNameById = id => {
    let found = this.state.supplierAll.find(name => name.supplierId === parseInt(id));
    if (found === undefined) {
      return "";
    } else {
      return found.supplierName;
    }
  };
  loadAllNr = bvId => {
    this.setState({
      reportNrAll:[]
    })
    return fetch(
      BaseUrl +
        "api/directionWork/readAllNr.php?bvId=" +
        bvId
    )
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.message === "No items found.") {
          this.setState({
            reportNrAll: []
          });
        } else {
          this.setState({
            reportNrAll: responseJson.records
          });
        }
      })
      .catch(error => {});
  };
  autocompleteBv = e => {
    if (e.keyCode === 9) {
      this.readBvNameById(e.target.value);
    }
  };

  onKeyPress = e => {
    this.search(e.target.value);
    this.setState({
      hideSpan: false
    });
  };
  readAllInfosProps = () => {
    this.props.readAllInfos(
      this.state.bvId,
      this.state.reportNr
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="row directionWorkReportInput no-print">
          <div className="col-2 tab">
            <p>Bv-Nr</p>
            <input
              required
              type="text"
              name="bvId"
              onChange={this.handleChange}
              value={this.state.bvId}
              onKeyDown={this.autocompleteBv}
            />
            <span>↹</span>
          </div>
          <div className="col-5 input">
            <p>BV</p>
            <select
              name="bv"
              value={this.state.bv}
              onChange={this.handleChange}
            >
              <option key="-1" value="-">
                -
              </option>
              {this.state.bvAll.map((bv, i) => (
                <option key={i} value={bv.bv}>
                  {bv.bv}
                </option>
              ))}
            </select>
          </div>
          <div className="col-3 input">
            <p>RegieberichtNr:</p>
            <select
              name="reportNr"
              value={this.state.reportNr}
              onChange={this.handleChange}
            >
              <option key="-1" value="-">
                -
              </option>
              {this.state.reportNrAll.map((nr, i) => (
                <option key={i} value={nr.directionWorkNr}>
                  {nr.directionWorkNr}
                </option>
              ))}
            </select>
          </div>
          <div className=" col-2 input">
            <button
              type="button"
              className="btn-add-item"
              onClick={this.readAllInfosProps}
             >
              <FontAwesomeIcon icon="search" />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default DirectionWorkReportInput;
