import React, { Component } from "react";
import "../../css/bootstrap-grid.css";
import "../../css/App.css";
import "../../css/index.css";
import "../../css/mediaQuerys.css";
import BaseUrl from "../../config/config.js";
import "@fortawesome/fontawesome-free-solid";

var $ = require("jquery");

class HoursListReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: this.props.key,
      id: this.props.id,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      categorie: this.props.categorie,
      bv: this.props.bv,
      bvId: this.props.bvId,
      hours: this.props.hours,
      reportNr: this.props.reportNr,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.save !== this.props.save) {
      this.updateHours();
    }
    if (this.props.printAndCalc === true) {
      this.printAndCalcFunc();
    }
    if (prevProps.dataRefreshedHours !== this.props.dataRefreshedHours) {
      const timerId = setTimeout(() => {
        this.props.getChangedDataHours(this.state.hours, this.state.lastName, this.state.firstName, this.state.categorie);
      }, 750);
    }
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
 
  };

  convertDate = (date) => {
    if (date === undefined) {
      return date;
    } else {
      if (date.includes("-")) {
        var dateNew = date.split("-");
        dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
        return dateNew;
      } else {
        return date;
      }
    }
  };

  handleChange = (idx) => (evt) => {
    const newDateChange = this.state.hours.map((item, sidx) => {
      if (idx !== sidx) return item;
      return { ...item, [evt.target.name]: evt.target.value };
    });
    this.setState({ hours: newDateChange });
    if(this.props.print){
      this.props.resetButton();
    }
  };

  handleAddHoursReporting = () => {
    this.setState({
      hours: this.state.hours.concat([
        {
          date: "",
          beginning: "",
          end: "",
          pause: "",
          driveTime: "",
          bvId: "",
          bv: "",
          result: "",
        },
      ]),
    });
  };

  handleRemoveHour = (idx) => () => {
   
          this.setState(
        (prevState) => ({
         hours: this.state.hours.filter((s, sidx) => idx !== sidx),
        }),
        () => {
          this.props.helpCalcHours(
            this.props.objId,
            this.state.id,
            this.state.hours
          );
        }
      );
    this.removeHours(this.state.hours[idx].id);

  };

  calcResult = (idx) => (e) => {
    if (e.keyCode === 9) {
      this.calc(e, idx);
                this.props.helpCalcHours(
            this.props.objId,
            this.state.id,
            this.state.hours
          );
    }
  };
  calc = (evt, idx) => {
    this.calcTime(
      idx,
      this.state.hours[idx].beginning,
      this.state.hours[idx].end,
      "00:00",
      this.state.hours[idx].pause,
      this.state.hours[idx].driveTime
    );
  };
  calcTime = (idx, start, end, zero, pause, fahrt) => {
    if (this.state.hours[idx].pause === "") {
      pause = "00:00";
      this.state.hours[idx].pause = "00:00";
    }
    if (this.state.hours[idx].driveTime === "") {
      fahrt = "00:00";
      this.state.hours[idx].driveTime = "00:00";
    }
    if (pause && fahrt) {
      var start = start.split(":");
      var end = end.split(":");
      var zero = zero.split(":");
      var pause = pause.split(":");
      var fahrt = fahrt.split(":");

      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      var zeroDate = new Date(0, 0, 0, zero[0], zero[1], 0);
      var pauseDate = new Date(0, 0, 0, pause[0], pause[1], 0);
      var fahrtDate = new Date(0, 0, 0, fahrt[0], fahrt[1], 0);

      var diff =
        endDate.getTime() -
        startDate.getTime() -
        (pauseDate.getTime() - zeroDate.getTime()) -
        (fahrtDate.getTime() - zeroDate.getTime());
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);
      const result =
        (hours < 10 ? "0" : "") +
        hours +
        ":" +
        (minutes < 10 ? "0" : "") +
        minutes;

      this.state.hours[idx].result = result;
      console.log(result)
      this.setState(
        (prevState) => ({
          hours: this.state.hours,
        }),
        () => {
 
        }
      );
    }
  };
  updateHours = () => {
    let updateHours;
    this.state.hours.map((item, idx) => {
      updateHours = {
        id: item.id === undefined ? "0" : item.id,
        date: item.date,
        directionWorkNr: this.state.reportNr,
        lastName: this.state.lastName,
        firstName: this.state.firstName,
        bvId: this.state.bvId,
        beginning: item.beginning,
        end: item.end,
        pause: item.pause,
        driveTime: item.driveTime,
        result: item.result,
      };

      $.ajax({
        url: BaseUrl + "api/directionWork/updateHours.php",
        type: "POST",
        dataType: "text",
        data: JSON.stringify(updateHours),
        success: function (response) {
          this.setState({});
        }.bind(this),
        error: function (xhr, resp, text) {},
      });
      return this;
    });
  };

  removeHours = (idx) => {
    var deleteItem = {
      id: idx,
    };
 
    $.ajax({
      url: BaseUrl + "api/directionWork/deleteHoursForReport.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function (response) {}.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  printAndCalcFunc = () => {
    this.state.hours.map((i) => {
      return this.props.collectResult(i.result);
    });
  };
  render() {
    return (
      <React.Fragment>
        <div className="no-print" key={this.state.id}>
          <div className={this.state.hideList ? "hide " : "HoursListReport"}>
            <h4>
              {this.state.lastName} {this.state.firstName}
            </h4>

            <ul>
              <div className="row  ">
                <p className="col">
                  <span>Datum:</span>
                </p>
                <p className="col">
                  <span>Beginn:</span>
                </p>
                <p className="col">
                  <span>Ende:</span>
                </p>
                <p className="col">
                  <span>Pause:</span>
                </p>
                <p className="col">
                  <span>Fahrtzeit:</span>
                </p>
                <p className="col">
                  <span>Ergebnis:</span>
                </p>
                <p className="col-1">
                  <span />
                </p>
              </div>
              {this.state.hours.map((i, idx) => {
                return (
                  <div key={idx} className=" ">
                    <div className="row ">
                      <p className="col">
                        <input
                          type="date"
                          name="date"
                          placeholder={`Datum #${idx + 1}`}
                          onChange={this.handleChange(idx)}
                          value={i.date}
                        />
                      </p>
                      <p className="col">
                        <input
                          type="time"
                          name="beginning"
                          placeholder={`Beginn #${idx + 1}`}
                          onChange={this.handleChange(idx)}
                          value={i.beginning}
                        />
                      </p>
                      <p className="col">
                        <input
                          type="time"
                          name="end"
                          placeholder={`Ende #${idx + 1}`}
                          onChange={this.handleChange(idx)}
                          value={i.end}
                        />
                      </p>
                      <p className="col span">
                        <input
                          type="time"
                          name="pause"
                          placeholder={`Pause #${idx + 1}`}
                          onChange={this.handleChange(idx)}
                          value={i.pause}
                        />
                      </p>
                      <p className="col span">
                        <input
                          type="time"
                          name="driveTime"
                          placeholder={`Fahrtzeit #${idx + 1}`}
                          onChange={this.handleChange(idx)}
                          value={i.driveTime}
                          onKeyDown={this.calcResult(idx)}
                        />
                        <span id="span-icon">↹</span>
                      </p>
                      <p className="col">
                        <input
                          type="text"
                          name="result"
                          placeholder={`Ergebnis #${idx + 1}`}
                          onChange={this.handleChange(idx)}
                          value={i.result}
                        />
                      </p>
                      <p className="col-1">
                        <button
                          type="button"
                          onClick={this.handleRemoveHour(idx)}
                          className="btn-remove"
                        >
                          -
                        </button>
                      </p>
                    </div>
                  </div>
                );
              })}
              <div className="row">
                <p className="offset-11 col-1">
                  <button
                    type="button"
                    onClick={this.handleAddHoursReporting}
                    className="btn-add-item"
                  >
                    +
                  </button>
                </p>
              </div>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default HoursListReport;
