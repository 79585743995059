import React, { Component } from "react";
import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../config/config.js";
var $ = require("jquery");

class ModalHoursReporting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      id: this.props.id,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      year: this.props.year,
      month: this.props.month,
      date: this.props.date,
      payed: this.props.payed,
      beginning: this.props.beginning,
      end: this.props.end,
      pause: this.props.pause,
      driveTime: this.props.driveTime,
      bv: this.props.bv,
      bvId: this.props.bvId,
      result: this.props.result,
      fahrtArbeit: this.props.fahrtArbeit,
      showUpdate: true,
      showDelete: false,
      hideNotes: false,
      hideArticle: false,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false,
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true,
      });
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "bv") {
      this.readBvIdByName(e.target.value, true);
    }
  };
  handleChangeCheck = (e) => {
    this.setState({
      done: !this.state.done,
    });
  };
  updateHoursReporting = (e) => {
    let updateHoursReport = {
      id: this.state.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      year: this.state.year,
      month: this.state.month,
      date: this.state.date,
      payed: this.state.payed,
      beginning: this.state.beginning,
      end: this.state.end,
      pause: this.state.pause,
      driveTime: this.state.driveTime,
      bvId: this.state.bvId,
      bv: this.state.bv,
      result: this.state.result,
      fahrtArbeit: this.state.fahrtArbeit,
    };
    $.ajax({
      url: BaseUrl + "api/hoursreporting/updateHoursReporting.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(updateHoursReport),
      success: function (response) {
        this.setState({
          notesNrOld: this.state.notesNr,
          modal: !this.state.modal,
        });
      }.bind(this),
      error: function (xhr, resp, text) {},
    });

    e.preventDefault();
  };

  removeHoursReport = () => {
    let deleteItem = {
      id: this.state.id,
    };
    $.ajax({
      url: BaseUrl + "api/hoursreporting/deleteHoursReporting.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function (response) {
        this.setState({
          modal: !this.state.modal,
          hideNotes: true,
        });
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };

  autocompleteBv = (e) => {
    if (e.keyCode === 9) {
      this.readBvNameById(e.target.value, true);
    }
  };

  calcResult = (e) => {
    if (e.keyCode === 9) {
      this.calc(e);
    }
  };
  calc = (evt, idx) => {
    this.calcTime(
      idx,
      this.state.beginning,
      this.state.end,
      "00:00",
      this.state.pause,
      this.state.driveTime
    );
  };

  calcTime = (idx, start, end, zero, pause, fahrt) => {
    if (this.state.pause === "") {
      pause = "00:00";
      this.state.pause = "00:00";
    }
    if (this.state.driveTime === "") {
      fahrt = "00:00";
      this.state.driveTime = "00:00";
    }
    if (pause && fahrt) {
      var start = start.split(":");
      var end = end.split(":");
      var zero = zero.split(":");
      var pause = pause.split(":");
      var fahrt = fahrt.split(":");

      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      var zeroDate = new Date(0, 0, 0, zero[0], zero[1], 0);
      var pauseDate = new Date(0, 0, 0, pause[0], pause[1], 0);
      var fahrtDate = new Date(0, 0, 0, fahrt[0], fahrt[1], 0);

      var diff =
        endDate.getTime() -
        startDate.getTime() -
        (pauseDate.getTime() - zeroDate.getTime()) -
        (fahrtDate.getTime() - zeroDate.getTime());
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);
      const result =
        (hours < 10 ? "0" : "") +
        hours +
        ":" +
        (minutes < 10 ? "0" : "") +
        minutes;

      this.state.result = result;

      this.setState({
        hours: this.state.hours,
      });
    }
  };
  handlePayed = (evt) => {
    if (
      evt.target.value === "Krank" ||
      evt.target.value === "Urlaub" ||
      evt.target.value === "Feiertag" ||
      evt.target.value === "Kurzarbeit" ||
      evt.target.value === "Unbezahlt"
    ) {
      this.setState({
        beginning: "00:00",
        end: "00:00",
        pause: "00:00",
        driveTime: "00:00",
        bvId: "-",
        bv: "-",
        result: "00:00",
        fahrtArbeit: "-",
        [evt.target.name]: evt.target.value,
      });
    } else {
      this.setState({
        [evt.target.name]: evt.target.value,
      });
    }
  };
  convertDate = (date) => {
    if (date === null || date === "0000-00-00" || date === undefined) {
      return date;
    }

    if (date.includes("-")) {
      var dateNew = date.split("-");
      dateNew = dateNew[2] + "." + dateNew[1] + "." + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  convertDateNormal = (date) => {
    if (date === null || date === "0000-00-00" || date === undefined) {
      return date;
    }

    if (date.includes(".")) {
      var dateNew = date.split(".");
      dateNew = dateNew[2] + "-" + dateNew[1] + "-" + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  readBvNameById = (id, bool) => {
    let found = this.props.bvAll.find((name) => name.bvId === id);
    if (found === undefined) {
      this.props.readOldBvById(id);
      setTimeout(() => {
        this.setState({
          bv: this.props.bvAllFound,
        });
      }, 200);
    }
    if (bool === true && found !== undefined) {
      return this.setState({
        bv: found.bv,
      });
    } else if (bool === false && found !== undefined) {
      return found.bv;
    }
  };
  readBvIdByName = (name, bool) => {
    let found = this.props.bvAllInput.find((id) => id.bv === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        bvId: found.bvId,
      });
    } else {
      return found.bvId;
    }
  };
  
  render() {
    return (
      <React.Fragment>
        <li
          onClick={this.toggle}
          className={this.state.hideNotes ? "hide " : "hoursReporting-li"}
        >
          <div>
            <span className="row hide-span">
              <p className="col-2 max-150">
                <span>Datum:</span>
                {this.convertDate(this.state.date)}
              </p>
              <p className="col-1">
                <span>Bezahlte h:</span>
                {this.state.payed}
              </p>
              <p className="col-1  max-85">
                <span>Beginn:</span>
                {this.state.beginning}
              </p>
              <p className="col-1 max-85">
                <span>Ende:</span>
                {this.state.end}
              </p>
              <p className="col-1 max-85">
                <span>Pause:</span>
                {this.state.pause}
              </p>
              <p className="col-1">
                <span>Fahrt-/Arbeitszeit:</span>
                {this.state.fahrtArbeit}
              </p>
              <p className="col-1 max-85">
                <span>Baustelle Id:</span>
                {this.state.bvId}
              </p>
              <p className="col">
                <span>Baustelle:</span>
                {this.state.bv}
              </p>
              <p className="col-1 max-85">
                <span>Ergebnis:</span>
                {this.state.result}
              </p>
            </span>
          </div>
        </li>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal-header">
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <p>
                Stundenbericht bearbeiten?
                <br />
              </p>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <p>
                Stundenbericht wirklich Löschen?
                <br />
              </p>

              <span>Dieser Vorgang kann nicht Rückgänging gemacht werden</span>
            </div>
            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div
              className={
                this.state.showUpdate ? "show  hoursreporting" : "hide"
              }
            >
              <form className="row">
                <div className="col-md-2 max-150">
                  <p>Datum</p>
                  <input
                    type="date"
                    name="date"
                    onChange={this.handleChange}
                    value={this.convertDateNormal(this.state.date)}
                  />
                </div>
                <div className="col-1  tab tab-select">
                  <p>Bezahlte h:</p>

                  <select
                    name="payed"
                    value={this.state.payed}
                    onChange={this.handlePayed}
                  >
                    <option key="-1" value="-">
                      -
                    </option>
                    <option key={1} value="Krank">
                      Krank
                    </option>
                    <option key={2} value="Urlaub">
                      Urlaub
                    </option>
                    <option key={3} value="Feiertag">
                      Feiertag
                    </option>
                    <option key={4} value="Kurzarbeit">
                      Kurzarbeit
                    </option>
                    <option key={5} value="Unbezahlt">
                      Unbezahlt
                    </option>
                  </select>
                </div>
                <div className="col-md-1 max-85">
                  <p>Beginn</p>
                  <input
                    type="time"
                    name="beginning"
                    onChange={this.handleChange}
                    value={this.state.beginning}
                  />
                </div>
                <div className="col-md-1 max-85">
                  <p>Ende</p>

                  <input
                    type="time"
                    name="end"
                    onChange={this.handleChange}
                    value={this.state.end}
                  />
                </div>
                <div className="col-md-1 max-85">
                  <p>Pause</p>

                  <input
                    type="time"
                    name="pause"
                    onChange={this.handleChange}
                    value={this.state.pause}
                  />
                </div>
                <div className="col-md-2 max-150">
                  <p>Fahrt-/Arbeitszeit</p>
                  <select
                    name="fahrtArbeit"
                    value={this.state.fahrtArbeit}
                    onChange={this.handleChange}
                  >
                    <option key="-1" value="-">
                      -
                    </option>
                    <option key={1} value="Arbeitszeit">
                      Arbeitszeit
                    </option>
                    <option key={2} value="Fahrtzeit">
                      Fahrtzeit
                    </option>
                  </select>
                </div>
                <div className="col-1  max-85 tab">
                  <p>Baustelle Id:</p>
                  <input
                    type="text"
                    name="bvId"
                    onChange={this.handleChange}
                    value={this.state.bvId}
                    onKeyDown={this.autocompleteBv}
                  />
                  <span id="span-icon">↹</span>
                </div>
                <div className="col tab tab-select">
                  <p>Baustelle:</p>

                  <select
                    name="bv"
                    value={this.state.bv}
                    onChange={this.handleChange}
                    onKeyDown={this.calcResult}
                  >
                    <option key="-1" value="-">
                      -
                    </option>
                    {this.props.bvAllInput.map((bv, i) => (
                      <option key={i} value={bv.bv}>
                        {bv.bv}
                      </option>
                    ))}
                  </select>
                  <span id="span-icon">↹</span>
                </div>
                <div className="col-md-1  max-85">
                  <p>Ergebnis</p>
                  <input
                    type="text"
                    name="result"
                    onChange={this.handleChange}
                    value={this.state.result}
                  />
                </div>
              </form>

              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Löschen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
                <div className="col">
                  <button
                    onClick={this.updateHoursReporting}
                    className="btn-green"
                  >
                    Ändern <FontAwesomeIcon icon={["far", "edit"]} />
                  </button>
                </div>
              </div>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Nein <FontAwesomeIcon icon="ban" />
                  </button>
                </div>

                <div className="col">
                  <button
                    onClick={this.removeHoursReport}
                    className="btn-green"
                  >
                    Ja <FontAwesomeIcon icon="check-circle" />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalHoursReporting;
