 import React, { Component } from "react";

import "../css/bootstrap-grid.css";
import "../css/App.css";
import "../css/index.css";
import "../css/mediaQuerys.css";
import CalcData from "./CalcData";
import CalcEmployees from "./CalcEmployees";
import SavedCalcHourlyWage from "./SavedCalcHourlyWage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "@fortawesome/fontawesome-free-solid";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


const calcMain = () => {
  return (
    <React.Fragment>
      <section id="insertCalcHourlyWage">
        <div className=" wrapper-btn-text row">
          <div className="col-md-2">
            <Link to="/home">
              <FontAwesomeIcon icon="arrow-left" /> Home
            </Link>
          </div>
          <div className="col-md-8">
            <h1>
              <FontAwesomeIcon color="var(--redBtn)" icon="list-ol" />{" "}
              Stundenkalkulation
            </h1>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col">
            <Link className="btn-start-red" to="/home/stundenkalkulation/stammdaten">
              <FontAwesomeIcon icon="database" />
              <br />
              Stundenkalkulation Stammdaten
            </Link>
          </div>
          <div className="col">
            <Link className="btn-start-red" to="/home/stundenkalkulation/mitarbeiter">
              <FontAwesomeIcon icon="user" />
              <br />
              Stundenkalkulation Mitarbeiter
            </Link>
          </div>
        </div>
        <div className="row">
        <div className="col">
          <Link className="btn-start-red" to="/home/stundenkalkulation/mitarbeiterkosten">
            <FontAwesomeIcon icon="chart-line" />
             <br />
            Übersicht Mitarbeiterkosten/h
          </Link>
        </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const calcData = () => {
  return <CalcData />;
};
const calcEmployees = () => {
  return <CalcEmployees />;
};
const savedCalcHourlyWage = () => {
  return <SavedCalcHourlyWage />;
};
class CalcMain extends Component {

  render() {
    return (
         <React.Fragment>
          <Route exact path="/home/stundenkalkulation" component={calcMain} />
          <Route
            exact
            path="/home/stundenkalkulation/stammdaten"
            component={calcData}
          />
          <Route
            exact path="/home/stundenkalkulation/mitarbeiter"
            component={calcEmployees}
          />
          <Route
            exact path="/home/stundenkalkulation/mitarbeiterkosten"
            component={savedCalcHourlyWage}
          />
        </React.Fragment>
     );
  }
}
export default CalcMain;
