import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { Modal, ModalBody } from "reactstrap";
import Textarea from "react-textarea-autosize";
import BaseUrl from "../config/config.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var $ = require("jquery");
var Highlight = require('react-highlighter');
var currencyFormatter = require("currency-formatter");

class ModalProducts extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      id: this.props.id,
      nr: this.props.nr,
      dsc: this.props.dsc,
      unit: this.props.unit,
      calcPrice: this.props.calcPrice,
      category: this.props.category,
      showUpdate: true,
      showDelete: false,
      hideproducts: false,
     };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    if(sessionStorage.getItem("userData").indexOf("admin") === -1){
       this.setState({
       modal:false
     })

   }
   else{
     this.setState({
       modal: !this.state.modal
     });
   }
  };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleChangeArtGroup = e => {
   this.setState({
     [e.target.name]: e.target.value,
    });
 };
  updateProducts = e => {
    let updateproducts = {
      id: this.state.id,
      nr: this.state.nr.trim(),
      dsc: this.state.dsc,
      unit: this.state.unit,
      calcPrice: parseFloat(this.state.calcPrice.replace(/,/g, ".")).toFixed(2),
      category: this.state.category,
    };

    // submit form data to api
    $.ajax({
      url: BaseUrl + "api/products/updateProducts.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(updateproducts),
      success: function(response) {
        this.setState({
          modal: !this.state.modal
        });
      }.bind(this),
      error: function(xhr, resp, text) {
        // show error to console
       }
    });

    e.preventDefault();
  };

  removeProducts = () => {
     let deleteproducts = {
      id: this.state.id
    };

    $.ajax({
      url: BaseUrl + "api/products/deleteProducts.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteproducts),
      success: function(response) {
        this.setState({
          modal: !this.state.modal,
          hideproducts: true
        });
      }.bind(this),
      error: function(xhr, resp, text) {

      }
    });
  };
  convertPrice = price => {
    return currencyFormatter.format(price, { locale: "de-DE" });
  };
  render() {
     return (
      <React.Fragment>
        <li
          onClick={this.toggle}
          className={this.state.hideproducts ? "hide " : " "}
          key={this.state.id}
        >
          <div className="row fullwidth showSpan">
            <p className="col-1"><span>Artikel-Nr</span><br/><Highlight id="font-normal"  search={this.props.search}>{this.state.nr}</Highlight></p>
            <p className="col-8"><span>Artikelbeschreibung</span><br/> <Highlight id="font-normal"  search={this.props.search}>{this.state.dsc}</Highlight> </p>
            <p className="col-1"><span>Einheit</span><br/>{this.state.unit}</p>
            <p className="col-1 text-right"><span>Kalk. Preis</span><br/>{this.convertPrice(this.state.calcPrice)}</p>
            <p className="col-1"><span>Kategorie</span><br/><Highlight id="font-normal"  search={this.props.search}>{this.state.category}</Highlight></p>

          </div>
        </li>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal-header">
            <p className={this.state.showUpdate ? "show" : "hide"}>
              Produkt Bearbeiten
            </p>
            <p className={this.state.showDelete ? "show" : "hide"}>
              Produkt wirklich Löschen?<br />
              <span>Dieser Vorgang kann nicht Rückgänging gemacht werden</span>
            </p>
            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <form>
                <div className="row">
                  <div className="col-md-2">
                    <p>Artikel-Nr</p>
                    <input
                      type="text"
                      name="nr"
                      placeholder="Nummer"
                      onChange={this.handleChange}
                      value={this.state.nr}
                    />
                  </div>
                  <div className="col-md-6">
                    <p>Artikelbezeichnung</p>
                    <Textarea
                      type="text"
                      name="dsc"
                      placeholder="Artikelbezeichnung"
                      onChange={this.handleChange}
                      value={this.state.dsc}
                    />
                  </div>
                  <div className="col-md-2">
                    <p>Einheit</p>
                    <select
                      name="unit"
                      onChange={this.handleChange}
                      value={this.state.unit}
                    >
                      <option key="-1" value="-">
                        -
                      </option>
                      <option key={1} value="Beutel">
                        Beutel
                      </option>
                      <option key={2} value="Bund">
                        Bund
                      </option>
                      <option key={3} value="Dose(n)">
                        Dose(n)
                      </option>
                      <option key={4} value="Eimer">
                        Eimer
                      </option>
                      <option key={5} value="Flasche(n)">
                        Flasche(n)
                      </option>
                      <option key={6} value="Gebinde">
                        Gebinde
                      </option>
                      <option key={7} value="Gramm">
                        Gramm
                      </option>
                      <option key={8} value="Karton">
                        Karton
                      </option>
                      <option key={9} value="KG">
                        KG
                      </option>
                      <option key={10} value="Kübel">
                        Kübel
                      </option>
                      <option key={11} value="LE">
                        LE
                      </option>
                      <option key={12} value="Lfm.">
                        Lfm.
                      </option>
                      <option key={13} value="Liter">
                        Liter
                      </option>
                      <option key={14} value="m2">
                        m2
                      </option>
                      <option key={15} value="m3">
                        m3
                      </option>
                      <option key={16} value="Minuten">
                        Minuten
                      </option>
                      <option key={17} value="Paar">
                        Paar
                      </option>
                      <option key={18} value="Pack">
                        Pack
                      </option>
                      <option key={19} value="Paket">
                        Paket
                      </option>
                      <option key={20} value="Palette(n)">
                        Palette(n)
                      </option>
                      <option key={21} value="Pauschal">
                        Pauschal
                      </option>
                      <option key={22} value="Rolle(n)">
                        Rolle(n)
                      </option>
                      <option key={23} value="Sack">
                        Sack
                      </option>
                      <option key={24} value="Satz">
                        Satz
                      </option>
                      <option key={25} value="Set">
                        Set
                      </option>
                      <option key={26} value="Stück">
                        Stück
                      </option>
                      <option key={27} value="Stunden">
                        Stunden
                      </option>
                      <option key={28} value="Tag/e">
                        Tag/e
                      </option>
                      <option key={29} value="Tonne(n)">
                        Tonne(n)
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2">
                  <p>Kalk. Preis</p>
                    <input
                      required
                      type="text"
                      name="calcPrice"
                      placeholder="Kalk. Preis"
                      onChange={this.handleChange}
                      value={this.state.calcPrice}
                    />
                  </div>
                </div>
                <div className="row">
                <div className="col-md-2">
                  <p>Kategorie</p>
                  <select
                    name="category"
                    onChange={this.handleChange}
                    value={this.state.category}
                  >
                    <option key="-1" value="-">
                      -
                    </option>
                    <option key={"0"} value="Decke">
                      Decke
                    </option>
                    <option key={"1"} value="Wand">
                      Wand
                    </option>
                    <option key={"2"} value="Boden">
                      Boden
                    </option>
                    <option key={"3"} value="Abbruch">
                      Abbruch
                    </option>
                    <option key={"4"} value="Logistik">
                      Logistik
                    </option>
                  </select>
                </div>

                </div>
              </form>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Löschen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.updateProducts} className="btn-green">
                    Ändern <FontAwesomeIcon icon={["far", "edit"]} />
                  </button>
                </div>
              </div>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Nein <FontAwesomeIcon icon="ban" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.removeProducts} className="btn-green">
                    Ja <FontAwesomeIcon icon="check-circle" />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalProducts;
