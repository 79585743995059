import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Textarea from "react-textarea-autosize";
import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../../config/config.js";
var Highlight = require("react-highlighter");
var currencyFormatter = require("currency-formatter");

class OfferCreateSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      productsAll: []
    };
  }
  toggle = () => {
    this.props.toggle(this.props.idx, "searchModal");
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSearch = e => {
    this.state.search = e.target.value;
    if (e.target.value === "") {
      this.setState({
        productsAll: this.state.productsAllCopy
      });
    }
  };

  search = e => {
    if (e.key === "Enter") {
      console.log("hiers");

      let convertEingabe = this.state.search
        .replace(/ /g, "%")
        .replace("+", "%2B");

      this.setState({
        productsAll: []
      });
      return fetch(
        BaseUrl + "api/products/searchProducts.php?eingabe=" + convertEingabe
      )
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.message === "No itms found.") {
            this.setState({
              productsAll: []
            });
          } else {
            this.setState({
              productsAll: responseJson.records[0]
            });
            console.log(this.state.productsAll);
          }
        })
        .catch(error => {});
    }
  };
  convertPrice = price => {
    return currencyFormatter.format(price, { locale: "de-DE" });
  };
  selectProduct = (idx, artNr, dsc, unit, pricePiece) => e => {
    e.preventDefault();
     this.props.searchedProduct(idx, artNr, dsc, unit, pricePiece);
setTimeout(() => {
  this.toggle();
}, 600);
 
  };
  render() {
    return (
      <Modal isOpen={this.props.searchModal} toggle={this.toggle}>
        <div className="modal-header">
          <p>Produkt Suchen</p>

          <button className="close" onClick={this.toggle}>
            {" "}
            <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
          </button>
        </div>
        <ModalBody>
          <div>
            <input
              className="search"
              type="text"
              name="search"
              placeholder="Suchen"
              value={this.state.search}
              onChange={this.handleChange}
              onKeyPress={this.search}
            />
            <ul className="product-search">
              {this.state.productsAll.length > 0 ? (
                <div className="row search-text">
                  <div className="col-1">
                    <p>ArtNr</p>
                  </div>
                  <div className="col-8">
                    <p>Beschreibung</p>
                  </div>
                  <div className="col-1">
                    <p>Einheit</p>
                  </div>
                  <div className="col-1 text-right">
                    <p>Preis</p>
                  </div>
                  <div className="col-1">
                    <p>Kategorie</p>
                    <br />
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.productsAll.length > 0
                ? this.state.productsAll.map(item => {
                    return (
                      <li
                        onClick={this.selectProduct(
                          this.props.idx,
                          item.nr,
                          item.dsc,
                          item.unit,
                          item.calcPrice
                        )}
                        key={item.id}
                      >
                        <div className="row">
                          <div className="col-1">
                            <Highlight
                              id="font-normal"
                              search={this.state.search}
                            >
                              {item.nr}
                            </Highlight>
                          </div>
                          <div className="col-8">
                            <Highlight
                              id="font-normal"
                              search={this.state.search}
                            >
                              {item.dsc}
                            </Highlight>{" "}
                          </div>
                          <div className="col-1">{item.unit}</div>
                          <div className="col-1 text-right">
                            {this.convertPrice(item.calcPrice)}
                          </div>
                          <div className="col-1">
                            <Highlight
                              id="font-normal"
                              search={this.state.search}
                            >
                              {item.category}
                            </Highlight>
                          </div>
                        </div>
                      </li>
                    );
                  })
                : ""}
            </ul>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
export default OfferCreateSearch;
