import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";
import CSVReader from "react-csv-reader";
import BaseUrl from "../config/config.js";
import ModalGeo from "./ModalGeo.js";
var $ = require("jquery");

class HoursReportingFormInputTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      name: this.props.name,
      lastName: this.props.lastName,
      firstName: this.props.firstName,
      year: new Date().getFullYear(),
      yearsPreNext: [],
      month: this.props.month,
      hours: [
        {
          date: "",
          payed: "",
          beginning: "",
          end: "",
          pause: "",
          fahrtArbeit: "",
          bv: "",
          bvId: "",
          result: "",
          locLogin: "",
          locLogout: "",
        },
      ],
      errorSearch: false,
      errorInput: false,
      btnAddItem: true,
      isDisabled: true,
      employeesNames: [],
      bvAll: [],
      monthNames: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
      minDate: "",
      maxDate: "",
      showGeoLoc: false,
      locLogin: "",
      locLogout: "",
    };
  }
  componentDidMount = () => {
    this.readEmployeesNames();
    this.loadYears();

    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true,
      });
    }
  };
  loadYears = () => {
    this.state.yearsPreNext = [
      new Date().getFullYear() - 1,
      new Date().getFullYear(),
      new Date().getFullYear() + 1,
    ];
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errorInput: false,
    });
    if (
      (e.target.name === "name" && e.target.value === "-") ||
      (e.target.name === "month" && e.target.value === "-")
    ) {
      this.setState({
        isDisabled: true,
      });
    }
    if (e.target.name === "year") {
      this.setState({
        year: e.target.value,
      });
      this.minMaxDate(this.state.month, e.target.value);
    }
    if (
      e.target.name === "name" &&
      e.target.value !== "-" &&
      this.state.month === "-"
    ) {
      this.setState({
        isDisabled: true,
      });
    }
    if (
      e.target.name === "name" &&
      e.target.value !== "-" &&
      this.state.month !== "-"
    ) {
      this.setState({
        isDisabled: false,
      });
    }
    if (
      e.target.name === "month" &&
      e.target.value !== "-" &&
      this.state.name !== "-"
    ) {
      this.minMaxDate(e.target.value, this.state.year);

      this.setState({
        isDisabled: false,
      });
    }
    if (
      e.target.name === "month" &&
      e.target.value !== "-" &&
      this.state.name === undefined
    ) {
      this.setState({
        isDisabled: true,
      });
    }
    if (e.target.name === "payed" && e.target.value !== "-") {
      this.state.hours.map((item, i) => {
        return this.setState({});
      });
    }
  };
  minMaxDate = (value, year) => {
    this.state.monthNames.map((i, idx) => {
      if (i === value) {
        let helpMax;
        let helpMin;
        if (
          idx === 0 ||
          idx === 2 ||
          idx === 4 ||
          idx === 6 ||
          idx === 7 ||
          idx === 9 ||
          idx === 11
        ) {
          helpMin = year + "-" + String(idx + 1).padStart(2, 0) + "-01";
          helpMax = year + "-" + String(idx + 1).padStart(2, 0) + "-31";
          return this.setState({
            maxDate: helpMax,
            minDate: helpMin,
          });
        }

        if (idx === 3 || idx === 5 || idx === 8 || idx === 10) {
          helpMin = year + "-" + String(idx + 1).padStart(2, 0) + "-01";
          helpMax = year + "-" + String(idx + 1).padStart(2, 0) + "-30";
          return this.setState({
            maxDate: helpMax,
            minDate: helpMin,
          });
        }
        if (idx === 1) {
          helpMin = year + "-" + String(idx + 1).padStart(2, 0) + "-01";
          helpMax = year + "-" + String(idx + 1).padStart(2, 0) + "-29";
          return this.setState({
            maxDate: helpMax,
            minDate: helpMin,
          });
        }
      }
      return this;
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let hoursReporting;

    var check = this.state.hours.map((item, i) => {
      if (
        item.date === "" ||
        item.beginning === "" ||
        item.end === "" ||
        item.result === ""
      ) {
        this.setState({
          showMissed: true,
        });
        return false;
      } else {
        this.setState({
          showMissed: false,
        });
        return true;
      }
    });
    if (check[0] === true) {
      this.setState({
        showMissed: false,
      });
      const wait = this.state.employeesNames.map((name) => {
        if (name.lastName + " " + name.firstName === this.state.name) {
          this.setState({
            firstName: name.firstName,
            lastName: name.lastName,
          });
        }
        return this;
      });
      Promise.all(wait).then((values) => {
        this.state.hours.map(
          (items, idx) => (
            (hoursReporting = {
              lastName: this.state.lastName,
              firstName: this.state.firstName,
              year: this.state.year,
              month: this.state.month,
              date: items.date,
              payed: items.payed,
              beginning: items.beginning,
              end: items.end,
              pause: items.pause,
              driveTime: "00:00",
              fahrtArbeit: items.fahrtArbeit,
              bvId: items.bvId,
              result: items.result,
            }),
            this.saveHoursReporting(hoursReporting)
          )
        );
      });
    }
  };

  saveHoursReporting = (hoursReporting) => {
    this.setState({
      errorInput: false,
    });

    $.ajax({
      url: BaseUrl + "api/hoursreporting/createHoursReporting.php",
      type: "POST",
      dataType: "json",
      data: JSON.stringify(hoursReporting),
      success: function (response) {
        this.setState({
          name: "-",
          hours: [
            {
              date: "TT.MM.JJJJ",
              payed: "-",
              beginning: "00:00",
              end: "00:00",
              pause: "00:00",
              fahrtArbeit: "-",
              bvId: "",
              bv: "",
              result: "",
              locLogin: "",
              locLogout:""
            },
          ],
          isDisabled: true,
        });
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };

  handleAddHoursReporting = () => {
    this.setState({
      hours: this.state.hours.concat([
        {
          date: "",
          payed: "",
          beginning: "",
          end: "",
          pause: "",
          fahrtArbeit: "",
          bvId: "",
          bv: "",
          result: "",
          locLogin: "",
          locLogout: "",
        },
      ]),
    });
  };

  handleRemoveDeliveryItem = (idx) => () => {
    this.setState({
      hours: this.state.hours.filter((s, sidx) => idx !== sidx),
    });
  };
  handleChangeDate = (idx) => (evt) => {
    const newDateChange = this.state.hours.map((item, sidx) => {
      if (idx !== sidx) return item;
      return { ...item, [evt.target.name]: evt.target.value };
    });

    this.setState({ hours: newDateChange });
    if (evt.target.name === "bv") {
      const newDateChange = this.state.hours.map((item, sidx) => {
        if (idx !== sidx) return item;
        return { ...item, [evt.target.name]: evt.target.value };
      });
      this.setState({ hours: newDateChange });
      Promise.all([this.readBvIdByName(evt.target.value, idx)]).then(
        (values) => {
          this.calc(evt, idx);
        }
      );
    }
    if (evt.target.name === "bv" && evt.target.value === "-") {
      this.state.hours[idx].bvId = evt.target.value;
      this.state.hours[idx].bv = evt.target.value;
      this.setState({
        hours: this.state.hours,
      });
    }
  };

  handlePayed = (idx) => (evt) => {
    const newDateChange = this.state.hours.map((item, sidx) => {
      if (idx !== sidx) return item;

      if (
        evt.target.value === "Krank" ||
        evt.target.value === "Urlaub" ||
        evt.target.value === "Feiertag" ||
        evt.target.value === "Kurzarbeit" ||
        evt.target.value === "Unbezahlt"
      ) {
        item.beginning = "00:00";
        item.end = "00:00";
        item.pause = "00:00";
        item.fahrtArbeit = "-";
        item.bvId = "-";
        item.bv = "-";
        item.result = "00:00";
        item.locLogin = "";
        item.locLogout = "";
        return { ...item, [evt.target.name]: evt.target.value };
      } else {
        item.beginning = "";
        item.end = "";
        item.pause = "";
        item.fahrtArbeit = "";
        item.bvId = "";
        item.bv = "";
        item.result = "";
        item.locLogin = "";
        item.locLogout = "";
        return { ...item, [evt.target.name]: evt.target.value };
      }
    });
    this.setState({ hours: newDateChange });
  };

  calcResult = (idx) => (e) => {
    if (e.keyCode === 9) {
      this.calc(e, idx);
    }
  };
  calc = (evt, idx) => {
    this.calcTime(
      idx,
      this.state.hours[idx].beginning,
      this.state.hours[idx].end,
      "00:00",
      this.state.hours[idx].pause,
      "00:00"
    );
  };

  calcTime = (idx, start, end, zero, pause, fahrt) => {
    if (this.state.hours[idx].pause === "") {
      pause = "00:00";
      this.state.hours[idx].pause = "00:00";
    }

    if (pause && fahrt) {
      var start = start.split(":");
      var end = end.split(":");
      var zero = zero.split(":");
      var pause = pause.split(":");
      var fahrt = fahrt.split(":");

      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      var zeroDate = new Date(0, 0, 0, zero[0], zero[1], 0);
      var pauseDate = new Date(0, 0, 0, pause[0], pause[1], 0);
      var fahrtDate = new Date(0, 0, 0, fahrt[0], fahrt[1], 0);

      var diff =
        endDate.getTime() -
        startDate.getTime() -
        (pauseDate.getTime() - zeroDate.getTime()) -
        (fahrtDate.getTime() - zeroDate.getTime());
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);
      const result =
        (hours < 10 ? "0" : "") +
        hours +
        ":" +
        (minutes < 10 ? "0" : "") +
        minutes;

      this.state.hours[idx].result = result;

      this.setState({
        hours: this.state.hours,
      });
    }
  };

  readEmployeesNames = () => {
    return fetch(BaseUrl + "api/hoursreporting/readEmployeesNames.php")
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.records.map((item) => {
          return this.setState({
            employeesNames: responseJson.records,
          });
        });
      })
      .catch((error) => {});
  };

  convertDate = (date) => {
    date = date.replace(" ", "");

    if (date.includes(".")) {
      var dateNew = date.split(".");
      dateNew = dateNew[2] + "-" + dateNew[1] + "-" + dateNew[0];
      return dateNew;
    } else {
      return date;
    }
  };
  checkPause = (pause) => {
    if (pause.length === 4) {
      return pause.replace(" ", "0");
    } else {
      return pause.replace(" ", "");
    }
  };
  replaceUmlaute = (bv) => {
    var chars = {
      "aÃ„": "Ä",
      "Ã¤": "ä",
      "Ã–": "Ö",
      "Ã¶": "ö",
      Ãœ: "Ü",
      "Ã¼": "ü",
      ÃŸ: "ß",
      "â€“": "-",
    };
    var bvChanged = bv.replace(/aÃ„|Ã¤|Ã–|Ã¶|Ãœ|Ã¼|ÃŸ|â€“/g, (m) => chars[m]);
    return bvChanged;
  };
  checkBvIdInput = (bv) => {
    var chars = {
      "aÃ„": "Ä",
      "Ã¤": "ä",
      "Ã–": "Ö",
      "Ã¶": "ö",
      Ãœ: "Ü",
      "Ã¼": "ü",
      ÃŸ: "ß",
      "â€“": "-",
    };
    var bvChanged = bv.replace(/aÃ„|Ã¤|Ã–|Ã¶|Ãœ|Ã¼|ÃŸ|â€“/g, (m) => chars[m]);
    let found = this.props.bvAllInput.find((id) => id.bv === bvChanged);
    if (found === undefined) {
      return "";
    } else {
      return found.bvId;
    }
  };
  calcHours = (start, end, zero, pause, fahrt) => {
    var start = start === "-" ? "00:00" : start;
    start = start.split(":");
    var end = end === "-" ? "00:00" : end;
    end = end.split(":");
    var zero = zero.split(":");
    var pause = pause === "-" ? "00:00" : pause;
    pause = pause.split(":");
    var fahrt = fahrt === "-" ? "00:00" : fahrt;
    fahrt = fahrt.split(":");

    var startDate = new Date(0, 0, 0, start[0], start[1], 0);
    var endDate = new Date(0, 0, 0, end[0], end[1], 0);
    var zeroDate = new Date(0, 0, 0, zero[0], zero[1], 0);
    var pauseDate = new Date(0, 0, 0, pause[0], pause[1], 0);
    var fahrtDate = new Date(0, 0, 0, fahrt[0], fahrt[1], 0);

    var diff =
      endDate.getTime() -
      startDate.getTime() -
      (pauseDate.getTime() - zeroDate.getTime()) -
      (fahrtDate.getTime() - zeroDate.getTime());
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);
    const result =
      (hours < 10 ? "0" : "") +
      hours +
      ":" +
      (minutes < 10 ? "0" : "") +
      minutes;
    return result;
  };

  handleUpload = (data) => {
    this.setState({
      hours: [],
    });
    let helpArray = [];
    data.map((item, i) => {
      if (i > 0 && i + 1 < data.length) {
        helpArray = helpArray.concat([
          {
            date: this.convertDate(item[1]),
            beginning: item[2].trim() == "-" ? "00:00" : item[2].trim(),
            end: item[3].trim() == "-" ? "00:00" : item[3].trim(),
            pause:
              (this.checkPause(item[4]) == "-") | (item[4] === " ")
                ? "00:00"
                : item[4].trim(),
            payed: item[5].trim() === "Keine" ? "-" : item[5].trim(),
            fahrtArbeit:
              item[6].trim() === "firmen-Kfz" || item[8] === "Fahrtzeit" ? "Fahrtzeit" : "Arbeitszeit",
            bvId: this.checkBvIdInput(item[7].trim()),
            bv: this.replaceUmlaute(item[7].trim()),
            result: this.calcHours(
              item[2].trim(),
              item[3].trim(),
              "00:00",
              (this.checkPause(item[4]) == "-") | (item[4] === " ")
                ? "00:00"
                : item[4].trim(),
              "00:00"
            ),
            locLogin:
              item[9] === undefined || item[9].trim() === "notAllowed" || item[9] === 'NULL'
                ? ""
                : item[9].trim(),
            locLogout:
              item[10] === undefined || item[10].trim() === "notAllowed" || item[10] === 'NULL'
                ? ""
                : item[10].trim(),
          },
        ]);
      }

      return this;
    });
    helpArray.sort(function (a, b) {
      if (a.date === b.date) {
        return a.beginning > b.beginning ? 1 : -1;
      }
      return a.date > b.date ? 1 : -1;
    });
    this.combineSameValues(helpArray);
    this.setState({
      hours: helpArray,
    });
  };
 combineSameValues = (arr) => {
   let helpDate = [];
 


  // Use the reduce method to combine objects with the same dates
  const combinedArr = arr.reduce((acc, current) => {
    const existingItem = acc.find(item => item.date === current.date);

    if (existingItem) {
      // If the date already exists in the accumulator array, combine the hours
      existingItem.hours += this.timeStringToMinutes(current.result);
    } else {
      // If the date doesn't exist in the accumulator array, add it as a new element
      acc.push({ date: current.date, hours: this.timeStringToMinutes(current.result) });
    }

    return acc;
  }, []);

  let help =  combinedArr.map(item => ({
    ...item,
    hours: this.minutesToTimeString(item.hours),
  }));
  console.log(help);
 
}
timeStringToMinutes = (timeString) => {
  const [hours, minutes] = timeString.split(':').map(parseFloat);
  return hours * 60 + minutes;
}

minutesToTimeString = (totalMinutes) => {
   const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

  autocompleteBv = (idx) => (e) => {
    if (e.keyCode === 9) {
      Promise.all([this.readBvNameById(e.target.value, idx, true)]).then(
        (values) => {

          this.calc(e, idx);
        }
      );
    }
  };
  readBvNameById = (id, idx, bool) => {
    let found = this.props.bvAllInput.find((name) => parseInt(name.bvId) === parseInt(id));
    if (found === undefined) {
      this.props.readOldBvById(id);
      setTimeout(() => {
        const newDateChange = this.state.hours.map((item, sidx) => {
          if (idx !== sidx) return item;
          return { ...item, bvId: id, bv: this.props.bvAllFound };
        });

        this.setState({ hours: newDateChange });
      }, 200);
    }
    if (bool === true && found !== undefined) {
      const newDateChange = this.state.hours.map((item, sidx) => {
        if (idx !== sidx) return item;
        return { ...item, bvId: id, bv: found.bv };
      });

      this.setState({ hours: newDateChange });
    } else if (bool === false && found !== undefined) {
      return found.bv;
    }
  };

  readBvIdByName = (name, idx) => {
    let found = this.props.bvAllInput.find((id) => id.bv === name);
    if (found === undefined) {
      return "";
    } else {
      const newDateChange = this.state.hours.map((item, sidx) => {
        if (idx !== sidx) return item;
        this.state.hours[idx].bv = name;
        return { ...item, bvId: found.bvId };
      });

      this.setState({ hours: newDateChange });
    }
  };

  toggleGeoModal = (login, logout) => (e) => {
    e.preventDefault();
    if (login === "toggle") {
      this.setState({
        showGeoLoc: !this.state.showGeoLoc,
      });
    } else if (login === undefined && logout === undefined) {
      this.setState({
        showGeoLoc: false,
      });
    } else {
      this.setState({
        showGeoLoc: !this.state.showGeoLoc,
        locLogin: login,
        locLogout: logout,
      });
    }
  };
/*   checkBVisAvailable = (bvId, show) => {
     let found = this.props.bvAllInput.find((id) => id.bvId === bvId);
     console.log(found)
    if (show) {
      if (bvId !== "" && (found === undefined || found.length !== 0)) {
        return true;
      } else {
        return false;
      }
    }
  }; */
  render() {
    return (
      <React.Fragment>
        <section id="insertHoursReporting" className="no-print">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home/stundenbericht">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon
                  color="var(--greenSeyband)"
                  icon="sign-in-alt"
                />{" "}
                Stundenbericht
              </h1>
            </div>
          </div>
          <h4>Stundenbericht Hinzufügen</h4>
          <form className="hoursreporting" onSubmit={this.handleSubmit}>
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="row">
                <div className="col-3 offset-3">
                  <select
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                  >
                    <option key="-1" value="-">
                      Name
                    </option>
                    {this.state.employeesNames.map((names, i) => (
                      <option key={i} value={names.name}>
                        {names.lastName} {names.firstName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-1">
                  <select
                    name="year"
                    value={this.state.year}
                    onChange={this.handleChange}
                  >
                    {this.state.yearsPreNext.map((year, i) => (
                      <option key={i} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-2">
                  <select
                    name="month"
                    value={this.state.month}
                    onChange={this.handleChange}
                  >
                    <option key="-1" value="-">
                      -
                    </option>
                    {this.state.monthNames.map((month, i) => (
                      <option key={i} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row hoursreporting-errorSearch">
                <p
                  className={
                    this.state.showMissed ? "show errorSearch" : "hide"
                  }
                >
                  Bitte Datum, Beginn, Ende, Baustelle und Ergebnis ausfüllen
                  eingeben
                </p>
              </div>
              <hr />
              {this.state.hours.map((items, idx) => (
                <div key={idx} className={`row hoursreporting span-row-${idx}`}>
                  <div className="col max-150">
                    <label>
                      <span>Datum:</span>
                      <input
                        max={this.state.maxDate}
                        min={this.state.minDate}
                        disabled={this.state.isDisabled}
                        type="date"
                        name="date"
                        placeholder={`Datum #${idx + 1}`}
                        onChange={this.handleChangeDate(idx)}
                        value={items.date}
                      />
                    </label>
                  </div>
                  <div className="col-1 tab tab-select">
                    <label>
                      <span>Bezahlte h:</span>

                      <select
                        name="payed"
                        value={items.payed}
                        disabled={this.state.isDisabled}
                        onChange={this.handlePayed(idx)}
                      >
                        <option key="-1" value="-">
                          -
                        </option>
                        <option key={idx + 1} value="Krank">
                          Krank
                        </option>
                        <option key={idx + 2} value="Urlaub">
                          Urlaub
                        </option>
                        <option key={idx + 3} value="Feiertag">
                          Feiertag
                        </option>
                        <option key={idx + 4} value="Kurzarbeit">
                          Kurzarbeit
                        </option>
                        <option key={idx + 5} value="Unbezahlt">
                          Unbezahlt
                        </option>
                      </select>
                    </label>
                  </div>
                  <div className="col-1  max-85">
                    <label>
                      <span>Beginn:</span>
                      <input
                        disabled={this.state.isDisabled}
                        type="time"
                        name="beginning"
                        placeholder={`Beginn. #${idx + 1}`}
                        onChange={this.handleChangeDate(idx)}
                        value={items.beginning}
                      />
                    </label>
                  </div>
                  <div className="col-1  max-85">
                    <label>
                      <span>Ende:</span>
                      <input
                        disabled={this.state.isDisabled}
                        type="time"
                        name="end"
                        placeholder={`Ende #${idx + 1}`}
                        onChange={this.handleChangeDate(idx)}
                        value={items.end}
                      />
                    </label>
                  </div>
                  <div className="col-1 max-85">
                    <label>
                      <span>Pause:</span>
                      <input
                        disabled={this.state.isDisabled}
                        type="time"
                        name="pause"
                        placeholder={`Pause #${idx + 1}`}
                        onChange={this.handleChangeDate(idx)}
                        value={items.pause}
                      />
                    </label>
                  </div>
                  <div className="col-1">
                    <label>
                      <span>Fahrt-/Arbeit</span>

                      <select
                        name="fahrtArbeit"
                        value={items.fahrtArbeit}
                        disabled={this.state.isDisabled}
                        onChange={this.handleChangeDate(idx)}
                      >
                        <option key="-1" value="-">
                          -
                        </option>
                        <option key={idx + 1} value="Arbeitszeit">
                          Arbeitszeit
                        </option>
                        <option key={idx + 2} value="Fahrtzeit">
                          Fahrtzeit
                        </option>
                      </select>
                    </label>
                  </div>
                  <div className="col-1 tab">
                    <label>
                      <span>Baustelle Id:</span>
                      <input
                        disabled={this.state.isDisabled}
                        type="text"
                        name="bvId"
                        onChange={this.handleChangeDate(idx)}
                        value={items.bvId}
                        onKeyDown={this.autocompleteBv(idx)}
                      />
                      <span id="span-icon">↹</span>
                    </label>
                  </div>
                  <div className="col tab tab-select">
                    <label>
                      <span>Baustelle:</span>

                      <select
                        name="bv"
                        value={items.bv}
                        disabled={this.state.isDisabled}
                        onChange={this.handleChangeDate(idx)}
                        onKeyDown={this.calcResult(idx)}
                      >
                        <option key="-1" value="-">
                          -
                        </option>
 

                        {this.props.bvAllInput.map((bv, i) => (
                          <option key={i} value={bv.bv}>
                            {bv.bv}
                          </option>
                        ))}
                      </select>
                      <span id="span-icon">↹</span>
                    </label>
 
                  </div>
                  <div className="col-1">
                    <label>
                      <span>Ergebnis:</span>
                      <input
                        disabled={this.state.isDisabled}
                        type="text"
                        name="result"
                        placeholder={`Ergebnis #${idx + 1}`}
                        onChange={this.handleChangeDate(idx)}
                        value={items.result}
                      />
                    </label>
                  </div>
                  <div className="col max-60">
                    <label>
                      <span>&nbsp;</span>
                      <button
                        disabled={
                          this.state.isDisabled ||
                          (items.locLogin.length === 0 &&
                            items.locLogout.length === 0)
                        }
                        onClick={this.toggleGeoModal(
                          items.locLogin,
                          items.locLogout
                        )}
                        type="button"
                        className="btn-geo"
                      >
                        <FontAwesomeIcon icon={["fas", "map-marker-alt"]} />
                      </button>
                    </label>
                  </div>
                  <div className="col max-60">
                    <label>
                      <span>&nbsp;</span>
                      <button
                        disabled={this.state.isDisabled}
                        type="button"
                        onClick={this.handleRemoveDeliveryItem(idx)}
                        className="btn-remove"
                      >
                        -
                      </button>
                    </label>
                  </div>
                </div>
              ))}
              <div className="hoursreporting row">
                <div className="col-11" />
                <div className="col-1">
                  <button
                    disabled={this.state.isDisabled}
                    type="button"
                    onClick={this.handleAddHoursReporting}
                    className="btn-add-item"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="item-btn col-2">
                  <FontAwesomeIcon
                    id="upload-icon"
                    color="var(--white)"
                    icon="upload"
                  />
                  <CSVReader
                    parserOptions={{ encoding: `WINDOWS-1252` }}
                    fileEncoding="WINDOWS-1252"
                    cssClass="csv-input"
                    onFileLoaded={this.handleUpload}
                    inputId="upload"
                  />
                </div>
                <div className="item-btn col-10">
                  <button
                    disabled={this.state.isDisabled}
                    className="btn-create-item fullwidth"
                  >
                    Stundenbericht speichern <FontAwesomeIcon icon="save" />
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
          <p className={this.state.errorInput ? "show errorInput" : "hide"}>
            Bitte Bezeichnung ausfüllen
          </p>
          {this.state.showGeoLoc ? (
            <ModalGeo
              locLogin={this.state.locLogin}
              locLogout={this.state.locLogout}
              showGeoLoc={this.state.showGeoLoc}
              toggleGeoModal={this.toggleGeoModal("toggle", "")}
              geo={""}
            />
          ) : (
            ""
          )}
        </section>
      </React.Fragment>
    );
  }
}

export default HoursReportingFormInputTop;
