import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-regular";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Modal, ModalBody } from "reactstrap";
import BaseUrl from "../config/config.js";
var $ = require("jquery");

class CalcDataModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalDelete: false,
      backdrop: true,
      id: this.props.id,
      jahr: this.props.jahr,
      arbeitstage: this.props.arbeitstage,
      stunden_tag: this.props.stunden_tag,
      stunden_jahr: this.props.stunden_jahr,
      rentenvers: this.props.rentenvers,
      arbeitslosenvers: this.props.arbeitslosenvers,
      krankenvers: this.props.krankenvers,
      pflegevers: this.props.pflegevers,
      insolvenzgeldumlage: this.props.insolvenzgeldumlage,
      umlage_erstattung: this.props.umlage_erstattung,
      krankheitstage: this.props.krankheitstage,
      krankheitstageProzent: this.props.krankheitstageProzent,
      bezFeiertage: this.props.bezFeiertage,
      bgHauptumlage: this.props.bgHauptumlage,
      bgLastenverteilung: this.props.bgLastenverteilung,
      soka: this.props.soka,
      kosten_h: this.props.kosten_h,
      gk: this.props.gk,
      sk_h: this.props.sk_h,
      gewinn: this.props.gewinn,
      angebotspreis: this.props.angebotspreis,
      showUpdate: true,
      showDelete: false,
      hideItem: false,
      namesWageAll: [],
      disabledFieldset: false
    };
  }
  componentDidMount = () => {
    this.loadNameHourlyWage();

         if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
          this.setState({
            disabledFieldset: true
          });
        }
   };
  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    if(sessionStorage.getItem("userData").indexOf("admin") === -1){
       this.setState({
       modal:false
     })

   }
   else{
     this.setState({
       modal: !this.state.modal
     });
   }
  };
  toggleDelete = () => {
    if (!this.state.showUpdate) {
      this.setState({
        showUpdate: true,
        showDelete: false
      });
    } else {
      this.setState({
        showUpdate: false,
        showDelete: true
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value.replace(/,/g, ".")
    });
  };
  updateItem = (e, boolean) => {
    let updateItem = {
      id: this.state.id,
      jahr: this.state.jahr,
      arbeitstage: this.state.arbeitstage,
      stunden_tag: this.state.stunden_tag.replace(/,/g, "."),
      stunden_jahr: this.state.stunden_jahr.replace(/,/g, "."),
      rentenvers: this.state.rentenvers.replace(/,/g, "."),
      arbeitslosenvers: this.state.arbeitslosenvers.replace(/,/g, "."),
      krankenvers: this.state.krankenvers.replace(/,/g, "."),
      pflegevers: this.state.pflegevers.replace(/,/g, "."),
      insolvenzgeldumlage: this.state.insolvenzgeldumlage.replace(/,/g, "."),
      umlage_erstattung: this.state.umlage_erstattung.replace(/,/g, "."),
      krankheitstage: this.state.krankheitstage.replace(/,/g, "."),
      krankheitstageProzent: this.state.krankheitstageProzent.replace(
        /,/g,
        "."
      ),
      bezFeiertage: this.state.bezFeiertage.replace(/,/g, "."),
      bgHauptumlage: this.state.bgHauptumlage.replace(/,/g, "."),
      bgLastenverteilung: this.state.bgLastenverteilung.replace(/,/g, "."),
      soka: this.state.soka.replace(/,/g, "."),
      gk: this.state.gk.replace(/,/g, "."),
      kosten_h: this.state.kosten_h.replace(/,/g, "."),
      sk_h: this.state.sk_h.replace(/,/g, "."),
      angebotspreis: this.state.angebotspreis.replace(/,/g, "."),
      gewinn: this.state.gewinn.replace(/,/g, ".")
    };

    // submit form data to api
    $.ajax({
      url: BaseUrl + "api/calcHourlyWage/updateCalcData.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(updateItem),
      success: function(response) {
        if (boolean === false) {
        } else {
          this.setState({
            modal: !this.state.modal
          });
          this.handleClickAll();
        }
      }.bind(this),
      error: function(xhr, resp, text) {
        // show error to console
      }
    });

    e.preventDefault();
  };

  removeItem = () => {
    let deleteItem = {
      id: this.state.id
    };

    $.ajax({
      url: BaseUrl + "api/calcHourlyWage/deleteCalcData.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteItem),
      success: function(response) {
        this.setState({
          modal: !this.state.modal,
          hideItem: true
        });
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };

  loadNameHourlyWage = () => {
    return fetch(BaseUrl + "api/calcHourlyWage/readNameWage.php")
      .then(response => response.json())
      .then(responseJson => {
        responseJson.records.map(item => {
          return this.setState({
            namesWageAll: responseJson.records
          });
        });
      })
      .catch(error => {});
  };
  findHourlyWage = name => {
    const result = this.state.namesWageAll.find(
      hourlyWage => hourlyWage.lastName === name
    );
    return result;
  };
  calcEverything = name => {
    const getWage = this.findHourlyWage(name);
      this.setState({
      stundenlohn : getWage.hourlyWage,
      lastName:  getWage.lastName + " " + getWage.firstName
    })

    let bruttolohn = (
      this.state.stundenlohn *
      this.state.arbeitstage *
      this.state.stunden_tag
    ).toFixed(2);
    let rente = (bruttolohn * this.state.rentenvers / 100 / 2).toFixed(2);
    let arbeitslosen = (
      bruttolohn *
      this.state.arbeitslosenvers /
      100 /
      2
    ).toFixed(2);
    let kranken = (bruttolohn * this.state.krankenvers / 100 / 2).toFixed(2);
    let pflege = (bruttolohn * this.state.pflegevers / 100 / 2).toFixed(2);
    let insolvenz = (
      bruttolohn *
      this.state.insolvenzgeldumlage /
      100 /
      2
    ).toFixed(2);
    let umlage = (bruttolohn * this.state.umlage_erstattung / 100 / 2).toFixed(
      2
    );

    let lohnkosten = (
      parseFloat(bruttolohn) +
      parseFloat(rente) +
      parseFloat(arbeitslosen) +
      parseFloat(kranken) +
      parseFloat(pflege) +
      parseFloat(insolvenz) +
      parseFloat(umlage)
    ).toFixed(2);
    let kranktage = (
      this.state.krankheitstage *
      8 *
      (lohnkosten / (37 * 52)) *
      (this.state.krankheitstageProzent / 100)
    ).toFixed(2);
    let feiertage = (
      lohnkosten /
      this.state.arbeitstage *
      this.state.bezFeiertage
    ).toFixed(2);
    let jahreslohn_stunde = (
      parseFloat(lohnkosten) +
      parseFloat(kranktage) +
      parseFloat(feiertage)
    ).toFixed(2);

    let bgHaupt = (jahreslohn_stunde * this.state.bgHauptumlage / 100).toFixed(
      2
    );
    let bgLast = (
      jahreslohn_stunde *
      this.state.bgLastenverteilung /
      100
    ).toFixed(2);
    let sozka = (jahreslohn_stunde * this.state.soka / 100).toFixed(2);
    let kostenJahr = (
      parseFloat(jahreslohn_stunde) +
      parseFloat(bgHaupt) +
      parseFloat(bgLast) +
      parseFloat(sozka)
    ).toFixed(2);

    let kostenStunde = parseFloat(kostenJahr / this.state.stunden_jahr).toFixed(
      2
    );
    let gemeink = parseFloat(
      this.state.gk / (this.state.namesWageAll.length * this.state.stunden_jahr)
    ).toFixed(2);
    let skStunde = parseFloat(
      parseFloat(kostenStunde) + parseFloat(gemeink)
    ).toFixed(2);
    let gew = parseFloat(skStunde * this.state.gewinn / 100).toFixed(2);
    let angebot = parseFloat(parseFloat(skStunde) + parseFloat(gew)).toFixed(2);
    const help = {
      jahr: this.state.jahr,
      name: this.state.lastName,
      arbeitstage: this.state.arbeitstage,
      stunden_tag: this.state.stunden_tag,
      stunden_jahr: this.state.stunden_jahr,
      stundenlohn: this.state.stundenlohn,
      lohnBrutto: bruttolohn,
      rentenvers: rente,
      arbeitslosenvers: arbeitslosen,
      krankenvers: kranken,
      pflegevers: pflege,
      insolvenzgeldumlage: insolvenz,
      umlage_erstattung: umlage,
      lohnInklNebenkosten: lohnkosten,
      krankheitstage: kranktage,
      bezFeiertage: feiertage,
      jahresstundenlohn: jahreslohn_stunde,
      bgHauptumlage: bgHaupt,
      bgLastenverteilung: bgLast,
      soka: sozka,
      kosten_jahr: kostenJahr,
      kosten_h: kostenStunde,
      gk: gemeink,
      sk_h: skStunde,
      gewinn: gew,
      angebotspreis: angebot
    };

    this.updateCalcAll(help);
  };
  updateCalcAll = (help) => {

    $.ajax({
      url: BaseUrl + "api/calcHourlyWage/updateCalcEmployee.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(help),
      success: function(response) {
        this.setState({
          modal: !this.state.modal
        });
        this.calcCost();

      }.bind(this),
      error: function(xhr, resp, text) {
        // show error to console
      }
    });



  };
  handleClickAll = () => {
    const wait = this.state.namesWageAll.map(names => {
      return this.calcEverything(names.lastName);
      });
    Promise.all(wait).then(values => {
      });
  };
  calcCost = e => {
    this.setState({
      modal: false
    });

    return fetch(
      BaseUrl + "api/calcHourlyWage/readDataForCalcCost.php?eingabe=" +
        this.state.jahr
    )
      .then(response => response.json())
      .then(responseJson => {
        const wait = responseJson.records.map(item => {
          return this.setState({
            kosten_h: parseFloat(responseJson.records[0].avg_cost_h).toFixed(2),
            sk_h: parseFloat(responseJson.records[0].avg_sk_h).toFixed(2),
            angebotspreis: parseFloat(
              responseJson.records[0].avg_angebotspreis
            ).toFixed(2)
          });
        });
        Promise.all(wait).then(values => {
          this.updateItem(null, false);
        });
      })
      .catch(error => {});
  };

  render() {
    return (
      <React.Fragment>
        <li className={this.state.hideItem ? "hide " : " "} key={this.state.id}>
          <div className="row list-header">
            <p onClick={this.toggle} className="col-md-10">
              <span>Jahr:</span>
              <br />
              {this.state.jahr}
            </p>
            <button
            disabled={this.state.disabledFieldset}
              onClick={this.calcCost}
              className="col-md-2 btn-darkred"
            >
              Kosten Kalkulieren <FontAwesomeIcon icon="calculator" />
            </button>
          </div>
          <div onClick={this.toggle}>
            <div className="row fullwidth showSpan">
              <p className="col">
                <span>Arbeitstage</span>
                <br />
                {this.state.arbeitstage}
              </p>
              <p className="col">
                <span>Stunden pro Tag</span>
                <br />
                {this.state.stunden_tag} h
              </p>
              <p className="col">
                <span>Stunden pro Jahr</span>
                <br />
                {this.state.stunden_jahr} h
              </p>
              <p className="col">
                <span>Rentenversicherung</span>
                <br />
                {this.state.rentenvers} %
              </p>
              <p className="col">
                <span>Arbeitslosenversicherung</span>
                <br />
                {this.state.arbeitslosenvers} %
              </p>
              <p className="col">
                <span>Krankenversicherung</span>
                <br />
                {this.state.krankenvers} %
              </p>
              <p className="col">
                <span>Pflegeversicherung</span>
                <br />
                {this.state.pflegevers} %
              </p>
              <p className="col">
                <span>Insolvenzgeldumlage</span>
                <br />
                {this.state.insolvenzgeldumlage} %
              </p>
              <p className="col">
                <span>Umlage- und Erstattungssätze</span>
                <br />
                {this.state.umlage_erstattung} %
              </p>
            </div>
            <div className="row fullwidth showSpan">
              <p className="col">
                <span>Krankheitstage</span>
                <br />
                {this.state.krankheitstage}
              </p>
              <p className="col">
                <span>krankeitstage %</span>
                <br />
                {this.state.krankheitstageProzent} %
              </p>
              <p className="col">
                <span>Bezahlte Feiertage</span>
                <br />
                {this.state.bezFeiertage}
              </p>
              <p className="col">
                <span>BG-Hauptumlage</span>
                <br />
                {this.state.bgHauptumlage} %
              </p>
              <p className="col">
                <span>BG-Lastenverteilung</span>
                <br />
                {this.state.bgLastenverteilung} %
              </p>
              <p className="col">
                <span>Sozialkasse</span>
                <br />
                {this.state.soka} %
              </p>
            </div>
            <div className="row fullwidth showSpan">
              <p className="col">
                <span>Kosten pro Stunde</span>
                <br />
                {this.state.kosten_h} €
              </p>
              <p className="col">
                <span>Gemeinkosten</span>
                <br />
                {this.state.gk} €
              </p>
              <p className="col">
                <span>Selbskosten pro Stunde</span>
                <br />
                {this.state.sk_h} €
              </p>
              <p className="col">
                <span>Gewinn</span>
                <br />
                {this.state.gewinn} %
              </p>
              <p className="col">
                <span>Angebotspreis</span>
                <br />
                {this.state.angebotspreis} €
              </p>
              <p className="col" />
            </div>
          </div>
        </li>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal-header">
            <p className={this.state.showUpdate ? "show" : "hide"}>
              Stammdaten Bearbeiten
            </p>
            <p className={this.state.showDelete ? "show" : "hide"}>
              Stammdaten wirklich Löschen?<br />
              <span>Dieser Vorgang kann nicht Rückgänging gemacht werden</span>
            </p>
            <button className="close" onClick={this.toggle}>
              {" "}
              <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
            </button>
          </div>
          <ModalBody>
            <div className={this.state.showUpdate ? "show" : "hide"}>
              <form>
                <div className="row calcData">
                  <label className="col-6">
                    <span>Berechnungsjahr:</span>
                    <input
                      type="text"
                      name="jahr"
                      placeholder="z.b. 2018"
                      onChange={this.handleChange}
                      value={this.state.jahr}
                    />
                  </label>
                  <label className="col-6">
                    <span>Arbeitstage:</span>
                    <input
                      type="text"
                      name="arbeitstage"
                      placeholder="z.B. 220"
                      onChange={this.handleChange}
                      value={this.state.arbeitstage}
                    />
                  </label>
                  <label className="col-6">
                    <span>Stunden pro Tag:</span>
                    <input
                      type="text"
                      name="stunden_tag"
                      placeholder="z.B. 7,5"
                      onChange={this.handleChange}
                      value={this.state.stunden_tag}
                    />
                  </label>
                  <label className="col-6">
                    <span>Stunden pro Jahr:</span>
                    <input
                      type="text"
                      name="stunden_jahr"
                      placeholder="z.B. 1650"
                      onChange={this.handleChange}
                      value={this.state.stunden_jahr}
                    />
                  </label>
                  <label className="col-6">
                    <span>Rentenversicherung:</span>
                    <input
                      type="text"
                      name="rentenvers"
                      placeholder="00,00 %"
                      onChange={this.handleChange}
                      value={this.state.rentenvers}
                    />
                  </label>
                  <label className="col-6">
                    <span>Arbeitslosenversicherung:</span>
                    <input
                      type="text"
                      name="arbeitslosenvers"
                      placeholder="00,00%"
                      onChange={this.handleChange}
                      value={this.state.arbeitslosenvers}
                    />
                  </label>
                  <label className="col-6">
                    <span>Krankenversicherung:</span>
                    <input
                      type="text"
                      name="krankenvers"
                      placeholder="00,00%"
                      onChange={this.handleChange}
                      value={this.state.krankenvers}
                    />
                  </label>
                  <label className="col-6">
                    <span>Pflegeversicherung:</span>
                    <input
                      type="text"
                      name="pflegevers"
                      placeholder="00,00%"
                      onChange={this.handleChange}
                      value={this.state.pflegevers}
                    />
                  </label>
                  <label className="col-6">
                    <span>Insolvenzgeldumlage:</span>
                    <input
                      type="text"
                      name="insolvenzgeldumlage"
                      placeholder="00,00%"
                      onChange={this.handleChange}
                      value={this.state.insolvenzgeldumlage}
                    />
                  </label>
                  <label className="col-6">
                    <span>Umlage- und Erstattungssätze:</span>
                    <input
                      type="text"
                      name="umlage_erstattung"
                      placeholder="00,00%"
                      onChange={this.handleChange}
                      value={this.state.umlage_erstattung}
                    />
                  </label>
                  <label className="col-6">
                    <span>Anzahl Krankheitstage:</span>
                    <input
                      type="text"
                      name="krankheitstage"
                      placeholder="z.B. 3"
                      onChange={this.handleChange}
                      value={this.state.krankheitstage}
                    />
                  </label>
                  <label className="col-6">
                    <span>Krankheitstage %:</span>
                    <input
                      type="text"
                      name="krankheitstageProzent"
                      placeholder="00,00%"
                      onChange={this.handleChange}
                      value={this.state.krankheitstageProzent}
                    />
                  </label>
                  <label className="col-6">
                    <span>bezFeiertage:</span>
                    <input
                      type="text"
                      name="bezFeiertage"
                      placeholder="00,00%"
                      onChange={this.handleChange}
                      value={this.state.bezFeiertage}
                    />
                  </label>
                  <label className="col-6">
                    <span>BG-Hauptumlage:</span>
                    <input
                      type="text"
                      name="bgHauptumlage"
                      placeholder="00,00%"
                      onChange={this.handleChange}
                      value={this.state.bgHauptumlage}
                    />
                  </label>
                  <label className="col-6">
                    <span>BG-Lastenverteilung:</span>
                    <input
                      type="text"
                      name="bgLastenverteilung"
                      placeholder="00,00%"
                      onChange={this.handleChange}
                      value={this.state.bgLastenverteilung}
                    />
                  </label>
                  <label className="col-6">
                    <span>Sozialkasse:</span>
                    <input
                      type="text"
                      name="soka"
                      placeholder="00,00%"
                      onChange={this.handleChange}
                      value={this.state.soka}
                    />
                  </label>
                  <label className="col-6">
                    <span>Kosten pro Stunde:</span>
                    <input
                      disabled
                      type="text"
                      name="kosten_h"
                      placeholder="00,00 €"
                      onChange={this.handleChange}
                      value={this.state.kosten_h}
                    />
                  </label>
                  <label className="col-6">
                    <span>Gemeinkosten:</span>
                    <input
                      type="text"
                      name="gk"
                      placeholder="0.000,00 €"
                      onChange={this.handleChange}
                      value={this.state.gk}
                    />
                  </label>
                  <label className="col-6">
                    <span>Selbskosten pro Stunde:</span>
                    <input
                      disabled
                      type="text"
                      name="sk_h"
                      placeholder="00,00%"
                      onChange={this.handleChange}
                      value={this.state.sk_h}
                    />
                  </label>
                  <label className="col-6">
                    <span>Gewinn:</span>
                    <input
                      type="text"
                      name="gewinn"
                      placeholder="00,00%"
                      onChange={this.handleChange}
                      value={this.state.gewinn}
                    />
                  </label>
                  <label className="col-6">
                    <span>Angebotspreis:</span>
                    <input
                      disabled
                      type="text"
                      name="angebotspreis"
                      placeholder="00,00 €"
                      onChange={this.handleChange}
                      value={this.state.angebotspreis}
                    />
                  </label>
                </div>
              </form>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Löschen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.updateItem} className="btn-darkred">
                    Ändern <FontAwesomeIcon icon={["far", "edit"]} />
                  </button>
                </div>
              </div>
            </div>
            <div className={this.state.showDelete ? "show" : "hide"}>
              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggleDelete} className="btn-red">
                    Nein <FontAwesomeIcon icon="ban" />
                  </button>
                </div>
                <div className="col">
                  <button onClick={this.removeItem} className="btn-green">
                    Ja <FontAwesomeIcon icon="check-circle" />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CalcDataModal;
